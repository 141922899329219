import React, { Fragment, useEffect, useState } from 'react';

export default function FlacsPicker (props) {

  const[flacsPicker, setFlacsPicker]=useState('')

  let flacsAlwaysOff =
  (
    //current selection is "Cataract vision"
    props.lensOption === 'Cataract vision'
    ||
    //or contraindications
    // props.ptContraFilterLength > 0
    // ||
    //or doctor doesn't offer flacs at all
    props.flacsOffer === 'No'
    ||
    //or doctor doesn't offer flacs for this lens
    props.lensFlacsRequire === "No (don't offer)"
  )
    ? true
    : false

    // console.log(flacsAlwaysOff)

  let flacsAlwaysOn =
  (
    //current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and physician requires FLACS for this lens (props.lensFlacsRequire === "Yes (always)")
    (
      (
        props.flacsOffer === 'Yes'
        &&
        props.lensFlacsRequire === "Yes (always)"
      )
      ||
      (
        props.flacsOffer === 'Yes'
        &&
        props.criOffer === 'Yes'
        &&
        props.lensFlacsRequire === "Yes (only with CRI)"
        &&
        props.cri === true
      )
    )
  )
    ? true
    : false

    // console.log(flacsAlwaysOn)

  let flacsAllowToggle =
  (
    //current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and FLACS is optional for this lens
    props.flacsOffer === 'Yes'
    &&
    (
      props.lensFlacsRequire === "Optional (always offered)"
      ||
      (
        props.lensFlacsRequire === "Optional (only offered with CRI)"
        &&
        props.cri === true
      )
    )
  )
    ? true
    : false

    // console.log(flacsAllowToggle)

    let flacsModifier = () => {
      if (flacsAlwaysOff === true){
        setFlacsPicker('alwaysOff')
      } else if (flacsAlwaysOff === false && flacsAlwaysOn === true){
        setFlacsPicker('alwaysOn')
      } else if (flacsAlwaysOff === false && flacsAlwaysOn === false && flacsAllowToggle === true){
        setFlacsPicker('allowToggle')
      } else if (flacsAlwaysOff === false && flacsAlwaysOn === false && flacsAllowToggle === false){
        setFlacsPicker('notNeeded')
      }
    }

    useEffect(() => {
      flacsModifier();
    });

    useEffect(() => {
      if(flacsPicker === 'alwaysOff'){
        props.setFlacs(false)
        props.setFlacsAlwaysOn(false)
        props.setFlacsAllowToggle(false)
      } else if(flacsPicker === 'alwaysOn'){
        props.setFlacs(true)
        props.setFlacsAlwaysOn(true)
        props.setFlacsAllowToggle(false)
      } else if(flacsPicker === 'allowToggle'){
        props.setFlacsAlwaysOn(false)
        props.setFlacsAllowToggle(true)
      } else if(flacsPicker === 'notNeeded'){
        props.setFlacs(false)
        props.setFlacsAlwaysOn(false)
        props.setFlacsAllowToggle(false)
      }
    }, [flacsPicker]);


    let buttonFlacsStyleFalse = {
      border: '1px solid #F2F3F4',
      padding: '3px 10px',
      margin: '2px 2px -8px 2px',
      fontSize: '18px',
      color: 'lightgray',
      width: '80px',
      height: '34px',
      borderRadius: '5px',
      background: '#fff',
      textAlign:'center',
      position: 'absolute',
      right: '159px',
      bottom: '15px',
      zIndex: '8',
      cursor: 'pointer',
    }

    let buttonFlacsStyleTrue = {
      border: '3px solid #4CBB17',
      padding: '3px 10px',
      margin: '2px 2px -8px 2px',
      fontSize: '18px',
      fontWeight: '600',
      color: '#4CBB17',
      width: '80px',
      height: '34px',
      borderRadius: '5px',
      background: '#FFF',
      textAlign:'center',
      position: 'absolute',
      right: '159px',
      bottom: '15px',
      zIndex: '8',
      cursor: 'pointer',
    }

  return(
    <Fragment>
      {flacsAlwaysOff === true
        ? (null)
        : props.flacsAlwaysOn === true
          ?
            (
              <button
                style={buttonFlacsStyleTrue}
                >
                FLACS
              </button>
            )
          : props.flacsAllowToggle === true
            ?
              <button
                style={
                  props.flacs === true
                   ? buttonFlacsStyleTrue
                   : buttonFlacsStyleFalse
                 }
                 onClick={
                   props.flacs === false
                     ? () => props.setFlacs(true)
                     : () => props.setFlacs(false)
                   }
                >
                FLACS
              </button>
            : null


        }
      </Fragment>
    )
}
