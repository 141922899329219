import React, { Fragment } from 'react'

export default function Header (props){

  return(
    <div>
      <h2
        style={{
          fontSize: '60px',
          fontWeight: '400',
          color: '#595959',
          textAlign: 'center',
          margin: '40px auto 10px auto',
          maxWidth: '800px',
        }}
        >{props.title}
      </h2>
      <h4
        style={{
          fontSize: '30px',
          fontWeight: '400',
          color: '#595959',
          textAlign: 'center',
          margin: '0 auto 30px auto',
          maxWidth: '800px',
        }}
        >
        {props.subtitle}
      </h4>
      <hr style={{width: '600px'}}/>
    </div>
  )
}
