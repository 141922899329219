export default function FormSelect (props) {

  let selectDivStyle ={
    // display: 'flex',
    position: 'relative',
    margin: '10px 0 20px 0',
  }

  let selectStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '4px 0 4px 0px',
    padding: '5px',
    border: 'none',
    background: '#FFF',
    borderRadius: '7px',
    width: '450px'
  }

  let labelStyle = {
    margin: 'auto 30px auto 0',
    padding: '0 0 0 0px',
    textAlign: 'left',
    fontWeight: '600'
  }

  let commentStyle = {
    fontSize: '14px',
    margin: '0 0 0 10px',
    width: '440px'
  }

  return (
    <div style={selectDivStyle}>
      <p style={labelStyle}>{props.label}</p>
      <select
        style = {selectStyle}
        value={props.value}
        onChange={e => props.setHook(e.target.value)}
        >
        {(props.options)
          ?
          props.options.map(e => (
            <option
              key={e}
              >
              {e}
            </option>
          ))
          : null
        }
      </select>
      {props.comment.map((e,i) => (
        <p
          key={i}
          style={commentStyle}
          >
          {e}
        </p>
      ))}
      <hr
        style={{
          width: '450px',
          position: 'absolute',
          left: '0',
          bottom: '-20px',
        }}/>
    </div>
  )
}
