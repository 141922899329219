import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function About (props) {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    const[loginHover, setLoginHover]=useState(false)
    const[signUpHover, setSignUpHover]=useState(false)
    const[homeHover, setHomeHover]=useState(false)


    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'center',
        margin: '40px 0 0 0',
    }

    let subtitleStyle = {
        fontSize: '30px',
        color: '#FFF',
        textAlign: 'center',
        margin: '6px 0px 0px 0px',
    }

    let listStyle = {
        fontSize: '20px',
        color: '#FFF',

    }

    let buttonStyleTrue = {
        // border: '1px solid #007bff',
        color: '#FFF',
        borderRadius: '5px',
        background: "#007bff",
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
      }
      
    let buttonStyleFalse = {
        // border: '1px solid #007bff',
        color: '#007bff',
        background: '#FFF',
        borderRadius: '5px',
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
    }

    let blueLinkStyleTrue = {
        fontSize: '30px',
        textDecoration: 'underline',
        margin: '0 30px 0 30px',
        background: 'none',
        border: 'none',
        color: '#007bff',
      }
    
      let blueLinkStyleFalse = {
        fontSize: '30px',
        textDecoration: 'none',
        margin: '0 30px 0 30px',
        background: 'none',
        border: 'none',
        color: '#007bff',
      }

    let buttonTextStyle = {
        padding: '0',
        margin: '10px 20px',
        textAlign: 'center',
        fontSize: '40px',
    }

    let imgStyle = {
        margin: '0',
        borderRadius: '5px',
        border: '1px solid gray',
    }

    let hrDiv = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }

    let hrStyle = {
        color: '#FFF',
        width: '40%'
    }


    return(
        <div style={backgroundStyle}>
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                <p style={titleStyle}>Simplify the Process</p>
                <p style={{
                    fontSize: '30px',
                    color: '#FFF',
                    textAlign: 'center',
                    margin: '6px 30px 0px 0px',
                }}>
                        
                    </p>
                
                <div style={hrDiv}> <hr style={hrStyle}/> 
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: "70px 0 0 0",
                    }}
                >
                    <div 
                        style={{
                            width: '28%'
                        }}
                    >
                        <p style={subtitleStyle}>
                            No filing cabinets
                        </p>
                    </div>
                    <div 
                        style={{
                            width: '28%'
                        }}
                    >
                        <p style={subtitleStyle}>
                            No math
                        </p>
                    </div>
                    <div 
                        style={{
                            width: '28%'
                        }}
                    >
                        <p style={subtitleStyle}>
                            No lens confusion
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: "10px 0 0 0",
                    }}
                >
                    <div 
                        style={{
                            width: '28%'
                        }}
                    >
                        <img
                            src='noMoreFilingCabinets.jpeg'
                            width='100%'
                            style={imgStyle}
                        />
                    </div>
                    <div 
                        style={{
                            width: '28%'
                        }}
                    >
                        <img
                            src='noMoreMath.jpeg'
                            width='100%'
                            style={imgStyle}
                        />
                    </div>
                    <div 
                        style={{
                            width: '28%'
                        }}
                    >
                        <img
                            src='noMoreLensNames.jpeg'
                            width='100%'
                            style={imgStyle}
                        />
                    </div>
                </div>



                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: "70px 0 0 0",
                    }}
                >
                    <div 
                        style={{
                            width: '60%'
                        }}
                    >
                        <p style={subtitleStyle}>
                            Pictures
                        </p>
                    </div>
                    <div 
                        style={{
                            width: '36%'
                        }}
                    >
                        <p style={subtitleStyle}>
                            Not words
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: "10px 0 0 0",
                    }}
                >
                    <div 
                        style={{
                            width: '60%'
                        }}
                    >
                        <img
                            src='screenshotNightSimulatorAbout.jpeg'
                            width='100%'
                            style={imgStyle}
                        />
                    </div>
                    <div 
                        style={{
                            width: '37%'
                        }}
                    >
                        <img
                            src='notWords.jpeg'
                            width='100%'
                            style={imgStyle}
                        />
                    </div>
                </div>



                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: "70px 0 0 0",
                    }}
                >
                    <div 
                        style={{
                            width: '30%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <p style={subtitleStyle}>
                            MD preferences
                        </p>
                    </div>
                    <div 
                        style={{
                            width: '5%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <p
                            style={{
                                color: "#FFF",
                                fontSize: '80px',
                                margin: '0',
                            }}
                            >
                            +
                        </p>                       
                    </div>
                    <div 
                        style={{
                            width: '30%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <p style={subtitleStyle}>
                            Patient measurements
                        </p>
                    </div>
                    <div 
                        style={{
                            width: '5%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <p
                            style={{
                                color: "#FFF",
                                fontSize: '80px',
                                margin: '0',
                            }}
                            >
                            =
                        </p>                       
                    </div>
                    <div 
                        style={{
                            width: '30%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <p style={subtitleStyle}>
                            All in a tiny square
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: "10px 0 0 0",
                    }}
                >
                    <div 
                        style={{
                            width: '30%',
                            height: '60%',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '5px',
                            background: "#FFF",
                        }}
                    >
                        <img
                            src='mdBrain.jpeg'
                            width='84%'
                            style={{
                                margin: '0',
                                borderRadius: '5px',
                            }}
                        />
                    </div>
                    <div 
                        style={{
                            width: '5%'
                        }}
                    >
                    </div>

                    <div 
                        style={{
                            width: '30%'
                        }}
                    >
                        <img
                            src='patientsMeasurements.jpeg'
                            width='100%'
                            style={imgStyle}
                        />
                    </div>
                    <div 
                        style={{
                            width: '5%'
                        }}
                    >
                    </div>
                    <div 
                        style={{
                            width: '30%'
                        }}
                    >
                        <img
                            src='screenshotQr.jpeg'
                            width='86%'
                            style={imgStyle}
                        />
                    </div>
                </div>

                
                
                <div 
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '100px 0'
                    }}
                >
                    <Link
                        to={'/login'}
                    >
                        <button
                            style={
                                loginHover === true
                                ? blueLinkStyleTrue
                                : blueLinkStyleFalse
                                }
                            onMouseOver ={() => setLoginHover(true)}
                            onMouseOut={() => setLoginHover(false)}
                            onClick={() => window.scrollTo(0, 0)}
                            >Home
                        </button>
                    </Link>

                    
                    <Link
                        to={'/signup'}
                    >
                        <button
                            style={
                                signUpHover === true
                                ? blueLinkStyleTrue
                                : blueLinkStyleFalse
                                }
                            onMouseOver ={() => setSignUpHover(true)}
                            onMouseOut={() => setSignUpHover(false)}
                            >Sign-up
                        </button>        
                    </Link>
                </div>
                


                <div style={{
                    display: 'flex',
                    justifyContent:'center',
                    margin: '100px 0 0 0'
                }}> 
                    <p style={{
                        fontSize: '18px',
                        color: '#FFF',
                        textAlign: 'left',
                        margin: '6px 0px 0px 0px',
                    }}>Contact: cataractpreop@gmail.com</p>
                    
                </div>
                
            </div>
        </div>
    )
} 






// <div
//                     style={{
//                         display: 'flex',
//                         margin: "50px 0 0 0",
//                     }}
//                 >
//                     <div 
//                         style={{
//                             width: '40%'
//                         }}
//                     >
//                         <p style={subtitleStyle}>
//                             Paperwork page
//                         </p>
//                         <ul style={listStyle}>
//                             <li>
//                               STEP 3: Clinic staff print the bedsheet, consents, and preop/postop instructions.
//                             </li>
//                             <li>
//                               Consistently correct lens model number, laterality, and consent. 
//                             </li>
//                             <li>
//                               The software also has other warnings and prompts to prevent error, like including whether the patient chose a target of distance or near, when a myope has chosen to be corrected for distance, keratoconus targets, visual function questionnaire, and more.
//                             </li>
//                         </ul>
//                     </div>
//                     <div
//                         style={{
//                             display: 'flex',
//                             flexDirection: 'column',
//                             width: '60%'
//                     }}>
//                         <img
//                             src='bedsheetPrint.jpg'
//                             width='100%'
//                             style={imgStyle}
//                         />
//                         <img
//                             src='consentPrint.jpg'
//                             width='100%'
//                             style={imgStyle}
//                         />
//                     </div>
//                 </div>








// <div
// style={{
//     display: 'flex',
//     margin: "50px 0 0 0",
// }}
// >
// <div 
//     style={{
//         width: '40%'
//     }}
// >
//     <p style={subtitleStyle}>
//         Choose the next step
//     </p>
//     <ul style={listStyle}>
//         <li>Print QR code to finish up later</li>
//         <li>Learn about cataract surgery with the tutorial</li>
//         <li>Go straight to the vision simulator</li>
//     </ul>
// </div>
// <div
//     style={{
//         display: 'flex',
//         flexDirection: 'column',
//         width: '60%'
// }}>
//     <img
//         src='screenshotOptions.jpeg'
//         width='100%'
//         style={imgStyle}
//     />
// </div>
// </div>

// <div
// style={{
//     display: 'flex',
//     margin: "50px 0 0 0",
// }}
// >
// <div 
//     style={{
//         width: '40%'
//     }}
// >
//     <p style={subtitleStyle}>
//         QR code
//     </p>
//     <ul style={listStyle}>
//         {/* <li>Scan this QR code with an iPad camera to simulate this patient's vision (try it, this code works)</li> */}
//         <li>Techs print the QR code to be used later by the doctor or the patient</li>
//         <li>Patient measurement data is encrypted and stored in the QR code, so PHI is never sent to an outside server</li>
//     </ul>
// </div>
// <div
//     style={{
//         display: 'flex',
//         flexDirection: 'column',
//         width: '60%'
// }}>
//     <img
//         src='screenshotQr.jpeg'
//         width='100%'
//         style={imgStyle}
//     />
// </div>
// </div>


// <div
// style={{
//     display: 'flex',
//     margin: "50px 0 0 0",
// }}
// >
// <div 
//     style={{
//         width: '40%'
//     }}
// >
//     <p style={subtitleStyle}>
//         Patient tutorial
//     </p>
//     <ul style={listStyle}>
//         <li>Beautiful illustrations and simple wording explain cataract surgery</li>
//     </ul>
// </div>
// <div
//     style={{
//         display: 'flex',
//         flexDirection: 'column',
//         width: '60%'
// }}>
//     <img
//         src='screenshotTutorial.jpeg'
//         width='100%'
//         style={imgStyle}
//     />
// </div>
// </div>


// <div
// style={{
//     display: 'flex',
//     margin: "50px 0 0 0",
// }}
// >
// <div 
//     style={{
//         width: '40%'
//     }}
// >
//     <p style={subtitleStyle}>
//         Vision simulator
//     </p>
//     <ul style={listStyle}>
//         <li>
//           The Cataract Preop software algorithm uses the physician's preferences and the patient's eye measurements to generate a list of appropriate lenses
//         </li>
//         <li>
//           The patient chooses the lens they want from that list, and the simulator updates to reflect their lens choice. 
//         </li>
//     </ul>
// </div>
// <div
//     style={{
//         display: 'flex',
//         flexDirection: 'column',
//         width: '60%'
// }}>
//     <img
//         src='screenshotSimulator.jpeg'
//         width='100%'
//         style={imgStyle}
//     />
//     <img
//         src='screenshotSimulatorLensAbout.jpeg'
//         width='100%'
//         style={imgStyle}
//     />
//     <img
//         src='screenshotNightSimulatorAbout.jpeg'
//         width='100%'
//         style={imgStyle}
//     />
// </div>
// </div>           



// <div
// style={{
//     display: 'flex',
//     margin: "100px 0 0 0",
// }}
// >
// <div 
//     style={{
//         width: '40%'
//     }}
// >
//     <p style={subtitleStyle}>
//         Physician account page
//     </p>
//     <ul style={listStyle}>
//         <li>Choose your lenses and equipment</li>
//         <li>Customize your decision points and consents</li>
//         <li>The software can then counsel every patient as if you had cloned yourself</li>
//     </ul>
// </div>

// <div
//     style={{
//         display: 'flex',
//         flexDirection: 'column',
//         width: '60%'
// }}>
//     <img
//         src='account.jpg'
//         width='100%'
//         style={imgStyle}
//     />
//     <img
//         src='lensPage.jpg'
//         width='100%'
//         style={imgStyle}
//     />
// </div>
// </div>
