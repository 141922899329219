import React, {useState, Fragment} from 'react';

import LensNew from "./LensNew"
import FormInput from "../partials/FormInput"

export default function LensSearch (props) {

  const [lensPicked, setLensPicked] = useState('')
  const [searchString, setSearchString] = useState('')
  const [activeOption, setActiveOption] = useState('')
  const [filteredOptions, setFilteredOptions] = useState('')
  const [showOptions, setShowOptions] = useState('')




  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if(filteredOptions[activeOption] === undefined){
        alert("Please check spelling and choose a lens lens from the list")
      } else {
        setActiveOption(0)
        setShowOptions(false)
        setSearchString(filteredOptions[activeOption].lensName)
        setLensPicked(filteredOptions[activeOption])
      }}
      else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      setActiveOption(activeOption -1);
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length -1) {
        return;
      }
      setActiveOption(activeOption +1);
    }
  }


  const handleUserInput = e => {
    const searchText = e.currentTarget.value;
    const filteredLenses = props.staticLensListOptions && (props.staticLensListOptions).filter((c) =>
      c.lensName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

    if(searchText.length === 0){
      setSearchString('')
      setLensPicked('')
      setActiveOption(0)
      setFilteredOptions(filteredLenses)
      setShowOptions(false)
    } else {
      setSearchString(e.currentTarget.value)
      setActiveOption(0)
      setFilteredOptions(filteredLenses)
      setShowOptions(true)
    }
  }


  let optionsList;

  let ulStyle = {
    display: 'block',
    listStyle: 'none',
    transition: 'width 0.3s',
    margin: 'auto',
    position: 'relative',
    padding: '0 0 0 20px',
  }

  let listFilterStyle = {
    display: 'block',
    margin: '3px 3px 3px 120px',
    padding: '3px',
    fontSize: '1rem',
    width: '400px',
    borderRadius:'5px',
    color: 'darkgray',
  }

  let listActiveStyle = {
    margin: '3px 3px 3px 120px',
    padding: '3px',
    fontSize: '1rem',
    width: '400px',
    borderRadius:'5px',
    color: '#000',
    fontWeight: '600',
  }

  let inputStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '4px 0 4px 108px',
    padding: '5px',
    border: 'none',
    background: '#FFF',
    borderRadius: '7px',
    width: '330px',
  }

  let labelStyle = {
    margin: 'auto 10px auto 110px',
    padding: '0',
    textAlign: 'left',
    fontWeight: '600'
  }


  if (showOptions === true && searchString) {
    if (filteredOptions.length) {
      optionsList = (
        <ul style= {ulStyle}>
          {filteredOptions.map((optionName, index) => {
            let listName = listFilterStyle;
            if (index === activeOption) {
              listName = listActiveStyle;
            }
            return (
              <li
                style= {listName}
                key= {optionName.lensName}
                onClick={() => {
                  setLensPicked(optionName);
                  setSearchString(optionName.lensName);
                  setActiveOption(0);
                  setFilteredOptions([]);
                  setShowOptions(false);
                }}>
                  {optionName.lensName}
              </li>
            );
          })}
        </ul>
      );
    }
  }




  return(

    props.addLens === true
    ?
      <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <div
          style={{
            background: '#F2F3F4',
            borderRadius: '10px',
            padding: '0px 0 50px 20px',
            margin: '20px 0 20px 30px',
            width: '500px',
          }}
        >
          <div style= {{
              margin:'0',
              textAlign: 'left',
              position: 'relative',
            }}>
            <div style= {{
                padding: '15px 5px 0px 10px',
              }}>
              <p style= {labelStyle}>Lens name: </p>
              <input
                type="text"
                style={inputStyle}
                onChange={handleUserInput}
                onKeyDown={(e) => handleKeyDown(e)}
                value={searchString}
                />
            </div>
            <hr
              style={{
                width: '450px',
                position: 'absolute',
                left: '10px',
                bottom: '-20px',
              }}/>
            {optionsList}
          </div>
          <LensNew
            userName={props.userName}
            addLens={props.addLens}
            onAddLens={props.onAddLens}
            contraindicationsList={props.contraindicationsList}
            lensPicked={lensPicked}
            searchString={searchString}
            criOffer={props.criOffer}
            flacsOffer={props.flacsOffer}
            topographer={props.topographer}
            />
        </div>
      </div>
    :
      <div
        onClick={(e) => props.onAddLens(true)}
        style={{
          borderRadius: '5px',
          width: '165px',
          margin: '50px auto 50px auto',
          padding: '2px 7px 2px 7px',
          color: '#007bff',
          border: '1px solid #007bff',
          fontSize: '20px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        >
        +add new lens
      </div>
  )
}








// <FormInput
//   label='Sulcus lens'
//   value="props.sulcusLens"
//   setHook="props.sulcusLens"
//   />
