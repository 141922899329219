import React, { useEffect } from 'react'
import ForwardArrow from '../partials/ForwardArrow'
import BackArrow from '../partials/BackArrow'

export default function AdvancedMode() {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    let buttonStyleTrue = {
        fontSize: '22px',
        margin: '40px 30px 0 30px',
        padding: '20px',
        background: '#007bff',
        border: '1px solid #007bff',
        borderRadius: '5px',
        color: '#FFF',
        width: '100px',
        textAlign: 'center',
        textDecoration: 'none',
    }
    
    let buttonStyleFalse = {
        fontSize: '22px',
        margin: '40px 30px 0 30px',
        padding: '20px',
        background: '#FFF',
        border: '1px solid #007bff',
        borderRadius: '5px',
        color: '#007bff',
        width: '100px',
        textAlign: 'center',
        textDecoration: 'none',
    }

    let backgroundStyle = {
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '46px',
        color: '#292929',
        textAlign: 'center',
        margin: '40px 0 0 0',
    }

    let subtitleStyle = {
      fontSize: '28px',
      color: '#292929',
      textAlign: 'center',
      margin: '20px 0 0 0',
    }

    let paragraphStyle = {
        fontSize: '20px',
        color: '#292929',
        textAlign: 'center',
        maxWidth: '800px',
        margin: '16px 30px 0px 0px',
    }

  return (
    <div style={backgroundStyle}>
      <BackArrow
        linkTo='/lensguide'
      />
      <ForwardArrow
        linkTo='/eyeinfo'
        // linkTo='/premiumsimulator'
      />
      <div style={{width: '7vw'}}></div>
      <div style={{width: '84vw'}}>
        {/* <p style={titleStyle}>Welcome to Advanced Mode!</p>
        <p style={subtitleStyle}>Start by watching the 2 minute instructional video below</p> */}
        
        <div style={{display: 'flex', justifyContent: 'center', margin: '20px 0 0 0'}}>
        <div 
          style={{
            width: '80%',
            position: 'relative', // Ensuring relative positioning for the iframe
            paddingBottom: '56.25%' // 16:9 aspect ratio (height = width * 9/16)
          }}
        > 
          <iframe
            src="https://www.youtube.com/embed/zhahIBOrCwM?autoplay=1&loop=1&playlist=zhahIBOrCwM"  //NB: playlist="id of video and NOT id of playlist" 
            title="Simulator" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
            referrerPolicy="strict-origin-when-cross-origin" 
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '20px',
              borderBottom: '1px solid #494949',
            }}
          >  
          </iframe>
          
        </div>
        
        </div>
        <p style={subtitleStyle}>
          When you are done, click the large blue arrow on the top right of the screen
        </p>
      </div>
      <div style={{width: '7vw'}}></div>
    </div>
  )
}


// <div style={{display: 'flex', justifyContent: 'center'}}>
//           <Link
              
//               to='/premiumaccess2'
//               onMouseEnter={() => setButtonHover(true)}
//               onMouseLeave={() => setButtonHover(false)}
//               style={
//                 buttonHover === true
//                 ? buttonStyleTrue
//                 : buttonStyleFalse
//               }
//           >
//               Next
//           </Link>
//         </div>