import React, {useState, Fragment, useEffect} from 'react';
import api from "../services/api";

import FormInput from '../partials/FormInput'
import Header from '../partials/Header'
import Navbar from '../partials/Navbar'


function UserEdit (props){

  const[physicianName, setPhysicianName] = useState("");
  const[clinicName, setClinicName] = useState("");
  const[clinicStreet, setClinicStreet] = useState("");
  const[clinicCity, setClinicCity] = useState("");
  const[clinicState, setClinicState] = useState("");
  const[clinicZip, setClinicZip] = useState("");
  const[clinicPhone, setClinicPhone] = useState("");
  const[clinicFax, setClinicFax] = useState("");

  const[errorUserName, setErrorUserName] = useState("")
  const[errorPassword1, setErrorPassword1] = useState("")
  const[errorPassword2, setErrorPassword2] = useState("")
  const[errorPasswordLength, setErrorPasswordLength] = useState("")
  const[errorPasswordMatch, setErrorPasswordMatch] = useState("")
  const[errorPhysicianName, setErrorPhysicianName] = useState("")
  const[errorClinicName, setErrorClinicName] = useState("")
  const[errorClinicStreet, setErrorClinicStreet] = useState("")
  const[errorClinicCity, setErrorClinicCity] = useState("")
  const[errorClinicState, setErrorClinicState] = useState("")
  const[errorClinicZip, setErrorClinicZip] = useState("")
  const[errorClinicPhone, setErrorClinicPhone] = useState("")
  const[errorMessage, setErrorMessage] = useState("")
  const[showErrors, setShowErrors] = useState(false)

    const handleSave = async(e) => {
      e.preventDefault();

      let validationPhysicianName = ( !physicianName )
        ? setErrorPhysicianName("* Enter Physician's Name")
        : setErrorPhysicianName("")

      let validationClinicName = ( !clinicName )
        ? setErrorClinicName("* Enter Clinic's Name")
        : setErrorClinicName("")

      let validationClinicStreet = ( !clinicStreet )
        ? setErrorClinicStreet("* Enter Clinic's Street")
        : setErrorClinicStreet("")

      let validationClinicCity = ( !clinicCity )
        ? setErrorClinicCity("* Enter Clinic's City")
        : setErrorClinicCity("")

      let validationClinicState = ( !clinicState )
        ? setErrorClinicState("* Enter Clinic's State")
        : setErrorClinicState("")

      let validationClinicZip = ( !clinicZip )
        ? setErrorClinicZip("* Enter Clinic's Zip")
        : setErrorClinicZip("")

      let validationClinicPhone = ( !clinicPhone )
        ? setErrorClinicPhone("* Enter Clinic's Phone Number")
        : setErrorClinicPhone("")


      if (
             !physicianName
          || !clinicName
          || !clinicStreet
          || !clinicCity
          || !clinicState
          || !clinicZip
          || !clinicPhone
        ) {


        setShowErrors(true)

      } else {


        try {

          await api.put("/user", {
            physicianName,
            clinicName,
            clinicStreet,
            clinicCity,
            clinicState,
            clinicZip,
            clinicPhone,
            clinicFax
          }).then(window.location = "/myaccount");

        } catch (err) {
          console.log(err.message)
        }
      }
    }


    const getUser = async() => {

        try {
          const response = await api.get("/user");

          setPhysicianName(response.data.rows[0].physician_name)
          setClinicName(response.data.rows[0].clinic_name)
          setClinicStreet(response.data.rows[0].clinic_street)
          setClinicCity(response.data.rows[0].clinic_city)
          setClinicState(response.data.rows[0].clinic_state)
          setClinicZip(response.data.rows[0].clinic_zip)
          setClinicPhone(response.data.rows[0].clinic_phone)
          setClinicFax(response.data.rows[0].clinic_fax)

        } catch (err) {
          console.error(err.message)
        }
      }

      useEffect(() => {
          getUser();
        }, []);

    let warnStyle ={
      fontSize: '17px',
      margin: '3px 0 0 0',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      color: 'red',
    }

    return(
      <Fragment>
        <Navbar/>
        <div>
          <div style={{
              width: '7vw',
            }}>
          </div>
          <div>
            <Header
              title={"Profesional Information"}
              subtitle={"Edit and save your professional information"}
              />
            <form
              style={{
                background: '#F2F3F4',
                borderRadius: '10px',
                padding: '0px 0 50px 20px',
                margin: '20px auto 20px auto',
                width: '500px',
              }}
              >
              <div
                style={{
                  padding: '5px 5px 15px 10px',
                  }}>
                <FormInput
                  label='Name and degree'
                  value={physicianName}
                  setHook={setPhysicianName}
                  />
                <FormInput
                  label='Clinic or business name'
                  value={clinicName}
                  setHook={setClinicName}
                  />
                <FormInput
                  label='Street'
                  value={clinicStreet}
                  setHook={setClinicStreet}
                  />
                <FormInput
                  label='City'
                  value={clinicCity}
                  setHook={setClinicCity}
                  />
                <FormInput
                  label='State'
                  value={clinicState}
                  setHook={setClinicState}
                  />
                <FormInput
                  label='ZIP'
                  value={clinicZip}
                  setHook={setClinicZip}
                  />
                <FormInput
                  label='Phone number'
                  value={clinicPhone}
                  setHook={setClinicPhone}
                  />
                <FormInput
                  label='Fax number'
                  value={clinicFax}
                  setHook={setClinicFax}
                  />
              </div>
              <div style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <div
                  style={{
                    width:'600px',
                    padding: '10px',
                    margin: '10px',
                    }}
                  >
                  {(errorMessage !== "")
                      ? <p style={warnStyle}>{errorMessage}</p>
                      : null
                  }
                  {(errorUserName !== "")
                      ? <p style={warnStyle}>{errorUserName}</p>
                      : null
                  }
                  {(errorPassword1 !== "")
                      ? <p style={warnStyle}>{errorPassword1}</p>
                      : null
                  }
                  {(errorPassword2 !== "")
                    ? <p style={warnStyle}>{errorPassword2}</p>
                    : null
                  }
                  {(errorPasswordMatch !== "")
                    ? <p style={warnStyle}>{errorPasswordMatch}</p>
                    : null
                  }
                  {(errorPasswordLength !== "")
                    ? <p style={warnStyle}>{errorPasswordLength}</p>
                    : null
                  }
                  {(errorPhysicianName !== "")
                    ? <p style={warnStyle}>{errorPhysicianName}</p>
                    : null
                  }
                  {(errorClinicName !== "")
                    ? <p style={warnStyle}>{errorClinicName}</p>
                    : null
                  }
                  {(errorClinicStreet !== "")
                    ? <p style={warnStyle}>{errorClinicStreet}</p>
                    : null
                  }
                  {(errorClinicCity !== "")
                    ? <p style={warnStyle}>{errorClinicCity}</p>
                    : null
                  }
                  {(errorClinicState !== "")
                    ? <p style={warnStyle}>{errorClinicState}</p>
                    : null
                  }
                  {(errorClinicZip !== "")
                    ? <p style={warnStyle}>{errorClinicZip}</p>
                    : null
                  }
                  {(errorClinicPhone !== "")
                    ? <p style={warnStyle}>{errorClinicPhone}</p>
                    : null
                  }

                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div
                  onClick={(e => handleSave(e))}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#007bff',
                    color: '#fff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Save
                </div>
                <div
                  onClick={() => window.location = `/myaccount`}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Cancel
                </div>
              </div>
            </form>
          </div>
          <div style={{
              width: '7vw',
              height: '84vh'
            }}>
          </div>
        </div>
      </Fragment>
    )
  }

export default UserEdit
