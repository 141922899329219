import React from 'react'
import { QRCodeSVG } from 'qrcode.react';

export default function QrCodePhysician(props) {

  //set base url to www version (need to make sure others redirect to www)
  const baseUrl = `${process.env.REACT_APP_API_URL}`


  return (
    <>
        <QRCodeSVG
            style={{
            margin: '40px auto'
            }}
            value={`${baseUrl}/${props.linkTo}/${props.qrToken}.${props.cipherText}`}
        />
    </>
  )
}
