import React, { Fragment, useEffect, useState } from 'react';

export default function CriPicker (props) {

  const[criPicker, setCriPicker]=useState('')


  let criAlwaysOff =
  (
    props.criOffer === 'No'
    ||
    (props.biometerTopographerAstigDiff*1 > props.criBioTopoAstigMax*1)
    ||
    (props.biometerTopographerAxisDiff*1 > props.criBioTopoAxisMax*1)
    ||
    props.lensOption === 'Cataract vision'
    ||
    props.lensCriRequire === "No (don't offer)"
  )
    ? true
    : false

    // console.log(criAlwaysOff)

  let criAlwaysOn =
  (
    //Require CRI if corneal astig from preferred machine is greater than lensCriMinAstig and less than lensCriMaxAstig
    (
      props.equipmentPreferenceAstig*1 > props.lensCriMinAstig
      &&
      props.equipmentPreferenceAstig*1 < props.lensCriMaxAstig
    )
    &&
    //and current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and physician requires CRI for thiss lens (props.lensCriRequire === "Yes (always)")
    props.lensCriRequire === "Yes (always)"
  )
    ? true
    : false

    // console.log(criAlwaysOn)

  let criAllowToggle =
  (
    // If corneal astig from preferred machine is greater than lensCriMinAstig and less than lensCriMaxAstig
    (
      props.equipmentPreferenceAstig*1 > props.lensCriMinAstig
      &&
      props.equipmentPreferenceAstig*1 < props.lensCriMaxAstig
    )
    &&
    //and current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and CRI is optional for this lens
    props.lensCriRequire === "Optional (always offered)"
  )
    ? true
    : false

    // console.log(criAllowToggle)

    let criModifier = () => {
      if (criAlwaysOff === true){
        setCriPicker('alwaysOff')
      } else if (criAlwaysOff === false && criAlwaysOn === true){
        setCriPicker('alwaysOn')
      } else if (criAlwaysOff === false && criAlwaysOn === false && criAllowToggle === true){
        setCriPicker('allowToggle')
      } else if (criAlwaysOff === false && criAlwaysOn === false && criAllowToggle === false){
        setCriPicker('notNeeded')
      }
    }

    useEffect(() => {
      criModifier();
    });




    //NB: may be able to get rid of useEffect in Lens.js and LensNew.js for props.lensFlacsRequire


    //If cri === true and props.lensFlacsRequire === "Yes (only with CRI)", then props.setFlacs(true)

    useEffect(() => {
      if(criPicker === 'alwaysOff'){
        props.setCri(false)
        props.setCriAlwaysOn(false)
        props.setCriAllowToggle(false)
      } else if(criPicker === 'alwaysOn' && props.lensFlacsRequire === "Yes (only with CRI)"){
        props.setCri(true)
        props.setFlacs(true)
        props.setCriAlwaysOn(true)
        props.setFlacsAlwaysOn(true)
        props.setCriAllowToggle(false)
        props.setFlacsAllowToggle(false)
      } else if(criPicker === 'alwaysOn'){
        props.setCri(true)
        props.setCriAlwaysOn(true)
        props.setCriAllowToggle(false)
      } else if(criPicker === 'allowToggle'){
        props.setCriAlwaysOn(false)
        props.setCriAllowToggle(true)
      } else if(criPicker === 'notNeeded'){
        props.setCri(false)
        props.setCriAlwaysOn(false)
        props.setCriAllowToggle(false)
      }
    }, [criPicker]);


    let buttonCriStyleFalse = {
      border: '1px solid #F2F3F4',
      padding: '3px 10px',
      margin: '2px 2px -8px 2px',
      fontSize: '18px',
      color: 'lightgray',
      width: '65px',
      height: '34px',
      borderRadius: '5px',
      background: '#fff',
      textAlign:'center',
      position: 'absolute',
      right: '246px',
      bottom: '15px',
      zIndex: '8',
      cursor: 'pointer',
    }

    let buttonCriStyleTrue = {
      border: '3px solid #4CBB17',
      padding: '3px 10px',
      margin: '2px 2px -8px 2px',
      fontSize: '18px',
      fontWeight: '600',
      color: '#4CBB17',
      width: '65px',
      height: '34px',
      borderRadius: '5px',
      background: '#FFF',
      textAlign:'center',
      position: 'absolute',
      right: '246px',
      bottom: '15px',
      zIndex: '8',
      cursor: 'pointer',
    }

    let buttonCriStyleOff = {
      border: '1px solid #F2F3F4',
      padding: '3px 10px',
      margin: '2px 2px -8px 2px',
      fontSize: '18px',
      color: 'lightgray',
      width: '65px',
      height: '34px',
      borderRadius: '5px',
      background: '#fff',
      textAlign:'center',
      position: 'absolute',
      right: '246px',
      bottom: '15px',
      zIndex: '8',
      cursor: 'pointer',
      textDecoration: 'line-through',
    }

  return(
    <Fragment>
      {criAlwaysOff === true
        ? (null)
        : props.criAlwaysOn === true
          ?
            (
              <button
                style={buttonCriStyleTrue}
                >
                CRI
              </button>
            )
          : props.criAllowToggle === true
            ?
              <button
                style={
                  props.cri === true
                   ? buttonCriStyleTrue
                   : buttonCriStyleFalse
                 }
                 onClick={
                   props.cri === false
                     ? (props.lensFlacsRequire === 'Yes (only with CRI)')
                        ? () => {
                                  props.setCri(true)
                                  props.setFlacs(true)
                                }
                        : () => props.setCri(true)
                     : (props.lensFlacsRequire === 'Yes (only with CRI')
                        ? () => {
                               props.setCri(false)
                               props.setFlacs(false)
                             }
                        : () => props.setCri(false)
                   }
                >
                CRI
              </button>
            : null


        }
      </Fragment>
    )
}
