import StyleButton from './StyleButton'
import '../consentStyle.css';

export default function BlockStyleControls (props) {

  const selection = (props.editorState).getSelection();

  const blockType = (props.editorState).getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  // console.log(selection)
  // console.log(blockType)

  const BLOCK_TYPES = [
    {label: 'Title', style: 'title'},
    {label: 'Header', style: 'header'},
    {label: 'Paragraph', style: 'paragraph'},
    {label: 'Small Font', style: 'small-font'},
    {label: 'List', style: 'unordered-list-item'},
    {label: 'Watermark', style: 'watermark'},
    // {label: 'Left', style: 'leftAlign'},
    // {label: 'Center', style: 'centerAlign'},
    // {label: 'Right', style: 'rightAlign'},
    // {label: 'Checkbox', style: 'square-list'},
  ];

  return (
    <div
      className="RichEditor-controls"
      style={{
        fontFamily: 'Helvetica',
        fontSize: '18px',
        userSelect: 'none',
        display: 'flex',
      }}
      >
      {BLOCK_TYPES.map((t) =>
       <StyleButton
         key={t.label}
         active={t.style === blockType}
         label={t.label}
         style={t.style}
         onToggle={props.onToggle}
         />
      )}
    </div>
  );
}
