import React from 'react';

const TemplateToolbar = ({ onTemplateInsert }) => {
  const templates = [
    { name: 'Side', content: 'eyeSide ' },
    { name: 'Lens Model', content: 'lensModel '},
    { name: 'Lens Option', content: 'lensOption '},
    { name: 'Vision Target', content: 'lensTarget '},
    { name: 'Hospital lens fee', content: 'lensHospitalFee '},
    { name: 'Physician lens fee', content: 'lensPhysicianFee ' },
    { name: 'CRI fee', content: 'lensCriFee '},
    { name: 'FLACS fee', content: 'lensFlacsFee '},
    { name: 'Out-of-pocket total', content: 'oopTotal '},
    { name: 'Out-of-pocket physician', content: 'oopPhysician '},
    { name: 'Out-of-pocket hospital', content: 'oopHospital '},
    { name: 'ICD-10', content: 'icdTen '},
    // { name: '', content: ' '},
    // Add more templates as needed
  ];

  const insertTemplate = (templateContent) => {
    onTemplateInsert(templateContent);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: "800px",
        margin: '20px 0 0px 0',
      }}
    >
      {templates.map((template, index) => (
        <button 
          key={index} 
          style={{
            margin: "3px",
            padding: '5px 10px',
            border: 'none',
            borderRadius: '3px',
            background: "lightyellow",
          }}
          onClick={() => insertTemplate(template.content)}  
        >
          {template.name}
        </button>
      ))}
    </div>
  );
};

export default TemplateToolbar;
