import React from 'react';

export default function TokenToolbar ({ onTemplateInsert }) {
  const templates = [
    { name: 'Side', content: 'eyeSide ' },
    { name: 'Lens Model', content: 'lensModel '},
    { name: 'Lens Option', content: 'lensOption '},
    { name: 'Vision Target', content: 'lensTarget '},
    { name: 'Lenses offered', content: 'lensesOffered '},
    { name: 'Hospital lens fee', content: 'lensHospitalFee '},
    { name: 'Physician Lens Fee', content: 'lensPhysicianFee ' },
    { name: 'CRI fee', content: 'lensCriFee '},
    { name: 'FLACS fee', content: 'lensFlacsFee '},
    { name: 'Out-of-pocket total', content: 'oopTotal '},
    { name: 'Out-of-pocket physician', content: 'oopPhysician '},
    { name: 'Out-of-pocket hospital', content: 'oopHospital '},
    { name: 'ICD-10 modifier', content: 'icdTen '},
    { name: 'Sulcus lens', content: 'sulcusLens '},
    { name: 'Patient MRx', content: 'ptRefraction '},
    { name: 'BAT', content: 'eyeBat '},
    { name: 'Equip preference axis', content: 'equipmentPreferenceAxis '},
    { name: 'Biometer astig and axis', content: 'bioAstigAxis '},
    { name: 'Biometer chord mu', content: 'chordMu '},
    { name: 'Topgrapher astig and axis', content: 'topoAstigAxis '},
    { name: 'Topographer Sph Ab', content: 'topoSphAb '},
    { name: 'Topographer HOA', content: 'topoHoa '},
    { name: 'Contraindications', content: 'ptContraindications '},
    { name: 'Procedure', content: 'procedureName '},
    { name: 'Procedure and Codes', content: 'dxNameAndCodes '},
    { name: 'Add-on procedures', content: 'dxAddOns '},
    { name: 'CRI and FLACS', content: 'criFlacsAddOn '},
    { name: 'Beware of myope', content: 'bewareOfMyope ' },
    { name: 'Lens and target', content: 'lensAndTarget '},
    { name: '-2.00 near target', content: 'nearTargetMrx '},
    { name: 'Sulcus lens', content: 'sulcusLens '},
    { name: 'Allergies', content: 'ptAllergies '},
    { name: 'Code status', content: 'codeStatus '},
    { name: 'Toric alignment', content: 'toricAlignment '},
    { name: 'Checkbox', content: ' '},
    // { name: '', content: ' '},
    // Add more templates as needed
  ];

  const insertTemplate = (templateContent) => {
    onTemplateInsert(templateContent);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: "820px",
        margin: '20px 0 0px 0',
      }}
    >
      {templates.map((template, index) => (
        <button 
          key={index} 
          style={{
            margin: "3px",
            padding: '5px 10px',
            border: 'none',
            borderRadius: '3px',
            background: "lightyellow",
          }}
          onClick={() => insertTemplate(template.content)}  
        >
          {template.name}
        </button>
      ))}
    </div>
  );
};

