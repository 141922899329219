// surgicalCenterPhone: '801-442-8601',
// anesthesiaPhone: '801-990-1911',
// costEstimation: '801-906-2500',

function PreopInstCataract (props) {
    return(
        <div>
          <div>
            <h1 style={{
                textAlign: 'center',
                margin: '0',
              }}>
              BEFORE cataract surgery:
            </h1>
            <h3 style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: '0 0 5px 0',
              }}>
              {props.fName} {props.lName} <span style={{margin: '0 0 0 50px'}}></span> {props.dob}
            </h3>
          </div>

          <div style={{
              display: 'flex'
            }}>
            <div style={{width: '7vw'}}></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '800px',
                width: '86vw',
              }}>

              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <h3 style={{margin: '20px 0 3px 0'}}>
                  Two weeks before preoperative appointment
                </h3>
                <ul style={{margin: '0', fontSize: '14px'}}>
                  <li>
                    Do not use contacts lenses for at least 2 weeks prior to your preoperative measurements.
                  </li>
                  <li>
                    Consistently use artificial tears 4 times per day for at least 2 weeks prior to your preoperative measurements (set an alarm if needed to remember)
                  </li>
                  <li>
                    Consistently use artificial tear gel drops at bedtime for at least 2 weeks prior to your preoperative measurements
                  </li>
                </ul>
                <h3 style={{margin: '20px 0 3px 0'}}>
                  Few days before surgery
                </h3>
                <ul style={{margin: '0', fontSize: '14px'}}>
                  <li>
                    Arrange to have a driver available to drop you off and pick you up on the <strong>day of</strong> your surgery and on the <strong>day after</strong> your surgery
                  </li>
                  <li>
                    The surgical center will call you before 4:00 pm the business day prior to your surgery to give you the specific time of your surgery.  They will also go over which medicines to take or not take.
                  </li>
                </ul>

                <h3 style={{margin: '20px 0 3px 0'}}>
                  Evening before surgery
                </h3>
                <ul style={{margin: '0', fontSize: '14px',}}>
                  <li>
                    Remove all makeup
                  </li>
                  <li>
                    If you wear mascara, you will need to soak your eyelashes to make sure there is no mascara residue on your eyelids
                  </li>
                </ul>

                <h3 style={{margin: '20px 0 3px 0'}}>
                  Day of surgery: _______________________
                </h3>
                <ul style={{margin: '0', fontSize: '14px',}}>
                  <li style={{fontWeight: '700'}}>
                    Do not eat not eat any solid food after 11:00 pm the night before surgery.  You may have clear liquids up to two hours prior to your arrival time.
                  </li>
                  <li>
                    Do not wear any makeup, hairspray, lotions, perfumes, or cologne
                  </li>
                  <li>
                    The surgery last 30-60 minutes, but plan on half of a day by the time you check in and check out from the OR
                  </li>
                  <li>Bring your driver</li>
                </ul>
              </div>

              <h3 style={{margin: '20px 0 3px 0'}}>
                Postop follow-up schedule
              </h3>
              <ul style={{margin: '0', fontSize: '14px',}}>
                <li>
                  Postop Day 1: ____________________
                </li>
                <li>
                  Postop Week 1: __________________
                </li>
                <li>
                  Postop Month 1: __________________
                </li>
              </ul>

              <h3 style={{margin: '20px 0 3px 0'}}>
                Questions Regarding Surgical Costs
              </h3>
              <ul style={{
                  margin: '0',
                  fontSize: '14px',
                }}>
                <li>
                  Common Cataract Surgery CPT Codes: 66984 or 66982
                </li>
                {props.surgicalCenterPhone === ''
                  ?
                    <li>
                      Surgical Center:
                    </li>
                  :
                    <li>
                      Surgical Center: {props.surgicalCenterPhone}
                    </li>
                }
                {props.anesthesiaPhone === ''
                  ?
                    <li>
                      Anesthesia:
                    </li>
                  :
                    <li>
                      Anesthesia: {props.anesthesiaPhone}
                    </li>
                }
              </ul>
            </div>
            <div style={{width: '7vw'}}></div>
          </div>
        </div>

    )};

export default PreopInstCataract
