import React, { useEffect } from 'react';

import ForwardArrow from '../partials/ForwardArrow'
import BackArrow from '../partials/BackArrow'
import Header from '../partials/Header'
import Questions from './Questions'
import QuestionsHorizontal from './QuestionsHorizontal'

import pF from "./images/PFpink.jpeg"
import readingBook from './images/readingBookNarrow.jpeg'
import stairs from './images/stairs.jpeg'
import streetSign from './images/streetSign.jpeg'
import sewing from './images/crossStitchWoodCarving.jpeg'
import taxForm from './images/taxForm.jpeg'
import cardGame from './images/cards.jpeg'
import tv from './images/tv.jpeg'


export default function Questionnaire (props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return(
    <div>
      <BackArrow
        linkTo='/patientinfo'
        />

      <ForwardArrow
        linkTo='/choice1'
        />

        <Header
          title="Rate your vision"
          subtitle={`Do you have difficulty with your ${props.side} eye, even with glasses, while doing the following activities?`}
          />

        <Questions
          title="Reading small print such as labels on medicine bottles, a telephone book or food labels?"
          hook={props.q1}
          setHook={props.setQ1}
          step='2'
          image={pF}
          />
        <Questions
          title="Reading a newspaper or book?"
          hook={props.q2}
          setHook={props.setQ2}
          step='1.5'
          image={readingBook}
          />
        <Questions
          title="Seeing steps, stairs or curbs?"
          hook={props.q3}
          setHook={props.setQ3}
          step='3'
          image={stairs}
          />
        <Questions
          title="Reading traffic signs, street signs or store signs?"
          hook={props.q4}
          setHook={props.setQ4}
          step='3'
          image={streetSign}
          />
        <Questions
          title="Doing fine handwork like sewing, knitting, crocheting or carpentry?"
          hook={props.q5}
          setHook={props.setQ5}
          step='2'
          image={sewing}
          />
        <Questions
          title="Writing checks or filling out forms?"
          hook={props.q6}
          setHook={props.setQ6}
          step='1'
          image={taxForm}
          />
        <Questions
          title="Playing games such as bingo, dominos, or cards?"
          hook={props.q7}
          setHook={props.setQ7}
          step='2'
          image={cardGame}
          />
        <Questions
          title="Watching television?"
          hook={props.q8}
          setHook={props.setQ8}
          step='2'
          image={tv}
          />
        <div
          style={{height: '50px'}}
        >
        </div>
        <Header
          title="Rate Your Symptoms"
          subtitle={`Have you been bothered in the ${props.side} eye by any of the following:`}
          />
        <QuestionsHorizontal
          title="Poor night vision?"
          hook={props.q9}
          setHook={props.setQ9}
          step='0.75'
          />
        <QuestionsHorizontal
          title="Glare caused by headlights, streetlights, or bright sunlight?"
          hook={props.q10}
          setHook={props.setQ10}
          step='0.75'
          />
        <QuestionsHorizontal
          title="Hazy and/or blurry vision?"
          hook={props.q11}
          setHook={props.setQ11}
          step='0.75'
          />
        <QuestionsHorizontal
          title="Seeing poorly in dim light?"
          hook={props.q12}
          setHook={props.setQ12}
          step='0.75'
          />
        <QuestionsHorizontal
          title="Poor color vision?"
          hook={props.q13}
          setHook={props.setQ13}
          step='0.75'
          />
        <QuestionsHorizontal
          title="Cataract surgery can almost always be safely postponed until you feel you need better vision.  If a change in glasses won't improve your vision anymore and if the only way to help you see better is cataract surgery, do you feel your vision problems are bad enough to consider cataract surgery now?"
          hook={props.q14}
          setHook={props.setQ14}
          step='0.75'
          />
        <div
          style={{height: '300px'}}
        >
        </div>
    </div>

  )
}


//credit photographer for

//stairs: By Axcordion at English Wikipedia, CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=31854843

// cross-stitch: By Sally Wilson 19 - Own work, CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=75532581

//Woodcarving: The original uploader was Kaiserb at English Wikipedia., CC BY-SA 2.5 <https://creativecommons.org/licenses/by-sa/2.5>, via Wikimedia Commons

//Cards: Photo: Graeme Main/MOD, OGL v1.0OGL v1.0, via Wikimedia Commons

//TV: Julian Tysoe, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons.  https://www.pbs.org/newshour/show/american-mikaela-shiffrin-makes-history-as-most-successful-female-world-cup-skier
