import React, { useEffect, useState } from 'react';
import { FaFontAwesome } from 'react-icons/fa';

export default function FeePicker (props) {

  const[feeTotal, setFeeTotal]=useState('')
  const[feePhysician, setFeePhysician]=useState('')
  const[feeHospital, setFeeHospital]=useState('')


  let lensTotal = (props.lensPhysicianFee*1 + props.lensHospitalFee*1)


  let feeModifier = () => {
    if (
      props.cri === false
      &&
      props.flacs === false
      &&
      lensTotal === 0
    ){
      setFeeTotal(0)
      setFeePhysician(0)
      setFeeHospital(0)

    } else if (
      props.cri === false
      &&
      props.flacs === false
    ){
      setFeeTotal(lensTotal)
      setFeePhysician(props.lensPhysicianFee*1)
      setFeeHospital(props.lensHospitalFee*1)

    } else if (
      props.cri === false
      &&
      props.flacs === true
    ){
      setFeeTotal(props.lensFlacsFee*1 + lensTotal*1)
      setFeePhysician(props.lensPhysicianFee*1)
      setFeeHospital(props.lensHospitalFee*1 + props.lensFlacsFee*1)
    
    } else if (
      props.cri === true
      &&
      props.flacs === false
    ){
      setFeeTotal(props.lensCriFee*1 + lensTotal*1)
      setFeePhysician(props.lensPhysicianFee*1)
      setFeeHospital(props.lensHospitalFee*1 + props.lensCriFee*1)

    } else if (
      props.cri === true
      &&
      props.flacs === true
    ){
      setFeeTotal(props.lensCriFee*1 + props.lensFlacsFee*1 + lensTotal*1)
      setFeePhysician(props.lensPhysicianFee*1 + props.lensCriFee*1)
      setFeeHospital(props.lensHospitalFee*1 + props.lensFlacsFee*1)

    } else {
      setFeeTotal('Consult with your doctor')
    }
  }

    useEffect(() => {
      feeModifier()
      props.setOopTotal(feeTotal)
      props.setOopPhysician(feePhysician)
      props.setOopHospital(feeHospital)
    });

  let feeStyle = {
    padding: '3px 0 0 0',
    fontWeight: '400',
    fontSize: '16px',
  }

  if(feeTotal > 0 ){
    return(
      <div style={feeStyle}>
        ${feeTotal} out-of-pocket
      </div>
    )
  } else if (
    props.lensOption === 'Cataract vision'
  ) {
    return(
      <>
        
      </>
    )
  } else {
    return(
      <div style={feeStyle}>
        Covered by insurance
      </div>
    )
  }

  
}
