import React, { useEffect } from 'react';

import ReferenceImage from './ReferenceImage'
import { TfiRuler } from "react-icons/tfi";

export default function Argos (props) {

  //Calculate chord mu every time PX or PY changes
  useEffect(() => {
    let finalChordMu = Math.round((Math.sqrt((props.chordMuX * props.chordMuX) + (props.chordMuY * props.chordMuY))) * 100) / 100;
    props.setChordMuTotal(finalChordMu);
  }, [props.chordMuX, props.chordMuY])


  let inputStyle = {
    border: 'none',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '135px',
    height: '40px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
  }

  let warnStyle = {
    border: '2px solid red',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '131px',
    height: '36px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
  }

  let subtitleStyle = {
    textAlign: 'left',
    padding: '0 0 0 0px',
    fontSize: '18px',
    fontWeight: '600',
    margin: 'auto 0',
  }
  
  let iconStyle = {
    margin: 'auto 0',
    padding: '0',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
  }
  
  return(
    <div style={{
        display: 'flex',
        justifyContent: 'left',
        margin: '0 0 8px 80px',
      }}>
        <div 
          style={{
            display: 'flex',
            width: "210px"
          }}
        >
          <div
            style={iconStyle}
          >
            <TfiRuler
              style={{fontSize: '40px'}}
            />
          </div>
          
          <p style={subtitleStyle}>{props.biometer} </p>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{
            display: 'flex',
            position: 'relative',
            width: '160px',
          }}>
            <input
            style={
              props.biometerAstig >= 0 && props.biometerAstig < 8 || props.biometerAstig === ''
              ? inputStyle
              : warnStyle
            }
            value={props.biometerAstig}
            onChange={e => props.setBiometerAstig(e.target.value)}
            placeholder="Astigmatism"
            type='number'
            onWheel={(e) => e.target.blur()}
            />
          <ReferenceImage
            referenceImage='/argosAstigmatism.jpg'
            />
          </div>
          <div style={{
            display: 'flex',
            position: 'relative',
            width: '160px',
          }}>
            <input
              style={
                props.biometerAxis > 0 && props.biometerAxis < 181 || props.biometerAxis === ''
                ? inputStyle
                : warnStyle
              }
              value={props.biometerAxis}
              onChange={e => props.setBiometerAxis(e.target.value)}
              placeholder="Steep Axis"
              type='number'
              onWheel={(e) => e.target.blur()}
            />
            <ReferenceImage
              referenceImage='/argosSteepAxis.jpg'
              />
          </div>
          {/* <div style={{
            display: 'flex',
            position: 'relative',
            width: '160px',
          }}>
            <input
              style={
                props.chordMuX >= 0 && props.chordMuX < 1 || props.chordMuX === ''
                ? inputStyle
                : warnStyle
              }
              value={props.chordMuX}
              onChange={e => props.setChordMuX(e.target.value)}
              placeholder="PX"
              type='number'
              onWheel={(e) => e.target.blur()}
            />
            <ReferenceImage
              referenceImage='/argospX.jpg'
              />
          </div> */}
          {/* <div style={{
            display: 'flex',
            position: 'relative',
            width: '160px',
          }}>
            <input
              style={
                props.chordMuY >= 0 && props.chordMuY < 1 || props.chordMuY === ''
                ? inputStyle
                : warnStyle
              }
              value={props.chordMuY}
              onChange={e => props.setChordMuY(e.target.value)}
              placeholder="PY"
              type='number'
              onWheel={(e) => e.target.blur()}
            />
            <ReferenceImage
              referenceImage='/argospY.jpg'
              />
          </div> */}
        </div>
    </div>
  )
}
