import React, { Fragment } from 'react';

export default function NavCluster (props){

return(
  <Fragment>
    <div
      onClick={() => window.location =`/${props.location}`}
      style={{
        border: '1px solid #DDDDDD',
        borderRadius: '10px',
        width: '150px',
        height: '150px',
        cursor: 'pointer',
        padding: '5px',
        margin: '20px',
      }}
      >
      <div
        style={{
          textAlign: 'center',
          padding: '10px 0 20px 0',
        }}
        >
        {props.icon}
      </div>
      <p
        style={{
          textAlign: 'center',
          fontSize: '20px',
          margin: '0',
          padding: '5px',
        }}
        >
        {props.label}
      </p>
    </div>
  </Fragment>
)
}
