export default function TermsOfUse (props) {

    return(
        <>
            {
                props.seeTerms === false
                ? 
                    <AcceptBox
                        acceptTerms={props.acceptTerms}
                        setAcceptTerms={props.setAcceptTerms}
                        seeTerms={props.seeTerms}
                        setSeeTerms={props.setSeeTerms}
                    />
                : 
                    <TextOfUse
                        acceptTerms={props.acceptTerms}
                        setAcceptTerms={props.setAcceptTerms}
                        seeTerms={props.seeTerms}
                        setSeeTerms={props.setSeeTerms}
                    />
            }              
        </>
    )
}



function AcceptBox (props){
    return(
        <>
        
        
            {
                props.acceptTerms === true
                ?  null
                : 
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100vw',
                            height: '100vh',
                            background: 'rgba(250,250,250,0.4)',
                            zIndex: '10',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        }}
                    >
                        <div
                            style={{
                                background: '#FFF',
                                height: '300px',
                                boxShadow: '0 4px 8px 0 rgba(0,0,0, .2), 0 3px 10px 0 rgba(0,0,0, .15)',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0 20px',
                            }}
                        >
                            <div
                                style={{
                                    border: "1px solid black",
                                    background: '#FFF',
                                    borderRadius: "5px",
                                    width: '30px',
                                    height: '30px',
                                    margin: '0 10px 0 0',

                                }}
                                onClick={() => props.setAcceptTerms(true)}
                            >

                            </div>
                            <div
                                style={{
                                    fontSize: '34px',
                                    
                                    
                                }}
                            >
                                I have read and agree to the  
                                <span
                                    onClick={() => props.setSeeTerms(true)}
                                    style={{
                                        color: 'blue',
                                        margin: "0 0 0 7px",
                                    }}
                                >
                                    terms of use
                                </span>
                            </div>
                        </div>
                        
                    </div>
            }
        </>
        
    )
}


function TextOfUse (props){
    return(
        <div
            style={{
                display: 'flex',
                width: '100vw',
                padding: '40px 0 150px 0',
                background: 'rgba(250,250,250,1)',
                zIndex: '10',
                position: 'absolute',
                top: '0',
                left: '0',
            }}
        >
            <div style={{width: "7vw"}}></div>
            <div style={{width: "84vw"}}>
                <p>
                    Welcome to Cataract Preop.  Please read the following terms and conditions carefully before using our website. By using our website, you agree to these terms and conditions.
                </p>

                <p>
                    Acceptance of Terms
                </p>
                <p>
                    By accessing or using our website, you agree to be bound by these terms and conditions, as well as any additional terms and conditions that are referenced herein or that are otherwise communicated to you.
                </p>
                <p>
                    Use of the Site
                </p>
                <p>
                    You may use the site for lawful purposes only. You may not use the site in any manner that could damage, disable, overburden, or impair the site or interfere with any other party's use and enjoyment of the site.
                </p>
                <p>
                    Software License
                </p>
                <p>
                    You may use the software provided on our website only for its intended purposes. You may not modify, adapt, translate, sell, reverse engineer, decompile, or disassemble the software or otherwise attempt to derive its source code.
                </p>
                <p>
                    Intellectual Property Rights
                </p>
                <p>
                    All content and materials on our website, including but not limited to, text, graphics, logos, images, and software, are the property of our company or its affiliates and are protected by copyright, trademark, patent, or other intellectual property laws. You may not use any content or materials on our website without our prior written consent.
                </p>
                <p>
                    Disclaimer of Warranties
                </p>
                <p>
                    The software and materials on our website are provided "as is" and without warranties of any kind, whether express or implied. We do not warrant that the software or materials on our website will meet your requirements, be error-free, or be available on an uninterrupted basis.
                </p>
                <p>
                    Limitation of Liability
                </p>
                <p>
                    Our company and its affiliates will not be liable for any damages of any kind arising from the use of our website or the software or materials provided on our website. This includes, but is not limited to, direct, indirect, incidental, punitive, and consequential damages. 
                </p>
                <p>
                    Indemnification
                </p>
                <p>
                    You agree to indemnify, defend, and hold harmless our company and its affiliates, officers, directors, employees, agents, and licensors from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our website or the software or materials provided on our website.
                </p>
                <p>
                    Modifications
                </p>
                <p>
                    We may modify these terms and conditions at any time without prior notice. Your continued use of our website following any such modification constitutes your agreement to be bound by the modified terms and conditions.
                </p>
                <p>
                    Governing Law
                </p>
                <p>
                    These terms and conditions shall be governed by and construed in accordance with the laws of Utah. Any disputes arising out of or in connection with these terms and conditions shall be resolved in accordance with the dispute resolution procedures set forth in Section 10.
                </p>
                <p>
                    Dispute Resolution
                </p>
                <p>
                    Any disputes arising out of or in connection with these terms and conditions shall be resolved through binding arbitration in accordance with the rules of the [insert arbitration association]. The arbitration shall take place in Utah. The arbitration award shall be final and binding, and judgment on the award may be entered in any court having jurisdiction thereof.
                </p>
                <p>
                    Termination
                </p>
                <p>
                    We may terminate your access to our website at any time, without notice, for any reason whatsoever.
                </p>
                <p>
                    Entire Agreement
                </p>
                <p>
                    These terms and conditions constitute the entire agreement between you and our company with respect to the use of our website and the software and materials provided on our website.
                    If you have any questions or comments about these terms and conditions, please contact us at cataractpreop@gmail.com.
                </p>      
                <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  margin: '60px 0 0 0',
                  }}>
                <div
                  onClick={() => {
                    props.setAcceptTerms(true)
                    props.setSeeTerms(false)
                    }}
                  style={{
                    borderRadius: '5px',
                    // width: '100px',
                    margin: '5px',
                    padding: '7px 20px',
                    background: '#007bff',
                    color: '#fff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  I have read and accept the terms of use
                </div>
                <div
                  onClick={() => {
                    props.setSeeTerms(false)
                    props.setAcceptTerms(false)
                    }}
                  style={{
                    borderRadius: '5px',
                    // width: '100px',
                    margin: '5px',
                    padding: '7px 20px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  I do not accept the terms of use
                </div>
              </div>
            </div>
            <div style={{width: "7vw"}}></div>
                
        </div>
    )
}




              