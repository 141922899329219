import React, { Fragment, useState, useEffect } from 'react';

import FormInputMargin from '../partials/FormInputMargin'
import FormInputMarginComment from '../partials/FormInputMarginComment'
import FormInputWithButton from '../partials/FormInputWithButton'
import FormInputWithButtonComment from '../partials/FormInputWithButtonComment'
// import FormRangeWithButton from '../partials/FormRangeWithButton'
import FormSelectMargin from '../partials/FormSelectMargin'
import FormSelect from '../partials/FormSelect'
import AddItem from '../partials/AddItem'
import AddConsent from '../partials/AddConsent'
import ReferenceArticle from '../partials/ReferenceArticle'
import CriQuestions from './CriQuestions'
import FlacsQuestions from './FlacsQuestions'

import api from "../services/api";

export default function Lens (props){

  const lensId = props.lensId
  const [lensName, setLensName] = useState(props.lensName)
  const [lensModel, setLensModel] = useState(props.lensModel)
  const [lensLocation, setLensLocation] = useState(props.lensLocation)
  const [lensKAstigMin, setLensKAstigMin] = useState(props.lensKAstigMin)
  const [lensKAstigMax, setLensKAstigMax] = useState(props.lensKAstigMax)
  const [lensKAstigResidual, setLensKAstigResidual] = useState(props.lensKAstigResidual)
  const [lensIntermediateVision, setLensIntermediateVision] = useState(props.lensIntermediateVision)
  const [lensNearVision, setLensNearVision] = useState(props.lensNearVision)
  const [lensGlare, setLensGlare] = useState (props.lensGlare)
  const [lensPhysicianFee, setLensPhysicianFee] = useState(props.lensPhysicianFee)
  const [lensHospitalFee, setLensHospitalFee] = useState(props.lensHospitalFee)
  const [lensSphAb, setLensSphAb] = useState(props.lensSphAb)
  const [lensKSphAbResidual, setLensKSphAbResidual] = useState(props.lensKSphAbResidual)
  const [lensHoaMax, setLensHoaMax] = useState(props.lensHoaMax)
  const [lensBioTopoAstigMax, setLensBioTopoAstigMax] = useState(props.lensBioTopoAstigMax)
  const [lensBioTopoAxisMax, setLensBioTopoAxisMax] = useState(props.lensBioTopoAxisMax)
  const [lensChordMuMax, setLensChordMuMax] = useState(props.lensChordMuMax)
  const [lensBiometerStdDevMax, setLensBiometerStdDevMax] = useState(props.lensBiometerStdDevMax)
  const [lensCriMinAstig, setLensCriMinAstig] = useState(props.lensCriMinAstig)
  const [lensCriMaxAstig, setLensCriMaxAstig] = useState(props.lensCriMaxAstig)
  const [lensCriRequire, setLensCriRequire] = useState(props.lensCriRequire)
  const [lensCriFee, setLensCriFee] = useState(props.lensCriFee)
  const [lensFlacsRequire, setLensFlacsRequire] = useState(props.lensFlacsRequire)
  const [lensFlacsFee, setLensFlacsFee] = useState(props.lensFlacsFee)
  const [contraindications, setContraindications] = useState(props.contraindications)
  const [consents, setConsents] = useState(props.consents)


  //++++++++++++++ERROR MESSAGE HANDLING++++++++++++++++++++++++++++++++++++++++++++
  //create a hook so that you can store the messages in state so they show up in JSX tags
  const[errorMessage, setErrorMessage]=useState([])
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  const updateLens = async(e) => {
    e.preventDefault();
    try {



      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        //create blank array to send error messages
        let errorMessageArr = [];

        //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false

        let propsArr = [];
          if(
            lensKAstigMin > 0
            ||
            (props.criOffer === "No" && props.flacsOffer === "No")
          ){
            propsArr =
            [
              {name: `${lensName}`, message: '*Please enter the lens name'},
              {name: `${lensModel}`, message: '*Please enter the lens model number (can be same as lens name)'},
              {name: `${lensLocation}`, message: '*Please enter lens location'},
              {name: `${lensPhysicianFee}`, message: '*Please enter out-of-pocket fee for the proceedure'},
              {name: `${lensHospitalFee}`, message: '*Please enter out-of-pocket fee for the lens'},
              {name: `${lensKAstigMin}`, message: '*Please enter miNimum astigmatism corrected by lens'},
              {name: `${lensKAstigMax}`, message: '*Please enter maXimum astigmatism corrected by lens'},
              {name: `${lensKAstigResidual}`, message: '*Please enter residual astigmatism tolerated by the lens'},
              {name: `${lensIntermediateVision}`, message: '*Please choose whether or not the lens corrects intermediate vision'},
              {name: `${lensNearVision}`, message: '*Please choose whether or not the lens corrects near vision'},
              {name: `${lensGlare}`, message: '*Please choose what type glare pattern this lens produces around a point of light'},
              {name: `${lensSphAb}`, message: '*Please enter the spherical aberration of the lens'},
              {name: `${lensKSphAbResidual}`, message: '*Please enter the maximum tolerated residual spherical aberration for this lens'},
              {name: `${lensHoaMax}`, message: '*Please enter the maXimum corneal higher order aberration for this lens'},
              {name: `${lensChordMuMax}`, message: '*Please enter the maXimum chord mu value for this lens'},
              {name: `${lensBioTopoAstigMax}`, message: '*Please enter the maXimum ASTIGMATISM difference between biometer and topographer'},
              {name: `${lensBioTopoAxisMax}`, message: '*Please enter the maXimum AXIS difference between biometer and topographer'},
            ]
          } else if (
            props.criOffer === "No" 
            && 
            props.flacsOffer === "Yes"
            (
              lensFlacsRequire === "Yes (always)"
              ||
              lensFlacsRequire === "Yes (only with CRI)"
              ||
              lensFlacsRequire === "Optional (always offered)"
            )
            ){
            propsArr =
              [
                {name: `${lensName}`, message: '*Please enter the lens name'},
                {name: `${lensModel}`, message: '*Please enter the lens model number (can be same as lens name)'},
                {name: `${lensLocation}`, message: '*Please enter lens location'},
                {name: `${lensPhysicianFee}`, message: '*Please enter out-of-pocket fee for the proceedure'},
                {name: `${lensHospitalFee}`, message: '*Please enter out-of-pocket fee for the lens'},
                {name: `${lensKAstigMin}`, message: '*Please enter miNimum astigmatism corrected by lens'},
                {name: `${lensKAstigMax}`, message: '*Please enter maXimum astigmatism corrected by lens'},
                {name: `${lensKAstigResidual}`, message: '*Please enter residual astigmatism tolerated by the lens'},
                {name: `${lensIntermediateVision}`, message: '*Please choose whether or not the lens corrects intermediate vision'},
                {name: `${lensNearVision}`, message: '*Please choose whether or not the lens corrects near vision'},
                {name: `${lensGlare}`, message: '*Please choose what type glare pattern this lens produces around a point of light'},
                {name: `${lensSphAb}`, message: '*Please enter the spherical aberration of the lens'},
                {name: `${lensKSphAbResidual}`, message: '*Please enter the maximum tolerated residual spherical aberration for this lens'},
                {name: `${lensHoaMax}`, message: '*Please enter the maXimum corneal higher order aberration for this lens'},
                {name: `${lensChordMuMax}`, message: '*Please enter the maXimum chord mu value for this lens'},
                {name: `${lensBioTopoAstigMax}`, message: '*Please enter the maXimum ASTIGMATISM difference between biometer and topographer'},
                {name: `${lensBioTopoAxisMax}`, message: '*Please enter the maXimum AXIS difference between biometer and topographer'},
                {name: `${lensFlacsRequire}`, message: '*Please choose whether or not you require FLACS for this lens'},
                {name: `${lensFlacsFee}`, message: '*Please enter out-of-pocket physician fee for FLACS for this lens'},
              ]
          } else if (
              props.criOffer === "Yes" 
              && 
              (
                lensCriRequire === "Yes (always)"
                ||
                lensCriRequire === "Optional (always offered)"
              )
              && 
              props.flacsOffer === "No"
            ){
            propsArr =
              [
                {name: `${lensName}`, message: '*Please enter the lens name'},
                {name: `${lensModel}`, message: '*Please enter the lens model number (can be same as lens name)'},
                {name: `${lensLocation}`, message: '*Please enter lens location'},
                {name: `${lensPhysicianFee}`, message: '*Please enter out-of-pocket fee for the proceedure'},
                {name: `${lensHospitalFee}`, message: '*Please enter out-of-pocket fee for the lens'},
                {name: `${lensKAstigMin}`, message: '*Please enter miNimum astigmatism corrected by lens'},
                {name: `${lensKAstigMax}`, message: '*Please enter maXimum astigmatism corrected by lens'},
                {name: `${lensKAstigResidual}`, message: '*Please enter residual astigmatism tolerated by the lens'},
                {name: `${lensIntermediateVision}`, message: '*Please choose whether or not the lens corrects intermediate vision'},
                {name: `${lensNearVision}`, message: '*Please choose whether or not the lens corrects near vision'},
                {name: `${lensGlare}`, message: '*Please choose what type glare pattern this lens produces around a point of light'},
                {name: `${lensSphAb}`, message: '*Please enter the spherical aberration of the lens'},
                {name: `${lensKSphAbResidual}`, message: '*Please enter the maximum tolerated residual spherical aberration for this lens'},
                {name: `${lensHoaMax}`, message: '*Please enter the maXimum corneal higher order aberration for this lens'},
                {name: `${lensChordMuMax}`, message: '*Please enter the maXimum chord mu value for this lens'},
                {name: `${lensBioTopoAstigMax}`, message: '*Please enter the maXimum ASTIGMATISM difference between biometer and topographer'},
                {name: `${lensBioTopoAxisMax}`, message: '*Please enter the maXimum AXIS difference between biometer and topographer'},
                {name: `${lensCriMinAstig}`, message: '*Please enter the miNumum astigmatism cutoff for corneal relaxing incisions'},
                {name: `${lensCriMaxAstig}`, message: '*Please enter the maXumum astigmatism cutoff for corneal relaxing incisions'},
                {name: `${lensCriRequire}`, message: '*Please choose whether or not you require CRIs for this lens'},
                {name: `${lensCriFee}`, message: '*Please enter out-of-pocket physician fee for CRI for this lens'},
              ]
          } else if (
            props.criOffer === "Yes" 
            && 
            (
              lensCriRequire === "Yes (always)"
              ||
              lensCriRequire === "Optional (always offered)"
            )
            && 
            props.flacsOffer === "Yes"
            &&
            (
              lensFlacsRequire === "Yes (always)"
              ||
              lensFlacsRequire === "Yes (only with CRI)"
              ||
              lensFlacsRequire === "Optional (always offered)"
            )
          ){
            propsArr =
              [
                {name: `${lensName}`, message: '*Please enter the lens name'},
                {name: `${lensModel}`, message: '*Please enter the lens model number (can be same as lens name)'},
                {name: `${lensLocation}`, message: '*Please enter lens location'},
                {name: `${lensPhysicianFee}`, message: '*Please enter out-of-pocket fee for the proceedure'},
                {name: `${lensHospitalFee}`, message: '*Please enter out-of-pocket fee for the lens'},
                {name: `${lensKAstigMin}`, message: '*Please enter miNimum astigmatism corrected by lens'},
                {name: `${lensKAstigMax}`, message: '*Please enter maXimum astigmatism corrected by lens'},
                {name: `${lensKAstigResidual}`, message: '*Please enter residual astigmatism tolerated by the lens'},
                {name: `${lensIntermediateVision}`, message: '*Please choose whether or not the lens corrects intermediate vision'},
                {name: `${lensNearVision}`, message: '*Please choose whether or not the lens corrects near vision'},
                {name: `${lensGlare}`, message: '*Please choose what type glare pattern this lens produces around a point of light'},
                {name: `${lensSphAb}`, message: '*Please enter the spherical aberration of the lens'},
                {name: `${lensKSphAbResidual}`, message: '*Please enter the maximum tolerated residual spherical aberration for this lens'},
                {name: `${lensHoaMax}`, message: '*Please enter the maXimum corneal higher order aberration for this lens'},
                {name: `${lensChordMuMax}`, message: '*Please enter the maXimum chord mu value for this lens'},
                {name: `${lensBioTopoAstigMax}`, message: '*Please enter the maXimum ASTIGMATISM difference between biometer and topographer'},
                {name: `${lensBioTopoAxisMax}`, message: '*Please enter the maXimum AXIS difference between biometer and topographer'},
                {name: `${lensCriMinAstig}`, message: '*Please enter the miNumum astigmatism cutoff for corneal relaxing incisions'},
                {name: `${lensCriMaxAstig}`, message: '*Please enter the maXumum astigmatism cutoff for corneal relaxing incisions'},
                {name: `${lensCriRequire}`, message: '*Please choose whether or not you require CRIs for this lens'},
                {name: `${lensCriFee}`, message: '*Please enter out-of-pocket physician fee for CRI for this lens'},
                {name: `${lensFlacsRequire}`, message: '*Please choose whether or not you require FLACS for this lens'},
                {name: `${lensFlacsFee}`, message: '*Please enter out-of-pocket physician fee for FLACS for this lens'},
              ]
          } else {
            propsArr =
              [
                {name: `${lensName}`, message: '*Please enter the lens name'},
                {name: `${lensModel}`, message: '*Please enter the lens model number (can be same as lens name)'},
                {name: `${lensLocation}`, message: '*Please enter lens location'},
                {name: `${lensPhysicianFee}`, message: '*Please enter out-of-pocket fee for the proceedure'},
                {name: `${lensHospitalFee}`, message: '*Please enter out-of-pocket fee for the lens'},
                {name: `${lensKAstigMin}`, message: '*Please enter miNimum astigmatism corrected by lens'},
                {name: `${lensKAstigMax}`, message: '*Please enter maXimum astigmatism corrected by lens'},
                {name: `${lensKAstigResidual}`, message: '*Please enter residual astigmatism tolerated by the lens'},
                {name: `${lensIntermediateVision}`, message: '*Please choose whether or not the lens corrects intermediate vision'},
                {name: `${lensNearVision}`, message: '*Please choose whether or not the lens corrects near vision'},
                {name: `${lensGlare}`, message: '*Please choose what type glare pattern this lens produces around a point of light'},
                {name: `${lensSphAb}`, message: '*Please enter the spherical aberration of the lens'},
                {name: `${lensKSphAbResidual}`, message: '*Please enter the maximum tolerated residual spherical aberration for this lens'},
                {name: `${lensHoaMax}`, message: '*Please enter the maXimum corneal higher order aberration for this lens'},
                {name: `${lensChordMuMax}`, message: '*Please enter the maXimum chord mu value for this lens'},
                {name: `${lensBioTopoAstigMax}`, message: '*Please enter the maXimum ASTIGMATISM difference between biometer and topographer'},
                {name: `${lensBioTopoAxisMax}`, message: '*Please enter the maXimum AXIS difference between biometer and topographer'},
                {name: `${lensCriRequire}`, message: '*Please choose whether or not you require CRIs for this lens'},
                {name: `${lensFlacsRequire}`, message: '*Please choose whether or not you require FLACS for this lens'},
              ]
          }


        //Map through props you are interested in and send error message to errorMessageArr if there is an error

        Object.entries(propsArr).map(p => {
            if(p[1].name === '' || p[1].name=== "Choose an option below"){
              // console.log(p[1].message)
              return errorMessageArr.push(p[1].message)
            } else {
              return null
            }

          })
          setErrorMessage(errorMessageArr)
          //if there are errors, do not complete "put" or "update" function
          if(errorMessageArr.length > 0){
            return null
          } else {

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


      await api.put(`/lens`, {
        lensId,
        lensName,
        lensModel,
        lensLocation,
        lensKAstigMin,
        lensKAstigMax,
        lensKAstigResidual,
        lensIntermediateVision,
        lensNearVision,
        lensGlare,
        lensPhysicianFee,
        lensHospitalFee,
        lensSphAb,
        lensKSphAbResidual,
        lensHoaMax,
        lensBioTopoAstigMax,
        lensBioTopoAxisMax,
        lensChordMuMax,
        lensBiometerStdDevMax,
        lensCriMinAstig,
        lensCriMaxAstig,
        lensCriRequire,
        lensFlacsRequire,
        lensCriFee,
        lensFlacsFee,
        contraindications,
        consents,
      }).then(window.location = `/lenses`)

    }}
      catch (err) {
       console.error(err.message)
    }
  }




//DELETE single lens function
const deleteLens = async (e) => {
  e.preventDefault();
  try {
    await api.delete(`/lens`, {
      headers: {
        // Authorization: authorizationToken
      },
      data: {
        lensId: lensId
      }
    }).then(window.location = `/lenses`)
    // add the line above so that project list automatically shows new shorter list when project is deleted
  } catch (err) {
      console.error(err.message)
  }
}


  let pStyle = {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '5px 5px 5px 30px',
    width: '200px',
  }

  let editStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: '500',
    margin: '5px',
    padding: '2px 0 2px 0',
    width: '65px',
    color: '#007bff',
    cursor: 'pointer'
  }

  let lensLocationStyle = {
     fontFamily: 'Montserrat',
     fontSize: '14px',
     fontWeight: '600',
     textAlign: 'left',
     margin: '5px 5px 5px 5px',
   }

   let warnStyle ={
     fontSize: '17px',
     margin: '3px 0 0 0',
     fontFamily: 'Montserrat',
     fontWeight: '600',
     color: 'red',
   }


  return(
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      {
        props.modal === props.lensId
        ?
          <Fragment>
            <form
              style={{
                background: '#F2F3F4',
                borderRadius: '10px',
                padding: '0px 0 50px 20px',
                margin: '20px 0 20px 30px',
                width: '500px',
              }}>
              <div style = {{
                  padding: '5px 5px 15px 10px',
                }}>
                <FormInputMargin
                  label="Name:"
                  value={lensName}
                  setHook={setLensName}
                  />
                <FormInputMargin
                  label="Model number:"
                  value={lensModel}
                  setHook={setLensModel}
                  />
                <FormSelectMargin
                  label="Intraocular location of lens:"
                  value={lensLocation}
                  setHook={setLensLocation}
                  options={["Posterior chamber (typical)", "Sulcus", "Anterior Chamber"]}
                  />
                <FormInputMargin
                  label="Out-of-pocket fee for procedure ($):"
                  value={lensPhysicianFee}
                  setHook={setLensPhysicianFee}
                  type={'number'}
                  />
                <FormInputMargin
                  label="Out-of-pocket fee for lens ($):"
                  value={lensHospitalFee}
                  setHook={setLensHospitalFee}
                  type={'number'}
                  />
                <FormInputMarginComment
                  label="MiNimum amount of corneal astigmatism corrected by this lens (D):"
                  value={lensKAstigMin}
                  setHook={setLensKAstigMin}
                  type={'number'}
                  comment={['*Enter "0" for any non-toric lens']}
                  />
                <FormInputMarginComment
                  label="MaXimum amount of corneal astigmatism corrected by this lens (D):"
                  value={lensKAstigMax}
                  setHook={setLensKAstigMax}
                  type={'number'}
                  comment={['*Enter "0" for any non-toric lens']}
                  />
                <FormInputWithButtonComment
                  label="How much residual astigmatism will the lens tolerate (D):"
                  value={lensKAstigResidual}
                  setHook={setLensKAstigResidual}
                  type={'number'}
                  comment={['*Turn "off" if there is no limit']}
                  />
                <FormSelectMargin
                  label="Corrects intermediate vision:"
                  value={lensIntermediateVision}
                  setHook={setLensIntermediateVision}
                  options={["Yes", "No"]}
                  />
                <FormSelectMargin
                  label="Corrects near vision:"
                  value={lensNearVision}
                  setHook={setLensNearVision}
                  options={["Yes", "No"]}
                  />
                <FormSelectMargin
                  label="Glare pattern for this lens:"
                  value={lensGlare}
                  setHook={setLensGlare}
                  options={["Glare", "Halo", "EDOF rings"]}
                  />
                <FormInputMargin
                  label="Lens spherical aberration (µm):"
                  value={lensSphAb}
                  setHook={setLensSphAb}
                  type={'number'}
                  />
                  <div style={{position: 'relative'}}>
                    <FormInputWithButtonComment
                      label="How much postoperative residual spherical aberration z(4,0) will this lens tolerate for a 6 mm zone (µm)?"
                      value={lensKSphAbResidual}
                      setHook={setLensKSphAbResidual}
                      type={'number'}
                      comment={['Turn "off" if this is a monofocal lens or their is no limit']}
                      />
                    <ReferenceArticle
                      data={
                        [
                          {
                            author: "Jack T. Holladay",
                            year: "2006",
                            title: "Spherical Aberration: The Next Frontier",
                            publication: "Cataract & Refractive Surgery Today",
                            link: 'https://crstoday.com/articles/2006-nov/crst1106_18-php'
                          },
                        ]
                      }
                      />
                  </div>
                  <div style={{position: 'relative'}}>
                    <FormInputWithButton
                      label=
                        {props.topographer === "Pentacam"
                          ? "maXimum corneal higher order aberration for 4mm zone (µm):"
                          : "maXimum corneal higher order aberration for 6mm zone (µm):"
                        }
                      value={lensHoaMax}
                      setHook={setLensHoaMax}
                      type={'number'}
                      />
                      <ReferenceArticle
                        data={
                          [
                            {
                              author: "Naoyuki Maeda",
                              year: "2011",
                              title: "Assessment of Corneal Optical Quality for Premium IOLs with Pentacam",
                              publication: "Highlights of Ophthalmology",
                              link: 'https://www.pentacam.com/fileadmin/user_upload/pentacam.de/downloads/publikationen/artikel/2011_assessment_of_corneal_optical_quality.pdf'
                            },
                            {
                              author: "Michael Belin",
                              year: "2015",
                              title: "Clinical Pearls for Utilizing the OCULUS Pentacam",
                              publication: "Oculus online seminar",
                              link: 'https://www.youtube.com/watch?v=ci0ICuj2EG4'
                            },
                            {
                              author: "Amar Agarwal",
                              year: "2019",
                              title: "Chord mu: a new reference marker and its clinical relevance",
                              publication: "Occular Surgery News",
                              link: 'https://www.healio.com/news/ophthalmology/20191007/chord-mu-a-new-reference-marker-and-its-clinical-relevance#:~:text=As%20suggested%20by%20Chang%20and,coaxially%20sighted%20corneal%20light%20reflex'
                            },
                          ]
                        }
                        />
                  </div>
                  <div style={{position: 'relative'}}>
                    <FormInputWithButton
                      label="maXimum chord mu (mm):"
                      value={lensChordMuMax}
                      setHook={setLensChordMuMax}
                      type={'number'}
                      />
                    <ReferenceArticle
                      data={[
                        {
                          author: "Jack T. Holladay",
                          year: "2018",
                          title: "Hidden Figures",
                          publication: "Cataract & Refractive Surgery Today",
                          link: 'https://crstoday.com/wp-content/uploads/sites/4/2018/09/0918CRST_cs_Holladay.pdf'
                        },
                        {
                          author: "Amar Agarwal",
                          year: "2019",
                          title: "Chord mu: a new reference marker and its clinical relevance",
                          publication: "Occular Surgery News",
                          link: 'https://www.healio.com/news/ophthalmology/20191007/chord-mu-a-new-reference-marker-and-its-clinical-relevance#:~:text=As%20suggested%20by%20Chang%20and,coaxially%20sighted%20corneal%20light%20reflex'
                        },
                      ]}
                    />
                  </div>
                  <div style={{position: 'relative'}}>
                    <FormInputWithButton
                      label="maXimum biometer keratometry standard deviation (mm):"
                      value={lensBiometerStdDevMax}
                      setHook={setLensBiometerStdDevMax}
                      type={'number'}
                      />
                    <ReferenceArticle
                      data={[
                        {
                          author: "Jack T. Holladay",
                          year: "2018",
                          title: "Hidden Figures",
                          publication: "Cataract & Refractive Surgery Today",
                          link: 'https://crstoday.com/wp-content/uploads/sites/4/2018/09/0918CRST_cs_Holladay.pdf'
                        },
                      ]}
                    />
                  </div>
                  <div style={{position: 'relative'}}>
                    <FormInputWithButton
                      label="maXimum astigmatism difference between topographer and biometer (D):"
                      value={lensBioTopoAstigMax}
                      setHook={setLensBioTopoAstigMax}
                      type={'number'}
                      />
                    <ReferenceArticle
                      data={[
                        {
                          author: "Douglas Koch",
                          year: "2020",
                          title: "20happy: ASCRS Master Class in Refractive Cataract surgery.  Module 1",
                          publication: "ASCRS",
                          link: 'https://ascrs.org/20happy'
                        },
                      ]}
                    />
                  </div>
                  <div style={{position: 'relative'}}>
                    <FormInputWithButton
                      label="maXimum axis difference between topographer and biometer:"
                      value={lensBioTopoAxisMax}
                      setHook={setLensBioTopoAxisMax}
                      type={'number'}
                      />
                    <ReferenceArticle
                      data={[
                        {
                          author: "Douglas Koch",
                          year: "2020",
                          title: "20happy: ASCRS Master Class in Refractive Cataract surgery.  Module 1",
                          publication: "ASCRS",
                          link: 'https://ascrs.org/20happy'
                        },
                      ]}
                    />
                  </div>
                  {
                (
                  props.flacsOffer === "Yes"
                  &&
                  props.criOffer === "Yes"
                  &&
                  lensKAstigMin*1 > 0
                )
                  ?
                    <FlacsQuestions
                      lensFlacsRequire={lensFlacsRequire}
                      setLensFlacsRequire={setLensFlacsRequire}
                      lensFlacsFee={lensFlacsFee}
                      setLensFlacsFee={setLensFlacsFee}
                      />
                  : 
                    (
                      props.flacsOffer === "Yes"
                      &&
                      props.criOffer === "Yes"
                      &&
                      lensKAstigMin*1 === 0
                    )
                      ?
                        <Fragment>
                          <CriQuestions
                            lensCriMinAstig={lensCriMinAstig}
                            setLensCriMinAstig={setLensCriMinAstig}
                            lensCriMaxAstig={lensCriMaxAstig}
                            setLensCriMaxAstig={setLensCriMaxAstig}
                            lensCriRequire={lensCriRequire}
                            setLensCriRequire={setLensCriRequire}
                            lensCriFee={lensCriFee}
                            setLensCriFee={setLensCriFee}
                            />
                          <FlacsQuestions
                            lensFlacsRequire={lensFlacsRequire}
                            setLensFlacsRequire={setLensFlacsRequire}
                            lensCriRequire={lensCriRequire}
                            lensCriMinAstig={lensCriMinAstig}
                            lensCriMaxAstig={lensCriMaxAstig}
                            lensFlacsFee={lensFlacsFee}
                            setLensFlacsFee={setLensFlacsFee}
                            />
                        </Fragment>
                      :
                        (
                          props.flacsOffer === "Yes"
                          &&
                          props.criOffer === "No"
                        )
                        ?
                          <FlacsQuestions
                            lensFlacsRequire={lensFlacsRequire}
                            setLensFlacsRequire={setLensFlacsRequire}
                            lensFlacsFee={lensFlacsFee}
                            setLensFlacsFee={setLensFlacsFee}
                            />
                        : (
                            props.flacsOffer === "No"
                            &&
                            props.criOffer === "Yes"
                          )
                          ?
                            <CriQuestions
                              lensCriMinAstig={lensCriMinAstig}
                              setLensCriMinAstig={setLensCriMinAstig}
                              lensCriMaxAstig={lensCriMaxAstig}
                              setLensCriMaxAstig={setLensCriMaxAstig}
                              lensCriRequire={lensCriRequire}
                              setLensCriRequire={setLensCriRequire}
                              lensCriFee={lensCriFee}
                              setLensCriFee={setLensCriFee}
                              />
                          : null
              }
              </div>
              <AddItem
                label="Lens contraindications"
                hook={contraindications}
                setHook={setContraindications}
                />
              <AddConsent
                label="Consent forms:"
                consents={consents}
                setConsents={setConsents}
                />
              <div
                style={{
                   justifyContent: 'left',
                   margin: '0 0 50px 0px',
                 }}>
                {errorMessage.map((m, i) => (
                    <p
                      key={i}
                      style={warnStyle}
                      >
                      {m}
                    </p>
                  ))
                }
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div
                  onClick={(e => updateLens(e))}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#007bff',
                    color: '#fff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Save
                </div>
                <div
                  onClick={() => window.location = `/lenses`}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Cancel
                </div>
                <div
                  onClick={(e => deleteLens(e))}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#F25835',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Delete
                </div>
              </div>
            </form>
            </Fragment>
        :
        <div
          style={{
            display: 'flex',
          }}
          >
          <p style = {pStyle}>
            {lensName}
            {
              (lensLocation === 'Sulcus')
                ? <span style={lensLocationStyle}> (*Sulcus lens*)</span>
                : (lensLocation === 'Anterior Chamber')
                  ? <span style={lensLocationStyle}> (*AC-IOL*)</span>
                  : null
            }
          </p>
          <div
            style={editStyle}
            onClick={(e) => props.onLensClick(props.lensId)}
            >
            EDIT
          </div>
        </div>
      }
    </div>
  )
}





// //Check to see if FLACS is not shown but required
// useEffect(() => {
//   if(
//       props.flacsOffer === "Yes"
//       &&
//       props.criOffer === "Yes"
//       &&
//       lensKAstigMin*1 === 0
//       &&
//       lensCriRequire === "Yes (always)"
//     ){
//       setLensFlacsRequire("Yes (always)")
//     }
// }, [lensCriRequire]);
