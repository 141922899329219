import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";

import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, Modifier} from 'draft-js';
import 'draft-js/dist/Draft.css';
import './consentStyle.css';
import InlineStyleControls from './partials/InlineStyleControls'
import BlockStyleControls from './partials/BlockStyleControls'
import TemplateToolbar from './partials/TemplateToolbar'
import FormSelect from '../../partials/FormSelect'
import FormInput from '../../partials/FormInput'

export default function ConsentEdit (props) {

  const fromDatabase =
    (props.consentData !== '')
      ? convertFromRaw(JSON.parse(props.consentData.consent_data))
      : ''
  const [editorState, setEditorState] = useState(
    (fromDatabase.size > 0)
      ? EditorState.createWithContent(fromDatabase)
      : EditorState.createEmpty()

  )
  const [consentId, setConsentId] = useState(props.consentId)

  const [consentName, setConsentName] = useState(props.consentName)
  const [consentType, setConsentType] = useState(props.consentType)
  const [consentData, setConsentData] = useState({})
  const [errorMessage, setErrorMessage] = useState([])

  const editor = useRef(null);

  const focusEditor = () => {
     editor.current.focus();
   }


   // Post to consent database
   const postConsent = async(e) => { 
     e.preventDefault();
     
     //create blank array to send error messages
     let errorMessageArr = [];

     //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false.
    let errArr = [
      {name: `${consentName}`, message: '*Please name your consent form'},
      {name: `${consentType}`, message: '*Please select a consent type'},
      {name: `${consentData}`, message: '*Cannot save an empty consent form.'},
    ];


     //Map through props you are interested in and send error message to errorMessageArr if there is an error

     Object.entries(errArr).map(p => {
         if(p[1].name === ''){
           // console.log(p[1].message)
           return errorMessageArr.push(p[1].message)
         } else {
           return null
         }

       })
       // console.log(errorMessageArr)
       setErrorMessage(errorMessageArr);
  
        if (errorMessageArr.length > 0) {
          const timer = setTimeout(() => {
            setErrorMessage(['']);
          }, 3000);
          return () => clearTimeout(timer);
        } else {

   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

       try {
         await api.post(`/consent` , {
           consentName,
           consentData,
           consentType,
         }).then(window.location =`/consents` )

     } catch (err) {
       console.error(err.message)
     }
   }}

   // Update consent database
   const updateConsent = async(e) => {
     e.preventDefault();
     // alert('update route')
       try {
         await api.put(`/consent` , {
           consentId,
           consentName,
           consentData,
           consentType,
         }).then(window.location = `/consents` )
         }
       catch (err) {
        console.error(err.message)
     }
   }

   // Delete consent from database
   const deleteConsent = async(e) => {
     e.preventDefault();
       try {
         await api.delete(`/consent` , {
           headers: {
             // Authorization: authorizationToken
           },
           data: {
             consentId: consentId
           }
         }).then(window.location = `/consents`)
       }
       catch (err) {
         console.error(err.message)
       }
   }

   const handleKeyCommand = (command, editorState) => {
    if (command === 'backspace') {
      const selection = editorState.getSelection();
      if (selection.isCollapsed() && selection.getStartOffset() === 0) {
        // Handle backspace at the beginning of the content
        return 'handled';
      }
    }
  
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // const onTab = (e) => {
  //   const maxDepth = 4;
  //   setEditorState(RichUtils.onTab(e, editorState, maxDepth))
  // }

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType))
  }

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor';

  let contentState = editorState && editorState.getCurrentContent();

  if (contentState && !contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  // console.log(convertToRaw(contentState));

  const saveContent = () => {
    const rawData = convertToRaw(contentState);
      // console.log('raw data', rawData)
    const stringyData = JSON.stringify(rawData)
      // console.log('string data', stringyData)
    setConsentData(stringyData);
  }


  // Custom overrides for "code" style.
  const styleMap = {
    CODE: {
      backgroundColor: '#F8FF00',
    },
    bolded: {
      fontWeight: '600'
    }
  };

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case 'title': return 'RichEditor-title';
      case 'header': return 'RichEditor-header';
      case 'paragraph': return 'RichEditor-paragraph';
      case 'small-font': return 'RichEditor-small-font';
      case 'watermark': return 'RichEditor-watermark';
      // case "leftAlign":
      //   return "RichEditor-leftAlign";
      // case "rightAlign":
      //   return "RichEditor-rightAlign";
      // case "centerAlign":
      //   return "RichEditor-centerAlign";
      default: return null;
    }
  }

  useEffect(() => {
    focusEditor()
    saveContent()
  }, []);


  const handleTemplateInsert = (templateContent) => {
    // console.log(templateContent)
    // console.log(editorState)
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
  
    // Create a new ContentState with the templateContent inserted at the current selection
    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      templateContent
    );
  
    // console.log(newContentState)
    // Update the EditorState with the new ContentState
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    setEditorState(newEditorState);
  };
  
  let warnStyle ={
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

  
  return (
    <div
      className="RichEditor-root"
      style={{
        width: '700px',
      }}
      >
      <div
        style={{
          display: 'flex',
          position: 'fixed',
          background: "#FFF",
          padding: '10px',
          width: '100%',
          zIndex: '2',
          margin: '0',
        }}
        >
          <div>
            <div style={{display: 'flex'}}>
              <InlineStyleControls
                editorState={editorState}
                onToggle={toggleInlineStyle}
                />
              <BlockStyleControls
                editorState={editorState}
                onToggle={toggleBlockType}
                />
            </div>
              <TemplateToolbar 
                onTemplateInsert={handleTemplateInsert} 
              />
          </div>
      </div>
      <div
        className={className}
        onClick={focusEditor}
        style={{
          cursor: 'text',
          marginTop: '80px',
          padding: '50px 50px 150px 50px',
          background: '#EFEFEF',
          borderRadius: '5px',
          width: '800px',
        }}
        >
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          ref={editor}
          onChange={editorState => {
            setEditorState(editorState);
            saveContent();
          }}
          spellCheck={true}
          placeholder="Start typing..."
          />
      </div>
      
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '100px 0 150px 0',
        }}>
        <FormInput
          label="Name this consent:"
          value={consentName}
          setHook={setConsentName}
          />
        <FormSelect
          label={'What type of consent is this?'}
          value={consentType}
          setHook={setConsentType}
          options={
            (props.modal === 'new')
              ? ['Choose consent type...', 'Lens consent', 'CRI consent', 'FLACS consent', 'Financial disclosure', 'Other']
              : ['Lens consent', 'CRI consent', 'FLACS consent', 'Financial disclosure', 'Other']
            }
          />
        <div
          style={{display: 'flex'}}>
          <div
            onClick={(e => {
              props.modal === 'new'
                ? postConsent(e)
                : updateConsent(e)
              }
            )}
            style={{
              borderRadius: '5px',
              width: '100px',
              margin: '5px',
              padding: '7px',
              background: '#007bff',
              color: '#fff',
              fontSize: '20px',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            >
            Save
          </div>
          <div
            onClick={() => props.setModal('')}
            style={{
              borderRadius: '5px',
              width: '100px',
              margin: '5px',
              padding: '7px',
              background: '#292929',
              color: '#FFF',
              fontSize: '20px',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            >
            Cancel
          </div>
          {
            (props.modal === 'new')
            ? null
            :
            <div
                onClick={(e => deleteConsent(e))}
                style={{
                  borderRadius: '5px',
                  width: '100px',
                  margin: '5px',
                  padding: '7px',
                  background: '#F25835',
                  color: '#FFF',
                  fontSize: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                >
                Delete
              </div>
          }
        </div>
        <div
          style={{
              justifyContent: 'left',
              margin: '0 0 50px 280px',
            }}>
          {errorMessage.map((m, i) => (
              <p
                key={i}
                style={warnStyle}
                >
                {m}
              </p>
            ))
          }
      </div>
      </div>
    </div>
  );
}


// https://jsfiddle.net/m6z0xn4r/114/
