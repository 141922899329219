import React, { Fragment, useState, useEffect } from 'react';
import api from "../services/api";



export default function ContraindicationsPtInfo(props){

  //Get all lens contraindications
  //make hook to temporarily store all lens' contraindications
  // const [dbList, setDbList] = useState([])

  const getContraindications = async() => {

      try {
      const response = await api.get("/contraindications");
  
      if (response.data.rows.length !== 0 && response.data.rows !== undefined) {
        // Extract the contraindication names from the response
        const newArr = response.data.rows.flatMap(c => Object.values(c.contraindications).map(m => m.name));
  
        // Filter out duplicates and set all contraindication values to false
        const uniqueContraindications = [...new Set(newArr)].map(name => ({ name, value: 'false' }));
  
        // map the contraindications to set their values to true when they are true
        const textArray = props.contraindications;
        const mergedArr = uniqueContraindications.map(contra => {
          if (textArray.includes(contra.name)) {
            return { ...contra, value: 'true' };
          }
          return contra;
        });
  
        props.setContraindications(mergedArr);
      } else {
        props.setContraindications(staticContraindications)
      }
    } catch (err) {
      console.error(err.message);
    }
  };

    //Create new array that includes every contraindication from all lenses and set them to false so they can appear on the patient information page.
    // let usersContraindications = () => {
    //   if(props.contraindications.length > 1){
    //     return []
    //   } else {
    //
    //   }
    // }

    //get contraindications from database when page loads
    useEffect(() => {
      if(props.contraindications.length < 1){
        getContraindications()
      }
    }, []);


    //after contraindications are loaded from database, check to prevent overwriting, and if not overwriting then run usersContraindications
    // useEffect(() => (
    //   usersContraindications()
    // ), [dbList]);

    // toggle contraindications by using the following code:
    let handleClick = (c) => {
      // console.log(c[1].name + ": " + c[1].value)
      let currentName = c[1].name
      // console.log("name: " + currentName)
      let currentVal = c[1].value
      // console.log("value: " + currentVal)
      //use Object.entries to allow mapping of props.contraindications
      //have to access index of 1 because Object.entries puts index as first item in array
      const newArr = Object.entries(props.contraindications).map(m =>
        //if the object's name matches the clicked object's name
        (m[1].name === currentName)
          //then toggle true and false
          ? (currentVal === 'true')
            ? {...m[1], value: 'false'}
            : {...m[1], value: 'true'}
          //otherwise, if not clicked, just return the same object pair name and value
          : {...m[1]}
      )
        // console.log(newArr)
        props.setContraindications(newArr)
    }


  let divStyleFalse = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px solid #F2F3F4',
    padding: '0',
    margin: '2px',
    fontSize: '18px',
    color: '#7D7E7F',
    width: '135px',
    height: '60px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
  }

  let divStyleTrue = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px solid #4CBB17',
    padding: '0',
    margin: '2px',
    fontSize: '18px',
    fontWeight: '600',
    color: '#4CBB17',
    width: '135px',
    height: '60px',
    borderRadius: '5px',
    background: '#FFF',
    textAlign:'center',
  }


  return(
    <Fragment>
      <div
        style={{
            display: 'flex',
            flexWrap: 'wrap',
        }}
        >
        {Object.entries(props.contraindications).map((c) => (
          <Fragment
            key={c[1].name}>
            <div
              style={
                (c[1].value === 'true')
                  ? divStyleTrue
                  : divStyleFalse
                }
              onClick={(e) => handleClick(c)}
              >
              {c[1].name}
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  )
}




const staticContraindications = [
  {
    name: 'Lasik or PRK',
    value: 'false',
  },
  {
    name: 'Radial Keratotomy',
    value: 'false',
  },
  {
    name: 'Keratoconus',
    value: 'false',
  },
  {
    name: 'Corneal Guttata',
    value: 'false',
  },
  {
    name: 'Epiretinal membrane',
    value: 'false',
  },
  {
    name: 'Diabetic retinopathy',
    value: 'false',
  },
  {
    name: 'Macular degeneration',
    value: 'false',
  },
  {
    name: 'Retinal detachment',
    value: 'false',
  },
  {
    name: 'Amblyopia',
    value: 'false',
  },
  {
    name: 'Pterygium',
    value: 'false',
  },
]