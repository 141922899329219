

export default function FormInputMarginComment (props) {

  let inputDivStyle ={
    // display: 'flex',
    position: 'relative',
    margin: '10px 0 20px 0',
  }

  let inputStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '4px 0 4px 108px',
    padding: '5px',
    border: 'none',
    background: '#FFF',
    borderRadius: '7px',
    width: '330px'
  }

  let labelStyle = {
    margin: 'auto 30px auto 0',
    padding: '0 0 0 108px',
    textAlign: 'left',
    fontWeight: '600',
    width: '330px',
  }

  let rowStyle = {
    display: 'flex',
  }

  let commentStyle = {
    fontSize: '14px',
    margin: '0 0 0 110px',
    width: '330px'
  }

  return (
    <div style={inputDivStyle}>
      <p style={labelStyle}>{props.label}</p>
      <div style={rowStyle}>
        <div>
          </div>
          <div>
            {props.comment.map(e => (
              <p
                key={e}
                style={commentStyle}
                >
                {e}
              </p>
            ))}
            <input
              style = {inputStyle}
              value={props.value}
              onChange={(e) => props.setHook(e.target.value)}
              type={`${props.type}`}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        </div>
        <hr
          style={{
            width: '450px',
            position: 'absolute',
            left: '0',
            bottom: '-20px',
          }}/>
    </div>
  )
}
