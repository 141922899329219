import React, { useState } from 'react';

import { BsPrinter } from "react-icons/bs";

export default function PrintPage (props) {

  const[printArrow, setPrintArrow]=useState(false)

  let printArrowTrue = {
    color: '#007bff',
    fontSize: '82px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    right: '30px',
    zIndex: '12',
  }

  let printArrowFalse = {
    color: '#007bff',
    fontSize: '80px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    right: '30px',
    zIndex: '12',
    opacity: '0.7',
  }

  return (
    <BsPrinter
      className="dontPrint"
      onClick={(e) => window.print()}
      onMouseOver={() => setPrintArrow(true)}
      onMouseLeave={() => setPrintArrow(false)}
      style={
        printArrow === true
        ? printArrowTrue
        : printArrowFalse
      }
      />
  )
}
