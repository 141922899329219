import React from 'react'

export default function OcularNotes( props ) {

    if (!props) {
        return null; // Or return a loading state or an error message
      }
    
    // Use equipmentPreference for astigmatism
    let equipmentPreferenceAstig =
    props.equipmentPreference === props.biometer
        ? props.biometerAstig
        : props.equipmentPreference === props.topographer
        ? props.topographerAstig
        : null;
        // console.log(props.equipmentPreference)
        // console.log(props.biometer)
        // console.log(props.topographer)
        // console.log(equipmentPreferenceAstig)

    // Use equipmentPreference for axis
    let equipmentPreferenceAxis =
    props.equipmentPreference === props.biometer
        ? props.biometerAxis
        : props.equipmentPreference === props.topographer
        ? props.topographerAxis
        : null;

    // Check if props.power exists before accessing its properties
    let p =
    props.power && (props.power.toLowerCase() === 'plano' || props.power.toLowerCase() === 'pl' || props.power === '')
        ? 0
        : props.power * 1;

    // Check if props.cylinder exists before accessing its properties
    let c =
    props.cylinder &&
    (props.cylinder.toLowerCase() === 'sphere' || props.cylinder.toLowerCase() === 'sph' || props.cylinder === '')
        ? 0
        : props.cylinder * 0.5;



    let se = (p*1+c*1)


    let h4Style = {
    margin: '20px 0 3px 0',
    fontWeight: '400'
    }

    let minih4Style = {
    margin: '10px 0 3px 20px',
    fontWeight: '400',
    fontSize: '16px',
    }

    let liStyle = {
    margin: '0',
    padding: '0',
    }

    let twoLenses = '(2 please)'

    let flacs =
    (props.flacs === true)
    ? {margin: '0 0 0 20px', fontSize:'16px', borderRadius: '10px', border: '2px solid red', padding: '5px'}
    : {margin: '0 0 0 20px', fontSize:'16px', color: '#FFF'}

    let icd10 = (props.side === 'RIGHT')
    ? 'H25.11 or H25.811'
    : (props.side === 'LEFT')
        ? 'H25.12 or H25.812'
        : null

    let contraArray = [];
    if (props.contraindications) {
        Object.entries(props.contraindications).map((m) =>
        //if the object's name matches the clicked object's name
        m[1].value === 'true'
            //then toggle true and false
            ? contraArray.push(m[1].name.toLowerCase())
            : null
        );
    }
    // console.log(contraArray)

    let addOnLabel = props.cataractAddOn?.filter(c => c.value === 'true') || [];
    


  return (
    <>
        <div>
              
              <ul style={{
                listStyle: 'none',
                padding: '0',
                margin: '40px 0 0 0px',
                fontSize: '16px'
              }}>
                
                <li>Biometer: <span style = {{margin: '0 0 0 38px'}}></span> {props.biometerAstig === ''
                            ? ''
                            : `${props.biometerAstig} D @ ${props.biometerAxis}`
                          }
                </li>
                <li>
                  Chord mu:
                  <span style = {{margin: '0 0 0 34px'}}></span>
                  {`${props.chordMuTotal} mm`}
                </li>
                <li>Topographer:
                  <span style = {{margin: '0 0 0 10px'}}></span>
                  {props.topographerAstig === ''
                    ? ''
                    : `${props.topographerAstig} D @ ${props.topographerAxis}`
                  }
                </li>
                <li>Sph Ab: <span style = {{margin: '0 0 0 54px'}}></span>{props.topographerSphAb} µm</li>
                <li>HOA: <span style = {{margin: '0 0 0 76px'}}></span>{props.topographerHoa} µm</li>

                <li>{contraArray.find(c => c ==="lasik or prk")
                      ?
                        <div>
                          <p style={{margin: '0'}}>Hx of LASIK or PRK</p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 0 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 1 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 2 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 3 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 4 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Pentacam TNP 4mm zone: </p>
                        </div>
                      : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="radial keratotomy")
                      ?
                        <div>
                          <p style={{margin: '0'}}>Hx of RK</p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 1 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 2 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 3 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Atlas 4 mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Pentacam PWR SF 4mm: </p>
                          <p style={{margin: '0 0 0 10px'}}>Pentacam CT Min: </p>
                            {
                              props.lensTargetNear === true
                                ? <p style={{margin: '0 0 0 10px'}}>Target -2.75 for near </p>
                                : <p style={{margin: '0 0 0 10px'}}>Target -0.75 for distance </p>
                            }
                        </div>
                      : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="keratoconus")
                      ?
                        <div>
                          <p style={{margin: '0'}}>Hx of KCN</p>
                            {
                              props.lensTargetNear === true
                                ? <a
                                    href="http://calc.apacrs.org/Barrett_True_K_Universal_2105/"
                                    style={{textDecoration: 'none', color: '#333'}}
                                    >
                                    <p style={{margin: '0 0 0 10px'}}>
                                      Use Barrett True K
                                      <br></br>
                                      Target -2.75 for near
                                      <br></br>
                                      (-3.25 D if steep K > 47)
                                    </p>
                                  </a>
                                : <a
                                    href="http://calc.apacrs.org/Barrett_True_K_Universal_2105/"
                                    style={{textDecoration: 'none', color: '#333'}}
                                    >
                                    <p style={{margin: '0 0 0 10px'}}>
                                      Use Barrett True K
                                      <br></br>
                                      Target -0.75 for distance
                                      <br></br>
                                      (-1.25 D if steep K > 47)
                                    </p>
                                  </a>
                            }
                        </div>
                      : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="corneal guttata")
                      ? 'Hx of corneal guttata'
                      : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="epiretinal membrane")
                      ? 'Hx of ERM'
                      : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="diabetic retinopathy")
                      ? 'Hx of diabetic retinopathy'
                      : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="macular degeneration")
                      ? 'Hx of AMD'
                      : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="retinal detachment")
                      ? 'Hx of retinal detachment'
                    : ''
                    }
                </li>
                <li>{contraArray.find(c => c ==="amblyopia")
                      ?
                        <div>
                          <p style={{margin: '0'}}>Hx of amblyopia</p>
                          <p style={{margin: '0 0 0 10px'}}>BCVA as young adult: </p>
                        </div>
                      : ''
                    }
                </li>
                <li>
                  {
                    (
                      props.lensTargetNear === true
                      &&
                      (
                        !contraArray.find(c => c ==="keratoconus")
                        &&
                        !contraArray.find(c => c ==="radial keratotomy")
                      )
                    )
                    ?
                      <div>
                        <p style={{margin: '0'}}>Target -2.00 for near</p>
                      </div>
                  : ''
                  }
                </li>
                <li>
                  {
                    (
                      
                      (se) <= -1.5
                      &&
                      (se) >= -3
                      &&
                      props.lensTargetNear === false
                      &&
                      props.lensCorrectsIntermediateVision === "No"
                    )
                    ? 
                      <>
                        <p style={liStyle}>Myopic patient prefers trial frame:</p>
                        <div style={{
                            display: 'flex',
                            margin: '5px 0 5px 10px',
                          }}>
                          <div style={{
                              width: '10px',
                              height: '10px',
                              border: '1px solid black',
                              margin: '0 5px 0 0',
                            }}>
                          </div>
                          <p style={{margin: '0'}}>
                            {
                              (props.power !== '' && props.cylinder !== '' && props.axis !== '')
                              ? `${props.power} ${props.cylinder} x${props.axis}`
                              : (props.power !== '' && props.cylinder !== '' && props.axis === '')
                                ? `${props.power} ${props.cylinder}`
                                :``
                            } (distance)
                          </p>
                        </div>
                        <div style={{
                            display: 'flex',
                            margin: '5px 0 5px 10px',
                          }}>
                          <div style={{
                              width: '10px',
                              height: '10px',
                              border: '1px solid black',
                              margin: '0 5px 0 0',
                            }}>
                          </div>
                          <p style={{margin: '0'}}>
                            {
                              (props.power !== '' && props.cylinder !== '' && props.axis !== '')
                              ? 
                                p*1+2 === 0
                                  ? `Plano ${props.cylinder} x${props.axis}`
                                  : p*1+2 > 0
                                    ? `+${(Math.floor((p*1+2) * 100) / 100).toFixed(2)} ${props.cylinder} x${props.axis}`
                                    : `${(Math.floor((p*1+2) * 100) / 100).toFixed(2)} ${props.cylinder} x${props.axis}`
                                  
                              : (props.power !== '' && props.cylinder !== '' && props.axis === '')
                                ? 
                                  p*1+2 === 0
                                    ? `Plano ${props.cylinder}`
                                    : p*1+2 > 0
                                      ? `+${(Math.floor((p*1+2) * 100) / 100).toFixed(2)} ${props.cylinder}`
                                      : `${(Math.floor((p*1+2) * 100) / 100).toFixed(2)} ${props.cylinder}`
                                :``
                            } (near)
                          </p>
                        </div>
                      </>
                    : ''
                  }
                </li>

                <li>{
    
                    equipmentPreferenceAstig*1 <= 0.75
                      ? ''

                      :
                        <div>
                          <p style={{margin: '0'}}>Corneal astigmatism > 0.75 D</p>
                        </div>
                    }
                </li>
                <li>{
                    props.cri === true
                      ? <p style={{margin: '0'}}>
                         FLACS info:
                          <br></br>
                          <span style={{margin: '0 0 0 10px'}}>K1 (Flat):</span>
                          <br></br>
                          <span style={{margin: '0 0 0 10px'}}>K2 (Steep):</span>
                          <br></br>
                          <span style={{margin: '0 0 0 10px'}}>Steep Axis: {equipmentPreferenceAxis}</span>
                          <br></br>
                          <span style={{margin: '0 0 0 10px'}}>Capsulotomy: Capsulotomy</span>
                          <br></br>
                          <span style={{margin: '0 0 0 10px'}}>Lens Fragmentation: Standard {props.side}</span>
                          <br></br>
                          <span style={{margin: '0 0 0 10px'}}>Arcuates: Donnenfeld CRI</span>
                        </p>
                      : ""
                    }
                </li>
              </ul>
            </div>
    </>
  )
}
