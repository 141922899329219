import React, {useState, Fragment, useEffect } from 'react';
import api from "../services/api";

import FormInput from '../partials/FormInput'
import Header from '../partials/Header'
import Navbar from '../partials/Navbar'


export default function UserNew (props){

// Blank data for production environment
const[username, setUsername] = useState('');
const[password1, setPassword1] = useState('');
const[password2, setPassword2] = useState('');
const[physicianName, setPhysicianName] = useState('');
const[clinicName, setClinicName] = useState('');
const[clinicStreet, setClinicStreet] = useState('');
const[clinicCity, setClinicCity] = useState('');
const[clinicState, setClinicState] = useState('');
const[clinicZip, setClinicZip] = useState('');
const[clinicPhone, setClinicPhone] = useState('');
const[clinicFax, setClinicFax] = useState('');


//Prefilled data for Testing
// const[username, setUsername] = useState('tyler');
// const[password1, setPassword1] = useState('password');
// const[password2, setPassword2] = useState('password');
// const[physicianName, setPhysicianName] = useState('some tyler');
// const[clinicName, setClinicName] = useState('TylerEye');
// const[clinicStreet, setClinicStreet] = useState('123 W 456 S');
// const[clinicCity, setClinicCity] = useState('Salt Lake City');
// const[clinicState, setClinicState] = useState('UT');
// const[clinicZip, setClinicZip] = useState('84083');
// const[clinicPhone, setClinicPhone] = useState('801-234-5678');
// const[clinicFax, setClinicFax] = useState('801-234-5679');


const[errorUserName, setErrorUserName] = useState("")
const[errorPassword1, setErrorPassword1] = useState("")
const[errorPassword2, setErrorPassword2] = useState("")
const[errorPasswordLength, setErrorPasswordLength] = useState("")
const[errorPasswordMatch, setErrorPasswordMatch] = useState("")
const[errorPhysicianName, setErrorPhysicianName] = useState("")
const[errorClinicName, setErrorClinicName] = useState("")
const[errorClinicStreet, setErrorClinicStreet] = useState("")
const[errorClinicCity, setErrorClinicCity] = useState("")
const[errorClinicState, setErrorClinicState] = useState("")
const[errorClinicZip, setErrorClinicZip] = useState("")
const[errorClinicPhone, setErrorClinicPhone] = useState("")
const[errorMessage, setErrorMessage] = useState("")
const[showErrors, setShowErrors] = useState(false)


const handleSave = async(e) => {
  e.preventDefault();

  let validationUserName = (!username)
    ? setErrorUserName("* Please enter username")
    : setErrorUserName("")

  let validationPassword = (!password1)
    ? setErrorPassword1("* Please enter password")
    : setErrorPassword1("")

  let validationPassword2 = (!password2)
    ? setErrorPassword2("* Please enter password confirmation")
    : setErrorPassword2("")

  let validationPasswordLength = (password1.length < 8)
    ? setErrorPasswordLength("* Password must have at least 8 characters")
    : setErrorPasswordLength("")

  let validationPasswordMatch = (password1 !== password2)
    ? setErrorPasswordMatch("* Passwords do not match")
    : setErrorPasswordMatch("")

  let validationPhysicianName = ( !physicianName )
    ? setErrorPhysicianName("* Enter Physician's Name")
    : setErrorPhysicianName("")

  let validationClinicName = ( !clinicName )
    ? setErrorClinicName("* Enter Clinic's Name")
    : setErrorClinicName("")

  let validationClinicStreet = ( !clinicStreet )
    ? setErrorClinicStreet("* Enter Clinic's Street")
    : setErrorClinicStreet("")

  let validationClinicCity = ( !clinicCity )
    ? setErrorClinicCity("* Enter Clinic's City")
    : setErrorClinicCity("")

  let validationClinicState = ( !clinicState )
    ? setErrorClinicState("* Enter Clinic's State")
    : setErrorClinicState("")

  let validationClinicZip = ( !clinicZip )
    ? setErrorClinicZip("* Enter Clinic's Zip")
    : setErrorClinicZip("")

  let validationClinicPhone = ( !clinicPhone )
    ? setErrorClinicPhone("* Enter Clinic's Phone Number")
    : setErrorClinicPhone("")


  if (
      !username
      || !password1
      || !password2
      || password1.length < 6
      || password1 !== password2
      || !physicianName
      || !clinicName
      || !clinicStreet
      || !clinicCity
      || !clinicState
      || !clinicZip
      || !clinicPhone
    ) {


    setShowErrors(true)

  } else {


    try {

      const response = await api.post("/user", {
        username,
        physicianName,
        password1,
        password2,
        clinicName,
        clinicStreet,
        clinicCity,
        clinicState,
        clinicZip,
        clinicPhone,
        clinicFax
      });

      // Get a JWT back
      const data = await response.data
      console.log(data)

      if (data.message) {
        setErrorMessage(data.message)
      } else {

        //Store JWT in localStorage so user is authenticated
        localStorage.setItem("@Cataractpreop", data.token);

        window.location = "/equipment";
      }



    } catch (err) {
      setErrorMessage(err.response.data.message)
    }
  }

}

useEffect(() => {
  window.scrollTo(0, 0)
}, [])


let warnStyle ={
  fontSize: '17px',
  margin: '3px 0 0 0',
  fontFamily: 'Montserrat',
  fontWeight: '600',
  color: 'red',
}

    return(
      <Fragment>
        <Navbar/>
        <div>
          <div style={{
              width: '7vw',
            }}>
          </div>
          <div>
            <Header
                title={"Welcome!"}
              subtitle={"Get started by creating your account"}
              />
            <form
              style={{
                background: '#F2F3F4',
                borderRadius: '10px',
                padding: '0px 0 50px 20px',
                margin: '20px auto 20px auto',
                width: '500px',
              }}
              >
              <div
                style={{
                  padding: '5px 5px 15px 10px',
                  }}>
                <FormInput
                  label='Name and degree'
                  value={physicianName}
                  setHook={setPhysicianName}
                  />
                <FormInput
                  label="Create a username"
                  value={username}
                  setHook={setUsername}
                  />
                <FormInput
                  label="Create a password"
                  value={password1}
                  setHook={setPassword1}
                  />
                <FormInput
                  label='Confirm password'
                  value={password2}
                  setHook={setPassword2}
                  />
                <FormInput
                  label='Clinic or business name'
                  value={clinicName}
                  setHook={setClinicName}
                  />
                <FormInput
                  label='Street'
                  value={clinicStreet}
                  setHook={setClinicStreet}
                  />
                <FormInput
                  label='City'
                  value={clinicCity}
                  setHook={setClinicCity}
                  />
                <FormInput
                  label='State'
                  value={clinicState}
                  setHook={setClinicState}
                  />
                <FormInput
                  label='ZIP'
                  value={clinicZip}
                  setHook={setClinicZip}
                  />
                <FormInput
                  label='Phone number'
                  value={clinicPhone}
                  setHook={setClinicPhone}
                  />
                <FormInput
                  label='Fax number'
                  value={clinicFax}
                  setHook={setClinicFax}
                  />
                
                <div 
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      border: '1px solid #292929',
                      borderRadius: '10px',
                      background: '#FFF',
                      padding: '10px 20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleSave(e)}
                  >
                    Submit
                  </div>
                </div>
                
                
              </div>
              <div style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <div
                  style={{
                    width:'600px',
                    padding: '10px',
                    margin: '10px',
                    }}
                  >
                  {(errorMessage !== "")
                      ? <p style={warnStyle}>{errorMessage}</p>
                      : null
                  }
                  {(errorUserName !== "")
                      ? <p style={warnStyle}>{errorUserName}</p>
                      : null
                  }
                  {(errorPassword1 !== "")
                      ? <p style={warnStyle}>{errorPassword1}</p>
                      : null
                  }
                  {(errorPassword2 !== "")
                    ? <p style={warnStyle}>{errorPassword2}</p>
                    : null
                  }
                  {(errorPasswordMatch !== "")
                    ? <p style={warnStyle}>{errorPasswordMatch}</p>
                    : null
                  }
                  {(errorPasswordLength !== "")
                    ? <p style={warnStyle}>{errorPasswordLength}</p>
                    : null
                  }
                  {(errorPhysicianName !== "")
                    ? <p style={warnStyle}>{errorPhysicianName}</p>
                    : null
                  }
                  {(errorClinicName !== "")
                    ? <p style={warnStyle}>{errorClinicName}</p>
                    : null
                  }
                  {(errorClinicStreet !== "")
                    ? <p style={warnStyle}>{errorClinicStreet}</p>
                    : null
                  }
                  {(errorClinicCity !== "")
                    ? <p style={warnStyle}>{errorClinicCity}</p>
                    : null
                  }
                  {(errorClinicState !== "")
                    ? <p style={warnStyle}>{errorClinicState}</p>
                    : null
                  }
                  {(errorClinicZip !== "")
                    ? <p style={warnStyle}>{errorClinicZip}</p>
                    : null
                  }
                  {(errorClinicPhone !== "")
                    ? <p style={warnStyle}>{errorClinicPhone}</p>
                    : null
                  }

                </div>
              </div>
            </form>
          </div>

          <div style={{
              width: '7vw',
            }}>
          </div>
        </div>
      </Fragment>
    )
  }


// const dateVar = new Date();
// const dateJoined = (((dateVar.getMonth() > 8) ? (dateVar.getMonth() + 1) : ('0' + (dateVar.getMonth() + 1))) + '/' + ((dateVar.getDate() > 9) ? dateVar.getDate() : ('0' + dateVar.getDate())) + '/' + dateVar.getFullYear());
