import React from 'react'
import BedsheetReader from './partials/BedsheetReader'
// import SignatureCapture from '../../partials/SignatureCapture'

export default function BedsheetRead( props ) {
  return (
    <>
        {
            props.bedsheetData && props.bedsheetData.map((m, i) => (
                <BedsheetReader
                    key={i}
                    power={props.power}
                    cylinder={props.cylinder}
                    axis={props.axis}
                    add={props.add}
                    lensOption={props.lensOption}
                    lensModel={props.lensModel}
                    sulcusLens={props.sulcusLens}
                    lensHospitalFee={props.lensHospitalFee}
                    lensPhysicianFee={props.lensPhysicianFee}
                    lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                    lensCorrectsNearVision={props.lensCorrectsNearVision}
                    flacs={props.flacs}
                    cri={props.cri}
                    lensTargetNear={props.lensTargetNear}
                    biometer={props.biometer}
                    biometerAstig={props.biometerAstig}
                    biometerAxis={props.biometerAxis}
                    chordMuX={props.chordMuX}
                    chordMuY={props.chordMuY}
                    chordMuTotal={props.chordMuTotal}
                    topographer={props.topographer}
                    topographerAstig={props.topographerAstig}
                    topographerAxis={props.topographerAxis}
                    topographerHoa={props.topographerHoa}
                    topographerSphAb={props.topographerSphAb}
                    equipmentPreference={props.equipmentPreference}
                    lensKAstigMin={props.lensKAstigMin}
                    bcva={props.bcva}
                    ns={props.ns}
                    contraindications={props.contraindications}
                    cataractAddOn={props.cataractAddOn}
                    setCataractAddOn={props.setCataractAddOn}
                    side = {props.side}
                    allergies = {props.allergies}
                    bat = {props.bat}
                    codeStatus = {props.codeStatus}
                    fName = {props.fName}
                    lName = {props.lName}
                    dob = {props.dob}

                    oopTotal={props.oopTotal}
                    oopPhysician={props.oopPhysician}
                    oopHospital={props.oopHospital}
                    lensCriFee={props.lensCriFee}
                    lensFlacsFee={props.lensFlacsFee}

                    physicianName={props.physicianName}
                    clinicName={props.clinicName}
                    clinicStreet={props.clinicStreet}
                    clinicCity={props.clinicCity}
                    clinicState={props.clinicState}
                    clinicZip={props.clinicZip}
                    clinicPhone={props.clinicPhone}
                    ptLensList={props.ptLensList}

                    linkTo='qr'
                    qrToken={props.qrToken}
                    cipherText={props.cipherText}
                    encryptionKey={props.encryptionKey}

                    bedsheetData={m.data}
                    bedsheetSplit={m.split}
                />
            ))
        }
    </>
  )
}
