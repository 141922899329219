import React, { useEffect } from 'react';

import { Link } from "react-router-dom";

import glistenings from './images/glistenings.jpeg'
import notPreloaded from './images/notPreloaded.jpeg'
import yellowing from './images/yellowing.jpeg'
import blueblocker from './images/blueblocker.jpeg'
import glasses from '../visionsimulator/images/glasses.jpeg';
import glareIcon from './images/glareIcon.jpeg'
import BackArrow from "../partials/BackArrow";
import monofocalImg from './images/monofocalFocusUnlabeled.jpeg'
import toricImg from './images/toricFocusUnlabeled.jpeg'
import toricEdofImg from './images/edofToricFocusUnlabeled.jpeg'
import edofImg from './images/edofFocusUnlabeled.jpeg'
import toricTrifocalImg from './images/trifocalToricFocusUnlabeled.jpeg'
import trifocalImg from './images/trifocalFocusUnlabeled.jpeg'
import PrintPage from "../partials/PrintPage";

export default function LensGuide(props) {
    // console.log(props.basicLensListOptions[0].lensName)



    const handleNewerLens = (e) => {
      const lens = props.basicLensListOptions.find(l => l.lensModel.includes(e));
      if (lens) {
        props.setLensOption(lens.lensName);
      }
    };
  
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const l = props.basicLensListOptions.find(option => option.lensName === props.basicLensOption);


  let labelStyle = {
    fontSize: '26px',
    color: '#292929',
    margin: "10px 0 0 0"
  }
  
  let proStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
  }
  
  let conStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
    color: 'red',
  }

  let imgStyle = {
    width: '200px',
    borderRadius: '20px',
  }

  let buttonStyleTrue = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#FFF',
    borderRadius: '10px',
    background: "#007bff",
    margin: '20px 10px 60px 10px',
    padding: '20px 40px',
    cursor: 'pointer',
    width: '340px',
    textAlign: 'center',
    textDecoration: 'none',
    // position: 'fixed',
    // zIndex: '3',
  }
  
let buttonStyleFalse = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#007bff',
    background: '#FFF',
    borderRadius: '5px',
    margin: '20px 10px 20px 10px',
    padding: '10px 0',
    cursor: 'pointer',
}

let columnStyle = {
  width: "48%",
  display:'flex',
  justifyContent: 'center',
}

let rowStyle = {
  display: 'flex', 
  justifyContent: 'space-evenly',
  // borderBottom: "1px solid #292929",
  padding: "8px 0 60px 0",
}
  
let pStyle={
  fontSize: '20px',
  margin: '5px 0 0 0',
  textAlign: 'center',
}


  return (
    <div style={{display:'flex', background: "#FFF", margin: "40px 0 0 0"}}>
      <div style={{width: '7vw'}}></div>
      <div style={{width: '84vw'}}>
      {
          props.basicLensOption === 'Cataract vision'
          || 
          props.basicLensOption === ''
          ? 
            <div>
              <BackArrow
                linkTo='/patientsimulator'
              />
              
              No lens has been selected.  Click the back arrow to select a lens.
            </div>
          :
          <>
          {
            (
              props.basicLensOption === 'Cataract vision'
              || 
              props.basicLensOption === ''
            )
            ? 
              <BackArrow
                linkTo='/patientsimulator'
              />
            : 
              <BackArrow
                linkTo='/patientnightsimulator'
              />

          }
            <div style={{margin: "40px 0 160px 0"}}>
              <div
              >

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {
                      (
                        l.lensNearVision === "Yes" 
                        && 
                        l.lensIntermediateVision === 'Yes'
                        &&
                        l.lensKAstigMin === '0'
                      )
                        ? 
                          <img
                            src={trifocalImg}
                            style={imgStyle}
                          />
                      : 
                      (
                        l.lensNearVision === "Yes" 
                        && 
                        l.lensIntermediateVision === 'Yes'
                        &&
                        l.lensKAstigMin*1 > 0
                      )
                        ? 
                          <img
                            src={toricTrifocalImg}
                            style={imgStyle}
                          />
                      : 
                      (
                        l.lensNearVision === "No" 
                        && 
                        l.lensIntermediateVision === 'Yes'
                        &&
                        l.lensKAstigMin === "0"
                      )
                        ? 
                          <img
                            src={edofImg}
                            style={imgStyle}
                          />
                      : 
                      (
                        l.lensNearVision === "No" 
                        && 
                        l.lensIntermediateVision === 'Yes'
                        &&
                        l.lensKAstigMin*1 > 0
                      )
                        ? 
                          <img
                            src={toricEdofImg}
                            style={imgStyle}
                          />
                      : 
                      (
                        l.lensNearVision === "No" 
                        && 
                        l.lensIntermediateVision === 'No'
                        &&
                        l.lensKAstigMin*1 > 0
                      )
                        ? 
                          <img
                            src={toricImg}
                            style={imgStyle}
                          />
                      : 
                      (
                        l.lensNearVision === "No" 
                        && 
                        l.lensIntermediateVision === 'No'
                        &&
                        l.lensKAstigMin === '0'
                      )
                        ? 
                          <img
                            src={monofocalImg}
                            style={imgStyle}
                          />
                      : null
                    }
                  </div>
                  <div style={{margin: "0"}}>
                    <div 
                      style={{
                        display:'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                          <p 
                            style={{
                              fontSize: '46px',
                              color: '#292929',
                              margin: "20px 0 0 0",
                              textAlign: 'center',
                              maxWidth: '600px',
                            }}
                          >
                            {l.lensName}
                          </p>
                          {
                            props.lensTargetNear === true
                            ? 
                              <p
                                style={{
                                  fontSize: '26px',
                                  color: '#292929',
                                  margin:'0',
                                  textAlign: 'center',
                                }}
                              >
                                Set for near
                              </p>
                            : null
                          }                               
                      </div>
                    </div>
      
                  </div>
  
                
                
                <div>
                  <div 
                    style={{
                      display: 'flex',
                      margin: '40px 0 0 0'
                      }} 
                  >
                    <div 
                      style={{
                        width: '120px',
                      }}
                    >
                      
                    </div>
                  </div>
                  








        <div style={rowStyle}>
          <div style={columnStyle}>
            <div
                style={{
                  width: '50%',
                  textAlign: 'center',
                }}
              >
                <p 
                  style={{
                    fontSize: '50px', 
                    fontWeight: '600px',
                    margin: "0",
                    }}>
                      ${
                        l && l.prosCons[0].cost*1
                      }
                  </p>
                <p style={pStyle}> 
                  Estimated extra out-of-pocket per eye
                </p>  
            </div>        
          </div>
        </div>
        

        <div style={rowStyle}>
          <div style={columnStyle}>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
              }}
            >
              <p 
                style={{
                  fontSize: '50px', 
                  fontWeight: '600px',
                  margin: '0',
                }}>
                  {l && l.prosCons[0].glasses}
              </p>   
              <img
                src={glasses}
                style={l && l.prosCons[0].glasses === 'Some'
                  ? 
                    {
                      width: '60%',
                      borderRadius: '8px',
                      opacity: "0.5",
                    }
                  : l && l.prosCons[0].glasses === 'Rare'
                  ? 
                    {
                      width: '60%',
                      borderRadius: '8px',
                      opacity: "0.1",
                    }
                  : 
                    {
                      width: '60%',
                      borderRadius: '8px',
                      opacity: "1",
                    }}
                alt='Need for glasses'
              />
              <p style={pStyle}>
                  need for glasses after surgery
              </p>
            </div>
          </div>
        </div>


        <div style={rowStyle}>
          <div style={columnStyle}>
            <div
                style={{
                  width: '50%',
                  textAlign: 'center',
                }}
              >
              <p 
                style={{
                  fontSize: '50px', 
                  fontWeight: '600px',
                  margin: '0',
                }}>
                  {l && l.prosCons[0].halos}
              </p> 
              <img
                src={glareIcon}
                style={l && l.prosCons[0].halos === 'Mild'
                  ? 
                    {
                      width: '40%',
                      borderRadius: '8px',
                      opacity: "0.4",
                    }
                  : l && l.prosCons[0].halos === 'Minimal'
                  ? 
                    {
                      width: '40%',
                      borderRadius: '8px',
                      opacity: "0.1",
                    }
                  : 
                    {
                      width: '40%',
                      borderRadius: '8px',
                      opacity: "0.8",
                    }}
                alt='Glare and halos'
              /> 
              <p style={pStyle}>
                glare and halos around lights after surgery
              </p> 
            </div>            
          </div>
        </div>
      </div>
        
      <div
                className="dontPrint"
              >
                <PrintPage/>
                <hr
                  style={{
                    width: '76%',
                    textAlign: 'center',
                  }}
                />
                <p 
                  style={{
                    textAlign: 'center',
                    fontSize: '60px',
                    margin: '60px 0 0 0',
                  }}
                >
                  Detail oriented? 
                </p>
              
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Link 
                        style={buttonStyleTrue}
                        to='/advancedmode'
                    >
                        Try Advanced Mode
                    </Link>

                </div>
              </div>
            </div>
  
          </div>
          </>
          }
      </div>
 
      <div style={{width: '7vw'}}></div>
      
    </div>
  )
}