import React, { useState, useEffect } from 'react';
import ForwardArrow from '../partials/ForwardArrow';
import BackArrow from '../partials/BackArrow';

export default function Tutorial2 (props) {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    const[tutorialHover, setTutorialHover]=useState(false)
    const[simulatorHover, setSimulatorHover]=useState(false)


    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'center',
        margin: '40px 0 0 0',
    }

    let subtitleStyle = {
        fontSize: '30px',
        color: '#FFF',
        textAlign: 'left',
        margin: '6px 30px 0px 0px',
        width: "30%",
        minWidth: '300px',
    }

    let buttonStyleTrue = {
        // border: '1px solid #007bff',
        color: '#FFF',
        borderRadius: '5px',
        background: "#007bff",
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
      }
      
    let buttonStyleFalse = {
        // border: '1px solid #007bff',
        color: '#007bff',
        background: '#FFF',
        borderRadius: '5px',
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
    }

    let buttonTextStyle = {
        padding: '0',
        margin: '10px 20px',
        textAlign: 'center',
        fontSize: '40px',
    }


    let imgContainerStyle = {
        minWidth: '200px',
    }

    let imgStyle = {
        margin: '10px 10px 0px 10px',
        borderRadius: '5px',
        width: '90%',
        minWidth: '200px',  
    }

    return(
        <div style={backgroundStyle}>
            <BackArrow
                linkTo='/tutorial1'
            />
            <ForwardArrow
                linkTo='/tutorial3'
            />
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                <p style={titleStyle}>How do you fix a cataract?</p>
                <div
                    style={{
                        display: 'flex',
                        margin: "40px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        Cataracts are fixed by doing cataract surgery, where the cloudy lens is removed and replaced with a clear artificial lens.
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='cataractSurgeryRemove.jpeg'
                                alt='Removal of cataract'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='cataractSurgeryReplace.jpeg'
                                alt='Replacement of cataract'
                                style={imgStyle}
                            /> 
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        margin: "100px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        After surgery, light can focus in the eye again.
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='postop.jpeg'
                                alt='Postoperative appearance with new lens'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='postopFocus.jpeg'
                                alt='postoperative focus with new lens'
                                style={imgStyle}
                            /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 


// During cataract surgery, your cloudy lens is removed and replaced with a new clear artificial lens.