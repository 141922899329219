import React, {useState, Fragment, useEffect} from 'react';
import api from "../services/api";

import FormInput from '../partials/FormInput'
import Header from '../partials/Header'
import Navbar from '../partials/Navbar'


export default function UserAccess (props){

  const[passwordOld, setPasswordOld] = useState("");
  const[passwordNew, setPasswordNew] = useState("");
  const[passwordConfirm, setPasswordConfirm] = useState("");

  const[changeSuccess, setChangeSuccess] = useState("")
  
  const[errorPasswordOld, setErrorPasswordOld] = useState("")
  const[errorPasswordNew, setErrorPasswordNew] = useState("")
  const[errorPasswordConfirm, setErrorPasswordConfirm] = useState("")
  const[errorPasswordLength, setErrorPasswordLength] = useState("")
  const[errorPasswordMatch, setErrorPasswordMatch] = useState("")
  const[errorMessage, setErrorMessage] = useState("")
  const[showErrors, setShowErrors] = useState(false)

    const handleSave = async(e) => {
      e.preventDefault();

      if (
             !passwordOld
          || !passwordNew
          || !passwordConfirm
          || passwordNew.length < 6
          || passwordNew !== passwordConfirm
        ) {


          // if (errorMessageArr.length > 0) {
          //   const timer = setTimeout(() => {
          //     setErrorMessage(['']);
          //   }, 3000);
          //   return () => clearTimeout(timer);

        setShowErrors(true)

      } else {


        try {
          await api.put("/updatepassword", {
            passwordOld,
            passwordNew, 
            passwordConfirm
          });
        
          // Alert message with a timeout
          setChangeSuccess("Password updated successfully!");
          setTimeout(() => {
            window.location = "/myaccount";
          }, 40); // 40 milliseconds (2 seconds) delay before redirecting
        } catch (err) {
          console.log(err.message);
          setErrorMessage(err.message);
        }
        
      }
    }


    let warnStyle ={
      fontSize: '17px',
      margin: '3px 0 0 0',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      color: 'red',
    }

    return(
      <Fragment>
        <Navbar/>
        <div>
          <div style={{
              width: '7vw',
            }}>
          </div>
          <div>
            <Header
              title={"Change your password"}
              subtitle={""}
              />
              {changeSuccess === ''
                ? null
                : 
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '30px',
                      color: '#4cbb17',
                    }}
                  >
                    {changeSuccess}
                  </div>
              }
            <form
              style={{
                background: '#F2F3F4',
                borderRadius: '10px',
                padding: '0px 0 50px 20px',
                margin: '20px auto 20px auto',
                width: '500px',
              }}
              >
              <div
                style={{
                  padding: '5px 5px 15px 10px',
                  }}>
                <FormInput
                  label="Current password"
                  value={passwordOld}
                  setHook={setPasswordOld}
                  />
                <FormInput
                  label='New password'
                  value={passwordNew}
                  setHook={setPasswordNew}
                  />
                <FormInput
                  label='Confirm new password'
                  value={passwordConfirm}
                  setHook={setPasswordConfirm}
                  />
              </div>
              <div style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <div
                  style={{
                    width:'600px',
                    padding: '10px',
                    margin: '10px',
                    }}
                  >
                  {(errorMessage !== "")
                      ? <p style={warnStyle}>{errorMessage}</p>
                      : null
                  }
                  {(errorPasswordOld !== "")
                      ? <p style={warnStyle}>{errorPasswordOld}</p>
                      : null
                  }
                  {(errorPasswordNew !== "")
                    ? <p style={warnStyle}>{errorPasswordNew}</p>
                    : null
                  }
                  {(errorPasswordConfirm !== "")
                    ? <p style={warnStyle}>{errorPasswordConfirm}</p>
                    : null
                  }
                  {(errorPasswordMatch !== "")
                    ? <p style={warnStyle}>{errorPasswordMatch}</p>
                    : null
                  }
                  {(errorPasswordLength !== "")
                    ? <p style={warnStyle}>{errorPasswordLength}</p>
                    : null
                  }

                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div
                  onClick={(e => handleSave(e))}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#007bff',
                    color: '#fff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Save
                </div>
                <div
                  onClick={() => window.location = `/myaccount`}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Cancel
                </div>
              </div>
            </form>
          </div>
          <div style={{
              width: '7vw',
              height: '84vh'
            }}>
          </div>
        </div>
      </Fragment>
    )
  }

