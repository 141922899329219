export default function Questions (props) {

  let labelStyleTrue={
      width: '160px',
      padding: '10px',
      textTransform: 'uppercase',
      background: '#292929',
      color: '#FFF',
      borderRadius: '5px',
      fontWeight: '700'
    }

  let labelStyleFalse={
      width: '160px',
      padding: '10px',
      textTransform: 'uppercase',
      color: 'gray'
    }


  return(
    <div>
      <div
        style={{
          width: '800px',
          margin: '70px auto 0 auto',
        }}>
        <p
          style={{
            margin: '0 0 20px 0',
            fontSize: '24px',
          }}>
          {props.title}
        </p>
        <div style={{
            display: 'flex',
            margin: '10px auto 50px auto',
            width: '800px'
          }}>
            <div
              style={{
                width: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              >

              <div style={{
                  fontSize: '24px',
                  margin: '0 0 0 30px',
                }}>
                <div
                  style={props.hook === 'yes'
                      ? labelStyleTrue
                      : labelStyleFalse
                    }
                  onClick={() => props.setHook('yes')}
                  >
                  Yes
                </div>
                <div
                  style={props.hook === 'no'
                      ? labelStyleTrue
                      : labelStyleFalse
                    }
                  onClick={() => props.setHook('no')}
                  >
                  No
                </div>
                <div
                  style={props.hook === 'na'
                      ? labelStyleTrue
                      : labelStyleFalse
                    }
                  onClick={() => props.setHook('na')}
                  >
                  Not Applicable
                </div>
              </div>
            </div>
        </div>
        <hr/>
      </div>
    </div>

  )
}
