import React, { useState, useEffect } from "react";
import { login } from "../services/auth";
import { useNavigate } from 'react-router-dom';
import api from "../services/api";

// Function to get the first parameter from the current URL
export default function ExtraSession(props) {

    const getPaymentQr = async() => {
        
      // Get the current URL
        const url = window.location.href;
    
        // Extract the query string part of the URL
        const queryString = url.split('extrasession/')[1];
        // console.log(queryString)
        // If there's no query string, return null
        if (!queryString) {
        return null;
        }
        
        //Get consent data
            props.setPaymentQr(queryString)

    }
    
    useEffect(() => {
        getPaymentQr();
      }, []);
    

    const navigate = useNavigate();

    const handleStart = async () => {
        try {
            const res = await api.post("/extrasession", { 
                paymentQr: props.paymentQr 
            });

            if (res.status === 200 && res.data === 'Your 3-hour session has ended') {
                navigate('/tutorial5');
                // window.location = `/renewsession`
            } else if (res.status === 200) {
                const { token } = res.data;
                login(token);  // Call the login function (synchronously)
                props.handleLoginSuccess();  // Call handleLoginSuccess to update authVerify
                navigate('/eyeinfo');  // Navigate after login has completed
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    //set base url to www version (need to make sure others redirect to www)
    const baseUrl = `${process.env.REACT_APP_API_URL}`



    let backgroundStyle = {
        // background: "#292929",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '76px',
        color: '#292929',
        textAlign: 'center',
        margin: '60px 0 0px 0',
    }
    
    
    let subtitleStyle = {
        fontSize: '46px',
        color: '#292929',
        textAlign: 'left',
        margin: '20px 0 30px 0',
    }
    
    let listStyle = {
        fontSize: '24px',
        color: '#292929',
        // textAlign: 'center',
        margin: '40px 0 0 0',
    }

    let cardStyle = {
        fontSize: '20px',
        backgroundColor: 'white',
        color: '#292929',
        // fontWeight: '600',
        padding: '20px',
        width: '260px',
        height: '400px',
        borderRadius: '20px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, .2), 0 3px 10px 0 rgba(0,0,0, .15)',
        margin: '0 auto',
    }

    let buttonStyleTrue = {
        fontSize: "50px",
        border: '1px solid #007bff',
        color: '#FFF',
        borderRadius: '10px',
        background: "#007bff",
        margin: '20px 10px 60px 10px',
        padding: '20px 40px',
        cursor: 'pointer',
        width: '260px',
        textAlign: 'center',
        textDecoration: 'none',
        // position: 'fixed',
        // zIndex: '3',
      }


    let buttonStyleFalse = {
        fontSize: "50px",
        border: '1px solid #007bff',
        color: '#007bff',
        background: '#FFF',
        borderRadius: '5px',
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
    }

    // Return the first parameter key-value pair
    return (
        <div
            style={backgroundStyle}
        >
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                <p style={titleStyle}>
                    Welcome Back!
                </p>
                <p style={{
                    fontSize: '24px',
                    color: '#292929',
                    textAlign: 'center',
                    margin: '0px 0 40px 0',
                }}>
                    Click the Start button below to begin your session
                </p>
                <div style={{display: 'flex', justifyContent: 'center',}}>
                   
                    <div>
                        {
                            (props.paymentQr && props.paymentQr.length > 0)
                            ? 
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <div 
                                        style={buttonStyleTrue}
                                        onClick={() => handleStart()}
                                    >
                                        Start
                                    </div>   
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div style={{width: '7vw'}}></div>
        </div>
      )
  }
  