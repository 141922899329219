
export default function EducationOnOffButton (props) {

    if(props.hook === 'false'){
        return(
            <div>
                <div style = {{
                    width: '86px',
                    height: '30px',
                    margin: '5px 20px 0px 0px',
                    borderRadius: '23px',
                    background: '#FFF',
                    border: '1px solid #dddddd',
                }}
                    onClick={() => {
                    props.setHook('true')
                    }}>

                <div style= {{display: 'flex',}}>

                    <div
                    style={{
                    border: 'none',
                    margin: '3px',
                    width: '24px',
                    height: '24px',
                    borderRadius: '12px',
                    textAlign: 'center',
                    background: '#a4a4a4',
                    }}></div>

                    <p style = {{
                    fontSize: '20px',
                    color: '#a4a4a4',
                    margin:'0px',
                    padding: '3px 6px 3px 0px',
                    }}>OFF</p>

                </div>

                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div style = {{
                    width: '86px',
                    height: '30px',
                    margin: '5px 20px 0px 0px',
                    borderRadius: '23px',
                    background: '#4cbb17',
                    border: '1px solid #4dbc18',
                    }}
                    onClick={() => {
                        props.setHook('false')
                    }}>

                    <div style= {{display: 'flex'}}>

                    <p style = {{
                        fontSize: '20px',
                        color: '#fff',
                        margin:'0px',
                        padding: '3px 3px 3px 12px',
                    }}>ON</p>

                    <div
                        style={{
                        border: 'none',
                        margin: '3px 3px 3px 10px',
                        width: '24px',
                        height: '24px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        background: '#fff',
                    }}></div>

                    </div>
                </div>
            </div>
        )
    }
}
  