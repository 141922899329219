import React, { useState, useEffect } from 'react';
import BackArrow from '../partials/BackArrow';
import PrintPage from '../partials/PrintPage';

export default function Summary (props) {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    const[tutorialHover, setTutorialHover]=useState(false)
    const[simulatorHover, setSimulatorHover]=useState(false)


    let backgroundStyle = {
        background: "#FFF",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '40px',
        color: '#292929',
        textAlign: 'center',
        margin: '100px 0 0 0',
        width: '800px',
    }

 

    return(
        <div style={backgroundStyle}>
            <BackArrow
                linkTo='/nightsimulator'
            />
            <PrintPage/>

            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw', display: 'flex', justifyContent: 'center'}}>
                <p style={titleStyle}>You picked the {props.lensOption} lens {props.lensTargetNear === true
                    ? 'with the goal of minimizing glasses for near'
                    : null
                }  </p>
                
            </div>
        </div>
    )
} 
