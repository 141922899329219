import React from 'react';

export default function SelectColumn(props) {

  const handleMouseEnter = (m) => {
    const element = document.getElementById(`${m}`);
    if (element) {
      element.style.background = '#FFF';
      element.style.fontWeight = '500';
    }
  };

  const handleMouseLeave = (m) => {
    const element = document.getElementById(`${m}`);
    if (element) {
      element.style.background = '#e6f3f8';
      element.style.fontWeight = '400';
    }
  };

  const handleClick = (m) => {
    props.setHook(m);
    props.setHookClick(false);
  };

  return (
    <div style={{ margin: '0 0 0 0px' }}>
      {props.data.map(m => (
        <div
          key={m}
          id={`${m}`}
          onClick={() => handleClick(m)}
          onMouseEnter={() => handleMouseEnter(m)}
          onMouseLeave={() => handleMouseLeave(m)}
          style={{ 
            padding: '0',
            fontSize: '18px',
            }}
        >
          {m}
        </div>
      ))}
    </div>
  );
}
