import React, { useState, useEffect } from 'react';
import BackArrow from '../partials/BackArrow';
import ForwardArrow from '../partials/ForwardArrow';

export default function Tutorial3 (props) {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    const[tutorialHover, setTutorialHover]=useState(false)
    const[simulatorHover, setSimulatorHover]=useState(false)


    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'center',
        margin: '40px 0 0 0',
    }

    let subtitleStyle = {
        fontSize: '30px',
        color: '#FFF',
        textAlign: 'left',
        margin: '6px 30px 0px 0px',
        width: '50%'
    }

    let buttonStyleTrue = {
        // border: '1px solid #007bff',
        color: '#FFF',
        borderRadius: '5px',
        background: "#007bff",
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
      }
      
    let buttonStyleFalse = {
        // border: '1px solid #007bff',
        color: '#007bff',
        background: '#FFF',
        borderRadius: '5px',
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
    }

    let buttonTextStyle = {
        padding: '0',
        margin: '10px 20px',
        textAlign: 'center',
        fontSize: '40px',
    }

    let imgStyle = {
        margin: '10px 10px 0px 10px',
        borderRadius: '5px',
        width: '90%',
        minWidth: '200px',  
    }

    let imgContainerStyle = {
        minWidth: '200px',
    }

    return(
        <div style={backgroundStyle}>
            <BackArrow
                linkTo='/tutorial2'
            />
            {
                (
                    (props.userType === 'md' || props.userType === 'qrmd')
                    &&
                    props.useTutorial === 'true'
                )
                ? 
                    <ForwardArrow
                        linkTo={'/visionsimulator'}
                    />
                : props.authVerify === true 
                    ? 
                        <ForwardArrow
                            linkTo={'/premiumaccess'}
                        />
                    : 
                        <ForwardArrow
                            linkTo={'/'}
                        />
            }
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                <p style={titleStyle}>Does everyone get the same new lens?</p>

                <div
                    style={{
                        display: 'flex',
                        margin: "40px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        Each replacement lens must be uniquely matched to the length and surface shape of your eye.
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='axialLength.jpeg'
                                alt='Axial length'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='topography.jpeg'
                                alt='Topography'
                                style={imgStyle}
                            /> 
                        </div>
                    </div>
                </div>


                <div
                    style={{
                        display: 'flex',
                        margin: "40px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        The surface shape of the eye can be smooth, bent, or irregular.  
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='smooth.jpeg'
                                alt='Smooth corneal surface'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='bent.jpeg'
                                alt='Bent corneal surface'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='irregular.jpeg'
                                alt='Irregular corneal surface'
                                style={imgStyle}
                            /> 
                        </div>
                    </div>
                </div>


                <div
                    style={{
                        display: 'flex',
                        margin: "40px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        First generation "Monofocal" lenses focus light to a single point.  They work well with eyes that have a smooth or irregular shape.
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='monofocal.jpeg'
                                alt='Monofocal intraocular lens'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='monofocalFocus.jpeg'
                                alt='Focus through a monofocal intraocular lens'
                                style={imgStyle}
                            /> 
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        margin: "40px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        Next generation "Toric" lenses work well with eyes that have a bent shape (called astigmatism).
                     </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='monofocalAstig.jpeg'
                                alt='Focus through astigmatism using monofocal lens'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='toricFocus.jpeg'
                                alt='Focus through astigmatism using toric lens'
                                style={imgStyle}
                            /> 
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        margin: "40px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        More recent "Trifocal" and "EDOF" lenses help people see well far away and up close, but they do NOT work well with eyes that have an irregular shape.
                     </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='edofFocus.jpeg'
                                alt='Focus through an extended depth of focus (EDOF) lens'
                                style={imgStyle}
                            /> 
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='trifocalFocus.jpeg'
                                alt='Focus through an trifocal lens'
                                style={imgStyle}
                            /> 
                        </div>
                    </div>
                </div>              
            </div>
        </div>
    )
} 


// During cataract surgery, your cloudy lens is removed and replaced with a new clear artificial lens.