import React from 'react';

export default function Quote(props) {
  return (
    <div
        style={{
            margin: "100px 20px 20px 20px",
        }}
    >
        <div style={{
            position: 'relative',
            width: '300px',
            }}>
            <div
                style={{
                    width: '300px',
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    margin: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(0, 123, 255, 0.1)',
                    boxShadow: '0 4px 8px rgba(0, 123, 255, 0.1)',
                    zIndex: '1',
                }}
                >
                
                <div style={{ padding: '0px' }}>
                    <blockquote
                        style={{
                            fontStyle: 'italic',
                            margin: '20px 15px 15px 15px', //make top margin 100px when you add image above this div
                        }}
                    >
                        {props.text}
                    </blockquote>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div style={{ maxWidth: '250px' }}>
                        <p
                        style={{
                            margin: '10px 20px 0 0',
                            fontWeight: '600',
                        }}
                        >
                        - {props.author}
                        </p>
                        <p
                        style={{
                            margin: '5px 20px 20px 12px',
                            color: '#292929',
                        }}
                        >
                        {props.location}
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};




// <img
//     src={props.imageSrc}
//     alt={`${props.author}'s portrait`}
//     style={{
//         width: '160px',
//         height: '160px',
//         borderRadius: '50%',
//         margin: '-80px auto 0 auto', // Adjust the negative margin and use auto for centering
//         position: 'absolute',
//         left: '50%', // Center horizontally using left: 50%
//         transform: 'translateX(-50%)', // Center horizontally using transform
//         zIndex: '0',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
//     }}
// />