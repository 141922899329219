import React, { useEffect, useState } from 'react';

import ReferenceImage from './ReferenceImage'
import { BsGlobe } from "react-icons/bs";

export default function Atlas9000 (props) {


  useEffect(() => {
    let finalHoa = Math.round((Math.sqrt((props.comaH * props.comaH) + (props.comaV * props.comaV))) * 100) / 100;
    props.setTopographerHoa(finalHoa);
  }, [props.comaH, props.comaV])

  let inputStyle = {
    border: 'none',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '135px',
    height: '40px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
  }

  let warnStyle = {
    border: '2px solid red',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '131px',
    height: '36px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
  }

  let subtitleStyle = {
    textAlign: 'left',
    padding: '0 0 0 0px',
    fontSize: '18px',
    fontWeight: '600',
    margin: 'auto 0',
  }

  let iconStyle = {
    margin: 'auto 0',
    padding: '0',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
  }
  
  return(
    <div style={{
        display: 'flex',
        justifyContent: 'left',
        margin: '0 0 8px 80px',
      }}>

        <div 
          style={{
            display: 'flex',
            width: "210px"
          }}
        >
          <div
            style={iconStyle}
          >
            <BsGlobe
              style={{
                fontSize: '36px',
              }}
            />
          </div>
          
          <p style={subtitleStyle}>{props.topographer}</p>
        </div>
      <div style={{
        display: 'flex',
        position: 'relative',
        width: '160px',
      }}>
        <input
        style={
              props.topographerAstig >= 0 && props.topographerAstig < 8 || props.topographerAstig === ''
              ? inputStyle
              : warnStyle
            }
        value={props.topographerAstig}
        onChange={e => props.setTopographerAstig(e.target.value)}
        placeholder="Astigmatism"
        type='number'
        onWheel={(e) => e.target.blur()}
        />
        <ReferenceImage
          referenceImage='/atlasAstigmatism.jpg'
          />
      </div>
      <div style={{
        display: 'flex',
        position: 'relative',
        width: '160px',
      }}>
        <input
          style={
            props.topographerAxis > 0 && props.topographerAxis < 181 || props.topographerAxis === ''
            ? inputStyle
            : warnStyle
          }
          value={props.topographerAxis}
          onChange={e => props.setTopographerAxis(e.target.value)}
          placeholder="Steep Axis"
          type='number'
          onWheel={(e) => e.target.blur()}
        />
        <ReferenceImage
          referenceImage='/atlasSteepAxis.jpg'
          />
      </div>
      <div style={{
        display: 'flex',
        position: 'relative',
        width: '160px',
      }}>
        <input
          style={
            props.topographerSphAb >= 0 && props.topographerSphAb < 1.5 || props.topographerSphAb === ''
            ? inputStyle
            : warnStyle
          }
          value={props.topographerSphAb}
          onChange={e => props.setTopographerSphAb(e.target.value)}
          placeholder="Sph. Ab."
          type='number'
          onWheel={(e) => e.target.blur()}
        />
        <ReferenceImage
          referenceImage='/atlasSphAb.jpg'
          />
      </div>
      <div style={{
        display: 'flex',
        position: 'relative',
        width: '160px',
      }}>
        <input
          style={
            props.comaH > -1 && props.comaH < 1 || props.comaH === ''
            ? inputStyle
            : warnStyle
          }
          value={props.comaH}
          onChange={e => props.setComaH(e.target.value)}
          placeholder="Horiz. Coma"
          type='number'
          onWheel={(e) => e.target.blur()}
        />
        <ReferenceImage
          referenceImage='/atlasComaHor.jpg'
          />
      </div>
      <div style={{
        display: 'flex',
        position: 'relative',
        width: '160px',
      }}>
        <input
          style={
            props.comaV > -1 && props.comaV < 1 || props.comaV === ''
            ? inputStyle
            : warnStyle
          }
          value={props.comaV}
          onChange={e => props.setComaV(e.target.value)}
          placeholder="Vertical Coma"
          type='number'
          onWheel={(e) => e.target.blur()}
        />
        <ReferenceImage
          referenceImage='/atlasComaVert.jpg'
          />
      </div>
    </div>
  )
}