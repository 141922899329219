import React, { Fragment } from 'react';

import Navbar from '../partials/Navbar'
import Header from '../partials/Header'
import NavCluster from './NavCluster'

import { FaUser, FaKey } from 'react-icons/fa';
import { FaFileSignature } from 'react-icons/fa';
import { RiCameraLensLine } from 'react-icons/ri';
// import { RiMedicineBottleLine } from 'react-icons/ri';
// import { MdChecklist } from 'react-icons/md';
import { MdPayment } from 'react-icons/md';
// import { MdAccessibility } from 'react-icons/md';
// import { GiLaserBurst } from 'react-icons/gi';
// import { IoIosListBox } from "react-icons/io";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FaBed } from "react-icons/fa";

export default function MyAccount (props){


return(
  <Fragment>
    <Navbar/>
    <div>
      <div
        style={{width: '7vw'}}>
      </div>
      <Header
        title={"Account"}
        subtitle={""}
        />
      <div style={{
          margin: '0 100px 0 100px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}>

        <NavCluster
          label={"Lenses"}
          location={"lenses"}
          icon={<img
            src="./ZCB00bw.png"
            alt='lens'
            style={{
              width: '96px'
            }}
            />}
          />
    
        <NavCluster
          label={"Topographer and biometer"}
          location={"equipment"}
          icon={<RiCameraLensLine
            style={{
              fontSize: '50px',
              color: 'gray',
            }}
            />}
          />

        <NavCluster
          label={"CRI and FLACS"}
          location={"cri"}
          icon={<img
            src="./cri.jpg"
            alt='cri'
            style={{
              width: '50px'
            }}
            />}
          />

        <NavCluster
          label={"Patient education"}
          location={"education"}
          icon={<FaChalkboardTeacher
            style={{
              fontSize: '50px',
              color: 'gray',
            }}
          />}
        />
       
       <NavCluster
          label={"Surgery facesheet"}
          location={"facesheet"}
          icon={<FaBed
            style={{
              fontSize: '50px',
              color: 'gray',
            }}
          />}
        />

        <NavCluster
          label={"Consents"}
          location={"consents"}
          icon={<FaFileSignature
            style={{
              fontSize: '50px',
              color: 'gray',
            }}
          />}
        />
 
        <NavCluster
          label={"Payments and billing"}
          location={"myaccount"}
          icon={<MdPayment
            style={{
              fontSize: '50px',
              color: 'gray',
            }}
          />}
        />

        <NavCluster
          label={"My info"}
          location={"user"}
          icon={<FaUser
            style={{
              fontSize: '50px',
              color: 'gray',
            }}
          />}
        />
        
        <NavCluster
          label={"Change password"}
          location={"useraccess"}
          icon={<FaKey
            style={{
              fontSize: '50px',
              color: 'gray',
            }}
          />}
        />

      </div>
      <div
        style={{width: '7vw'}}>
      </div>
    </div>


  </Fragment>
)
}


// <NavCluster
// label={"Paperwork"}
// location={"myaccount"}
// icon={<IoIosListBox
//   style={{
//     fontSize: '50px',
//     color: 'gray',
//   }}
//   />}
// />

//
// <NavCluster
//   label={"Visual function questionnaire"}
//   location={"myaccount"}
//   icon={<MdChecklist
//     style={{
//       fontSize: '50px',
//       color: 'gray',
//     }}
//     />}
//   />
//
// <NavCluster
//   label={"Preop/postop instructions"}
//   location={"myaccount"}
//   icon={<MdAccessibility
//     style={{
//       fontSize: '50px',
//       color: 'gray',
//     }}
//     />}
//   />
//
// <NavCluster
//   label={"Drop instructions"}
//   location={"myaccount"}
//   icon={<RiMedicineBottleLine
//     style={{
//       fontSize: '50px',
//       color: 'gray',
//     }}
//     />}
//   />
//
// <NavCluster
//   label={"FLACS"}
//   location={"flacs"}
//   icon={<GiLaserBurst
//     style={{
//       fontSize: '50px',
//       color: 'gray',
//     }}
//     />}
//   />
