import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import { login } from "../services/auth";
import api from "../services/api";
import { BsPrinter } from "react-icons/bs";

import { useParams } from "react-router-dom";

// Function to get the first parameter from the current URL
export default function PaymentSuccess(props) {

    let { id } = useParams();
    // console.log(id)
    
    const getPaymentQr = async() => {

        try {
            const res = await api.post("/paymentsuccess", {
                firstParamValue: id,
              });
            //  console.log('time left in hours ' + (((86400+res.data.rows[0].start_time*1)-Math.floor(Date.now() / 1000))/60)/60)

            //takes randomly generated qr_token and sets it as props.paymentQr
            props.setPaymentQr(res.data.rows[0].qr_token)

        } catch (err) {
          console.error(err.message)
        }


    }
    
    useEffect(() => {
        getPaymentQr();
      }, []);
    

    const navigate = useNavigate();
    const handleStart = async () => {
        try {
            const res = await api.post("/startsession", { 
                paymentQr: props.paymentQr 
            });

            if (res.status === 200 && res.data === 'Your 24-hour session has ended') {
                alert(res.data);
                // window.location = `/renewsession`
            } else if (res.status === 200) {
                const { token } = res.data;
                login(token);  // Call the login function (synchronously)
                props.handleLoginSuccess();  // Call handleLoginSuccess to update authVerify
                navigate('/premiumaccess');  // Navigate after login has completed
            }
        } catch (err) {
            console.error(err.message);
        }
    };
    //set base url to www version (need to make sure others redirect to www)
    const baseUrl = `${process.env.REACT_APP_API_URL}`



    let backgroundStyle = {
        // background: "#292929",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '76px',
        color: '#292929',
        textAlign: 'center',
        margin: '60px 0 0px 0',
    }
    
    
    let subtitleStyle = {
        fontSize: '46px',
        color: '#292929',
        textAlign: 'left',
        margin: '20px 0 30px 0',
    }
    
    let listStyle = {
        fontSize: '24px',
        color: '#292929',
        // textAlign: 'center',
        margin: '40px 0 0 0',
    }

    let cardStyle = {
        fontSize: '20px',
        backgroundColor: 'white',
        color: '#292929',
        // fontWeight: '600',
        padding: '20px',
        width: '260px',
        height: '400px',
        borderRadius: '20px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, .2), 0 3px 10px 0 rgba(0,0,0, .15)',
        margin: '0 auto',
    }

    let buttonStyleTrue = {
        fontSize: "50px",
        border: '1px solid #007bff',
        color: '#FFF',
        borderRadius: '10px',
        background: "#007bff",
        margin: '20px 10px 60px 10px',
        padding: '20px 40px',
        cursor: 'pointer',
        width: '340px',
        textAlign: 'center',
        textDecoration: 'none',
        // position: 'fixed',
        // zIndex: '3',
      }


    let buttonStyleFalse = {
        fontSize: "50px",
        border: '1px solid #007bff',
        color: '#007bff',
        background: '#FFF',
        borderRadius: '5px',
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
    }

    // Return the first parameter key-value pair
    return (
        <div
            style={backgroundStyle}
        >
            <div style={{width: '7vw'}}></div>
            <div style={{
                width: '84vw',
                display: 'flex',
                justifyContent: 'center',
                }}>
                <div
                    style={{
                        maxWdith: '800px'
                    }}
                >

                    
                    <p style={titleStyle}>
                        Purchase Complete!
                    </p>
                    <p style={{
                        fontSize: '24px',
                        color: '#292929',
                        textAlign: 'center',
                        margin: '0px 0 40px 0',
                    }}>
                        Don't close or refresh the page until you read the options below
                    </p>
                    <div 
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <div style={cardStyle}>
                                <p style={{textAlign: 'center', margin: '0'}}>Print or save this QR code to start your 24-hour session later</p>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <QRCodeSVG
                                        style={{
                                            margin: '40px auto',
                                        }}
                                        value={`${baseUrl}/qrpurchased/${props.paymentQr}`}
                                    />
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <BsPrinter
                                        onClick={(e) => window.print()}
                                        style={{
                                            color: '#007bff',
                                            fontSize: '120px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{
                                width: '10%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <p style={{fontSize: "60px"}}>or</p>
                        </div>
                        <div style={{
                                width: '40%',
                            }}>
                            {
                                (props.paymentQr && props.paymentQr.length > 0)
                                ? 
                                    <div style={cardStyle}>
                                        <p style={{textAlign: 'center', margin: '0'}}>Start your 24-hour session now if you are on a desktop or ipad</p>
                                
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <div 
                                                style={buttonStyleTrue}
                                                onClick={() => handleStart()}
                                            >
                                                Start
                                            </div>   
                                        </div>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: '7vw'}}></div>
        </div>
      )
  }
  