import React, { useRef, useEffect } from 'react';
import { MdRefresh } from 'react-icons/md';

export default function SignatureCapture() {
  const canvasRef = useRef(null);
  let isDrawing = false;
  let context = null;

  useEffect(() => {
    const canvas = canvasRef.current;
    context = canvas.getContext('2d');
    context.lineWidth = 2;
    context.strokeStyle = 'black';
    context.lineCap = 'round';

    // Set canvas size and background
    canvas.width = 540;
    canvas.style.width = '540px';
    canvas.height = 200;
    canvas.style.height = '200px';

    // Disable text recognition/input
    canvas.addEventListener('contextmenu', disableContextMenu);

    // Support touch events
    canvas.addEventListener('touchstart', handleTouchStart, false);
    canvas.addEventListener('touchmove', handleTouchMove, false);
    canvas.addEventListener('touchend', handleTouchEnd, false);

    // Support mouse events
    canvas.addEventListener('mousedown', handleMouseDown, false);
    canvas.addEventListener('mousemove', handleMouseMove, false);
    canvas.addEventListener('mouseup', handleMouseUp, false);

    return () => {
      // Cleanup event listeners
      canvas.removeEventListener('contextmenu', disableContextMenu);
      canvas.removeEventListener('touchstart', handleTouchStart, false);
      canvas.removeEventListener('touchmove', handleTouchMove, false);
      canvas.removeEventListener('touchend', handleTouchEnd, false);
      canvas.removeEventListener('mousedown', handleMouseDown, false);
      canvas.removeEventListener('mousemove', handleMouseMove, false);
      canvas.removeEventListener('mouseup', handleMouseUp, false);
    };
  }, []);

  const disableContextMenu = (event) => {
    event.preventDefault();
  };

  const getCoordinates = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;
    return { offsetX, offsetY };
  };

  const handleTouchStart = (event) => {
    const { offsetX, offsetY } = getCoordinates(event.touches[0]);
    context.beginPath();
    context.moveTo(offsetX, offsetY);
    isDrawing = true;
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    if (!isDrawing) return;
    const { offsetX, offsetY } = getCoordinates(event.touches[0]);
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  const handleTouchEnd = () => {
    isDrawing = false;
  };

  const handleMouseDown = (event) => {
    const { offsetX, offsetY } = getCoordinates(event);
    context.beginPath();
    context.moveTo(offsetX, offsetY);
    isDrawing = true;
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = getCoordinates(event);
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  const handleMouseUp = () => {
    isDrawing = false;
  };

  const handleClear = () => {
    const canvas = canvasRef.current;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.globalCompositeOperation = 'source-over'; 
  };

   // Function to format the date as Month/Day/Year
   const formatDate = () => {
    const currentDate = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return currentDate.toLocaleDateString('en-US', options);
  };

  return (
    <div style={{ 
      margin: '10px 0 0 0',      
      }}>
      <div
        style={{
          position: 'relative',
          width: '540px',  
          height: '120px',       
        }}
      >
        <canvas
          ref={canvasRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{
            background: 'transparent',
            width: '540px',
            height: '200px',
            position: 'absolute', // Position the canvas absolutely
            bottom: '-60px', // Move the canvas up by 100px
            // Add more styles here as needed
            zIndex: "4",
          }}
          inputMode="none"
        />

        <MdRefresh
          onClick={handleClear}
          className="trash-icon dontPrint"
          style={{
            color: '#66CC33',
            background: '#FFF',
            border: '1px solid #66CC33',
            borderRadius: '5px',
            padding: '7px',
            fontSize: '20px',
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            zIndex: '5'
          }}
        />
      
        <div
          className="dontPrint"
          style={{
            width: '60px', // Adjust the width of the pentagon-like shape
            height: '40px', // Adjust the height of the pentagon-like shape
            position: 'absolute',
            bottom: '10px', // Adjust the positioning of the pentagon-like shape
            left: '0',
            zIndex: '1',
            clipPath: 'polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%)', // Define the pentagon-like shape
            backgroundColor: 'yellow',
          }}
        >
          <p
          style={{
            margin: '10px 0 0 8px',
            opacity: '0.5'
          }}
        >Sign</p>
        </div>
        
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '-18px',
          fontSize: '14px',
        }}
      >
        <div style={{
          zIndex: '2',
          }}>
          <p style={{ margin: '0' }}>
            _____________________________________________________________________________
          </p>
          <p style={{ margin: '0' }}>
            Patient (or person authorized to sign for the patient)
          </p>
        </div>
        <div style={{ marginLeft: '50px' }}>
          <p
            style={{ 
              margin: '-22px 0 0px 0',
              fontSize: "18px",
            }}>
              {formatDate()}
          </p>
          <p style={{ margin: '0 0 0 0' }}>_______________________</p>
          <p style={{ margin: '0 0 0 0' }}>Date</p>
        </div>
      </div>
    </div>
  );
}
