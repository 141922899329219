import React, { useState, useEffect } from 'react';

import api from "../../services/api";

import ConsentEdit from './ConsentEdit'
import Header from '../../partials/Header'
import Navbar from '../../partials/Navbar'
import ConsentType from './partials/ConsentType'


export default function ConsentList (props) {

const[userName, setUserName]=useState('')
const[modal, setModal] = useState('')
const[addConsent, setAddConsent] = useState(false)

  const handleConsentClick = (e) => {
    let id = e.target.id
    setModal(id)
  }


//show ConsentList or ConsentEdit or ConsentNew
  //ConsentList has Navbar and Header (ConsentEdit and ConsentNew do not)
  //Show ConsentList if modal = ''
    //Only map consents if (props.consentData).length > 0
  //Show ConsentEdit if modal = '#'
  //Show ConsentNew if modal = 'new'

  return (
    <div style={{
        display: 'flex',
      }}>
      <div
        style={{
          width: '7vw',
        }}>
      </div>
      {
        (modal === '')
        ?
          <div
            style={{
              width: '84vw',
            }}>
            <Navbar
              setUserName={setUserName}/>
            <Header
              title={"Consent forms"}
              subtitle={"Add or edit your consent forms"}
              />
            <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <div style={{
                  margin: '50px 0 0 0',
                }}>

                <ConsentType
                  consentData={props.consentData}
                  consentFilter='Lens consent'
                  consentTitle='Lens consents'
                  onConsentClick={handleConsentClick}
                  />
                <ConsentType
                  consentData={props.consentData}
                  consentFilter='FLACS consent'
                  consentTitle='FLACS consents'
                  onConsentClick={handleConsentClick}
                  />
                <ConsentType
                  consentData={props.consentData}
                  consentFilter='CRI consent'
                  consentTitle='CRI consents'
                  onConsentClick={handleConsentClick}
                  />
                <ConsentType
                  consentData={props.consentData}
                  consentFilter='Financial disclosure'
                  consentTitle='Financial disclosure'
                  onConsentClick={handleConsentClick}
                  />
                <ConsentType
                  consentData={props.consentData}
                  consentFilter='Other'
                  consentTitle='Other'
                  onConsentClick={handleConsentClick}
                  />

                <div
                  onClick={() => setModal('new')}
                  style={{
                    borderRadius: '5px',
                    width: '200px',
                    margin: '50px auto 50px auto',
                    padding: '2px 7px 2px 7px',
                    color: '#007bff',
                    border: '1px solid #007bff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  +add new consent
                </div>
              </div>
            </div>
          </div>
        : (modal === 'new')
            ? <ConsentEdit
                consentData=''
                consentId=''
                consentName=''
                consentType=''
                modal={modal}
                setModal={setModal}
                side={props.side}
              />
            : ((props.consentData).length > 0 && modal)
              ?
              (props.consentData).map(d => (
                  (d.consent_id === modal*1)
                  ? <ConsentEdit
                      key={d.consent_id}
                      consentData={d}
                      consentId={d.consent_id}
                      consentName={d.consent_name}
                      consentType={d.consent_type}
                      setModal={setModal}
                      modal={modal}
                      side={props.side}
                    />
                  : null
                ))
              : null
    }
    <div
      style={{
        width: '7vw',
      }}>
    </div>
  </div>
  )
}
