import React, { useState, Fragment } from 'react'

export default function ReferenceArticle (props) {

  const[toggle, setToggle] = useState(false)

  let infoStyle = {
    border: 'none',
    width: '15px',
    height: '20px',
    textAlign: 'center',
    padding: '0',
    background: 'lightblue',
    borderRadius: '3px',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  }

  return(
    <Fragment>
      <button
        onMouseEnter={() => setToggle(true)}
        onMouseLeave={() => setToggle(false)}
        style={infoStyle}
        tabIndex="-1">
        i
      </button>
      {toggle === true
        ? props.data
          ?
            <div
              onMouseEnter={() => setToggle(true)}
              onMouseLeave={() => setToggle(false)}
              style={{
                width: '400px',
                margin: '10px',
                padding: '7px',
                background: "#FFF",
                transitionDuration: '0.15s ease-out',
                zIndex: '7',
                position: 'absolute',
                top: '-7px',
                right: '15px',
                boxShadow: '-2px 2px 10px gray',
                borderRadius: '10px',
              }}
              >
              {
                (props.data).map((d, i) => (
                  <a
                  href={d.link}
                  target="_blank"
                  key={i}
                  style={{
                    display: 'block',
                    margin: '0 0 10px 0',
                  }}
                  >
                  <span>{d.author}. </span>
                  <span>{d.year}. </span>
                  <cite>{d.title}. </cite>
                  <span>{d.publication}. </span>
                </a>
                ))
              }
            </div>
          : null
        : null
      }
    </Fragment>
  )

}
