import React, { useState } from 'react';

import glasses from '../visionsimulator/images/glasses.jpeg';
import glareIcon from './images/glareIcon.jpeg'
import BackArrow from "../partials/BackArrow";
import ForwardArrow from '../partials/ForwardArrow';
import glistenings from './images/glistenings.jpeg'
import notPreloaded from './images/notPreloaded.jpeg'
import yellowing from './images/yellowing.jpeg'
import blueblocker from './images/blueblocker.jpeg'
import PrintPage from "../partials/PrintPage";
import PremiumNightComparison from '../visionsimulator/PremiumNightComparison';
import PremiumComparison from '../visionsimulator/PremiumComparison'

export default function CompareLenses(props) {
    // console.log(props.staticLensListOptions[0].lensName)


    const [cri, setCri] = useState(false);
    const [criTwo, setCriTwo] = useState(false);
    const [criAlwaysOnTwo, setCriAlwaysOnTwo] = useState('')
    const [criAllowToggleTwo, setCriAllowToggleTwo] = useState('')

    const [lensTargetNear, setLensTargetNear] = useState(false);
    const [lensTargetNearTwo, setLensTargetNearTwo] = useState(false);
    const [onlyUpToDateTwo, setOnlyUpToDateTwo] = useState(true)


    const handleNewerLens = (e) => {
      const lens = props.staticLensListOptions.find(l => l && l.lensModel && l.includes(e));
      if (lens) {
        props.setLensOption(lens.lensName);
      }
    };
  
  
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])



  const l = props.staticLensListOptions.find(option => option.lensName === props.lensOption);
  const t = props.staticLensListOptions.find(option => option.lensName === props.lensOptionTwo);

  

  let subtitleStyle = {
    display: 'flex',
  } 

  let labelStyle = {
    fontSize: '26px',
    color: '#292929',
    margin: "10px 0 0 0"
  }
  
  let proStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
  }
  
  let conStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
    color: 'red',
  }

  let imgStyle = {
    width: '100%',
    borderRadius: '20px',
  }

  let buttonStyleTrue = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#FFF',
    borderRadius: '10px',
    background: "#007bff",
    margin: '20px 10px 60px 10px',
    padding: '20px 40px',
    cursor: 'pointer',
    width: '340px',
    textAlign: 'center',
    textDecoration: 'none',
    // position: 'fixed',
    // zIndex: '3',
  }
  
let buttonStyleFalse = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#007bff',
    background: '#FFF',
    borderRadius: '5px',
    margin: '20px 10px 20px 10px',
    padding: '10px 0',
    cursor: 'pointer',
}
  
let columnStyleTitle = {
  width: "20%",
  textAlign: 'right'
}

let columnStyle = {
  width: "48%",
  display:'flex',
  justifyContent: 'center',
}

let rowStyle = {
  display: 'flex', 
  justifyContent: 'space-evenly',
  // borderBottom: "1px solid #292929",
  padding: "8px 0 60px 0",
}

let pStyle={
  fontSize: '20px',
  margin: '5px 0 0 0',
  textAlign: 'center',
}

let titleStyle = {
  fontSize: '46px',
  color: '#292929',
  textAlign: 'center',
  margin: '40px 0 10px 0',
}

let hrStyle = {
  width: '60%',
  margin: '0 0 40px 0',
}

let biometerTopographerAstigDiff =
Math.abs(props.biometerAstig*1 - props.topographerAstig*1)
//console.log(biometerTopographerAstigDiff)

// need to make input of 180 = 0 and input of '' not count
let biometerTopographerAxisDiff;
props.biometerAxis*1 >= 165 && props.topographerAxis*1 <= 15
? biometerTopographerAxisDiff = ((props.topographerAxis*1 + 180)-props.biometerAxis*1)
: props.biometerAxis*1 <= 15 && props.topographerAxis*1 >= 165
  ? biometerTopographerAxisDiff = ((props.biometerAxis*1 + 180) - props.topographerAxis*1)
  : biometerTopographerAxisDiff = Math.abs(props.biometerAxis*1 - props.topographerAxis*1)

  return (
    <div style={{display:'flex', background: "#FFF", margin: "40px 0 0 0"}}>
      <div style={{width: '7vw'}}></div>
      <div style={{width: '84vw'}}>
        <BackArrow
          linkTo='/premiumnightsimulator'
        />
        <ForwardArrow
            linkTo='/lensguideadvanced'
          />
        <p style={titleStyle}>Compare lenses</p>
        <div
          style={{display:'flex', justifyContent: 'center'}}
        >
          <hr
            style={hrStyle}
          />
        </div>
        
              <div 
                style={{margin: "40px 0 300px 0"}} 
              >
              
              <div 
                style={{
                  display: 'flex', 
                  justifyContent: 'space-evenly',
                  // borderBottom: "1px solid #292929",
                  padding: "8px 0 60px 0",
                }}
              >
                <div 
                  style={{
                    width: "48%",
                    display:'flex',
                    justifyContent: 'center',
                  }}
                >
                  <PremiumComparison
                    power={props.power.toLowerCase().replace(/\s+/gm,'')}
                    cylinder={props.cylinder.toLowerCase().replace(/\s+/gm,'')}
                    axis={props.axis}
                    add={props.add}
                    bcva={props.bcva}
                    ns={props.ns}
    
                    biometer={props.biometer}
                    setBiometer={props.setBiometer}
                    biometerAstig={props.biometerAstig}
                    biometerAxis={props.biometerAxis}
                    biometerStdDev={props.biometerStdDev}
    
                    chordMuTotal={props.chordMuTotal}
    
                    topographer={props.topographer}
                    setTopographer={props.setTopographer}
                    topographerAstig={props.topographerAstig}
                    topographerAxis={props.topographerAxis}
                    topographerSphAb={props.topographerSphAb}
                    topographerHoa={props.topographerHoa}
    
                    equipmentPreference={props.equipmentPreference}
                    setEquipmentPreference={props.setEquipmentPreference}
                    sphAbTarget={props.sphAbTarget}
                    setSphAbTarget={props.setSphAbTarget}
                    hoaMaxPreference={props.hoaMaxPreference}
                    setHoaMaxPreference={props.setHoaMaxPreference}
    
                    lensData={props.lensData}
                    setLensData={props.setLensData}
                    lensOption={props.lensOption}
                    setLensOption={props.setLensOption}
                    choseBestLens={props.choseBestLens}
                    setChoseBestLens={props.setChoseBestLens}
    
                    lensName={props.lensName}
                    setLensName={props.setLensName}
                    lensModel={props.lensModel}
                    setLensModel={props.setLensModel}
                    lensLocation={props.lensLocation}
                    setLensLocation={props.setLensLocation}
                    lensHospitalFee={props.lensHospitalFee}
                    setLensHospitalFee={props.setLensHospitalFee}
                    lensPhysicianFee={props.lensPhysicianFee}
                    setLensPhysicianFee={props.setLensPhysicianFee}
                    lensSphAb={props.lensSphAb}
                    setLensSphAb={props.setLensSphAb}
                    lensKSphAbResidual={props.lensKSphAbResidual}
                    setLensKSphAbResidual={props.setLensKSphAbResidual}
                    lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                    setLensCorrectsIntermediateVision={props.setLensCorrectsIntermediateVision}
                    lensCorrectsNearVision={props.lensCorrectsNearVision}
                    setLensCorrectsNearVision={props.setLensCorrectsNearVision}
                    lensGlare={props.lensGlare}
                    setLensGlare={props.setLensGlare}
                    lensTopographerHoaCutoff={props.lensTopographerHoaCutoff}
                    setLensTopographerHoaCutoff={props.setLensTopographerHoaCutoff}
                    lensKAstigMax={props.lensKAstigMax}
                    setLensKAstigMax={props.setLensKAstigMax}
                    lensKAstigMin={props.lensKAstigMin}
                    setLensKAstigMin={props.setLensKAstigMin}
                    lensKAstigResidual={props.lensKAstigResidual}
                    setLensKAstigResidual={props.setLensKAstigResidual}
                    lensChordMu={props.lensChordMu}
                    setLensChordMu={props.setLensChordMu}
                    lensBiometerStdDev={props.lensBiometerStdDev}
                    setLensBiometerStdDev={props.setLensBiometerStdDev}
                    lensBiometerTopographerAstigCutoff={props.lensBiometerTopographerAstigCutoff}
                    setLensBiometerTopographerAstigCutoff={props.setLensBiometerTopographerAstigCutoff}
                    lensBiometerTopographerAxisCutoff={props.lensBiometerTopographerAxisCutoff}
                    setLensBiometerTopographerAxisCutoff={props.setLensBiometerTopographerAxisCutoff}
                    setLensConsentId={props.setLensConsentId}
    
                    contraindications={props.contraindications}
                    lensTargetNear={lensTargetNear}
                    setLensTargetNear={setLensTargetNear}
    
                    lensCriFee={props.lensCriFee}
                    setLensCriFee={props.setLensCriFee}
                    lensFlacsFee={props.lensFlacsFee}
                    setLensFlacsFee={props.setLensFlacsFee}
    
                    criOffer={props.criOffer}
                    setCriOffer={props.setCriOffer}
                    lensCriMinAstig={props.lensCriMinAstig}
                    setLensCriMinAstig={props.setLensCriMinAstig}
                    lensCriMaxAstig={props.lensCriMaxAstig}
                    setLensCriMaxAstig={props.setLensCriMaxAstig}
                    lensCriRequire={props.lensCriRequire}
                    setLensCriRequire={props.setLensCriRequire}
                    lensFlacsRequire={props.lensFlacsRequire}
                    setLensFlacsRequire={props.setLensFlacsRequire}
                    cri={props.cri}
                    setCri={props.setCri}
                    criAlwaysOn={props.criAlwaysOn}
                    setCriAlwaysOn={props.setCriAlwaysOn}
                    criAllowToggle={props.criAllowToggle}
                    setCriAllowToggle={props.setCriAllowToggle}
                    criBioTopoAstigMax={props.criBioTopoAstigMax}
                    setCriBioTopoAstigMax={props.setCriBioTopoAstigMax}
                    criBioTopoAxisMax={props.criBioTopoAxisMax}
                    setCriBioTopoAxisMax={props.setCriBioTopoAxisMax}
    
                    flacs={props.flacs}
                    setFlacs={props.setFlacs}
                    flacsOffer={props.flacsOffer}
                    setFlacsOffer={props.setFlacsOffer}
                    flacsAlwaysOn={props.flacsAlwaysOn}
                    setFlacsAlwaysOn={props.setFlacsAlwaysOn}
                    flacsAllowToggle={props.flacsAllowToggle}
                    setFlacsAllowToggle={props.setFlacsAllowToggle}
    
                    physicianName={props.physicianName}
    
                    oopTotal={props.oopTotal}
                    setOopTotal={props.setOopTotal}
                    oopPhysician={props.oopPhysician}
                    setOopPhysician={props.setOopPhysician}
                    oopHospital={props.oopHospital}
                    setOopHospital={props.setOopHospital}
    
                    setPtLensList={props.setPtLensList}
    
                    userType={props.userType}
                    useVideo={props.useVideo}
                    useTutorial={props.useTutorial}
    
                    staticLensListOptions={props.staticLensListOptions}
                    selectedColor={props.selectedColor}
                    setSelectedColor={props.setSelectedColor}
                    onlyUpToDate={props.onlyUpToDate}
                    setOnlyUpToDate={props.setOnlyUpToDate}
                    onlyCompatible={true}
                  />
                </div>
                <div style={columnStyle}>
                <PremiumComparison
                    power={props.power.toLowerCase().replace(/\s+/gm,'')}
                    cylinder={props.cylinder.toLowerCase().replace(/\s+/gm,'')}
                    axis={props.axis}
                    add={props.add}
                    bcva={props.bcva}
                    ns={props.ns}
    
                    biometer={props.biometer}
                    setBiometer={props.setBiometer}
                    biometerAstig={props.biometerAstig}
                    biometerAxis={props.biometerAxis}
                    biometerStdDev={props.biometerStdDev}
    
                    chordMuTotal={props.chordMuTotal}
    
                    topographer={props.topographer}
                    setTopographer={props.setTopographer}
                    topographerAstig={props.topographerAstig}
                    topographerAxis={props.topographerAxis}
                    topographerSphAb={props.topographerSphAb}
                    topographerHoa={props.topographerHoa}
    
                    equipmentPreference={props.equipmentPreference}
                    setEquipmentPreference={props.setEquipmentPreference}
                    sphAbTarget={props.sphAbTarget}
                    setSphAbTarget={props.setSphAbTarget}
                    hoaMaxPreference={props.hoaMaxPreference}
                    setHoaMaxPreference={props.setHoaMaxPreference}
    
                    lensData={props.lensData}
                    setLensData={props.setLensData}
                    lensOption={props.lensOptionTwo}
                    setLensOption={props.setLensOptionTwo}
                    choseBestLens={props.choseBestLens}
                    setChoseBestLens={props.setChoseBestLens}
    
                    lensName={props.lensName}
                    setLensName={props.setLensName}
                    lensModel={props.lensModel}
                    setLensModel={props.setLensModel}
                    lensLocation={props.lensLocation}
                    setLensLocation={props.setLensLocation}
                    lensHospitalFee={props.lensHospitalFee}
                    setLensHospitalFee={props.setLensHospitalFee}
                    lensPhysicianFee={props.lensPhysicianFee}
                    setLensPhysicianFee={props.setLensPhysicianFee}
                    lensSphAb={props.lensSphAb}
                    setLensSphAb={props.setLensSphAb}
                    lensKSphAbResidual={props.lensKSphAbResidual}
                    setLensKSphAbResidual={props.setLensKSphAbResidual}
                    lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                    setLensCorrectsIntermediateVision={props.setLensCorrectsIntermediateVision}
                    lensCorrectsNearVision={props.lensCorrectsNearVision}
                    setLensCorrectsNearVision={props.setLensCorrectsNearVision}
                    lensGlare={props.lensGlare}
                    setLensGlare={props.setLensGlare}
                    lensTopographerHoaCutoff={props.lensTopographerHoaCutoff}
                    setLensTopographerHoaCutoff={props.setLensTopographerHoaCutoff}
                    lensKAstigMax={props.lensKAstigMax}
                    setLensKAstigMax={props.setLensKAstigMax}
                    lensKAstigMin={props.lensKAstigMin}
                    setLensKAstigMin={props.setLensKAstigMin}
                    lensKAstigResidual={props.lensKAstigResidual}
                    setLensKAstigResidual={props.setLensKAstigResidual}
                    lensChordMu={props.lensChordMu}
                    setLensChordMu={props.setLensChordMu}
                    lensBiometerStdDev={props.lensBiometerStdDev}
                    setLensBiometerStdDev={props.setLensBiometerStdDev}
                    lensBiometerTopographerAstigCutoff={props.lensBiometerTopographerAstigCutoff}
                    setLensBiometerTopographerAstigCutoff={props.setLensBiometerTopographerAstigCutoff}
                    lensBiometerTopographerAxisCutoff={props.lensBiometerTopographerAxisCutoff}
                    setLensBiometerTopographerAxisCutoff={props.setLensBiometerTopographerAxisCutoff}
                    setLensConsentId={props.setLensConsentId}
    
                    contraindications={props.contraindications}
                    lensTargetNear={lensTargetNearTwo}
                    setLensTargetNear={setLensTargetNearTwo}
    
                    lensCriFee={props.lensCriFee}
                    setLensCriFee={props.setLensCriFee}
                    lensFlacsFee={props.lensFlacsFee}
                    setLensFlacsFee={props.setLensFlacsFee}
    
                    criOffer={props.criOffer}
                    setCriOffer={props.setCriOffer}
                    lensCriMinAstig={props.lensCriMinAstig}
                    setLensCriMinAstig={props.setLensCriMinAstig}
                    lensCriMaxAstig={props.lensCriMaxAstig}
                    setLensCriMaxAstig={props.setLensCriMaxAstig}
                    lensCriRequire={props.lensCriRequire}
                    setLensCriRequire={props.setLensCriRequire}
                    lensFlacsRequire={props.lensFlacsRequire}
                    setLensFlacsRequire={props.setLensFlacsRequire}
                    cri={criTwo}
                    setCri={setCriTwo}
                    criAlwaysOn={criAlwaysOnTwo}
                    setCriAlwaysOn={setCriAlwaysOnTwo}
                    criAllowToggle={criAllowToggleTwo}
                    setCriAllowToggle={setCriAllowToggleTwo}
                    criBioTopoAstigMax={props.criBioTopoAstigMax}
                    setCriBioTopoAstigMax={props.setCriBioTopoAstigMax}
                    criBioTopoAxisMax={props.criBioTopoAxisMax}
                    setCriBioTopoAxisMax={props.setCriBioTopoAxisMax}
    
                    flacs={props.flacs}
                    setFlacs={props.setFlacs}
                    flacsOffer={props.flacsOffer}
                    setFlacsOffer={props.setFlacsOffer}
                    flacsAlwaysOn={props.flacsAlwaysOn}
                    setFlacsAlwaysOn={props.setFlacsAlwaysOn}
                    flacsAllowToggle={props.flacsAllowToggle}
                    setFlacsAllowToggle={props.setFlacsAllowToggle}
    
                    physicianName={props.physicianName}
    
                    oopTotal={props.oopTotal}
                    setOopTotal={props.setOopTotal}
                    oopPhysician={props.oopPhysician}
                    setOopPhysician={props.setOopPhysician}
                    oopHospital={props.oopHospital}
                    setOopHospital={props.setOopHospital}
    
                    setPtLensList={props.setPtLensList}
    
                    userType={props.userType}
                    useVideo={props.useVideo}
                    useTutorial={props.useTutorial}
    
                    staticLensListOptions={props.staticLensListOptions}
                    selectedColor={props.selectedColor}
                    setSelectedColor={props.setSelectedColor}
                    onlyUpToDate={onlyUpToDateTwo}
                    setOnlyUpToDate={setOnlyUpToDateTwo}
                    onlyCompatible={true}
                  />
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <PremiumNightComparison
                    power={props.power.toLowerCase().replace(/\s+/gm,'')}
                    cylinder={props.cylinder.toLowerCase().replace(/\s+/gm,'')}
                    axis={props.axis}
                    add={props.add}
                    bcva={props.bcva}
                    ns={props.ns}
    
                    biometer={props.biometer}
                    setBiometer={props.setBiometer}
                    biometerAstig={props.biometerAstig}
                    biometerAxis={props.biometerAxis}
                    biometerStdDev={props.biometerStdDev}
    
                    chordMuTotal={props.chordMuTotal}
    
                    topographer={props.topographer}
                    setTopographer={props.setTopographer}
                    topographerAstig={props.topographerAstig}
                    topographerAxis={props.topographerAxis}
                    topographerSphAb={props.topographerSphAb}
                    topographerHoa={props.topographerHoa}
    
                    equipmentPreference={props.equipmentPreference}
                    setEquipmentPreference={props.setEquipmentPreference}
                    sphAbTarget={props.sphAbTarget}
                    setSphAbTarget={props.setSphAbTarget}
                    hoaMaxPreference={props.hoaMaxPreference}
                    setHoaMaxPreference={props.setHoaMaxPreference}
    
                    lensData={props.lensData}
                    setLensData={props.setLensData}
                    lensOption={props.lensOption}
                    setLensOption={props.setLensOption}
                    choseBestLens={props.choseBestLens}
                    setChoseBestLens={props.setChoseBestLens}
    
                    lensName={props.lensName}
                    setLensName={props.setLensName}
                    lensModel={props.lensModel}
                    setLensModel={props.setLensModel}
                    lensLocation={props.lensLocation}
                    setLensLocation={props.setLensLocation}
                    lensHospitalFee={props.lensHospitalFee}
                    setLensHospitalFee={props.setLensHospitalFee}
                    lensPhysicianFee={props.lensPhysicianFee}
                    setLensPhysicianFee={props.setLensPhysicianFee}
                    lensSphAb={props.lensSphAb}
                    setLensSphAb={props.setLensSphAb}
                    lensKSphAbResidual={props.lensKSphAbResidual}
                    setLensKSphAbResidual={props.setLensKSphAbResidual}
                    lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                    setLensCorrectsIntermediateVision={props.setLensCorrectsIntermediateVision}
                    lensCorrectsNearVision={props.lensCorrectsNearVision}
                    setLensCorrectsNearVision={props.setLensCorrectsNearVision}
                    lensGlare={props.lensGlare}
                    setLensGlare={props.setLensGlare}
                    lensTopographerHoaCutoff={props.lensTopographerHoaCutoff}
                    setLensTopographerHoaCutoff={props.setLensTopographerHoaCutoff}
                    lensKAstigMax={props.lensKAstigMax}
                    setLensKAstigMax={props.setLensKAstigMax}
                    lensKAstigMin={props.lensKAstigMin}
                    setLensKAstigMin={props.setLensKAstigMin}
                    lensKAstigResidual={props.lensKAstigResidual}
                    setLensKAstigResidual={props.setLensKAstigResidual}
                    lensChordMu={props.lensChordMu}
                    setLensChordMu={props.setLensChordMu}
                    lensBiometerStdDev={props.lensBiometerStdDev}
                    setLensBiometerStdDev={props.setLensBiometerStdDev}
                    lensBiometerTopographerAstigCutoff={props.lensBiometerTopographerAstigCutoff}
                    setLensBiometerTopographerAstigCutoff={props.setLensBiometerTopographerAstigCutoff}
                    lensBiometerTopographerAxisCutoff={props.lensBiometerTopographerAxisCutoff}
                    setLensBiometerTopographerAxisCutoff={props.setLensBiometerTopographerAxisCutoff}
                    setLensConsentId={props.setLensConsentId}
    
                    contraindications={props.contraindications}
                    lensTargetNear={lensTargetNear}
                    setLensTargetNear={setLensTargetNear}
    
                    lensCriFee={props.lensCriFee}
                    setLensCriFee={props.setLensCriFee}
                    lensFlacsFee={props.lensFlacsFee}
                    setLensFlacsFee={props.setLensFlacsFee}
    
                    criOffer={props.criOffer}
                    setCriOffer={props.setCriOffer}
                    lensCriMinAstig={props.lensCriMinAstig}
                    setLensCriMinAstig={props.setLensCriMinAstig}
                    lensCriMaxAstig={props.lensCriMaxAstig}
                    setLensCriMaxAstig={props.setLensCriMaxAstig}
                    lensCriRequire={props.lensCriRequire}
                    setLensCriRequire={props.setLensCriRequire}
                    lensFlacsRequire={props.lensFlacsRequire}
                    setLensFlacsRequire={props.setLensFlacsRequire}
                    cri={props.cri}
                    setCri={props.setCri}
                    criAlwaysOn={props.criAlwaysOn}
                    setCriAlwaysOn={props.setCriAlwaysOn}
                    criAllowToggle={props.criAllowToggle}
                    setCriAllowToggle={props.setCriAllowToggle}
                    criBioTopoAstigMax={props.criBioTopoAstigMax}
                    setCriBioTopoAstigMax={props.setCriBioTopoAstigMax}
                    criBioTopoAxisMax={props.criBioTopoAxisMax}
                    setCriBioTopoAxisMax={props.setCriBioTopoAxisMax}
    
                    flacs={props.flacs}
                    setFlacs={props.setFlacs}
                    flacsOffer={props.flacsOffer}
                    setFlacsOffer={props.setFlacsOffer}
                    flacsAlwaysOn={props.flacsAlwaysOn}
                    setFlacsAlwaysOn={props.setFlacsAlwaysOn}
                    flacsAllowToggle={props.flacsAllowToggle}
                    setFlacsAllowToggle={props.setFlacsAllowToggle}
    
                    physicianName={props.physicianName}
    
                    oopTotal={props.oopTotal}
                    setOopTotal={props.setOopTotal}
                    oopPhysician={props.oopPhysician}
                    setOopPhysician={props.setOopPhysician}
                    oopHospital={props.oopHospital}
                    setOopHospital={props.setOopHospital}
    
                    setPtLensList={props.setPtLensList}
    
                    userType={props.userType}
                    useVideo={props.useVideo}
                    useTutorial={props.useTutorial}
    
                    staticLensListOptions={props.staticLensListOptions}
                    selectedColor={props.selectedColor}
                    setSelectedColor={props.setSelectedColor}
                    onlyUpToDate={props.onlyUpToDate}
                    setOnlyUpToDate={props.setOnlyUpToDate}
                    onlyCompatible={props.onlyCompatible}
                    setOnlyCompatible={props.setOnlyCompatible}
                  />
                </div>
                <div style={columnStyle}>
                <PremiumNightComparison
                    power={props.power.toLowerCase().replace(/\s+/gm,'')}
                    cylinder={props.cylinder.toLowerCase().replace(/\s+/gm,'')}
                    axis={props.axis}
                    add={props.add}
                    bcva={props.bcva}
                    ns={props.ns}
    
                    biometer={props.biometer}
                    setBiometer={props.setBiometer}
                    biometerAstig={props.biometerAstig}
                    biometerAxis={props.biometerAxis}
                    biometerStdDev={props.biometerStdDev}
    
                    chordMuTotal={props.chordMuTotal}
    
                    topographer={props.topographer}
                    setTopographer={props.setTopographer}
                    topographerAstig={props.topographerAstig}
                    topographerAxis={props.topographerAxis}
                    topographerSphAb={props.topographerSphAb}
                    topographerHoa={props.topographerHoa}
    
                    equipmentPreference={props.equipmentPreference}
                    setEquipmentPreference={props.setEquipmentPreference}
                    sphAbTarget={props.sphAbTarget}
                    setSphAbTarget={props.setSphAbTarget}
                    hoaMaxPreference={props.hoaMaxPreference}
                    setHoaMaxPreference={props.setHoaMaxPreference}
    
                    lensData={props.lensData}
                    setLensData={props.setLensData}
                    lensOption={props.lensOptionTwo}
                    setLensOption={props.setLensOptionTwo}
                    choseBestLens={props.choseBestLens}
                    setChoseBestLens={props.setChoseBestLens}
    
                    lensName={props.lensName}
                    setLensName={props.setLensName}
                    lensModel={props.lensModel}
                    setLensModel={props.setLensModel}
                    lensLocation={props.lensLocation}
                    setLensLocation={props.setLensLocation}
                    lensHospitalFee={props.lensHospitalFee}
                    setLensHospitalFee={props.setLensHospitalFee}
                    lensPhysicianFee={props.lensPhysicianFee}
                    setLensPhysicianFee={props.setLensPhysicianFee}
                    lensSphAb={props.lensSphAb}
                    setLensSphAb={props.setLensSphAb}
                    lensKSphAbResidual={props.lensKSphAbResidual}
                    setLensKSphAbResidual={props.setLensKSphAbResidual}
                    lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                    setLensCorrectsIntermediateVision={props.setLensCorrectsIntermediateVision}
                    lensCorrectsNearVision={props.lensCorrectsNearVision}
                    setLensCorrectsNearVision={props.setLensCorrectsNearVision}
                    lensGlare={props.lensGlare}
                    setLensGlare={props.setLensGlare}
                    lensTopographerHoaCutoff={props.lensTopographerHoaCutoff}
                    setLensTopographerHoaCutoff={props.setLensTopographerHoaCutoff}
                    lensKAstigMax={props.lensKAstigMax}
                    setLensKAstigMax={props.setLensKAstigMax}
                    lensKAstigMin={props.lensKAstigMin}
                    setLensKAstigMin={props.setLensKAstigMin}
                    lensKAstigResidual={props.lensKAstigResidual}
                    setLensKAstigResidual={props.setLensKAstigResidual}
                    lensChordMu={props.lensChordMu}
                    setLensChordMu={props.setLensChordMu}
                    lensBiometerStdDev={props.lensBiometerStdDev}
                    setLensBiometerStdDev={props.setLensBiometerStdDev}
                    lensBiometerTopographerAstigCutoff={props.lensBiometerTopographerAstigCutoff}
                    setLensBiometerTopographerAstigCutoff={props.setLensBiometerTopographerAstigCutoff}
                    lensBiometerTopographerAxisCutoff={props.lensBiometerTopographerAxisCutoff}
                    setLensBiometerTopographerAxisCutoff={props.setLensBiometerTopographerAxisCutoff}
                    setLensConsentId={props.setLensConsentId}
    
                    contraindications={props.contraindications}
                    lensTargetNear={lensTargetNearTwo}
                    setLensTargetNear={setLensTargetNearTwo}
    
                    lensCriFee={props.lensCriFee}
                    setLensCriFee={props.setLensCriFee}
                    lensFlacsFee={props.lensFlacsFee}
                    setLensFlacsFee={props.setLensFlacsFee}
    
                    criOffer={props.criOffer}
                    setCriOffer={props.setCriOffer}
                    lensCriMinAstig={props.lensCriMinAstig}
                    setLensCriMinAstig={props.setLensCriMinAstig}
                    lensCriMaxAstig={props.lensCriMaxAstig}
                    setLensCriMaxAstig={props.setLensCriMaxAstig}
                    lensCriRequire={props.lensCriRequire}
                    setLensCriRequire={props.setLensCriRequire}
                    lensFlacsRequire={props.lensFlacsRequire}
                    setLensFlacsRequire={props.setLensFlacsRequire}
                    cri={criTwo}
                    setCri={setCriTwo}
                    criAlwaysOn={criAlwaysOnTwo}
                    setCriAlwaysOn={setCriAlwaysOnTwo}
                    criAllowToggle={criAllowToggleTwo}
                    setCriAllowToggle={setCriAllowToggleTwo}
                    criBioTopoAstigMax={props.criBioTopoAstigMax}
                    setCriBioTopoAstigMax={props.setCriBioTopoAstigMax}
                    criBioTopoAxisMax={props.criBioTopoAxisMax}
                    setCriBioTopoAxisMax={props.setCriBioTopoAxisMax}
    
                    flacs={props.flacs}
                    setFlacs={props.setFlacs}
                    flacsOffer={props.flacsOffer}
                    setFlacsOffer={props.setFlacsOffer}
                    flacsAlwaysOn={props.flacsAlwaysOn}
                    setFlacsAlwaysOn={props.setFlacsAlwaysOn}
                    flacsAllowToggle={props.flacsAllowToggle}
                    setFlacsAllowToggle={props.setFlacsAllowToggle}
    
                    physicianName={props.physicianName}
    
                    oopTotal={props.oopTotal}
                    setOopTotal={props.setOopTotal}
                    oopPhysician={props.oopPhysician}
                    setOopPhysician={props.setOopPhysician}
                    oopHospital={props.oopHospital}
                    setOopHospital={props.setOopHospital}
    
                    setPtLensList={props.setPtLensList}
    
                    userType={props.userType}
                    useVideo={props.useVideo}
                    useTutorial={props.useTutorial}
    
                    staticLensListOptions={props.staticLensListOptions}
                    selectedColor={props.selectedColor}
                    setSelectedColor={props.setSelectedColor}
                    onlyUpToDate={props.onlyUpToDate}
                    setOnlyUpToDate={props.setOnlyUpToDate}
                    onlyCompatible={props.onlyCompatible}
                    setOnlyCompatible={props.setOnlyCompatible}
                  />
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                      style={{
                        width: '50%',
                        textAlign: 'center',
                      }}
                    >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: "0",
                          }}>
                            ${
                              props.cri && props.cri === true
                              ? (l && l.prosCons[0].cost*1 + 800)
                              : (l && l.prosCons[0].cost*1)
                            }
                        </p>
                      <p style={pStyle}>  
                        {
                          props.cri && props.cri === true
                          ? 'Estimated extra out-of-pocket per eye' 
                          : 'Estimated extra out-of-pocket per eye'
                        } 
                      </p>  
                  </div>        
                </div>
                <div style={columnStyle}>
                  { props.lensOptionTwo === "Cataract vision"
                    ? null
                    : 
                    <div
                      style={{
                        width: '50%',
                        textAlign: 'center',
                      }}
                    >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: "0",
                          }}>
                            ${
                              criTwo && criTwo === true
                              ? (t && t.prosCons[0].cost*1 + 800)
                              : (t && t.prosCons[0].cost*1)
                            }
                        </p>
                      <p style={pStyle}>  
                        {
                          criTwo && criTwo === true
                          ? 'Estimated extra out-of-pocket per eye' 
                          : 'Estimated extra out-of-pocket per eye'
                        } 
                      </p>  
                  </div> 
                  }
                </div>
              </div>
              

              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}>
                        {l && l.prosCons[0].glasses}
                    </p>   
                    <img
                      src={glasses}
                      style={l && l.prosCons[0].glasses === 'Some'
                        ? 
                          {
                            width: '60%',
                            borderRadius: '8px',
                            opacity: "0.5",
                          }
                        : l && l.prosCons[0].glasses === 'Rare'
                        ? 
                          {
                            width: '60%',
                            borderRadius: '8px',
                            opacity: "0.1",
                          }
                        : 
                          {
                            width: '60%',
                            borderRadius: '8px',
                            opacity: "1",
                          }}
                      alt='Need for glasses'
                    />
                    <p style={pStyle}>
                        need for glasses after surgery
                    </p>
                  </div>
                </div>
                <div style={columnStyle}>
                { props.lensOptionTwo === "Cataract vision"
                    ? null
                    : 
                      <div
                        style={{
                          width: '50%',
                          textAlign: 'center',
                        }}
                      >
                        <p 
                          style={{
                            fontSize: '50px', 
                            fontWeight: '600px',
                            margin: '0',
                          }}>
                            {t && t.prosCons[0].glasses}
                        </p>   
                        <img
                          src={glasses}
                          style={t && t.prosCons[0].glasses === 'Some'
                            ? 
                              {
                                width: '60%',
                                borderRadius: '8px',
                                opacity: "0.5",
                              }
                            : t && t.prosCons[0].glasses === 'Rare'
                            ? 
                              {
                                width: '60%',
                                borderRadius: '8px',
                                opacity: "0.1",
                              }
                            : 
                              {
                                width: '60%',
                                borderRadius: '8px',
                                opacity: "1",
                              }}
                          alt='Need for glasses'
                        />
                        <p style={pStyle}>
                            need for glasses after surgery
                        </p>
                      </div>
                    }
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                      style={{
                        width: '50%',
                        textAlign: 'center',
                      }}
                    >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}>
                        {l && l.prosCons[0].halos}
                    </p> 
                    <img
                      src={glareIcon}
                      style={l && l.prosCons[0].halos === 'Mild'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.4",
                          }
                        : l && l.prosCons[0].halos === 'Minimal'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.1",
                          }
                        : 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.8",
                          }}
                      alt='Glare and halos'
                    /> 
                    <p style={pStyle}>
                      glare and halos around lights after surgery
                    </p> 
                  </div>            
                </div>
                <div style={columnStyle}>
                { props.lensOptionTwo === "Cataract vision"
                    ? null
                    : 
                      <div
                        style={{
                          width: '50%',
                          textAlign: 'center',
                        }}
                      >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: '0',
                        }}>
                          {t && t.prosCons[0].halos}
                      </p> 
                      <img
                        src={glareIcon}
                        style={t && t.prosCons[0].halos === 'Mild'
                          ? 
                            {
                              width: '40%',
                              borderRadius: '8px',
                              opacity: "0.4",
                            }
                          : t && t.prosCons[0].halos === 'Minimal'
                          ? 
                            {
                              width: '40%',
                              borderRadius: '8px',
                              opacity: "0.1",
                            }
                          : 
                            {
                              width: '40%',
                              borderRadius: '8px',
                              opacity: "0.8",
                            }}
                        alt='Glare and halos'
                      /> 
                      <p style={pStyle}>
                        glare and halos around lights after surgery
                      </p> 
                    </div>  
                  }
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}>
                        {l && l.prosCons[0].blueblocker}
                    </p>   
                    <img
                      src={blueblocker}
                      style={l && l.prosCons[0].blueblocker === 'Does not'
                        ? 
                          {
                            width: '30%',
                            borderRadius: '8px',
                            opacity: "0.1",
                          }
                        : 
                          {
                            width: '30%',
                            borderRadius: '8px',
                            opacity: "1",
                          }}
                      alt='Blue blocker'
                    />         
                    <p style={pStyle}>
                      block blue light
                    </p>  
                  </div>
                </div>
                <div style={columnStyle}>
                { props.lensOptionTwo === "Cataract vision"
                    ? null
                    : 
                    <div
                      style={{
                        width: '60%',
                        textAlign: 'center',
                      }}
                    >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: '0',
                        }}>
                          {t && t.prosCons[0].blueblocker}
                      </p>   
                      <img
                        src={blueblocker}
                        style={t && t.prosCons[0].blueblocker === 'Does not'
                          ? 
                            {
                              width: '30%',
                              borderRadius: '8px',
                              opacity: "0.1",
                            }
                          : 
                            {
                              width: '30%',
                              borderRadius: '8px',
                              opacity: "1",
                            }}
                        alt='Blue blocker'
                      />         
                      <p style={pStyle}>
                        block blue light
                      </p>  
                    </div>
                  }
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}
                    >
                      {l && l.prosCons[0].yellowing}
                    </p> 
                    <img
                        src={yellowing}
                        style={l && l.prosCons[0].yellowing === 'No'
                          ? 
                            {
                              width: '30%',
                              borderRadius: '8px',
                              opacity: "0.1",
                            }
                          : 
                            {
                              width: '30%',
                              borderRadius: '8px',
                              opacity: "0.4",
                            }}
                        alt='Yellowing'
                      />     
                    <p style={pStyle}>
                      yellowing of vision
                    </p> 
                  </div>        
                </div>
                <div style={columnStyle}>
                { props.lensOptionTwo === "Cataract vision"
                    ? null
                    : 
                    <div
                      style={{
                        width: '60%',
                        textAlign: 'center',
                      }}
                    >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: '0',
                        }}
                      >
                        {t && t.prosCons[0].yellowing}
                      </p> 
                      <img
                          src={yellowing}
                          style={t && t.prosCons[0].yellowing === 'No'
                            ? 
                              {
                                width: '30%',
                                borderRadius: '8px',
                                opacity: "0.1",
                              }
                            : 
                              {
                                width: '30%',
                                borderRadius: '8px',
                                opacity: "0.4",
                              }}
                          alt='Yellowing'
                        />     
                      <p style={pStyle}>
                        yellowing of vision
                      </p> 
                    </div> 
                  }  
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                  <p 
                    style={{
                      fontSize: '50px', 
                      fontWeight: '600px',
                      margin: '0',
                    }}
                  >
                    {l && l.prosCons[0].preloaded}
                  </p> 
                  <img
                      src={notPreloaded}
                      style={l && l.prosCons[0].preloaded === 'Minimal'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.4",
                          }
                        : 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "1",
                          }}
                      alt='Preloaded lens'
                    />
                    <p style={pStyle}>
                      risk of damaging lens when preparing it in the operating room
                    </p>  
                  </div>
                </div>
                <div style={columnStyle}>
                { props.lensOptionTwo === "Cataract vision"
                    ? null
                    : 
                      <div
                        style={{
                          width: '60%',
                          textAlign: 'center',
                        }}
                      >
                        <p 
                          style={{
                            fontSize: '50px', 
                            fontWeight: '600px',
                            margin: '0',
                          }}
                        >
                          {t && t.prosCons[0].preloaded}
                        </p> 
                        <img
                            src={notPreloaded}
                            style={t && t.prosCons[0].preloaded === 'Minimal'
                              ? 
                                {
                                  width: '40%',
                                  borderRadius: '8px',
                                  opacity: "0.4",
                                }
                              : 
                                {
                                  width: '40%',
                                  borderRadius: '8px',
                                  opacity: "1",
                                }}
                            alt='Preloaded lens'
                          />
                          <p style={pStyle}>
                            risk of damaging lens when preparing it in the operating room
                          </p>  
                        </div>
                    }
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                  <p 
                    style={{
                      fontSize: '50px', 
                      fontWeight: '600px',
                      margin: '0',
                    }}
                  >
                    {l && l.prosCons[0].glistenings}
                  </p> 
                  <img
                      src={glistenings}
                      style={l && l.prosCons[0].glistenings === 'Unlikely'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.8",
                          }
                        : 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "1",
                          }}
                      alt='Preloaded lens'
                    />
                    <p style={pStyle}>
                      to develop lens glistenings
                    </p>  
                  </div>
                </div>
                <div style={columnStyle}>
                { props.lensOptionTwo === "Cataract vision"
                    ? null
                    : 
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}
                    >
                      {t && t.prosCons[0].glistenings}
                    </p> 
                    <img
                        src={glistenings}
                        style={t && t.prosCons[0].glistenings === 'Unlikely'
                          ? 
                            {
                              width: '40%',
                              borderRadius: '8px',
                              opacity: "0.8",
                            }
                          : 
                            {
                              width: '40%',
                              borderRadius: '8px',
                              opacity: "1",
                            }}
                        alt='Preloaded lens'
                      />
                      <p style={pStyle}>
                        to develop lens glistenings
                      </p>  
                    </div>
                  }
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  {
                    l && l.prosCons[0].other
                    ? 
                      <div
                        style={{
                          width: '60%',
                          textAlign: 'center',
                        }}
                      >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: '0',
                        }}
                      >
                        Of Note:
                      </p> 
                        <p style={pStyle}>
                          {l && l.prosCons[0].other}
                        </p>  
                      </div>
                    : null
                  }
                </div>
                <div style={columnStyle}>
                  <div style={rowStyle}>
                    {
                      t && t.prosCons[0].other && props.lensOptionTwo !== 'Cataract vision'
                      ? 
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'center',
                          }}
                        >
                        <p 
                          style={{
                            fontSize: '50px', 
                            fontWeight: '600px',
                            margin: '0',
                          }}
                        >
                          Of Note:
                        </p> 
                          <p style={pStyle}>
                            {t && t.prosCons[0].other}
                          </p>  
                        </div>
                      : null
                    }
                  </div> 
                </div>
              </div>                

            </div>

      </div>        
      <div style={{width: '7vw'}}></div>
      
    </div>
  )
}





// <img
// src={trifocalImg}
// style={imgStyle}
// />

// <img
// src={toricTrifocalImg}
// style={imgStyle}
// />

// <img
// src={edofImg}
// style={imgStyle}
// />

// <img
// src={toricEdofImg}
// style={imgStyle}
// />

// <img
// src={toricImg}
// style={imgStyle}
// />

// <img
// src={monofocalImg}
// style={imgStyle}
// />

// <FaDollarSign
// style={{
// fontSize: '80px',
// borderRadius: "5px",
// }}
// />

// <img
// src={glasses}
// style={{
// width: '100px',
// height: "74px",
// borderRadius: "5px",
// margin: '0 30px 0 0',
// maxWidth: '100%' // Add this line
// }}
// />
// <img
// src={glareIcon}
// style={{
// width: '100px',
// height: '98px',
// borderRadius: "5px",
// margin: '0 30px 0 0',
// maxWidth: '100%' // Add this line
// }}
// />
// <div
// className="dontPrint"
// >
// <PrintPage/>
// </div>
