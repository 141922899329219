import StyleButton from './StyleButton'
import '../consentStyle.css';

export default function InlineStyleControls (props) {

  var currentStyle = props.editorState && (props.editorState).getCurrentInlineStyle();

  const INLINE_STYLES = [
    {label: 'B', style: 'bolded'},
    {label: 'I', style: 'ITALIC'},
    {label: 'U', style: 'UNDERLINE'},
    {label: 'Monospace', style: 'CODE'},
  ];

  return (
    <div
      className="RichEditor-controls"
      style={{
        fontFamily: 'Helvetica',
        fontSize: '18px',
        userSelect: 'none',
        display: 'flex',
      }}>
      {INLINE_STYLES.map(s => (
        <StyleButton
          key={s.label}
          active={currentStyle.has(s.style)}
          label={s.label}
          style={s.style}
          onToggle={props.onToggle}
          />
          )
        )
      }
    </div>
  );
};
