import React, { useState, useEffect } from 'react'

import Header from '../partials/Header'
import ForwardArrow from '../partials/ForwardArrow'
import BackArrow from '../partials/BackArrow'
import ChoiceTile from './ChoiceTile'
import QrCodePhysician from '../partials/QrCodePhysician'
import Vf8Questionnaire from '../paperwork/questionnaire/Vf8Questionnaire'
import OcularNotes from './OcularNotes'

import { BsPrinter, BsCameraFill } from "react-icons/bs";
import { FaChalkboardUser } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";

import api from "../services/api";


export default function Choice1(props) {

  const [isPrinting, setIsPrinting] = useState(false);


  const handlePrint = async() => {
    encFxn()

    let qrEncryptionKey = props.encryptionKey
    let qrToken = props.qrToken
    let qrDate = new Date();
    console.log(qrDate)

    try {
      const res = await api.post("/qrcodecreate", { 
        qrToken,
        qrEncryptionKey,
        qrDate,
      });

      if (res.status === 200) {
        setIsPrinting(true);
      }
    } catch (err) {
      console.error(err.message)
    }

    setTimeout(() => {
      window.print(); // Trigger the browser's print functionality
      setIsPrinting(false);
    }, 500); // Delay to allow for any asynchronous actions to complete before printing
  };

  const doNothing = () => {

  }


  //create contraindications array
  let contraArray = []
  let contraindications = props.contraindications.map(c => {
    if(c.value === 'true'){
      contraArray.push(c.name)
    }
  })

  let addOn = (props.cataractAddOn.length > 0)
  ? (props.cataractAddOn)
      .filter(c => c.value === 'true')
      .map(c => `${c.name}`)
  : []

  //create a string for the QR code using the props below
  let propsData = [
    props.side, 
    props.power, 
    props.cylinder, 
    props.axis, 
    props.add, 
    props.bcva, 
    props.ns, 
    props.bat, 
    props.biometerAstig, 
    props.biometerAxis, 
    props.chordMuTotal, 
    props.topographerAstig, 
    props.topographerAxis, 
    props.topographerSphAb, 
    props.topographerHoa, 
    props.codeStatus, 
    props.allergies, 
    props.lensOption,
    props.q1,
    props.q2,
    props.q3,
    props.q4,
    props.q5,
    props.q6,
    props.q7,
    props.q8,
    props.q9,
    props.q10,
    props.q11,
    props.q12,
    props.q13,
    props.q14,
    addOn,
    contraArray,
  ]

  let fillSvgArr = propsData.map(m => 
    `${m}`).join('&')

  // console.log(fillSvgArr)
  
  //generate encryption key
  const CryptoJS = require('crypto-js');
 
  // Generate an encryption key when page loads
  const encKeys = () => {
     const encKey = CryptoJS.lib.WordArray.random(32).toString()
    //  console.log(encKey)
     props.setEncryptionKey(encKey)
 
     //create qrToken
     const qrTok = CryptoJS.lib.WordArray.random(32).toString().replace(/\+/g, "-").replace(/\//g, "_")
     // console.log(qrTok)
     props.setQrToken(qrTok)
  }


  //encrypt when hit print button to give props time to load
  const encFxn = () => {
    //encrypt the props
    // console.log(props.encryptionKey)
    const ciText = CryptoJS.AES.encrypt(fillSvgArr, (props.encryptionKey)).toString().replace(/\+/g, "-").replace(/\//g, "_")
    props.setCipherText(ciText)
  }
  
  
  //store encryption key in qr_table
  

  
  //save/post to qr_table:  user_id, qr_token, qr_encryption_key, current_date

useEffect(() => {
    encKeys()
  }, []);






  let instructionStyle = {
    fontSize: '24px',
    // textAlign: 'center',
    margin: "0 0 60px 0",
    width: '400px',
  }

  let mdInstructionStyle = {
    fontSize: '24px',
    textAlign: 'center',
    margin: "0 0 60px 0",
    width: '400px',
  }
  
  let headerStyle = {
    fontSize: '24px',
  }

  let rowDivStyle = {
    display: 'flex',
    margin: '10px 0 60px 0',
  }

  let imgDivStyle = {
    width: '240px', 
    display: 'flex', 
    justifyContent: 'start',
  }

   // Render the printable version when isPrinting is true
   if (isPrinting) {
    return (
      <> 
        <Header
          title={"Surgeon QR code"}
          subtitle={""}
        />
        <div style={{display: 'flex'}}>
          <div style={{width: '7vw'}}></div>
          <div style={{width: '84vw'}}>
            <div
              style={{
                width: '800px',
              }}
            >
              <div
                style={{
                  display:'flex',
                  justifyContent: 'center',
                }}
              >
                <QrCodePhysician
                  linkTo='qr'
                

                  encryptionKey={props.encryptionKey}
                  setEncryptionKey={props.setEncryptionKey}
                  cipherText={props.cipherText}
                  setCipherText={props.setCipherText}
                  qrToken={props.qrToken}
                  setQrToken={props.setQrToken}
                  
                />  
              </div>
              
              <div
                style={{
                  display:'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <p style={mdInstructionStyle}>1: Scan this QR code</p>
                  <p style={mdInstructionStyle}>2: Use the simulator</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{width: '7vw'}}></div>    
          
        </div>
          

        <Header
          title={"Surgical Considerations"}
          subtitle={""}
        />
        <div style={{display: 'flex'}}>
          <div style={{width: '7vw'}}></div>
          <div style={{width: '84vw'}}>
            <div
              style={{
                display:'flex',
                justifyContent: 'center',
                width: '800px',
              }}
            >
              <OcularNotes
                power={props.power.toLowerCase().replace(/\s+/gm,'')}
                cylinder={props.cylinder.toLowerCase().replace(/\s+/gm,'')}
                axis={props.axis}
                add={props.add}
                codeStatus={props.codeStatus}
                allergies={props.allergies}
                side={props.side}
                bcva={props.bcva}
                ns={props.ns}
                bat={props.bat}
                equipmentPreference={props.equipmentPreference}
                biometer={props.biometer}
                biometerAstig={props.biometerAstig}
                biometerAxis={props.biometerAxis}
                chordMuTotal={props.chordMuTotal}
                topographer={props.topographer}
                topographerAstig={props.topographerAstig}
                topographerAxis={props.topographerAxis}
                topographerSphAb={props.topographerSphAb}
                topographerHoa={props.topographerHoa}
                lensOption={props.lensOption}
                lensModel={props.lensModel}
                lensLocation={props.lensLocation}
                sulcusLens={props.sulcusLens}
                contraindications={props.contraindications}

                lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                lensCorrectsNearVision={props.lensCorrectsNearVision}
                lensTargetNear={props.lensTargetNear}
                lensKAstigMin={props.lensKAstigMin}
                cri={props.cri}
                flacs={props.flacs}

                cataractAddOn={props.cataractAddOn}
              />
            </div>
          </div>
        <div style={{width: '7vw'}}></div>   
      </div>



        <div>
          <div
            style ={{
              pageBreakBefore: 'always'}}
              >
          </div>
          <div style={{
              height: '150px',
              borderBottom: '1px dashed gray',
              margin: '0 0 40px 0',
            }}
            className = 'dontPrint'
            >
          </div>
        </div>



        <Header
          title={"Patient QR code"}
          subtitle={""}
        />
        <div style={{display: 'flex'}}>
          <div style={{width: '7vw'}}></div>
          <div style={{width: '84vw'}}>
            <div
              style={{
                width: '800px',
              }}
            >
              <div
                style={{
                  display:'flex',
                  justifyContent: 'center',
                }}
              >
                <QrCodePhysician
                  linkTo='qr'
                  qrToken={props.qrToken}  
                  cipherText={props.cipherText}
                  encryptionKey={props.encryptionKey}
                />  
              </div>
              
              <div
                style={{
                  display:'flex',
                  justifyContent: 'center',
                  width: '800px',
                  margin: '20px 0 0 0',
                }}
              >
                <div>
                  <div style={rowDivStyle}>
                    <div style={imgDivStyle}>
                      <img
                        src='ipadScan.jpg'
                        alt='ipad scan'
                        height='140px'
                      />
                    </div>
                    <p style={instructionStyle}>
                      1: Use your iPad camera app to scan the QR code above
                    </p>
                  </div>
                  <div style={rowDivStyle}>
                    <div style={imgDivStyle}>
                      <img
                        src='ipadLink.jpg'
                        alt='ipad link'
                        height='140px'
                      />
                    </div>
                    <p style={instructionStyle}>
                      2: Click on the link
                    </p>
                  </div>
                  <div style={rowDivStyle}>
                    <div style={imgDivStyle}>
                      <img
                        src='ipadChoose.jpg'
                        alt='ipad choose'
                        height='140px'
                      />
                    </div>
                    <p style={instructionStyle}>
                      3: Select "I am a patient", then follow the instructions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{width: '7vw'}}></div>    
          
        </div>
          

     

        <div>
          <div
            style ={{
              pageBreakBefore: 'always'}}
              >
          </div>
          <div style={{
              height: '150px',
              borderBottom: '1px dashed gray',
              margin: '0 0 40px 0',
            }}
            className = 'dontPrint'
            >
          </div>
        </div>


        <Vf8Questionnaire
          side={props.side}
          q1={props.q1}
          q2={props.q2}
          q3={props.q3}
          q4={props.q4}
          q5={props.q5}
          q6={props.q6}
          q7={props.q7}
          q8={props.q8}
        />
      </>
    );
  }

  // Render the regular component when isPrinting is false
  return (
    <>
      <BackArrow
          linkTo='/questionnaire'
      />

      <ForwardArrow
          linkTo={
            (
              props.useVideo === 'false' 
              && 
              props.useTutorial === 'false'
            )
            ? '/visionsimulator'
            : 
            (
              props.useVideo === 'false' 
              && 
              props.useTutorial === 'true'
            )
            ? '/tutorial1'
            : '/tutorialvideo'
          }
      />
      <Header
      title={"Choose an option below"}
      subtitle={""}
      />
      <div
        style={{
          width: '800px',
          margin: '70px auto 0 auto',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <ChoiceTile
          linkTo={'/choice1'}
          onPrint={handlePrint}
          linkIcon={BsPrinter}
          linkText={'Print QR'}
        />
        {
          (
            props.useVideo === 'false' 
            && 
            props.useTutorial === 'false'
          )
          ? null
          : 
          (
            props.useVideo === 'false' 
            && 
            props.useTutorial === 'true'
          )
          ?
            <ChoiceTile
              linkTo={'/tutorial1'}
              onPrint={doNothing}
              linkIcon={FaChalkboardUser}
              linkText={'Tutorial'}
            />
          : <ChoiceTile
              linkTo={'/tutorialvideo'}
              onPrint={doNothing}
              linkIcon={FaChalkboardUser}
              linkText={'Tutorial'}
            />
        }
        <ChoiceTile
          linkTo={'/visionsimulator'}
          onPrint={doNothing}
          linkIcon={FaEye}
          linkText={'Simulator'}
        />

      </div>
    </>
  )
}





// power={props.power}
// cylinder={props.cylinder}
// axis={props.axis}
// add={props.add}
// codeStatus={props.codeStatus}
// allergies={props.allergies}
// side={props.side}
// bcva={props.bcva}
// ns={props.ns}
// bat={props.bat}

// biometer={props.biometer}
// biometerAstig={props.biometerAstig}
// biometerAxis={props.biometerAxis}
// chordMuTotal={props.chordMuTotal}

// topographer={props.topographer}
// topographerAstig={props.topographerAstig}
// topographerAxis={props.topographerAxis}
// topographerSphAb={props.topographerSphAb}
// topographerHoa={props.topographerHoa}
// equipmentPreference={props.equipmentPreference}
// sphAbTarget={props.sphAbTarget}
// hoaMaxPreference={props.hoaMaxPreference}


// lensOption={props.lensOption}
// lensData={props.lensData}
// contraindications={props.contraindications}

// criOffer={props.criOffer}
// criBioTopoAstigMax={props.criBioTopoAstigMax}
// criBioTopoAxisMax={props.criBioTopoAxisMax}
// criConsentId={props.criConsentId}
// flacsOffer={props.flacsOffer}
// flacsConsentId={props.flacsConsentId}


