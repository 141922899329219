import React, { Fragment, useState, useEffect } from 'react';

import FormInput from '../partials/FormInput'
import FormSelect from '../partials/FormSelect'
import Header from '../partials/Header'
import Navbar from '../partials/Navbar'

import api from "../services/api";

export default function CriFlacs (props){


  const [flacsOffer, setFlacsOffer] = useState('No')
  const [criOffer, setCriOffer] = useState('No')
  const [criBioTopoAstigMax, setCriBioTopoAstigMax] = useState('')
  const [criBioTopoAxisMax, setCriBioTopoAxisMax] = useState('')
  const [criFlacsId, setCriFlacsId] = useState('')

  //++++++++++++++ERROR MESSAGE HANDLING++++++++++++++++++++++++++++++++++++++++++++
  //create a hook so that you can store the messages in state so they show up in JSX tags
  const[errorMessage, setErrorMessage]=useState([])

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  // Get CRI info from database
  const getCriFlacs = async() => {

      try {
        const response = await api.get(`/criflacs`);

        setCriFlacsId(response.data.rows[0].criflacs_id)
        setCriOffer(response.data.rows[0].cri_offer)
        setCriBioTopoAstigMax(response.data.rows[0].cri_bio_topo_astig_max)
        setCriBioTopoAxisMax(response.data.rows[0].cri_bio_topo_axis_max)
        setFlacsOffer(response.data.rows[0].flacs_offer)
      } catch (err) {
        console.error(err.message)
      }

    }

    useEffect(() => {
        getCriFlacs();
      }, []);


  // Post to CRI database
  const postCriFlacs = async(e) => {
    e.preventDefault();
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      //create blank array to send error messages
      let errorMessageArr = [];

      //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false.
      let propsArr = [];
        if(flacsOffer === 'No' && criOffer === 'Yes'){
          propsArr =
          [
            {name: `${flacsOffer}`, message: '*Please choose whether or not you offer FLACSs'},
            {name: `${criOffer}`, message: '*Please choose whether or not you offer CRIs'},
            {name: `${criBioTopoAstigMax}`, message: '*Please enter your biometer-topographer astigmatism cutoff for a CRI'},
            {name: `${criBioTopoAxisMax}`, message: '*Please enter your biometer-topographer axis cutoff for a CRI'},
          ];
        } else if (flacsOffer === 'Yes' && criOffer === 'No'){
          propsArr =
          [
            {name: `${flacsOffer}`, message: '*Please choose whether or not you offer FLACSs'},
            {name: `${criOffer}`, message: '*Please choose whether or not you offer CRIs'},
          ];
        } else {
          propsArr = []
        }


      //Map through props you are interested in and send error message to errorMessageArr if there is an error

      Object.entries(propsArr).map(p => {
          if(p[1].name === ''){
            // console.log(p[1].message)
            return errorMessageArr.push(p[1].message)
          } else {
            return null
          }

        })
        // console.log(errorMessageArr)
        setErrorMessage(errorMessageArr)
        //if there are errors, do not complete "put" or "update" function
        if(errorMessageArr.length > 0){
          return null
        } else {

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        try {
          await api.post(`/criflacs` , {
            //no header or body stuff here, just send the variables
            criOffer,
            criBioTopoAstigMax,
            criBioTopoAxisMax,
            flacsOffer,
          }).then(window.location =`/lenses` )

        } catch (err) {
          console.error(err.message)
        }

      }
    }

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  // Update CRI database
  const updateCriFlacs = async(e) => {
    e.preventDefault();

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      //create blank array to send error messages
      let errorMessageArr = [];

      //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false.
      let propsArr = [];
        if(flacsOffer === 'No' && criOffer === 'Yes'){
          propsArr =
          [
            {name: `${flacsOffer}`, message: '*Please choose whether or not you offer FLACSs'},
            {name: `${criOffer}`, message: '*Please choose whether or not you offer CRIs'},
            {name: `${criBioTopoAstigMax}`, message: '*Please enter your biometer-topographer astigmatism cutoff for a CRI'},
            {name: `${criBioTopoAxisMax}`, message: '*Please enter your biometer-topographer axis cutoff for a CRI'},
          ];
        } else if (flacsOffer === 'Yes' && criOffer === 'No'){
          propsArr =
          [
            {name: `${flacsOffer}`, message: '*Please choose whether or not you offer FLACSs'},
            {name: `${criOffer}`, message: '*Please choose whether or not you offer CRIs'},
          ];
        } else {
          propsArr = []
        }



      //Map through props you are interested in and send error message to errorMessageArr if there is an error

      Object.entries(propsArr).map(p => {
          // If criOffer is "no" then no errors
          if(p[1].name === ''){
            // console.log(p[1].message)
            return errorMessageArr.push(p[1].message)
          } else {
            return null
          }

        })
        // console.log(errorMessageArr)
        setErrorMessage(errorMessageArr)
        //if there are errors, do not complete "put" or "update" function
        if(errorMessageArr.length > 0){
          return null
        } else {

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      try {
        await api.put(`/criflacs` , {
          criFlacsId,
          criOffer,
          criBioTopoAstigMax,
          criBioTopoAxisMax,
          flacsOffer,
        }).then(window.location = `/myaccount` )

        }
          catch (err) {
           console.error(err.message)
      }
    }
  }


  //+++++++++Reset data when cri and flacs choices change++++++++++
  useEffect(() => {
      if(criOffer === "No"){
        setCriBioTopoAstigMax('')
        setCriBioTopoAxisMax('')
      } 
    }, [criOffer]);


//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  let yesNoOptions = [
    "No",
    "Yes",
  ]

  let chooseYesNoOptions = [
    "Choose an option below",
    "No",
    "Yes",
  ]

  let flacsOptions = [
    "Choose your FLACS",
    "LenSx",
    "Catalys",
    "LensAR",
    "Victus",
    "Femto LDV",
  ]

  let warnStyle ={
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

  return(
      <Fragment>
        <Navbar/>
        <Header
          title={"Femtosecond Laser and Corneal Relaxing Incisions"}
          subtitle={"Choose your parameters below"}
          />
        <form
          style={{
            background: '#F2F3F4',
            borderRadius: '10px',
            padding: '0px 0 50px 20px',
            margin: '20px auto 20px auto',
            width: '500px',
          }}>
          <div style = {{
              padding: '5px 5px 15px 10px',
            }}>
            <Fragment>
              <FormSelect
                label="Do you offer Femtosecond Laser-Assisted Cataract Surgery (FLACS)?"
                value={flacsOffer}
                setHook={setFlacsOffer}
                options={yesNoOptions}
                />
              <FormSelect
                label="Do you offer Corneal Relaxing Incisions (CRI)?"
                value={criOffer}
                setHook={setCriOffer}
                options={yesNoOptions}
                />
              {
                criOffer === "Yes"
                ? 
                  <>
                    <FormInput
                      label="maXimum astigmatism difference between topographer and biometer (D) for a CRI:"
                      value={criBioTopoAstigMax}
                      setHook={setCriBioTopoAstigMax}
                      type={'number'}
                      />
                    <FormInput
                      label="maXimum axis difference between topographer and biometer for a CRI:"
                      value={criBioTopoAxisMax}
                      setHook={setCriBioTopoAxisMax}
                      type={'number'}
                      />
                  </>
                : null
              }
            </Fragment>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              onClick={
                criFlacsId
                    ? (e) => updateCriFlacs(e)
                    : (e) => postCriFlacs(e)
                }
              style={{
                borderRadius: '5px',
                width: '100px',
                margin: '5px',
                padding: '7px',
                background: '#007bff',
                color: '#fff',
                fontSize: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              >
              Save
            </div>
            {criFlacsId
              ?
                <div
                  onClick={() => window.location = `/myaccount`}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Cancel
                </div>
              : null
            }
          </div>
        </form>
        <div
          style={{
             justifyContent: 'left',
             margin: '0 0 50px 280px',
           }}>
          {errorMessage.map((m, i) => (
              <p
                key={i}
                style={warnStyle}
                >
                {m}
              </p>
            ))
          }
        </div>
      </Fragment>
  )
}
