import React, { useState, Fragment } from 'react'

export default function ReferenceImage (props) {

  const[toggle, setToggle] = useState(false)

  let infoStyle = {
    border: 'none',
    width: '15px',
    height: '20px',
    textAlign: 'center',
    padding: '0',
    background: 'lightblue',
    borderRadius: '3px',
    position: 'absolute',
    bottom: '3px',
    right: '3px',
  }

  return(
    <Fragment>
      <div
        onMouseEnter={() => setToggle(true)}
        onMouseLeave={() => setToggle(false)}
        style={infoStyle}
        tabIndex="-1">
        ?
      </div>
      {props.referenceImage
        ?
          <img
            src={props.referenceImage}
            alt='reference'
            style={{
              height: `${toggle === true ? '60vh' : '0px'}`,
              width: `${toggle === true ? 'auto' : '0px'}`,
              margin: '10px',
              transitionDuration: '0.15s ease-out',
              zIndex: '15',
              position: 'fixed',
              top: '34px',
              right: '0px',
              boxShadow: '-2px 2px 10px gray',
              borderRadius: '10px',
              pointerEvents: 'none',
            }}
            >
          </img>
        : null
      }
    </Fragment>
  )
}
