import React, { Fragment, useState, useEffect } from 'react';

import FormSelect from '../partials/FormSelect'
import FormSelectComment from '../partials/FormSelectComment'
import FormInput from '../partials/FormInput'
import FormInputComment from '../partials/FormInputComment'
import ReferenceArticle from '../partials/ReferenceArticle'

import api from "../services/api";

export default function Biometer (props){

  const [biometerName, setBiometerName] = useState(props.biometerName)
  const [topographerName, setTopographerName] = useState(props.topographerName)
  const [equipmentPreference, setEquipmentPreference] = useState(props.equipmentPreference)
  const equipmentId = props.equipmentId
  const [hoaMaxPreference, setHoaMaxPreference] = useState(props.hoaMaxPreference)
  const [sphAbTarget, setSphAbTarget] = useState(props.sphAbTarget)
  const [equipmentList, setEquipmentList] = useState([props.biometerName, props.topographer])

  //++++++++++++++ERROR MESSAGE HANDLING++++++++++++++++++++++++++++++++++++++++++++
  //create a hook so that you can store the messages in state so they show up in JSX tags
  const[errorMessage, setErrorMessage]=useState([])
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const updateBiometer = async(e) => {
    e.preventDefault();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //create blank array to send error messages
    let errorMessageArr = [];

    let equipmentError =
    (equipmentPreference === "Choose an option below")
      ? ''
      : equipmentPreference
    //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false

    let errArr = [
      {name: `${biometerName}`, message: '*Please choose your biometer'},
      {name: `${topographerName}`, message: '*Please choose your topographer'},
      {name: `${equipmentError}`, message: '*Please choose your prefered machine for cylinder and axis measurements'},
      {name: `${sphAbTarget}`, message: '*Please enter your target residual spherical aberration'},
      {name: `${hoaMaxPreference}`, message: '*Please enter your HOA cutoff'},
    ];

    console.log(equipmentPreference)
    console.log(equipmentError)

    //Map through props you are interested in and send error message to errorMessageArr if there is an error

    Object.entries(errArr).map(p => {
        if(p[1].name === ''){
          // console.log(p[1].message)
          return errorMessageArr.push(p[1].message)
        } else {
          return null
        }

      })
      setErrorMessage(errorMessageArr)
      //if there are errors, do not complete "put" or "update" function
      if(errorMessageArr.length > 0){
        return null
      } else {

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      try {
        await api.put(`/equipment` , {
          biometerName,
          topographerName,
          equipmentPreference,
          sphAbTarget,
          hoaMaxPreference,
          equipmentId
        }).then(window.location = `/myaccount` )

        }
          catch (err) {
           console.error(err.message)
      }
    }
  }


// //DELETE single biometer function
// const deleteBiometer = async (e) => {
//   e.preventDefault();
//   try {
//     await api.delete(`/equipment` , {
//       headers: {
//         // Authorization: authorizationToken
//       },
//       data: {
//         equipmentId: equipmentId
//       }
//     })
//     // .then(window.location = `/equipment` )
//     // add the line above so that project list automatically shows new shorter list when project is deleted
//   } catch (err) {
//       console.error(err.message)
//   }
// }

  //Update preferedEquipment hook based on equipment selections
  useEffect(() => {
    //Make a list without blanks
    let list;

    //if saved equipmentPreference is same as the biometer then the list should be default [equipment preference, topographer]
    if (biometerName === equipmentPreference){
      list = [equipmentPreference, topographerName]

      //if saved equipmentPreference is same as the topographer then the list should be default [equipment preference, biometer]
    } else if (topographerName === equipmentPreference){
      list = [equipmentPreference, biometerName]

      //if they haven't saved an equipmentPreference, then show the "Choose an option list"
    } else if(biometerName && topographerName){
      list = ["Choose an option below", biometerName, topographerName]
      setEquipmentPreference("Choose an option below")
    }
    setEquipmentList(list);
  }, [biometerName, topographerName])

  // If you want the error to go away for choosing equipment preference, make a useEffect that setEquipmentList([]) with a dependency of , [equipmentPreference]


  let biometerOptions = [
    "IOL Master 500",
    "IOL Master 700",
    "Lenstar LS 900",
    "Argos",
  ]

  let topographerOptions = [
    "Pentacam",
    "Atlas",
  ]

  let warnStyle ={
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

  return(
      <Fragment>
        <form
          style={{
            background: '#F2F3F4',
            borderRadius: '10px',
            padding: '0px 0 50px 20px',
            margin: '20px auto 20px auto',
            width: '500px',
          }}>
          <div style = {{
              padding: '5px 5px 15px 10px',
            }}>
            <FormSelect
              label="Biometer:"
              value={biometerName}
              setHook={setBiometerName}
              options={biometerOptions}
              />
            <FormSelectComment
              label="Topographer:"
              value={topographerName}
              setHook={setTopographerName}
              options={topographerOptions}
              comment={["*Must review each lens' HOA limit if you change topographer (because the diameter of the measured zone changes)"]}
              />
            <FormSelect
              label="Which machine's numbers do you use for your IOL calculations?"
              value={equipmentPreference}
              setHook={setEquipmentPreference}
              options={equipmentList}
              />
            <div style={{position: 'relative'}}>
              <FormInput
                label="What is the ideal residual post-operative spherical aberration target? (usually between 0 and +0.10)"
                value={sphAbTarget}
                setHook={setSphAbTarget}
                type={'number'}
                />
              <ReferenceArticle
                data={
                  [
                    {
                      author: "George Beiko",
                      year: "2006",
                      title: "Customizing the Correction of Spherical Aberration",
                      publication: "Cataract & Refractive Surgery Today",
                      link: 'https://crstoday.com/articles/2006-nov/crst1106_17-php'
                    },
                    {
                      author: "Claudio Orlich",
                      year: "2018",
                      title: "Understanding spherical aberration",
                      publication: "ASCRS EyeWorld",
                      link: 'https://www.eyeworld.org/2018/understanding-spherical-aberration/'
                    },
                  ]
                }
                />
            </div>
            <FormInputComment
              label="HOA cutoff for spherical aberration-correcting monofocal lenses (µm):"
              value={hoaMaxPreference}
              setHook={setHoaMaxPreference}
              comment={["At some point, the corneal higher order aberration is severe enough that you want to choose a monofocal lens that has a spherical aberration closest to zero to avoid introducing additional aberration"]}
              type={'number'}
              />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              onClick={(e => updateBiometer(e))}
              style={{
                borderRadius: '5px',
                width: '100px',
                margin: '5px',
                padding: '7px',
                background: '#007bff',
                color: '#fff',
                fontSize: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              >
              Save
            </div>
            <div
              onClick={() => window.location = `/myaccount`}
              style={{
                borderRadius: '5px',
                width: '100px',
                margin: '5px',
                padding: '7px',
                background: '#292929',
                color: '#FFF',
                fontSize: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              >
              Cancel
            </div>
          </div>
        </form>
        <div
          style={{
             justifyContent: 'left',
             margin: '0 0 50px 280px',
           }}>
          {errorMessage.map((m, i) => (
              <p
                key={i}
                style={warnStyle}
                >
                {m}
              </p>
            ))
          }
        </div>
      </Fragment>
  )
}
