import { useState, useRef, useEffect} from "react";
import api from "../services/api";

export default function AddConsent (props){

  // const[urlList, setUrlList]=useState([])
  const[consentData, setConsentData] = useState([])

  const getConsent = async() => {

      try {
        const response = await api.get("/consent");

        setConsentData(response.data.rows)

      } catch (err) {
        console.error(err.message)
      }
    }


    useEffect(() => {
        getConsent();
      }, []);



  const pushConsent = (m) => {
    if ((props.consents).includes(m)) {
    } else {
      props.setConsents([...(props.consents), m])
    }
  }


  const popConsent = (m) =>  {
    props.setConsents(p => (
      p.filter((data) =>
        data != m
      )
    )
  )}


  let divStyleFalse = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '3px solid #FFF',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    color: '#BEBEBE',
    width: '200px',
    height: '60px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
    cursor: 'pointer',
    position: 'relative',
  }

  let divStyleTrue = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '3px solid #4CBB17',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    fontWeight: '600',
    color: '#4CBB17',
    width: '200px',
    height: '60px',
    borderRadius: '5px',
    background: '#FFF',
    textAlign:'center',
    cursor: 'pointer',
  }

  let addStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #BEBEBE',
    padding: '0',
    margin: '2px',
    fontSize: '18px',
    color: 'gray',
    width: '220px',
    height: '68px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
    cursor: 'pointer',
  }

  return(
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <p
        style={{
          height: 'auto',
          textAlign: 'left',
          padding: '0 5px 0 0',
          margin: '10px 0 0 10px',
          fontSize: '16px',
          fontWeight: '600',
        }}>
        {props.label}
      </p>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: '5px 5px 15px 10px'
        }}>
          {(consentData)
            ? 
            consentData.map(m =>
              (props.consents).includes(m.consent_id)
              ?
                <div
                  style={{display: 'flex'}}
                  key={m.consent_id}
                  >
                  <div
                    style={divStyleTrue}
                    onClick={() => popConsent(m.consent_id)}
                    >
                    {m.consent_name}
                  </div>
                </div>
              :
                <div
                  style={{display: 'flex'}}
                  key={m.consent_id}
                  >
                  <div
                    style={divStyleFalse}
                    onClick={() => pushConsent(m.consent_id)}
                    >
                    {m.consent_name}
                  </div>
                </div>
            )
            :null
          }
        </div>
    </div>
  )
}
