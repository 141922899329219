// import React, { Fragment } from 'react';

// export default function videoType (props){


//   let pStyle = {
//     fontFamily: 'Montserrat',
//     fontSize: '18px',
//     fontWeight: '500',
//     textAlign: 'left',
//     margin: '5px 5px 5px 34px',
//     width: '300px',

//   }

//   let editStyle = {
//     fontFamily: 'Montserrat',
//     fontSize: '16px',
//     textAlign: 'center',
//     fontWeight: '500',
//     margin: '5px',
//     padding: '2px 0 0 0',
//     width: '65px',
//     color: '#007bff',
//     cursor: 'pointer',
//     // border: '1px solid #007bff',
//   }

//   let subtitleStyle = {
//     fontFamily: 'Montserrat',
//     fontSize: '20px',
//     fontWeight: '400',
//     textAlign: 'left',
//     margin: '20px 5px 5px 30px',
//     textDecoration: 'underline',
//   }

//   return(
//     <Fragment>
//       <p style={subtitleStyle}>{props.videoTitle}</p>
//       {
//         (props.videoData)
//         ?
//         ((props.videoData).length > 0)
//           ?
//             (props.videoData).map(v => (
//               (v.video_type === (props.videoFilter))
//               ?
//               <div
//                 key={v.video_id}
//                 style={{
//                   display: 'flex',
//                 }}
//                 >
//                 <div
//                   style={editStyle}
//                   id={v.video_id}
//                   order={v.video_order}
//                   onClick={() => alert("order clicked")}
//                   >
//                   {v.video_order}
//                 </div>
//                 <p style = {pStyle}>
//                   {`"${v.video_name}"`}
//                 </p>
//                 <div
//                   style={editStyle}
//                   id={v.video_id}
//                   type={v.video_type}
//                   onClick={() => props.setModal(v.video_id)}
//                   >
//                   EDIT
//                 </div>
                
//               </div>
//               : null
//             ))
//           : null
//           : null
//       }
//     </Fragment>
//   )

// }





//========================================================================

import React, { Fragment, useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import api from '../services/api';

const VideoItem = ({ video, index, moveVideo, editVideo }) => {
  const [, ref] = useDrag({
    type: 'VIDEO',
    item: { id: video.video_id, index },
  });

  const [, drop] = useDrop({
    accept: 'VIDEO',
    hover: (item) => {
      // Check if the hover event is coming from the same item
      if (item.id !== video.video_id && item.index !== index) {
        moveVideo(item.index, index);
        item.index = index;
      }
    },
  });

  let pStyle = {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: '500',
      textAlign: 'left',
      margin: '5px 5px 5px 34px',
      width: '300px',

    }

    let editStyle = {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      textAlign: 'center',
      fontWeight: '500',
      margin: '5px',
      padding: '2px 0 0 0',
      width: '65px',
      color: '#007bff',
      cursor: 'pointer',
      // border: '1px solid #007bff',
    }

  return (
    <div 
      ref={(node) => ref(drop(node))}
      style={{ display: 'flex', marginBottom: '10px' }}
    >
      <p style={pStyle}>{`${video.video_name}`}</p>
      <div 
        onClick={() => editVideo(video.video_id)}
        style={editStyle}
      >
        EDIT
      </div>
    </div>
  );
};


const VideoType = (props) => {
  const [videos, setVideos] = useState(props.videoData);
  // console.log('videoData in VideoType', videos);

  const moveVideo = (fromIndex, toIndex) => {
    const updatedVideos = [...videos];
    // console.log(updatedVideos)
    const [movedVideo] = updatedVideos.splice(fromIndex, 1);
    updatedVideos.splice(toIndex, 0, movedVideo);
    setVideos(updatedVideos);


    // need to make video_order the index +1 instead of the video_order already associated with the videoData
    // Extract video_id and video_order from each video
    const updatedOrder = updatedVideos.map((v, i) => ({
      video_id: v.video_id,
      video_name: v.video_name,
      video_url: v.video_url,
      video_type: v.video_type,
      video_order: i + 1,
      video_start: v.video_start,
      video_end: v.video_end,
    }));
    
    // console.log(updatedOrder)

    // Update the video order in the database
    updateVideoOrderInDatabase(updatedOrder);
  };
 
  
  

  const updateVideoOrderInDatabase = async (updatedOrder) => {
    try {
      // Use Promise.all to wait for all asynchronous operations to complete
      await Promise.all(
        updatedOrder.map(async (v) => {
          if(v.video_type === props.videoFilter){
            let videoId = v.video_id;
            let videoName = v.video_name;
            let videoUrl = v.video_url;
            let videoType = v.video_type;
            let videoOrder = v.video_order;
            let videoStart = v.video_start;
            let videoEnd = v.video_end;
    
            // Assuming your API supports updating by ID, use the appropriate endpoint
            await api.put(`/video/${videoId}`, {
              videoName,
              videoUrl,
              videoType,
              videoOrder,
              videoStart,
              videoEnd,
            });
    
            console.log(`Video order for ${videoId} updated successfully`);
          }
        })
      );
  
      console.log('All video orders updated successfully');
    } catch (error) {
      console.error('Error updating video orders:', error);
    }
  };
  

  const editVideo = (videoId) => {
    props.setModal(videoId);
  };

  useEffect(() => {
    // Update state when props.videoData changes
    setVideos(props.videoData || []);
  }, [props.videoData]);

  let subtitleStyle = {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '400',
    textAlign: 'left',
    margin: '20px 5px 5px 30px',
    textDecoration: 'underline',
  }

  return (
    <Fragment>
      <p style={subtitleStyle}>{props.videoTitle}</p>
      {videos &&
        videos.length > 0 &&
        videos.map((video, index) =>
          video.video_type === props.videoFilter ? (
            <VideoItem
              key={video.video_id}
              video={video}
              index={index}
              moveVideo={moveVideo}
              editVideo={editVideo}
            />
          ) : null
        )}
    </Fragment>
  );
};

const SortableVideoType = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <VideoType {...props} />
    </DndProvider>
  );
};

export default SortableVideoType;

