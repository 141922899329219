import React, {useState, Fragment} from 'react'

import { IoBanOutline } from 'react-icons/io5';
import { IoTrashOutline } from 'react-icons/io5';
import { IoAdd } from 'react-icons/io5';

export default function AddItem(props){

  const [addItem, setAddItem] = useState(false)
  const [newItem, setNewItem] = useState('')

  //create function to change value of contraindition from true to false and vice versa
  let handleClick = (c) => {
    // console.log(c[1].name + ": " + c[1].value)
    let currentName = c[1].name
    // console.log("name: " + currentName)
    let currentVal = c[1].value
    // console.log("value: " + currentVal)
    //use Object.entries to allow mapping of props.hook
    //have to access index of 1 because Object.entries puts index as first item in array
    const newArr = Object.entries(props.hook).map(m =>
      //if the object's name matches the clicked object's name
      (m[1].name === currentName)
        //then toggle true and false
        ? (currentVal === 'true')
          ? {...m[1], value: 'false'}
          : {...m[1], value: 'true'}
        //otherwise, if not clicked, just return the same object pair name and value
        : {...m[1]}
    )
      // console.log(newArr)
      //setHook refers to setItems prop from Lens.js
      props.setHook(newArr)
  }

  let handleSaveAdd = (e) => {
    let newArr = Object.entries(props.hook).map(m => (
      {...m[1]}
    ));
    // console.log(newArr)
    newArr.push({name: `${newItem}`, value: 'true'})
    props.setHook(newArr)
    setNewItem('')
    setAddItem(false)
  }

  let handleCancelAdd = (e) => {
    setNewItem('')
    setAddItem(false)
  };


  let icd10Glaucoma = (props.side === 'RIGHT')
      ? 'H40.111'
      : (props.side === 'LEFT')
        ? 'H40.112'
        : null

  let icd10Cornea = (props.side === 'RIGHT')
      ? 'H40.113'

      : (props.side === 'LEFT')
        ? 'H40.123'
        : null

  let divStyleFalse = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px solid #F2F3F4',
    padding: '0',
    margin: '2px',
    fontSize: '18px',
    color: 'lightgray',
    width: '450px',
    height: '60px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
  }

  let divStyleTrue = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px solid #4CBB17',
    padding: '0',
    margin: '2px',
    fontSize: '18px',
    color: '#4CBB17',
    fontWeight: "600",
    width: '450px',
    height: '60px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
  }



  return(
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <div
            style={{
              padding: '0'
            }}>
            {
                Object.entries(props.hook).map((c) => (
                  <div
                    key={c[1].name}
                    style={{
                      position: 'relative'
                    }}
                    >
                    <div
                      style={
                        (c[1].value === 'true')
                          ? divStyleTrue
                          : divStyleFalse
                        }
                      onClick={(e) => handleClick(c)}
                      >
                      <span>{c[1].name}</span>
                    </div>
                  </div>
                ))
              }

          </div>
        </div>
      )
    }
