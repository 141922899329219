import React, { useState, useEffect } from "react";

import api from "../services/api";
import { login } from "../services/auth";
import Navbar from "../partials/Navbar";
import About from '../About'

export default function Login({ history }) {
  const initialState = {
    username: "",
    password: ""
  };

  const [user, setUser] = useState(initialState);
  const [errors, setErrors] = useState([]);
  const [transform, setTransform] = useState('')

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
    }, []);


  const handleScroll = (e) => {
    let r = (window.scrollY);
    let u = r/6
    setTransform(u)
  }

  const handleInputs = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = async e => {
    e.preventDefault();
    const { username, password } = user;

    if (!username.trim() || !password.trim()) {
      console.log(`Please complete all fields`);
      return;
    }

    try {
      const res = await api.post("/auth", { username, password });
      if (res.status === 200) {
        const { token } = res.data;
        login(token);
        window.location = `/patientinfo`
      }
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  let inputStyle = {
    width: '300px',
    height: '30px',
    fontSize: '16px',
    border: '1px solid lightgray',
    borderRadius: '5px',
    margin: '5px 0 0 0',
    padding: '5px 5px 5px 15px',
  }

  let warnStyle ={
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

  return (
    <div>
      <Navbar/>
        <div
          style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>

          <h1
            style={{
              fontSize: '80px',
              fontWeight: '400',
              color: '#595959',
              margin: '40px 0 0 0',
              width: '100%',
              textAlign: 'center',
            }}
            >
            Cataract Preop
          </h1>
          <h3
            style={{
              fontSize: '30px',
              fontWeight: '400',
              color: '#595959',
              margin: '0 0 20px 0',
              width: '100%',
              textAlign: 'center',
            }}>

          </h3>
          <div
            onScroll={handleScroll}
            style={{
              margin: '0 0 50px 0',
              textAlign: 'center',
            }}
            >
            <img
              style={{
                transform: `rotate(${transform}deg)`,
                width: '300px',
                opacity: '0.6'
              }}
              src="logoImage.png"
              alt="ZCB00"
              >
            </img>
          </div>
        </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0 0 50px 0'
        }}>
        <form
          onSubmit={handleSave}
          style={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
          }}
          >
          <input
            type="text"
            name="username"
            autoFocus
            value={user.username}
            onChange={handleInputs}
            placeholder="Username"
            style={inputStyle}
          />
          <input
            type="password"
            name="password"
            value={user.password}
            onChange={handleInputs}
            placeholder="Password"
            style={inputStyle}
            onKeyDown={ (e) => (
                e.keyCode === 13
                  ? handleSave(e)
                  : null
                )}
          />
          <div
            style={{
              borderRadius: '5px',
              width: '100px',
              margin: '5px auto',
              padding: '5px',
              border: '1px solid #007bff',
              color: '#007bff',
              fontSize: '16px',
              fontWeight: '500',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={(e)=>handleSave(e)}
            >
            Login
          </div>
          <p style={warnStyle}>{errors}</p>
        </form>
      </div>
      <About/>
    </div>
  );
}
