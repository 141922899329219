import React, { useState, useEffect } from 'react';
import api from "../services/api";

import FormSelectMargin from '../partials/FormSelectMargin'
import FormInputMargin from '../partials/FormInputMargin'

export default function VideoNew (props) {

  const [videoName, setVideoName] = useState(props.videoName)
  const [videoUrl, setVideoUrl] = useState(props.videoUrl)
  const [videoType, setVideoType] = useState(props.videoType)
  const [videoOrder, setVideoOrder] = useState(props.videoOrder)
  const [videoStart, setVideoStart] = useState(props.videoStart)
  const [videoEnd, setVideoEnd] = useState(props.videoEnd)


   // Post to video database
   const postVideo = async(e) => {
     e.preventDefault();
     // alert('post route')
       try {
         await api.post(`/video` , {
            videoName,
            videoUrl,
            videoType,
            videoOrder,
            videoStart,
            videoEnd
         }).then(window.location = `/education`)

     } catch (err) {
       console.error(err.message)
     }
   }


   useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    setVideoName(props.videoName);
    setVideoUrl(props.videoUrl);
    setVideoType(props.videoType);
    setVideoOrder(props.videoOrder);
    setVideoStart(props.videoStart);
    setVideoEnd(props.videoEnd);
  }, [props.videoName, props.videoUrl, props.videoType, props.videoOrder, props.videoStart, props.videoEnd]);



  return (
    <div style={{
      display: 'flex',
      }}>
      <div>
    

          <div style={{
              display: 'flex',
              justifyContent: 'center',
            }}> 
          <div
            style={{
              width: '540px',
              background: '#EFEFEF',
              borderRadius: '10px',
              margin: "0px 0 0 0",
            }}
            >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: '10px 0 150px 0px',
              }}>

              <FormInputMargin
                label="Video link (URL):"
                value={videoUrl}
                setHook={setVideoUrl}
                />
              <div style={{display: 'flex', justifyContent:'center', margin: '0 0 20px 0'}}>
                <iframe                              
                    src={
                        videoUrl
                        ? `${videoUrl}&amp;start=${videoStart}&amp;end=${videoEnd}`
                        : ''
                      } 
                    title={videoName}
                    style={{
                        width: "520px" ,
                        height: "292px",
                        border: 'none',
                        borderRadius: '10px',
                    }} 
                    allowFullScreen
                >
                </iframe>
              </div>
              
              <FormSelectMargin
                label={'Video type:'}
                value={videoType}
                setHook={setVideoType}
                options={
                  (props.modal === 'new')
                    ? ['Choose video type...', 'Preop', 'Postop']
                    : ['Preop', 'Postop']
                  }
                />
              <FormInputMargin
                label="Video start at __ seconds:"
                value={videoStart}
                setHook={setVideoStart}
                />
              <FormInputMargin
                label="Video end at __ seconds:"
                value={videoEnd}
                setHook={setVideoEnd}
                />
              <div
                style={{display: 'flex', justifyContent: 'center',}}>
                <div
                  onClick={(e => postVideo(e))}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#007bff',
                    color: '#fff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Save
                </div>
                <div
                  onClick={() => props.setModal('')}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}