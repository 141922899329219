import React, { Fragment } from 'react';

export default function consentType (props){

  let pStyle = {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '5px 5px 5px 34px',
    width: '300px',

  }

  let editStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: '500',
    margin: '5px',
    padding: '2px 0 0 0',
    width: '65px',
    color: '#007bff',
    cursor: 'pointer'
  }

  let subtitleStyle = {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '400',
    textAlign: 'left',
    margin: '40px 5px 5px 30px',
    textDecoration: 'underline',
  }

  return(
    <Fragment>
      <p style={subtitleStyle}>{props.consentTitle}</p>
      {
        (props.consentData)
        ?
        ((props.consentData).length > 0)
          ?
            (props.consentData).map(consent => (
              (consent.consent_type === (props.consentFilter))
              ?
              <div
                key={consent.consent_id}
                style={{
                  display: 'flex',
                }}
                >
                <p style = {pStyle}>
                  {consent.consent_name}
                </p>
                <div
                  style={editStyle}
                  id={consent.consent_id}
                  type={consent.type}
                  onClick={(e) => props.onConsentClick(e)}
                  >
                  EDIT
                </div>
              </div>
              : null
            ))
          : null
          : null
      }
    </Fragment>
  )

}
