import React, { useState } from 'react';

import './ihcConsent.css'

function IhcConsent (props) {


  let c = (props.cataractAddOn)
    ? (props.cataractAddOn).filter(c => (
        (c.value === 'true' && c.name !== 'Cataract')
      ))
    : null


    return(
        <div className="ihcConsent">
          <div style={{
            maxWidth: '800px',
            width: '86vw',
          }}>
          
            <p style={{margin: '10px 30px 0 60px'}}>{props.side} eye</p>
            {props.cataractAddOn.some(c => c.value === 'true' && c.name === 'Cataract surgery')
              ? <p style={{margin: '10px 30px 0 60px'}}>Lens: {props.lensModel}</p>
              : null
            }
          </div>
          <div>
            <h3 style={{
                textAlign: 'center',
                margin: '0 0 30px 0',

              }}>
              INFORMED CONSENT
            </h3>
          </div>

          <div style={{
              display: 'flex'
            }}>
            <div style={{width: '7vw'}}></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '800px',
                width: '86vw',
              }}>

              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <p style={{margin: '0'}}>
                  Patient's Name: {props.fName} {props.lName}
                  <span style={{margin: '0 0 0 10px'}}>{props.dob}</span>
                </p>
                {props.physicianName === ''
                  ?
                  <p style={{margin: '0'}}>
                    Responsible Practicioner:
                  </p>
                  :
                  <p style={{margin: '0'}}>
                    Responsible Practicioner: {props.physicianName}
                  </p>
                }
                <p>
                  Procedure: 

                  {
                    props.cataractAddOn.map(c => 
                      (c.value === 'true')
                      ? `${c.name}, ` 
                      : null 
                    )
                  }

                  {props.flacs === true
                    ? 'femtosecond laser, '
                    : null
                  }
                  {props.cri === true
                    ? 'corneal relaxing incisions, '
                    : null
                  }

                  {props.side} eye 

                </p>

                <p style={{margin: '10px 0 0 0'}}>The risks, benefits, and anticipated outcomes of the procedure/treatment/test (the “Patient’s Procedure” or “my procedure”), the alternatives to the Patient's Procedure and the risks and benefits of those alternatives, and the roles and tasks of the personnel to be involved in the Patient's Procedure, were discussed with the patient or the patient’s representative.</p>
                <div  style={{
                    display: 'flex',
                    margin: '20px 0 0 0',
                  }}>
                  <div>
                    <p style={{margin: '0 20px 0 0', }}>_____________________________</p>
                    <p style={{margin: '0 20px 0 0', }}><strong>Practitioner Signature</strong></p>
                  </div>
                  <div>
                    {props.physicianName === ''
                      ?
                        <div>
                          <p style={{margin: '0 20px 0 0', }}>_____________________________</p>
                          <p style={{margin: '0 20px 0 0', }}><strong>Practicioner Printed Name</strong></p>
                        </div>
                      :
                        <div>
                          <p style={{margin: '-2px 20px -15px 0', }}>{props.physicianName}</p>
                          <p style={{margin: '0 20px 0 0', }}>_____________________________</p>
                          <p style={{margin: '0 20px 0 0', }}><strong>Practicioner Printed Name</strong></p>
                        </div>
                    }
                  </div>
                  <div>
                    <p style={{margin: '0 20px 0 0', }}>_________________</p>
                    <p style={{margin: '0 20px 0 0', }}><strong>Date Signed</strong></p>
                  </div>
                  <div>
                    <p style={{margin: '0 20px 0 0', }}>__________</p>
                    <p style={{margin: '0 20px 0 0', }}><strong>Time</strong></p>
                  </div>
                </div>


                <ol>
                  <li style={{margin: '10px 0 0 10px'}}>
                    <strong>I, the patient or personal representative, have talked with my doctor or healthcare team about:</strong>
                    <ol style={{
                        listStyleType: 'lower-alpha',
                        margin: '0 0 0 20px'
                      }}>
                      <li>
                        My medical condition, my procedure, why I am having it done, and what will happen during and after my procedure, including the possible need for blood or blood products and the possible risks.
                      </li>
                      <li>
                        How my procedure may help me (the benefits).
                      </li>
                      <li>
                        How my procedure might harm me (the most common and any serious risks, complications, and side effects).
                      </li>
                      <li>
                        The equipment my practitioner deems appropriate for my procedure with the goal of the best possible outcome, to the extent that information is significant to my decision making.
                      </li>
                      <li>
                        My other options for treatment and the risks and benefits of those options.
                      </li>
                      <li>
                        What will likely happen if I don’t have this procedure/treatment/test.
                      </li>
                      <li>The need for blood product andministration for my medical condition</li>
                    </ol>
                  </li>
                  <li style={{margin: '0 0 0 10px'}}>
                    <strong>I, the patient or personal representative, understand that:</strong>
                    <ol style={{
                        listStyleType: 'lower-alpha',
                        margin: '0 0 0 20px',
                      }}>
                      <li>
                        I can change my mind. If I do, I must tell my practitioner or team as soon as possible and before the procedure begins.
                      </li>
                      <li>
                        My practitioner may change my procedure, if doing so is best for me. For example, if my practitioner finds a serious problem or if complications arise during the procedure.
                      </li>
                      <li>
                        Healthcare provider training may occur during my procedure and that any of my care performed by a trainee will be performed under the supervision of my doctor and healthcare team.
                      </li>
                      <li>
                        No promise is made to me concerning a final result, outcome, or cure.
                      </li>
                      <li>
                        I am cared for by a team led by my attending practitioner. I understand that my attending practitioner may leave the procedure room and/or be involved in another procedure at certain non-critical portions of my procedure while members of the team continue to perform non-critical portions of my procedure under my practitioner’s direction.
                      </li>
                      <li>
                        Tissues and devices that are removed from my body may be examined, stored and/or disposed of.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p style={{margin: '10px 0 0 0',}}>
                  <strong>My questions have been answered to my satisfaction. I accept the risks and agree to the procedure/treatment/test.</strong>
                </p>
                <p style={{margin: '10px 0 0 0',}}>
                  <strong>PATIENT OR REPRESENTATIVE SIGNATURE ________________________________ DATE _________ TIME _____</strong>
                </p>
                <p style={{margin: '10px 0 0 0',}}>
                  <strong>Relationship of Representative to the Patient (if applicable) ____________________________________________</strong>
                </p>
              </div>
              <div style={{
                  display:'flex',
                  margin: '10px 0 0 0',
                }}>
                <div style={{
                    height: "90px",
                    width: "40px",
                    border: '1px solid black',
                    transform: 'rotate(180deg)',
                  }}>
                  <p style={{
                      writingMode: 'vertical-rl',
                      textAlign: 'center',
                      padding: '0',
                      margin: '0',
                      fontWeight: '600',
                      width: '40px',
                      height: '90px',
                    }}>Telephone or Verbal Consent</p>
                </div>
                <div style={{
                    border: '1px solid black',
                    borderLeft: 'none',
                    width: '660px',
                    height: '90px',
                  }}>
                  <div style={{
                      display: 'flex',
                      margin: '15px 0 0 0'
                    }}>
                    <div style={{margin: '0 10px 0 10px'}}>
                      <p style={{}}>_________________________________________________</p>
                      <p style={{margin: '0'}}>Name of Person Giving Consent</p>
                    </div>
                    <div style={{margin: '0 10px 0 10px'}}>
                      <p>_________________</p>
                      <p>Relationship</p>
                    </div>
                    <div style={{margin: '0 10px 0 10px'}}>
                      <p>_______________</p>
                      <p>Phone Number</p>
                    </div>
                  </div>
                  <p style={{margin: '20px 0 0 10px'}}>
                    Witness to Telephone/Verbal Consent_______________________________ Date ____________ Time _________
                  </p>
                </div>
              </div>
              <p style={{margin: '5px 0 0 0', fontSize: '11px'}}>
                Informed Consent for the following hospitals: Alta View, American Fork, Bear River, Cedar City, Intermountain Medical Center, LDS Hospital, Layton, Logan Regional, McKay-Dee, Orem, Park City, Primary Children’s, Riverton, Sevier, Spanish Fork, St. George Regional, The Orthopedic Specialty, Utah Valley, clinics, provider offices and Intermountain Homecare.
              </p>
              <p style={{margin: '5px 0 0 0', fontSize: '11px'}}>
                Intermountain Healthcare complies with applicable Federal civil rights laws and does not discriminate on the basis of race, color, national origin, age, disability, or sex.
              </p>
              <p style={{margin: '5px 0 0 0', fontSize: '11px'}}>
                我們將根據您的需求提供免費的口譯服務。請找尋工作人員協助
              </p>
              <p style={{margin: '5px 0 0 0', fontSize: '11px'}}>
                Se proveen servicios de interpretación gratis. Hable con un empleado para solicitarlo.
              </p>
              <div style={{display: 'flex', margin:'10px 0 0 0'}}>
                <div>
                  <p><strong>Interpreter’s name and agency (if applicable)</strong></p>
                </div>
                <div style={{margin: '0 0 0 10px'}}>
                  <p>____________________________________</p>
                  <p>Interpreter Printed Name</p>
                </div>
                <div style={{margin: '0 0 0 10px'}}>
                  <p>__________________</p>
                  <p>Interpreter Agency</p>
                </div>
              </div>
              
              <p>
              </p>
              <p>
              </p>
            </div>
            <div style={{width: '7vw'}}></div>
          </div>
        </div>

    )};

export default IhcConsent



              // <p>Responsible Practitioner: {props.physicianName}</p>



            //   {(g.length > 0 || (c.length > 0 && c.name !== 'Cataract') || props.cri === true || props.flacs === true)
            //   ? ' with'
            //   : null
            // }