import React, { useState, useEffect } from 'react';
import api from "../services/api";

import Header from '../partials/Header';
import FormSelectMargin from '../partials/FormSelectMargin'
import FormInputMargin from '../partials/FormInputMargin'
import Navbar from '../partials/Navbar'

export default function VideoEdit (props) {

  const[userName, setUserName]=useState('')

  const [videoId, setVideoId] = useState(props.videoId)
  const [videoName, setVideoName] = useState(props.videoName)
  const [videoUrl, setVideoUrl] = useState(props.videoUrl)
  const [videoType, setVideoType] = useState(props.videoType)
  const [videoOrder, setVideoOrder] = useState(props.videoOrder)
  const [videoStart, setVideoStart] = useState(props.videoStart)
  const [videoEnd, setVideoEnd] = useState(props.videoEnd)

  const [videoData, setVideoData] = useState({})


   // Update video database
   const updateVideo = async(e) => {
     e.preventDefault();
    //  alert('update route')
       try {
         await api.put(`/video/${videoId}` , {
           videoName,
           videoUrl,
           videoType,
           videoOrder,
           videoStart,
           videoEnd
         }).then(window.location = `/education`)
         }
       catch (err) {
        console.error(err.message)
     }
   }

   // Delete video from database
   const deleteVideo = async(e) => {
     e.preventDefault();
       try {
         await api.delete(`/video` , {
           headers: {
             // Authorization: authorizationToken
           },
           data: {
             videoId: videoId
           }
         }).then(window.location = `/education`)
       }
       catch (err) {
         console.error(err.message)
         
       }
   }

   useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <div style={{
      display: 'flex',
      }}>
      <div
          style={{
            width: '86vw',
          }}>
          <Navbar
            setUserName={setUserName}/>
          <Header
            title={"Patient Education"}
            subtitle={"Add or edit your education videos"}
            />

          <div style={{
              display: 'flex',
              justifyContent: 'center',
            }}> 
          <div
            style={{
              width: '540px',
              background: '#EFEFEF',
              borderRadius: '10px',
              margin: "60px 0 0 0",
            }}
            >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: '10px 0 150px 0px',
              }}>
              <FormInputMargin
                label="Video name:"
                value={videoName}
                setHook={setVideoName}
                />
              <FormInputMargin
                label="Video link (URL):"
                value={videoUrl}
                setHook={setVideoUrl}
                />
              <div style={{display: 'flex', justifyContent:'center', margin: '0 0 20px 0'}}>
                <iframe                              
                    src={
                        videoUrl
                        ? `${videoUrl}&amp;start=${videoStart}&amp;end=${videoEnd}`
                        : ''
                      } 
                    title={videoName}
                    style={{
                        width: "520px" ,
                        height: "292px",
                        border: 'none',
                        borderRadius: '10px',
                    }} 
                    allowFullScreen
                >
                </iframe>
              </div>
              
              <FormSelectMargin
                label={'Video type:'}
                value={videoType}
                setHook={setVideoType}
                options={
                  (props.modal === 'new')
                    ? ['Choose video type...', 'Preop', 'Postop']
                    : ['Preop', 'Postop']
                  }
                />
              <FormInputMargin
                label="Video start at __ seconds:"
                value={videoStart}
                setHook={setVideoStart}
                />
              <FormInputMargin
                label="Video end at __ seconds:"
                value={videoEnd}
                setHook={setVideoEnd}
                />
              <div
                style={{display: 'flex', justifyContent: 'center',}}>
                <div
                  onClick={(e => updateVideo(e))}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#007bff',
                    color: '#fff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Save
                </div>
                <div
                  onClick={() => props.setModal('')}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Cancel
                </div>
                <div
                  onClick={(e => deleteVideo(e))}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#F25835',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                    Delete
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}