import React, { useEffect } from 'react';


import glasses from '../visionsimulator/images/glasses.jpeg';
import glareIcon from './images/glareIcon.jpeg'
import BackArrow from "../partials/BackArrow";

export default function LensGuidePartial(props) {
    // console.log(props.basicLensListOptions[0].lensName)



    const handleNewerLens = (e) => {
      const lens = props.basicLensListOptions.find(l => l.lensModel.includes(e));
      if (lens) {
        props.setLensOption(lens.lensName);
      }
    };
  
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const l = props.basicLensListOptions.find(option => option.lensName === props.basicLensOption);


  let labelStyle = {
    fontSize: '26px',
    color: '#292929',
    margin: "10px 0 0 0"
  }
  
  let proStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
  }
  
  let conStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
    color: 'red',
  }

  let imgStyle = {
    width: '200px',
    borderRadius: '20px',
  }

  let buttonStyleTrue = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#FFF',
    borderRadius: '10px',
    background: "#007bff",
    margin: '20px 10px 60px 10px',
    padding: '20px 40px',
    cursor: 'pointer',
    width: '340px',
    textAlign: 'center',
    textDecoration: 'none',
    // position: 'fixed',
    // zIndex: '3',
  }
  
let buttonStyleFalse = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#007bff',
    background: '#FFF',
    borderRadius: '5px',
    margin: '20px 10px 20px 10px',
    padding: '10px 0',
    cursor: 'pointer',
}

let columnStyle = {
  width: "48%",
  display:'flex',
  justifyContent: 'center',
}

let rowStyle = {
  display: 'flex', 
  justifyContent: 'space-evenly',
  // borderBottom: "1px solid #292929",
  padding: "8px 0 60px 0",
}
  
let pStyle={
  fontSize: '20px',
  margin: '5px 0 0 0',
  textAlign: 'center',
}


  return (
    <div style={{display:'flex', background: "#FFF", margin: "40px 0 0 0"}}>
      <div style={{width: '7vw'}}></div>
      <div style={{width: '84vw'}}>
      {
          props.basicLensOption === 'Cataract vision'
          || 
          props.basicLensOption === ''
          ? 
            <div>
              
            </div>
          : 
        <div style={{margin: "40px 0 0 0"}}>
    
                  
                  



        <div style={rowStyle}>
          <div style={columnStyle}>
            <div
                style={{
                  width: '50%',
                  textAlign: 'center',
                }}
              >
                <p 
                  style={{
                    fontSize: '50px', 
                    fontWeight: '600px',
                    margin: "0",
                    }}>
                      ${
                        l && l.prosCons[0].cost*1
                      }
                  </p>
                <p style={pStyle}> 
                  Estimated extra out-of-pocket per eye
                </p>  
            </div>        
          </div>
        </div>
        

        <div style={rowStyle}>
          <div style={columnStyle}>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
              }}
            >
              <p 
                style={{
                  fontSize: '50px', 
                  fontWeight: '600px',
                  margin: '0',
                }}>
                  {l && l.prosCons[0].glasses}
              </p>   
              <img
                src={glasses}
                style={l && l.prosCons[0].glasses === 'Some'
                  ? 
                    {
                      width: '60%',
                      borderRadius: '8px',
                      opacity: "0.5",
                    }
                  : l && l.prosCons[0].glasses === 'Rare'
                  ? 
                    {
                      width: '60%',
                      borderRadius: '8px',
                      opacity: "0.1",
                    }
                  : 
                    {
                      width: '60%',
                      borderRadius: '8px',
                      opacity: "1",
                    }}
                alt='Need for glasses'
              />
              <p style={pStyle}>
                  need for glasses after surgery
              </p>
            </div>
          </div>
        </div>


        <div style={rowStyle}>
          <div style={columnStyle}>
            <div
                style={{
                  width: '50%',
                  textAlign: 'center',
                }}
              >
              <p 
                style={{
                  fontSize: '50px', 
                  fontWeight: '600px',
                  margin: '0',
                }}>
                  {l && l.prosCons[0].halos}
              </p> 
              <img
                src={glareIcon}
                style={l && l.prosCons[0].halos === 'Mild'
                  ? 
                    {
                      width: '40%',
                      borderRadius: '8px',
                      opacity: "0.4",
                    }
                  : l && l.prosCons[0].halos === 'Minimal'
                  ? 
                    {
                      width: '40%',
                      borderRadius: '8px',
                      opacity: "0.1",
                    }
                  : 
                    {
                      width: '40%',
                      borderRadius: '8px',
                      opacity: "0.8",
                    }}
                alt='Glare and halos'
              /> 
              <p style={pStyle}>
                glare and halos around lights after surgery
              </p> 
            </div>            
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            {
              l && l.prosCons[0].other
              ? 
                <div
                  style={{
                    width: '60%',
                    textAlign: 'center',
                  }}
                >
                <p 
                  style={{
                    fontSize: '50px', 
                    fontWeight: '600px',
                    margin: '0',
                  }}
                >
                  Of Note:
                </p> 
                  <p style={pStyle}>
                    {l && l.prosCons[0].other}
                  </p>  
                </div>
              : null
            }
          </div>
      </div>
  
        </div>

        }
        
      </div>
  
 
      <div style={{width: '7vw'}}></div>
      
    </div>
  )
}