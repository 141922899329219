import InlineStyleControls from '../../consents/partials/InlineStyleControls'
import BlockStyleControls from '../../consents//partials/BlockStyleControls'
import TokenToolbar from './TokenToolbar';

export default function BedsheetToolbar(props) {


  return (
    <div>
        <div style={{display: 'flex'}}>
            <InlineStyleControls
                editorState={props.editorState}
                onToggle={props.toggleInlineStyle}
            />
            <BlockStyleControls
                editorState={props.editorState}
                onToggle={props.toggleBlockType}
            />
        </div>
            <TokenToolbar 
                onTemplateInsert={props.onTemplateInsert} 
            />
    </div>
  )
}
