import React, { useState } from 'react';

import api from "../services/api";

import Lens from './Lens'
import LensSearch from './LensSearch'
import Header from '../partials/Header'
import Navbar from '../partials/Navbar'


export default function LensList (props){

const[userName, setUserName]=useState('')
const[modal, setModal] = useState('')
const[addLens, setAddLens] = useState(false)


  const handleLensClick = (e) => {
    let val = e
    // console.log(val)
    setModal(val)
  }

  const handleEditCancel = (e) => {
    let val = e
    setModal(val)
    // console.log(val)
  }

  const handleAddLens = (e) => {
    let val = e
    setAddLens(val)
    // console.log(val)
  }

  return (
    <div>
      <Navbar
        setUserName={setUserName}/>
      <div
        style={{
          width: '7vw',
        }}>
      </div>
      <Header
        title={"Intraocular lenses"}
        subtitle={"Add or edit your intraocular lenses"}
        />
      <div style={{
          margin: '50px 0 0 0',
        }}>
      {(props.lensData).length > 0
        ?
        (props.lensData).map(lens => (
          <Lens
            key={lens.lens_id}
            userName={userName}
            lensId={lens.lens_id}
            lensName={lens.lens_name}
            lensModel={lens.model_number}
            lensLocation={lens.lens_location}
            lensKAstigMin={lens.lens_astig_min}
            lensKAstigMax={lens.lens_astig_max}
            lensKAstigResidual={lens.lens_astig_residual}
            lensIntermediateVision={lens.corrects_intermediate_vision}
            lensNearVision={lens.corrects_near_vision}
            lensGlare={lens.lens_glare}
            lensPhysicianFee={lens.physician_lens_fee}
            lensHospitalFee={lens.hospital_lens_fee}
            lensSphAb={lens.lens_sph_ab}
            lensKSphAbResidual={lens.k_sph_ab_residual}
            lensHoaMax={lens.lens_hoa_max}
            lensBioTopoAstigMax={lens.biometer_topographer_astig_max}
            lensBioTopoAxisMax={lens.biometer_topographer_axis_max}
            lensChordMuMax={lens.chord_mu_max}
            lensBiometerStdDevMax={lens.biometer_std_dev_max}
            lensCriMinAstig={lens.cri_min_astig}
            lensCriMaxAstig={lens.cri_max_astig}
            lensCriRequire={lens.cri_require}
            lensFlacsRequire={lens.flacs_require}
            lensCriFee={lens.cri_fee}
            lensFlacsFee={lens.flacs_fee}
            contraindications={lens.contraindications}
            consents={lens.consents}
            topographer={props.topographer}
            onLensClick={handleLensClick}
            onCancel={handleEditCancel}
            modal={modal}
            addLens={addLens}
            onAddLens={handleAddLens}
            criOffer={props.criOffer}
            flacsOffer={props.flacsOffer}
            />
      ))
      : null
    }
    <LensSearch
        addLens={addLens}
        onAddLens={handleAddLens}
        userName={userName}
        topographer={props.topographer}
        criOffer={props.criOffer}
        flacsOffer={props.flacsOffer}
        contraindicationsList={props.contraindicationsList}
        staticLensListOptions={props.staticLensListOptions}
      />
    </div>
    <div
      style={{
        width: '7vw',
      }}>
    </div>
  </div>
  )
}
