import React, { useState, useEffect } from 'react';
import BackArrow from '../partials/BackArrow';
import ForwardArrow from '../partials/ForwardArrow';

export default function TutorialVideo (props) {

    const[f, setF]=useState('')

    let forwardLink = () => {
        if(
            props.userType === 'qrpt'
            &&
            props.useTutorial === 'false'
            && 
            props.useVideo === 'true'
        ){
            return setF('/tutorial4')
        } else if(
            (
                props.userType === 'md' 
                || 
                props.userType === 'qrmd'
            )
            &&
            props.useTutorial === 'false'
            && 
            props.useVideo === 'true'
        ){
            return setF('/visionsimulator')
        } else if(
            (
                props.userType === 'md' 
                || 
                props.userType === 'qrmd'
            )
            &&
            props.useTutorial === 'true'
            && 
            props.useVideo === 'true'
        ){
            return setF('/tutorial1')
        } else {
            return setF('/tutorial1')
        }
    }

    // null doesn't work to erase back arrow, but problem is that funciton still isn't working


    useEffect(() => {
        forwardLink()
      }, [props.userType, props.useVideo, props.useTutorial])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    


    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'center',
        margin: '80px 0 20px 0',
    }

    let subtitleStyle = {
        fontSize: '30px',
        color: '#FFF',
        textAlign: 'center',
    }


    return(
        <div style={backgroundStyle}>
            {
                props.userType === 'md'  || props.userType === 'qrmd'
                ? <BackArrow
                    linkTo='/choice1'
                    />
                : null
            }
            {
                <ForwardArrow
                    linkTo={`${f}`}
                    />
            }
            
            
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                <>
                <p style={titleStyle}>Preoperative Videos</p>
                <div
                    style={{
                        margin: "20px 0 0 0",
                    }}
                >
                    {
                        props.videoData.length > 0
                        ?  
                        (props.videoData).map(m => (
                            m.video_type === 'Preop'
                            ? 
                            <div
                                key={m.video_id}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '0 0 50px 0',
                                }}
                            >
                                <iframe                              
                                    src={
                                        m.video_url
                                        ? `${m.video_url}&amp;start=${m.video_start}&amp;end=${m.video_end}`
                                        : ''
                                    } 
                                    title={m.video_name}
                                    style={{
                                        width: "520px" ,
                                        height: "292px",
                                        border: 'none',
                                        borderRadius: '10px',
                                    }} 
                                    allowFullScreen
                                >
                                </iframe>
                            </div>
                            : null

                        ))
                        : 
                        <div
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: "520px" ,
                                height: "292px",
                                border: '1px solid #FFF',
                                borderRadius: '10px',
                            }}>
                                <p style={subtitleStyle}>
                                    No Videos Uploaded Yet
                                </p>
                            </div>
                        </div>
                    }
                </div> 
                </>
                <>
                <p style={titleStyle}>Postoperative Videos</p>
                <div
                    style={{
                        margin: "20px 0 0 0",
                    }}
                >
                    {
                        props.videoData.length > 0
                        ? 
                        (props.videoData).map(m => (
                            m.video_type === 'Postop'
                            ? 
                            <div
                                key={m.video_id}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '0 0 50px 0',
                                }}
                            >
                                <iframe 
                                    
                                    src={`${m.video_url}&amp;start=${m.video_start}`} 
                                    title={m.video_name}
                                    style={{
                                        width: "560px" ,
                                        height: "315px",
                                        border: 'none',
                                        borderRadius: '10px',
                                    }} 
                                    allowFullScreen
                                >
                                </iframe>
                            </div>
                            : null

                        ))
                        : 
                        <div
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: "520px" ,
                                height: "292px",
                                border: '1px solid #FFF',
                                borderRadius: '10px',
                            }}>
                                <p style={subtitleStyle}>
                                    No Videos Uploaded Yet
                                </p>
                            </div>
                        </div>
                    }
                </div> 
                </>            
            </div>
        </div>
    )
} 




