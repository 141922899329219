import React, { useState } from 'react';

import SelectColumn from './SelectColumn'

import { FaAngleDown } from "react-icons/fa";

export default function CustomSelectThree(props) {
  //props.sign needs to be either Plano, Sphere, +, or -

  const [hookClick, setHookClick] = useState(false)


  // const handleTab = (e) => {
  //   // next focusable element in the tab order
  //   const nextElement = e.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling
  //   // nextElementSibling
  //   // console.log(nextElement);
  //   setHookClick(false);
  //   nextElement.focus();
  // }


  // Add an event listener for the 'keydown' event on the document or a specific element
document.addEventListener('keydown', function(event) {
  // Check which key was pressed using the 'event.key' property
  const key = event.key;
  // console.log(key)
  // Perform an action based on the key pressed
  if (key === 'Tab') {


    return (
      setHookClick(false)

    )
    // Add your custom code here
  }
});


  return (
    <div
      style={{
        position: 'relative',
        width: '150px',
      }}
      onFocus={() => setHookClick(true)}
    >
      {
        hookClick === false
        ? <button
            style={{
              border: 'none',
              margin: '2px',
              padding: '3px 10px',
              width: `${props.selectWidth}px`,
              height: '46px',
              borderRadius: '5px',
              background: '#F2F3F4',
              display: 'flex',
          }}
            onClick={() => setHookClick(true)}
          >
            <p 
              style={{
                    width: "80%",
                    margin: "8px 0 0 0",
                    fontSize: '18px',
                    textAlign: 'left',
                  }}
              >
                {props.hook}
            </p>
            < FaAngleDown 
              style={{
                width: "20%",
                margin: "10px 0 0 0",
              }}
            />
          </button>
        : <button 
            style={{ 
              background: '#e6f3f8',
              border: "none",
              margin: '2px',
              padding: "3px 10px",
              borderRadius: "5px",
              width: `${props.selectWidth}px`,
              position: "absolute",
              zIndex: '2',
              }}
            onMouseLeave={() => setHookClick(false)}
              >
            <div 
              style={{ 
                maxHeight: '200px', 
                overflowY: 'auto',
                }}>
                  <div
                  >
                    {
                      props.info.map((m,i) => (
                        m.rowPlace === 'top'
                        ? <div 
                            style={{
                              textAlign: 'center',
                              margin: "8px 0 0 0",
                              }}>
                            <SelectColumn
                              setHook={props.setHook}
                              setHookClick={setHookClick}
                              data={m.data}
                            />
                          </div>
                        : null
                      ))
                    }

                    <div 
                      style={{
                        display: 'flex', 
                        justifyContent: 'space-evenly'
                        }}>
                      {
                        props.info.map((m,i) => (
                        m.rowPlace === 'left'
                        ?
                          <div>
                            <SelectColumn
                              setHook={props.setHook}
                              setHookClick={setHookClick}
                              data={m.data}
                            />
                          </div>
                        : null
                        ))
                      }
                       {
                        props.info.map((m,i) => (
                          m.rowPlace === 'right'
                          ?
                          <div>
                            <SelectColumn
                              setHook={props.setHook}
                              setHookClick={setHookClick}
                              data={m.data}
                            />
                          </div>
                        : null
                        ))
                      }
                    </div>
                  </div>
            </div>
          </button>
      }
    </div>
  )
}
