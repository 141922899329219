import React, {useState, Fragment} from 'react'

import { IoBanOutline } from 'react-icons/io5';
import { IoTrashOutline } from 'react-icons/io5';
import { IoAdd } from 'react-icons/io5';

export default function AddItem(props){

  const [addItem, setAddItem] = useState(false)
  const [newItem, setNewItem] = useState('')

  //create function to change value of contraindition from true to false and vice versa
  let handleClick = (c) => {
    // console.log(c[1].name + ": " + c[1].value)
    let currentName = c[1].name
    // console.log("name: " + currentName)
    let currentVal = c[1].value
    // console.log("value: " + currentVal)
    //use Object.entries to allow mapping of props.hook
    //have to access index of 1 because Object.entries puts index as first item in array
    const newArr = Object.entries(props.hook).map(m =>
      //if the object's name matches the clicked object's name
      (m[1].name === currentName)
        //then toggle true and false
        ? (currentVal === 'true')
          ? {...m[1], value: 'false'}
          : {...m[1], value: 'true'}
        //otherwise, if not clicked, just return the same object pair name and value
        : {...m[1]}
    )
      // console.log(newArr)
      //setHook refers to setItems prop from Lens.js
      props.setHook(newArr)
  }

  let handleSaveAdd = (e) => {
    let newArr = Object.entries(props.hook).map(m => (
      {...m[1]}
    ));
    // console.log(newArr)
    newArr.push({name: `${newItem}`, value: 'true'})
    props.setHook(newArr)
    setNewItem('')
    setAddItem(false)
  }

  let handleCancelAdd = (e) => {
    setNewItem('')
    setAddItem(false)
  };

  let handleDelete = (c) => {
    //define your array
    let newArr = Object.entries(props.hook).map(m => (
      {...m[1]}
    ));
    //splice your new array to get rid of the index you clicked to delete
    // 2nd parameter means remove one item only
    newArr.splice(c[0], 1);
    //set your hook hook in Lens.js so the screen updates by removing the deleted contraindication
    props.setHook(newArr)
  }


  let divStyleFalse = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '3px solid #FFF',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    color: '#BEBEBE',
    width: '200px',
    height: '60px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
    cursor: 'pointer',
  }

  let divStyleTrue = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '3px solid #4CBB17',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    fontWeight: '600',
    color: '#4CBB17',
    width: '200px',
    height: '60px',
    borderRadius: '5px',
    background: '#FFF',
    textAlign:'center',
    cursor: 'pointer',
  }

  let addStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #BEBEBE',
    padding: '4px 10px',
    margin: '2px',
    fontSize: '18px',
    color: 'gray',
    width: '200px',
    height: '60px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
    cursor: 'pointer',
  }

  return(
        <div
          style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <p
            style={{
              height: 'auto',
              textAlign: 'left',
              padding: '0 5px 0 0',
              margin: '0px 0 0 10px',
              fontSize: '16px',
              fontWeight: '600',
            }}>
            {props.label}:
          </p>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              padding: '5px 5px 15px 10px'
            }}>
            {
                Object.entries(props.hook).map((c) => (
                  <div
                    key={c[1].name}
                    style={{position: 'relative'}}
                    >
                    <div
                      style={
                        (c[1].value === 'true')
                          ? divStyleTrue
                          : divStyleFalse
                        }
                      onClick={(e) => handleClick(c)}
                      >
                      {c[1].name}
                    </div>
                      {(c[1].value === 'false')
                        ?
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            background: '#F25835',
                            color: '#FFF',
                            width: '30px',
                            position: 'absolute',
                            bottom: '2px',
                            right: '2px',
                            textAlign: 'center',
                            borderRadius: '5px',
                            padding: '3px 0 0 0',
                            margin: '4px',
                            cursor: 'pointer',
                            zIndex: '1',
                            opacity: '0.8',
                          }}
                          onClick={(e) => handleDelete(c)}
                          >
                          <IoTrashOutline/>
                        </div>
                        : <Fragment></Fragment>
                      }
                  </div>
                ))
              }
            {
              (addItem === false)
                ?
                  <div
                    style={addStyle}
                    value={addItem}
                    onClick={() => setAddItem(!addItem)}
                    >
                    +Add new
                  </div>
                :
                  <div
                    style={{
                      position: 'relative',
                      border: '3px solid #4CBB17',
                      padding: '3px 10px',
                      margin: '2px',
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#4CBB17',
                      width: '200px',
                      height: '60px',
                      borderRadius: '5px',
                      background: '#FFF',
                      textAlign:'center',
                    }}
                    >
                    <input
                      autoFocus
                      style={{
                        width: '110px',
                        padding: '3px 10px',
                        margin: '2px',
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#4CBB17',
                        border: 'none',
                        textAlign: 'center',
                        borderBottom: '1px solid #BEBEBE'
                      }}
                      value={newItem}
                      onChange={(e) => setNewItem(e.target.value)}
                    />
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        background: '#007bff',
                        color: '#FFF',
                        width: '30px',
                        position: 'absolute',
                        bottom: '2px',
                        left: '2px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        padding: '3px 0 0 0',
                        cursor: 'pointer',
                        zIndex: '1',
                        opacity: '0.8',
                      }}
                      onClick={(e) => handleSaveAdd(e)}
                      >
                      <IoAdd/>
                    </div>
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        background: '#292929',
                        color: '#FFF',
                        width: '30px',
                        position: 'absolute',
                        bottom: '2px',
                        right: '2px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        padding: '3px 0 0 0',
                        cursor: 'pointer',
                        zIndex: '1',
                        opacity: '0.7',
                      }}
                      onClick={(e) => handleCancelAdd(e)}
                      >
                      <IoBanOutline/>
                    </div>
                  </div>
            }

          </div>
          <hr
            style={{
              width: '450px',
              margin: '0 0 10px 10px'
            }}/>
        </div>
      )
    }



    const contraindicationsList = [
      {
        name: 'Lasik or PRK',
        value: 'false',
      },
      {
        name: 'Radial Keratotomy',
        value: 'false',
      },
      {
        name: 'Keratoconus',
        value: 'false',
      },
      {
        name: 'Corneal Guttata',
        value: 'false',
      },
      {
        name: 'Epiretinal membrane',
        value: 'false',
      },
      {
        name: 'Diabetic retinopathy',
        value: 'false',
      },
      {
        name: 'Macular degeneration',
        value: 'false',
      },
      {
        name: 'Retinal detachment',
        value: 'false',
      },
      {
        name: 'Amblyopia',
        value: 'false',
      },
    ]
