import React, { useState, Fragment, useEffect } from 'react';
import api from "../services/api";

import FormSelect from '../partials/FormSelect'
import FormInput from '../partials/FormInput'


export default function BiometerNew (props){

  const [biometerName, setBiometerName] = useState(props.biometerName)
  const [topographerName, setTopographerName] = useState(props.topographer)
  const [equipmentPreference, setEquipmentPreference] = useState(props.equipmentPreference)
  const [hoaMaxPreference, setHoaMaxPreference] = useState(props.hoaMaxPreference)
  const [sphAbTarget, setSphAbTarget] = useState([props.sphAbTarget])
  const [equipmentList, setEquipmentList] = useState([props.biometerName, props.topographer])

  //++++++++++++++ERROR MESSAGE HANDLING++++++++++++++++++++++++++++++++++++++++++++
  //create a hook so that you can store the messages in state so they show up in JSX tags
  const[errorMessage, setErrorMessage]=useState([])

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

const handleSave = async(e) => {
  e.preventDefault();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //create blank array to send error messages
    let errorMessageArr = [];

    let equipmentError =
    (equipmentPreference === "Choose an option below")
      ? ''
      : equipmentPreference
    //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false

    let propsArr = [
      {name: `${biometerName}`, message: '*Please choose your biometer'},
      {name: `${topographerName}`, message: '*Please choose your topographer'},
      {name: `${equipmentError}`, message: '*Please choose your prefered machine for cylinder and axis measurements'},
      {name: `${sphAbTarget}`, message: '*Please enter your target residual spherical aberration'},
      {name: `${hoaMaxPreference}`, message: '*Please enter your HOA cutoff'},

    ];

    // console.log(propsArr)
    // console.log(propsArr[0])
    // console.log(equipmentPreference)
    // console.log(equipmentError)

    //Map through props you are interested in and send error message to errorMessageArr if there is an error

    Object.entries(propsArr).map(p => {
        if(p[1].name === '' || p[1].name === 'undefined'){
          // console.log(p[1].message)
          return errorMessageArr.push(p[1].message)
        } else {
          return null
        }

      })
      setErrorMessage(errorMessageArr)
      //if there are errors, do not complete "put" or "update" function
      if(errorMessageArr.length > 0){
        return null
      } else {

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        try {
          await api.post(`/equipment` , {
            //no header or body stuff here, just send the variables
            biometerName,
            topographerName,
            equipmentPreference,
            sphAbTarget,
            hoaMaxPreference,
          }).then(window.location =`/cri` )

        } catch (err) {
          console.error(err.message)
      }
    }
  }

  //Update preferedEquipment hook based on equipment selections
  useEffect(() => {
    //Make a list without blanks
    let list;
    if(biometerName && topographerName){
      list = ["Choose an option below", biometerName, topographerName];
    }
    setEquipmentList(list);
  }, [biometerName, topographerName])

  let biometerOptions = [
    "Choose your biometer",
    "IOL Master 500",
    "IOL Master 700",
    "Lenstar LS 900",
    "Argos",
  ]

  let topographerOptions = [
    "Choose your topographer",
    "Pentacam",
    "Atlas",
  ]

  let warnStyle ={
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

    return(
      <Fragment>
        <form
          style={{
            background: '#F2F3F4',
            borderRadius: '10px',
            padding: '0px 0 50px 20px',
            margin: '20px auto 20px auto',
            width: '500px',
          }}
          >
          <div style = {{
              padding: '5px 5px 15px 10px',
            }}>
            <FormSelect
              label="Biometer:"
              value={biometerName}
              setHook={setBiometerName}
              options={biometerOptions}
              />
            <FormSelect
              label="Topographer:"
              value={topographerName}
              setHook={setTopographerName}
              options={topographerOptions}
              />
            <FormSelect
              label="Which machine do you use to get your cylinder and axis measurements for IOL calculations?"
              value={equipmentPreference}
              setHook={setEquipmentPreference}
              options={equipmentList}
              />
            <FormInput
              label="What is the ideal residual post-operative spherical aberration target? (usually between 0 and +0.10)"
              value={sphAbTarget}
              setHook={setSphAbTarget}
              type={'number'}
              />
            <FormInput
              label="At some point, the corneal higher order aberration is severe enough that you want to minimize spherical aberration introduced by the lens by choosing a monofocal lens that has the spherical aberration closest to 0.  What is your preferred HOA cutoff (e.g., 0.3 for 4mm zone and 0.5 for 6 mm zone)"
              value={hoaMaxPreference}
              setHook={setHoaMaxPreference}
              type={'number'}
              />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              onClick={(e => handleSave(e))}
              style={{
                borderRadius: '5px',
                width: '100px',
                margin: '5px',
                padding: '7px',
                background: '#007bff',
                color: '#fff',
                fontSize: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              >
              Save
            </div>
          </div>
        </form>
        <div
          style={{
             justifyContent: 'left',
             margin: '0 0 50px 280px',
           }}>
          {errorMessage.map((m, i) => (
              <p
                key={i}
                style={warnStyle}
                >
                {m}
              </p>
            ))
          }
        </div>
        </Fragment>
    )
  }
