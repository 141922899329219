import ReferenceImage from './ReferenceImage'

import imgChordMu from './images/iolMaster700ChordMu.jpg'
import { TfiRuler } from "react-icons/tfi";

export default function IOLMaster700 (props) {

  let inputStyle = {
    border: 'none',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '135px',
    height: '40px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
  }
  
  let warnStyle = {
    border: '2px solid red',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '131px',
    height: '36px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
  }

  let subtitleStyle = {
    textAlign: 'left',
    padding: '0 0 0 0px',
    fontSize: '18px',
    fontWeight: '600',
    margin: 'auto 0',
  }

  let iconStyle = {
    margin: 'auto 0',
    padding: '0',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
  }
  
  return(
    <div style={{
        display: 'flex',
        justifyContent: 'left',
        margin: '0 0 8px 80px',
      }}>
      <div 
          style={{
            display: 'flex',
            width: "210px"
          }}
        >
          <div
            style={iconStyle}
          >
            <TfiRuler
              style={{fontSize: '40px'}}
            />
          </div>
          
          <p style={subtitleStyle}>{props.biometer} </p>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{
            display: 'flex',
            position: 'relative',
            width: '160px',
          }}>
            <input
            style={
              props.biometerAstig >= 0 && props.biometerAstig < 8 || props.biometerAstig === ''
              ? inputStyle
              : warnStyle
            }
            value={props.biometerAstig}
            onChange={e => props.setBiometerAstig(e.target.value)}
            placeholder="Astigmatism"
            type='number'
            onWheel={(e) => e.target.blur()}
            />
          <ReferenceImage
            referenceImage='iolMaster700Astigmatism.jpg'
            />
          </div>
          <div style={{
            display: 'flex',
            position: 'relative',
            width: '160px',
          }}>
            <input
              style={
                props.biometerAxis > 0 && props.biometerAxis < 181 || props.biometerAxis === ''
                ? inputStyle
                : warnStyle
              }
              value={props.biometerAxis}
              onChange={e => props.setBiometerAxis(e.target.value)}
              placeholder="Steep Axis"
              type='number'
              onWheel={(e) => e.target.blur()}
            />
            <ReferenceImage
              referenceImage='iolMaster700SteepAxis.jpg'
              />
          </div>
          {/* <div style={{
            display: 'flex',
            position: 'relative',
            width: '160px',
          }}>
            <input
              style={
                props.chordMuTotal >= 0 && props.chordMuTotal < 1 || props.chordMuTotal === ''
                ? inputStyle
                : warnStyle
              }
              value={props.chordMuTotal}
              onChange={e => props.setChordMuTotal(e.target.value)}
              placeholder="Kappa"
              type='number'
              onWheel={(e) => e.target.blur()}
            />
            <ReferenceImage
              referenceImage={imgChordMu}
              />
          </div> */}
        </div>
        </div>
  )
}
