import React, { Fragment, useEffect, useState } from 'react';

export default function NearPickerComparison (props) {

  const[nearPicker, setNearPicker]=useState('')

  let nearAlwaysOff =
  (
    props.lensCorrectsNearVision === 'Yes' ||
    props.lensCorrectsIntermediateVision === 'Yes' ||
    props.lensOption === 'Cataract vision'
  )
    ? true
    : false

    // console.log(nearAlwaysOff)

    let modifier = () => {
      if (nearAlwaysOff === true){
        setNearPicker('alwaysOff')
      } else if (nearAlwaysOff === false){
        setNearPicker('allowToggle')
      }
    }

    useEffect(() => {
      modifier();
    });

    useEffect(() => {
      if(nearPicker === 'alwaysOff'){
        props.setLensTargetNear(false)
      }
    }, [nearPicker]);


    let buttonNearStyleFalse = {
      border: '1px solid #F2F3F4',
      padding: '3px 10px',
      margin: '2px 2px -8px 2px',
      fontSize: '18px',
      color: 'lightgray',
      width: '135px',
      height: '34px',
      borderRadius: '5px',
      background: '#fff',
      textAlign:'center',
      position: 'absolute',
      right: '0px',
      bottom: '-92px',
      zIndex: '8',
      cursor: 'pointer',
    }

    let buttonNearStyleTrue = {
      border: '3px solid #4CBB17',
      padding: '3px 10px',
      margin: '2px 2px -8px 2px',
      fontSize: '18px',
      fontWeight: '600',
      color: '#4CBB17',
      width: '135px',
      height: '34px',
      borderRadius: '5px',
      background: '#FFF',
      textAlign:'center',
      position: 'absolute',
      right: '0px',
      bottom: '-92px',
      zIndex: '8',
      cursor: 'pointer',
    }

  return(
    <Fragment>
      {nearAlwaysOff === true
        ? null
        :
        <button
          style={
            props.lensTargetNear === false
              ? buttonNearStyleFalse
              : buttonNearStyleTrue
          }
          onClick={
            props.lensTargetNear === false
              ? () => props.setLensTargetNear(true)
              : () => props.setLensTargetNear(false)
            }>
          Target Near
        </button>
      }
      </Fragment>
    )
}
