import React, { useState, useEffect, useRef } from 'react';

import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, Modifier} from 'draft-js';

import BedsheetToolbar from './BedsheetToolbar'
import 'draft-js/dist/Draft.css';
import '../../consents/consentStyle.css';

export default function BedsheetEditor (props) {


    //Check to see if there is any bedsheet data coming from the database
  const fromDatabase =
    (props.bedsheetData && props.bedsheetData.length > 0)
      ? convertFromRaw(JSON.parse(props.bedsheetData))
      : ''
  //Fill the editor if there is any bedsheet data in the database, otherwise create an empty editor
  const [editorState, setEditorState] = useState(
    (fromDatabase.size > 0)
      ? EditorState.createWithContent(fromDatabase)
      : EditorState.createEmpty()
  )

  const [isFocused, setIsFocused] = useState(props.isFocused)
  
  const editor = useRef(null);
 
  let className = 'RichEditor-editor'

  // Custom overrides for "code" style=====================================================
  const styleMap = {
    CODE: {
      backgroundColor: '#F8FF00',
    },
    bolded: {
      fontWeight: '600'
    }
  };

  //Custom overrides for blockstyles
  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case 'title': return 'RichEditor-title';
      case 'header': return 'RichEditor-header';
      case 'paragraph': return 'RichEditor-paragraph';
      case 'small-font': return 'RichEditor-small-font';
      case 'circle-list': return 'RichEditor-circle-list';
      case 'watermark': return 'RichEditor-watermark';
      // case 'square-list': return 'RichEditor-square-list';
      default: return null;
    }
  }

  const handleKeyCommand = (command) => {
    setEditorState(RichUtils.handleKeyCommand(editorState, command))
  }

  const focusEditor = () => {
     editor.current.focus();
   }

  let contentState = editorState.getCurrentContent();

  const saveContent = () => {
    const rawData = convertToRaw(contentState);
      // console.log('raw data', rawData)
    const stringyData = JSON.stringify(rawData)
      // console.log('string data', stringyData)
    props.setBedsheetData(stringyData);
  }

  
  // code for inserting template tokens ==========================================
  const handleTemplateInsert = (templateContent) => {
    const currentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
  
    // Create a new ContentState with the templateContent inserted at the current selection
    const newContent = Modifier.replaceText(
      currentState,
      currentSelection,
      templateContent
    );
  
    // Update the EditorState with the new ContentState
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    setEditorState(newEditorState);
  };
  //==============================================================================


  const toggleBlockType = (blockType) => {
    console.log(blockType)
    setEditorState(RichUtils.toggleBlockType(editorState, blockType))
  }

  const toggleInlineStyle = (inlineStyle) => {
    // console.log(inlineStyle)
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

 
  // If the user changes block type before entering any text, we can either style the placeholder or hide it. Let's just hide it now.

  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  //=======================================================================

  useEffect(() => {
    focusEditor()
  }, []);
 
  useEffect(() => {
    saveContent()
  }, [editorState]);
 


  //==============================================================================


  return (
    <div
      onFocus={() => setIsFocused('true')} // Handle focus event
    >
      {
        isFocused === 'true'
        ? 
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: '0',
              left: '0',
              background: "#FFF",
              padding: '10px',
              width: '100%',
              zIndex: '2',
              margin: '0',
            }}
            onMouseEnter={() => setIsFocused('true')}
            >
              <BedsheetToolbar
                editorState={editorState}
                setEditorState={setEditorState}
                onTemplateInsert={handleTemplateInsert} 
                toggleBlockType={toggleBlockType}
                toggleInlineStyle={toggleInlineStyle}
              />
          </div>
        : 
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: '0',
              left: '0',
              background: "#FFF",
              padding: '10px',
              width: '100%',
              zIndex: '0',
              margin: '0',
            }}
            >
              <BedsheetToolbar
                editorState={editorState}
                setEditorState={setEditorState}
                onTemplateInsert={handleTemplateInsert} 
                toggleBlockType={toggleBlockType}
                toggleInlineStyle={toggleInlineStyle}
              />
          </div>
        
      }

      <div
        className="RichEditor-root"
        style={{
          width: '700px',
        }}
      >
        {
          props.splitView === 'true'
          ? 
          <div>
            <div
              className={className}
              onClick={focusEditor}
              style={{
                cursor: 'text',
                padding: `${props.padding}`,
                margin: `${props.margin}`,
                border: `${props.border}`,
                background: '#EFEFEF',
                borderRadius: '5px',
                width: '400px',
              }}
              >

              <Editor
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                ref={editor}
                onChange={setEditorState}
                spellCheck={true}
                placeholder={props.placeholder}
                />
            </div>
            <div style={{width: '400px'}}></div>
          </div>
          : 
          <div
            className={className}
            onClick={focusEditor}
            style={{
              cursor: 'text',
              padding: `${props.padding}`,
              margin: `${props.margin}`,
              border: `${props.border}`,
              background: '#EFEFEF',
              borderRadius: '5px',
              width: '800px',
            }}
            >

            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              ref={editor}
              onChange={setEditorState}
              spellCheck={true}
              placeholder={props.placeholder}
              />
          </div>
        }
      </div>
    </div>
  );
}


