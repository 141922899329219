import React, { useState } from 'react';

import VideoNew from "./VideoNew"
import Header from '../partials/Header';
import Navbar from '../partials/Navbar';


export default function VideoSearch (props) {

  const [videoPicked, setVideoPicked] = useState('')
  const [searchString, setSearchString] = useState('')
  const [activeOption, setActiveOption] = useState('')
  const [filteredOptions, setFilteredOptions] = useState('')
  const [showOptions, setShowOptions] = useState('')


  const[userName, setUserName]=useState('')

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if(filteredOptions[activeOption] === undefined){
        alert("Please check spelling and choose a video from the list")
      } else {
        setActiveOption(0)
        setShowOptions(false)
        setSearchString(filteredOptions[activeOption].videoName)
        setVideoPicked(filteredOptions[activeOption])
      }}
      else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      setActiveOption(activeOption -1);
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length -1) {
        return;
      }
      setActiveOption(activeOption +1);
    }
  }


  const handleUserInput = e => {
    const searchText = e.currentTarget.value;
    const filteredVideoes = videoOptions.filter((c) =>
      c.videoName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

    if(searchText.length === 0){
      setSearchString('')
      setVideoPicked('')
      setActiveOption(0)
      setFilteredOptions(filteredVideoes)
      setShowOptions(false)
    } else {
      setSearchString(e.currentTarget.value)
      setActiveOption(0)
      setFilteredOptions(filteredVideoes)
      setShowOptions(true)
    }
  }


  let optionsList;

  let ulStyle = {
    display: 'block',
    listStyle: 'none',
    transition: 'width 0.3s',
    margin: 'auto',
    position: 'relative',
    padding: '0 0 0 20px',
  }

  let listFilterStyle = {
    display: 'block',
    margin: '3px 3px 3px 120px',
    padding: '3px',
    fontSize: '1rem',
    width: '400px',
    borderRadius:'5px',
    color: 'darkgray',
  }

  let listActiveStyle = {
    margin: '3px 3px 3px 120px',
    padding: '3px',
    fontSize: '1rem',
    width: '400px',
    borderRadius:'5px',
    color: '#000',
    fontWeight: '600',
  }

  let inputStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '4px 0 4px 98px',
    padding: '5px',
    border: 'none',
    background: '#FFF',
    borderRadius: '7px',
    width: '330px',
  }

  let labelStyle = {
    margin: 'auto 10px auto 100px',
    padding: '0',
    textAlign: 'left',
    fontWeight: '600'
  }


  if (showOptions === true && searchString) {
    if (filteredOptions.length) {
      optionsList = (
        <ul style= {ulStyle}>
          {filteredOptions.map((optionName, index) => {
            let listName = listFilterStyle;
            if (index === activeOption) {
              listName = listActiveStyle;
            }
            return (
              <li
                style= {listName}
                key= {optionName.videoName}
                onClick={() => {
                  setVideoPicked(optionName);
                  setSearchString(optionName.videoName);
                  setActiveOption(0);
                  setFilteredOptions([]);
                  setShowOptions(false);
                }}>
                  {optionName.videoName}
              </li>
            );
          })}
        </ul>
      );
    }
  }




  return(
    <div>
      <Navbar
        setUserName={setUserName}/>
      <Header
        title={"Patient Education"}
        subtitle={"Add your education videos"}
        />
      <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <div
          style={{
            background: '#F2F3F4',
            borderRadius: '10px',
            padding: '0px 0 50px 0px',
            margin: '20px 0 20px 0px',
            width: '540px',
          }}
        >
          <div style= {{
              margin:'0',
              textAlign: 'left',
              position: 'relative',
            }}>
            <div style= {{
                padding: '15px 5px 0px 10px',
              }}>
              <p style= {labelStyle}>Video name: </p>
              <input
                type="text"
                style={inputStyle}
                onChange={handleUserInput}
                onKeyDown={(e) => handleKeyDown(e)}
                value={searchString}
                autoFocus
                />
            </div>
            <hr
              style={{
                width: '450px',
                position: 'absolute',
                left: '10px',
                bottom: '-20px',
              }}/>
            {optionsList}
          </div>
          <VideoNew
            videoName={videoPicked.videoName || ''}
            videoUrl={videoPicked.videoUrl || ''}
            videoType={videoPicked.videoType || ''}
            videoOrder={videoPicked.videoOrder || ''}
            videoStart={videoPicked.videoStart || ''}
            videoEnd={videoPicked.videoEnd || ''}
            modal={props.modal}
            setModal={props.setModal}
            />
        </div>
      </div>
    </div>
  )
}



const videoOptions = [
    {
      videoName: 'Anatomy of the Eye', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/XuDZdE_XrQM?si=Epy68pcInuS_B9WC", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'What are Cataracts', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/_gvdXfwYfhk?si=9mPsI-CR26B23Wgc", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'Cataract Surgery', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/LIza4BiEoOk?si=LHTcdtaf4AZ69OrW", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'Intraocular lenses', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/G68gh_7IZaA?si=m2SgW58jKQ07zdQ3", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'Monofocal lenses', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/Khbr-gMuj7c?si=xFjEO6NjiMqEtb7O", 
      videoStart: '0', 
      videoEnd: ''
    },
    {
      videoName: 'Astigmatism', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/lDYEambEpOw?si=ZArZEiWG-R9bQBa1", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'Toric lenses', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/QcXy1Trat1c?si=vltxUhuKQSCfgaEA", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'Presbyopia', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/oLotpC9qmuU?si=LzpjQVmHa9Uz79JO", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'Trifocal lenses', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/jEOf04mxsXs?si=uMFV-gwAkJQ4Too0", 
      videoStart: '', 
      videoEnd: ''
    },
    {
      videoName: 'Risks and Benefits of Cataract Surgery', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/Ui9yIzckiDg?si=nanQcfCEf6Smrgtk", 
      videoStart: '34', 
      videoEnd: ''
    },
    {
      videoName: 'Femptosecond Laser-Assisted Cataract Surgery (FLACS)', 
      videoType: 'Preop', 
      videoOrder: '', 
      videoUrl: "https://www.youtube.com/embed/8zVKol3nKNo?si=pQhjKGAZzPoLzZ2H", 
      videoStart: '', 
      videoEnd: ''
    },   
]


