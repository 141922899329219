import React, { useEffect } from 'react'

import FormSelectMargin from '../partials/FormSelectMargin'
import FormInputMargin from '../partials/FormInputMargin'

export default function FlacsQuestions (props) {


  let requireOptions = [
    "Choose an option below",
    "Yes (always)",
    "Yes (only with CRI)",
    "Optional (always offered)",
    "Optional (only offered with CRI)",
    "No (don't offer)",
  ]


  useEffect(() => {
    if(
        props.lensFlacsRequire === "No (don't offer)"
        ||
        props.lensFlacsRequire === "Choose an option below"
        ){
      props.setLensFlacsFee('')
    } 
  }, [props.lensFlacsRequire]);


  return (
    <div>
      <FormSelectMargin
        label={'Use FLACS for this lens?'}
        value={props.lensFlacsRequire}
        setHook={props.setLensFlacsRequire}
        options={requireOptions}
      />
      {
        (
          props.lensFlacsRequire === "Yes (always)"
          ||
          props.lensFlacsRequire === "Yes (only with CRI)"
          ||
          props.lensFlacsRequire === "Optional (always offered)"
          ||
          props.lensFlacsRequire === "Optional (only offered with CRI)"
        )
        ? 
          <div>
            <FormInputMargin
              label="FLACS out-of-pocket fee ($):"
              value={props.lensFlacsFee}
              setHook={props.setLensFlacsFee}
              type={'number'}
              />
          </div>
        : null
      }
      
    </div>
  )
}
