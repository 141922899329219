
import React, { useState } from 'react';

export default function QuestionBox (props){
    
    const[q1Hover, setQ1Hover]=useState(false)
    const[q2Hover, setQ2Hover]=useState(false)

    let buttonStyleTrue = {
        border: '2px solid #007bff',
        fontSize: '24px',
        fontWeight: '600',
        color: '#FFF',
        borderRadius: '5px',
        background: "#007bff",
        margin: '20px auto 20px auto',
        padding: '10px 20px',
        width: '140px',
        textAlign: 'center',
        cursor: 'pointer',
      }
      
    let buttonStyleFalse = {
        border: '2px solid #007bff',
        fontSize: '24px',
        fontWeight: '600',
        color: '#007bff',
        borderRadius: '5px',
        margin: '20px auto 20px auto',
        padding: '10px 20px',
        width: '140px',
        textAlign: 'center',
        cursor: 'pointer',
    }

    return(
            <div 
                style={{
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    background: 'rgba(250, 250, 250, 0.7)',
                }}>
                <div
                    style={{
                        width: '600px',
                        height: '80vh',
                        background: "#FFF",
                        borderRadius: "10px",
                        opacity: '0.98',
                        margin: "50px auto 0 auto",
                    }}
                >
                    <p
                        style={{
                            padding: '20px',
                            fontSize: '40px',
                            textAlign: 'center',
                        }}
                    >
                        {props.question}
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={
                                q1Hover === true
                                ? buttonStyleTrue
                                : buttonStyleFalse
                                }
                            onMouseEnter={() => setQ1Hover(true)}
                            onMouseLeave={() => setQ1Hover(false)}
                            onClick={() => {
                                props.setHook(props.value1);
                                setQ1Hover(false);
                                props.setModalNum(props.modalNum*1+1);
                                }
                        }>
                            {props.q1}
                        </div>
                        <div
                            style={
                                q2Hover === true
                                ? buttonStyleTrue
                                : buttonStyleFalse
                                }
                            onMouseEnter={() => setQ2Hover(true)}
                            onMouseLeave={() => setQ2Hover(false)}
                            onClick={() => {
                                props.setHook(props.value2);
                                setQ2Hover(false);
                                props.setModalNum(props.modalNum*1+1);
                                }
                        }>
                            {props.q2}
                        </div>
                    </div>
                </div>
            </div>
    )
}