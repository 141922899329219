import React, { useState, useEffect, useRef } from 'react';

import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw} from 'draft-js';
import SignatureCapture from '../../partials/SignatureCapture'
import 'draft-js/dist/Draft.css';
import './consentStyle.css';

// Get rid of all the functionality and make read only with addition of replacing props.  Add readOnly to editor

export default function ConsentRead (props) {

  
  const str = props.consentData

  // console.log(str)

  let lensTarget = (props.lensTargetNear === true)
    ? 'near'
    : 'distance'

    // console.log(lensTarget)


  let icdTen = (props.side === 'RIGHT')
    ? '1'
    : (props.side === 'LEFT')
      ? '2'
      : null

  let lensCriFee = (props.cri === true)
    ? props.lensCriFee
    : 0

  let lensFlacsFee = (props.flacs === true)
    ? props.lensFlacsFee
    : 0

  const mapObj = {
    eyeSide: `${props.side}`,
    lensModel: `${props.lensModel}`,
    lensOption: `${props.lensOption}`,
    lensTarget: `${lensTarget}`,
    lensPhysicianFee: `${props.lensPhysicianFee}`,
    lensHospitalFee: `${props.lensHospitalFee}`,
    lensFlacsFee: `${lensFlacsFee}`,
    lensCriFee: `${lensCriFee}`,
    oopTotal: `${props.oopTotal}`,
    oopPhysician: `${props.oopPhysician}`,
    oopHospital: `${props.oopHospital}`,
    icdTen: `${icdTen}`,
    
  };

  // console.log(props.lensCriFee)
 

  const propReplaced = str.replace(/(?:eyeSide|lensModel|lensOption|lensTarget|lensPhysicianFee|lensHospitalFee|lensFlacsFee|lensCriFee|oopTotal|oopPhysician|oopHospital|icdTen)/gi, matched => mapObj[matched]);

 






  // console.log(str);

  const convertedData = convertFromRaw(JSON.parse(propReplaced))

  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertedData))

  let contentState = editorState.getCurrentContent();

  // console.log(convertToRaw(contentState));


  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case 'title': return 'RichEditor-title';
      case 'header': return 'RichEditor-header';
      case 'paragraph': return 'RichEditor-paragraph';
      default: return null;
    }
  }

  // Custom overrides for "code" style.
  const styleMap = {
    bolded: {
      fontWeight: '600'
    }
  };


  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '7vw'}}></div>
      <div
        className='RichEditor-root'
        style={{
          width: '800px',
        }}
        >
        <div style={{
          maxWidth: '800px',
          width: '86vw',
        }}>
         
          <p style={{margin: '10px 30px 0 0'}}>{props.side} eye</p>
          {/* <p style={{margin: '10px 30px 0 0'}}>Lens: {props.lensModel}</p> */}
        </div>
        <div
          className='RichEditor-editor'
          style={{
            cursor: 'text',
            marginTop: '40px',
          }}
          >
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            onChange={editorState => {setEditorState(editorState)}}
            readOnly={true}
              />
          <SignatureCapture 
          />
        </div>
       
      </div>
      <div style={{width: '7vw'}}></div>
    </div>
  );
}



// <p style={{margin: '0 0 30px 0'}}>{props.fName} {props.lName} • {props.dob}</p>