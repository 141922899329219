import React, { Fragment, useState, useEffect } from 'react';

import api from "../services/api";

import Biometer from './Biometer'
import BiometerNew from './BiometerNew'
import Header from '../partials/Header'
import Navbar from '../partials/Navbar'


export default function BiometerList (props){

const[equipmentData, setEquipmentData] = useState('')

const getEquipment = async() => {

    try {
      const response = await api.get(`/equipment`);

      setEquipmentData(response.data.rows)

    } catch (err) {
      console.error(err.message)
    }
  }

  useEffect(() => {
      getEquipment();
    }, []);


  return (
    <div>
      <Navbar/>
      <div style={{
          width: '7vw',
        }}>
      </div>
      <Header
        title={"Biometer and Topographer"}
        subtitle={"Choose your biometer and topographer below"}
        />
      {equipmentData.length > 0
        ?
          <Fragment>
            <Biometer
              key={equipmentData[0].equipment_id}
              equipmentId={equipmentData[0].equipment_id}
              biometerName={equipmentData[0].biometer_name}
              topographerName={equipmentData[0].topographer_name}
              equipmentPreference={equipmentData[0].equipment_preference}
              hoaMaxPreference={equipmentData[0].hoa_max_preference}
              sphAbTarget={equipmentData[0].sph_ab_target}
              />
          </Fragment>

        :
          <Fragment>
            <BiometerNew/>
          </Fragment>
      }
      <div style={{
          width: '7vw',
        }}>
      </div>
    </div>
  )
}
