import React, { useState, useEffect, useRef } from 'react';
import api from '../services/api.js';

import LensPickerComparison from './LensPickerComparison.js'

import distanceImage from "./images/distanceImage.jpeg"
import intermediateImage from "./images/intermediateImage.png"
import nearImage from "./images/nearImage.png"
// import ProfilePicker from '../purchaseUser/ProfilePicker.js';


// import api from "../services/api";
// import PrintPage from '../partials/PrintPage';

export default function PremiumSimulator(props){


  //Create phakic vision variables that come from patient info
    //phakicPower
    let p = (props.power.toLowerCase() === 'plano' ||
      props.power.toLowerCase() === 'pl' ||
      props.power === '')
        ? 0
        : props.power*1;

    //phakicCyl
    let c = (props.cylinder.toLowerCase() === 'sphere' ||
      props.cylinder.toLowerCase() === 'sph' ||
      props.cylinder === '')
        ? 0
        : props.cylinder*0.5;

    //phakicAdd
    let a = props.add*1;



    //First, get lens data for lens that matches lensOption -->
    let currentLens = [];
    if (props.lensData && Array.isArray(props.lensData)) {
      currentLens = props.lensData.filter(lens => (
        lens.lens_name === props.lensOption
      ));
    }
      // console.log(currentLens)

// need to make it so that when it changes to current vision, these things are set back to nothing
    //Then, create variables to store lens data
  const [lensName, setLensName] = useState('');
  const [lensModel, setLensModel] = useState('');
  const [lensLocation, setLensLocation] = useState('');
  const [lensHospitalFee, setLensHospitalFee] = useState('');
  const [lensPhysicianFee, setLensPhysicianFee] = useState('');
  const [lensSphAb, setLensSphAb] = useState('');
  const [lensKSphAbResidual, setLensKSphAbResidual] = useState('');
  const [lensCorrectsIntermediateVision, setLensCorrectsIntermediateVision] = useState('');
  const [lensCorrectsNearVision, setLensCorrectsNearVision] = useState('');
  const [lensGlare, setLensGlare] = useState('');
  const [lensTopographerHoaCutoff, setLensTopographerHoaCutoff] = useState('');
  const [lensKAstigMax, setLensKAstigMax] = useState('');
  const [lensKAstigMin, setLensKAstigMin] = useState('');
  const [lensKAstigResidual, setLensKAstigResidual] = useState('');
  const [lensChordMu, setLensChordMu] = useState('');
  const [lensBiometerStdDev, setLensBiometerStdDev] = useState('');
  const [lensBiometerTopographerAstigCutoff, setLensBiometerTopographerAstigCutoff] = useState('');
  const [lensBiometerTopographerAxisCutoff, setLensBiometerTopographerAxisCutoff] = useState('');
  const [lensCriMinAstig, setLensCriMinAstig] = useState('');
  const [lensCriMaxAstig, setLensCriMaxAstig] = useState('');
  const [lensCriRequire, setLensCriRequire] = useState('');
  const [lensFlacsRequire, setLensFlacsRequire] = useState('');
  const [lensConsentId, setLensConsentId] = useState('');
  const [lensCriFee, setLensCriFee] = useState('');
  const [lensFlacsFee, setLensFlacsFee] = useState('');
  

  useEffect(() => {
    if (currentLens.length > 0) {
      setLensName(currentLens[0].lens_name);
      setLensModel(currentLens[0].model_number);
      setLensLocation(currentLens[0].lens_location);
      setLensHospitalFee(currentLens[0].hospital_lens_fee);
      setLensPhysicianFee(currentLens[0].physician_lens_fee);
      setLensSphAb(currentLens[0].lens_sph_ab);
      setLensKSphAbResidual(currentLens[0].k_sph_ab_residual);
      setLensCorrectsIntermediateVision(currentLens[0].corrects_intermediate_vision);
      setLensCorrectsNearVision(currentLens[0].corrects_near_vision);
      setLensGlare(currentLens[0].lens_glare);
      setLensTopographerHoaCutoff(currentLens[0].lens_hoa_max);
      setLensKAstigMax(currentLens[0].lens_astig_max);
      setLensKAstigMin(currentLens[0].lens_astig_min);
      setLensKAstigResidual(currentLens[0].lens_astig_residual);
      setLensChordMu(currentLens[0].chord_mu_max);
      setLensBiometerStdDev(currentLens[0].biometer_std_dev_max);
      setLensBiometerTopographerAstigCutoff(currentLens[0].biometer_topographer_astig_max);
      setLensBiometerTopographerAxisCutoff(currentLens[0].biometer_topographer_axis_max);
      setLensCriMinAstig(currentLens[0].cri_min_astig);
      setLensCriMaxAstig(currentLens[0].cri_max_astig);
      setLensCriRequire(currentLens[0].cri_require);
      setLensFlacsRequire(currentLens[0].flacs_require);
      setLensConsentId(currentLens[0].consents);
      setLensCriFee(currentLens[0].cri_fee);
      setLensFlacsFee(currentLens[0].flacs_fee);
    } else {
      setLensName('');
      setLensModel('');
      setLensLocation('');
      setLensHospitalFee('');
      setLensPhysicianFee('');
      setLensSphAb('');
      setLensKSphAbResidual('');
      setLensCorrectsIntermediateVision('');
      setLensCorrectsNearVision('');
      setLensGlare('');
      setLensTopographerHoaCutoff('');
      setLensKAstigMax('');
      setLensKAstigMin('');
      setLensKAstigResidual('');
      setLensChordMu('');
      setLensBiometerStdDev('');
      setLensBiometerTopographerAstigCutoff('');
      setLensBiometerTopographerAxisCutoff('');
      setLensCriMinAstig('');
      setLensCriMaxAstig('');
      setLensCriRequire('');
      setLensFlacsRequire('');
      setLensConsentId('');
      setLensCriFee('');
      setLensFlacsFee('');
    }
  }, [currentLens[0]]);


    //Don't need to know how much cylinder to correct bc lens picker filters this
    let lensCorrectsAstigmatism = currentLens.length > 0
      ? (currentLens[0].lens_astig_min*1 === 0 || currentLens[0].lens_astig_min === 'dontuse')
        ? 'No'
        : currentLens[0].lens_astig_min*1 > 0
          ? 'Yes'
          : ''
      : ''
      // console.log(lensCorrectsAstigmatism)

    //Final Power
    //Lens set for distance? --> adjust postop distance blur to 0
    let finalPower = (props.lensOption === "Cataract vision" || props.lensOption === '')
      ? p+c
      //Lens set for near? --> adjust postop distance blur to -2.00
      : (props.lensTargetNear === true)
        ? -2.00
        : 0
        // console.log(finalPower + " final power")


    //Final Add
    let finalAdd = (lensCorrectsIntermediateVision === "Yes" && lensCorrectsNearVision === "Yes")
      //finalAdd should be 0 for lenses that correct intermediate and near
      ? 0
      : (lensCorrectsIntermediateVision === "Yes" && lensCorrectsNearVision === "No")
        //finalAdd should be 1 for lenses that correct intermediate but not near
        ? +1.00
        : (props.lensOption === "Cataract vision" || props.lensOption === '')
          //finalAdd should be patient's measured preop add if they haven't had surgery
          ? a
          : +2.50;
          // console.log(finalAdd + " final add")
          // console.log(a + " a")

    let distanceBlur =
      ((finalPower*1) > 0 && (finalPower*1)+(finalAdd*1-2.50) < 0)
      //if patient is hyperope preop but can accommodate, then there is no distance blur.  NB: this doesn't truly represent how a young hyperope might see well at distance without glasses
        ? 0
        : (finalPower*1) > 0
          //If patient is a hyperope preop and cannot accommodate enought to correct, then distance blur is difference between hyperopic power and accommodative power.
          ? (finalPower*1)+(finalAdd*1-2.50)
          //Otherwise, patient is plano or myopic (preop or postop) and you just set distance blur equal to finalPower (using absolute power to facilitate blur function)
          : Math.abs(finalPower*1);
          // console.log(distanceBlur + " distance blur")


    let intermediateBlur =
      ((finalPower*1+1.50) > 0 && ((finalPower*1+1.50)+(finalAdd*1-2.50)) <= 0)
        //If patient is more hyperopic than a -1.50 myope preop but can accommodate enough to see computer range, then intermediate blur is 0.  NB: Computer range requires +1.50 more of accommodative power than distance
        ? 0
        : (finalPower*1+1.50) > 0
          //If patient is more hyperopic than a -1.50 myope preop and cannot accommodate enought to correct for computer range (requires +1.50 more diopters of accommodation than distance vision), then intermediate blur is difference between (finalPower+1.50) and accommodative power.
          ? ((finalPower*1+1.50)+(finalAdd*1-2.50))
          //Otherwise, patient is a -1.50 myope or more myopic (preop or postop) and you just set intermediate blur equal to finalPower+1.50 (using absolute power to facilitate blur function)
          : Math.abs(finalPower*1+1.50);
          // console.log(intermediateBlur + " intermediate blur")


    let nearBlur =
      ((finalPower*1+2.50) > 0 && ((finalPower*1+2.50)+(finalAdd*1-2.50)) <= 0)
        //If patient is more hyperopic than a -2.50 myope preop but can accommodate enough to see near range, then near blur is 0.  NB: Near range requires +2.50 more of accommodative power than distance
        ? 0
        : (finalPower*1+2.50) > 0
          //If patient is more hyperopic than a -2.50 myope preop and cannot accommodate enought to correct for near range (requires +2.50 more diopters of accommodation than distance vision), then near blur is difference between (finalPower+2.50) and accommodative power.
          ? ((finalPower*1+2.50)+(finalAdd*1-2.50))
          : (finalPower*1 <= -2.50 && finalPower*1 >= -4.00)
          //If patient is between -2.50 and -4.00, there is no subjective blur.
            ? 0
            //Otherwise, patient is more than a -4.00 myope (preop or postop) and you just set near blur equal to finalPower+4 (using absolute power to facilitate blur function and +3.50 so that you get enough blur)
            : Math.abs(finalPower*1+4.00);
            // console.log(nearBlur + " near blur")


    //make bcva make vision more blurry as well as prescription
    let cataractBlur =
      (props.lensOption === "Cataract vision" || props.lensOption === '')
      ? props.bcva === 'CF'
        ? Math.sqrt((800/20)-1)*3
        : props.bcva === 'HM'
        ? Math.sqrt((2000/20)-1)*3
        : Math.sqrt(((props.bcva*1)/20)-1)*3
        : 0;
      // console.log(cataractBlur + " cataract blur")

    //make select for 0, 1+, 2+, 3+, 4+ NS.  cataractColor the A in RGBA color.  Might have to overlay color on image.
    // let cataractColor =
    //   (props.lensOption === "Cataract vision" || props.lensOption === '')
    //   ? (props.ns/2)
    //   : 0;
    //
    //
    // let sepiaBlur = `
    //     ${(0.393 + 0.607 * [1 - cataractColor])} ${(0.769 - 0.769 * [1 - cataractColor])} ${(0.189 - 0.189 * [1 - cataractColor])} 0 0
    //     ${(0.349 - 0.349 * [1 - cataractColor])} ${(0.686 + 0.314 * [1 - cataractColor])} ${(0.168 - 0.168 * [1 - cataractColor])} 0 0
    //     ${(0.272 - 0.272 * [1 - cataractColor])} ${(0.534 - 0.534 * [1 - cataractColor])} ${(0.131 + 0.869 * [1 - cataractColor])} 0 0
    //     0 0 0 1 0
    //     `
        // console.log(sepiaBlur + " sepia blur")




    // Use equipmentPreference for astigmatism
    let equipmentPreferenceAstig =
      props.equipmentPreference === props.biometer
      ? props.biometerAstig
      : props.equipmentPreference === props.topographer
        ? props.topographerAstig
        : ''
        // console.log(props.biometer)
        // console.log(props.topographer)
        // console.log(props.equipmentPreference)
        // console.log(equipmentPreference)
        // console.log(equipmentPreferenceAstig)


    // Use equipmentPreference for axis
    let equipmentPreferenceAxis =
      props.equipmentPreference === props.biometer
      ? props.biometerAxis
      : props.equipmentPreference === props.topographer
        ? props.topographerAxis
        : ''
        // console.log(props.biometer)
        // console.log(props.topographer)
        // console.log(props.equipmentPreference)
        // console.log(equipmentPreference)
        // console.log(equipmentPreferenceAxis)



    //  if preop, use props.cylinder.  if postop, use equipmentPreferenceAstig
    let cylAstigx =
      props.cylinder === '' ||
      props.cylinder === 'sph'||
      props.cylinder === 'sphere' ||
      props.axis === ''
      ? 0
      : (props.cylinder)*Math.cos(props.axis*(Math.PI/180))

    let cylAstigy =
      props.cylinder === '' ||
      props.cylinder === 'sph'||
      props.cylinder === 'sphere' ||
      props.axis === ''
      ? 0
      : (props.cylinder)*Math.sin(props.axis*(Math.PI/180))

    let topographerAstigX =
      equipmentPreferenceAstig === '' ||
      equipmentPreferenceAstig === 'sph' ||
      equipmentPreferenceAstig === 'sphere' ||
      equipmentPreferenceAxis === ''

      ? 0
      : (equipmentPreferenceAstig)*Math.cos(equipmentPreferenceAxis*(Math.PI/180))

    let topographerAstigY =
      equipmentPreferenceAstig === '' ||
      equipmentPreferenceAstig === 'sph' ||
      equipmentPreferenceAstig === 'sphere' ||
      equipmentPreferenceAxis === ''
      ? 0
      : (equipmentPreferenceAstig)*Math.sin(equipmentPreferenceAxis*(Math.PI/180))

    let astigmatismx  =
      props.lensOption === 'Cataract vision'
      ? cylAstigx
      : (
          lensCorrectsAstigmatism === 'Yes'
          &&
          currentLens[0].lens_astig_min*1 <= equipmentPreferenceAstig
          &&
          currentLens[0].lens_astig_max*1 >= equipmentPreferenceAstig
        )
        ||
        props.cri === true
        ? 0
        : (
            lensCorrectsAstigmatism === 'Yes'
            &&
            currentLens[0].lens_astig_min*1 <= equipmentPreferenceAstig
            &&
            currentLens[0].lens_astig_max*1 < equipmentPreferenceAstig
          )
          ? (equipmentPreferenceAstig*1-currentLens[0].lens_astig_max*1)*Math.cos(equipmentPreferenceAxis*(Math.PI/180))
          : topographerAstigX
        // console.log(astigmatismx + " x astigmatism")

      let astigmatismy  =
        props.lensOption === 'Cataract vision'
        ? cylAstigy
        : (
            lensCorrectsAstigmatism === 'Yes'
            &&
            currentLens[0].lens_astig_min*1 <= equipmentPreferenceAstig
            &&
            currentLens[0].lens_astig_max*1 >= equipmentPreferenceAstig
          )
          ||
          props.cri === true
          ? 0
          : (
              lensCorrectsAstigmatism === 'Yes'
              &&
              currentLens[0].lens_astig_min*1 <= equipmentPreferenceAstig
              &&
              currentLens[0].lens_astig_max*1 < equipmentPreferenceAstig
            )
            ? (equipmentPreferenceAstig*1-currentLens[0].lens_astig_max*1)*Math.sin(equipmentPreferenceAxis*(Math.PI/180))
            : topographerAstigY
        // console.log(astigmatismy + " y astigmatism")


    let biometerTopographerAstigDiff =
      Math.abs(props.biometerAstig*1 - props.topographerAstig*1)
      //console.log(biometerTopographerAstigDiff)

    // need to make input of 180 = 0 and input of '' not count
    let biometerTopographerAxisDiff;
    props.biometerAxis*1 >= 165 && props.topographerAxis*1 <= 15
      ? biometerTopographerAxisDiff = ((props.topographerAxis*1 + 180)-props.biometerAxis*1)
      : props.biometerAxis*1 <= 15 && props.topographerAxis*1 >= 165
        ? biometerTopographerAxisDiff = ((props.biometerAxis*1 + 180) - props.topographerAxis*1)
        : biometerTopographerAxisDiff = Math.abs(props.biometerAxis*1 - props.topographerAxis*1)

    // console.log(biometerTopographerAxisDiff)

    // const containerRef = useRef(null);

    // const [containerHeight, setContainerHeight] = useState(containerRef.current && containerRef.current.offsetWidth*0.51);

    // console.log(containerHeight)

    // useEffect(() => {
    //   const handleResize = () => {
    //     const backImage = document.querySelector('.distanceImage');
    //     setContainerHeight(backImage.offsetHeight);
    //   };
    //   window.addEventListener('resize', handleResize);
    //   return () => window.removeEventListener('resize', handleResize);
    // }, []);

    const [containerHeight, setContainerHeight] = useState(0);
    const containerRef = useRef(null);

    const updateHeight = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setContainerHeight(width * 0.52722);
      }
    };

    useEffect(() => {
      updateHeight();

      window.addEventListener('resize', updateHeight);
      return () => window.removeEventListener('resize', updateHeight);
    }, []);



    
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])


    const handleProfileSubmit = async () => {
      try {
        const res = await api.post("/premiumsimulator", {
        profile: 'full'
        });
        
        if (res.status === 200) {
            
            if(res.data[0].rows.length > 0){
                props.setLensData(res.data[0].rows)
                }

            // console.log(res.data[2].rows)
            if (res.data[2].rows.length > 0){
              props.setCriBioTopoAstigMax(res.data[2].rows[0].cri_bio_topo_astig_max)
              props.setCriBioTopoAxisMax(res.data[2].rows[0].cri_bio_topo_axis_max)
              props.setCriOffer(res.data[2].rows[0].cri_offer)
              props.setFlacsOffer(res.data[2].rows[0].flacs_offer)
            } 
            
            if(res.data[3].rows.length > 0){
                props.setBiometer(res.data[3].rows[0].biometer_name)
                props.setTopographer(res.data[3].rows[0].topographer_name)
                props.setEquipmentPreference(res.data[3].rows[0].equipment_preference)
                props.setSphAbTarget(res.data[3].rows[0].sph_ab_target)
                props.setHoaMaxPreference(res.data[3].rows[0].hoa_max_preference)
            }
        }
      } catch (error) {
      console.error(`Error: ${error.message}`);
      }
    };

    useEffect(() => {
    handleProfileSubmit();
    }, [])

    
    //Paralax scrolling for images===============================
    // const [scrollY, setScrollY] = useState(0);

    // useEffect(() => {
    //   const handleScroll = () => {
    //     setScrollY(window.scrollY);
    //   };
  
    //   window.addEventListener('scroll', handleScroll);
  
    //   return () => {
    //     window.removeEventListener('scroll', handleScroll);
    //   };
    // }, []);
  
    // const calculateParallaxOffset = (layer, strength) => {
    //   return -(scrollY * strength) / 100;
    // };
  

  return( 
    <div style={{
      width: '100%',
      padding: '0 0 0px 0',
      margin: "120px 0 0 0"
      }}>
        <div style={{ 
          display: 'flex', flexDirection: 'column'
          }}>

          <div
            style={{
              height: containerHeight,
              position: 'relative',
            }}
            ref={containerRef}
            >
            <LensPickerComparison
                biometerTopographerAstigDiff={biometerTopographerAstigDiff}
                biometerTopographerAxisDiff={biometerTopographerAxisDiff}
                lensData={props.lensData}
                choseBestLens={props.choseBestLens}
                setChoseBestLens={props.setChoseBestLens}
                lensOption={props.lensOption}
                setLensOption={props.setLensOption}
                lensHospitalFee={lensHospitalFee}
                setLensHospitalFee={setLensHospitalFee}
                lensPhysicianFee={lensPhysicianFee}
                setLensPhysicianFee={setLensPhysicianFee}
                lensSphAb={lensSphAb}
                contraindications={props.contraindications}
                biometer={props.biometer}
                biometerAstig={props.biometerAstig}
                biometerAxis={props.biometerAxis}
                biometerStdDev={props.biometerStdDev}
                chordMuTotal={props.chordMuTotal}
                topographer={props.topographer}
                topographerAstig={props.topographerAstig}
                topographerAxis={props.topographerAxis}
                topographerSphAb={props.topographerSphAb}
                topographerHoa={props.topographerHoa}
                sphAbTarget={props.sphAbTarget}
                hoaMaxPreference={props.hoaMaxPreference}
                equipmentPreference={props.equipmentPreference}
                physicianName={props.physicianName}
                lensCorrectsNearVision={lensCorrectsNearVision}
                lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
                lensTopographerHoaCutoff={lensTopographerHoaCutoff}
                lensTargetNear={props.lensTargetNear}
                setLensTargetNear={props.setLensTargetNear}
                lensKAstigMin={lensKAstigMin}
                lensKAstigMax={lensKAstigMax}
                lensKAstigResidual={lensKAstigResidual}
                lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
                lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
                lensCriMinAstig={lensCriMinAstig}
                lensCriMaxAstig={lensCriMaxAstig}
                lensCriRequire={lensCriRequire}
                lensCriFee={lensCriFee}
                lensFlacsFee={lensFlacsFee}
                cri={props.cri}
                setCri={props.setCri}
                criOffer={props.criOffer}
                criAlwaysOn={props.criAlwaysOn}
                setCriAlwaysOn={props.setCriAlwaysOn}
                criAllowToggle={props.criAllowToggle}
                setCriAllowToggle={props.setCriAllowToggle}
                criBioTopoAstigMax={props.criBioTopoAstigMax}
                criBioTopoAxisMax={props.criBioTopoAxisMax}
                flacs={props.flacs}
                setFlacs={props.setFlacs}
                flacsOffer={props.flacsOffer}
                lensFlacsRequire={lensFlacsRequire}
                flacsAlwaysOn={props.flacsAlwaysOn}
                setFlacsAlwaysOn={props.setFlacsAlwaysOn}
                flacsAllowToggle={props.flacsAllowToggle}
                setFlacsAllowToggle={props.setFlacsAllowToggle}
                oopTotal={props.oopTotal}
                setOopTotal={props.setOopTotal}
                oopPhysician={props.oopPhysician}
                setOopPhysician={props.setOopPhysician}
                oopHospital={props.oopHospital}
                setOopHospital={props.setOopHospital}
                setPtLensList={props.setPtLensList}
                selectedColor={props.selectedColor}
                setSelectedColor={props.setSelectedColor}
                staticLensListOptions={props.staticLensListOptions}
                onlyUpToDate={props.onlyUpToDate}
                setOnlyUpToDate={props.setOnlyUpToDate}
                onlyCompatible={props.onlyCompatible}
                setOnlyCompatible={props.setOnlyCompatible}
                containerHeight={props.containerHeight}
              />


              <img
                className='nearImage'
                style={{
                  position: 'absolute',
                  zIndex: '5',
                  bottom: `${containerHeight*-0.03 - astigmatismx*2}px`,
                  left:`${containerHeight*-0.06 - astigmatismy*2}px`,
                  width: '30%',
                  opacity:'28%',
                  filter: `blur(${(nearBlur*2.5 + cataractBlur*1)*0.6}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 10)}px, 0)`
                }}
                src={nearImage}
                alt="Near"
                />

              <img
                className='nearImage'
                style={{
                  position: 'absolute',
                  zIndex: '5',
                  bottom: `${containerHeight*-0.03 + astigmatismx*2}px`,
                  left:`${containerHeight*-0.06 + astigmatismy*2}px`,
                  width: '30%',
                  opacity:'28%',
                  filter: `blur(${(nearBlur*2.5 + cataractBlur*1)*0.6}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 10)}px, 0)`
                }}
                src={nearImage}
                alt="Near"
                />

              <img
                className='nearImage'
                style={{
                  position: 'absolute',
                  zIndex: '4',
                  bottom: `${containerHeight*-0.03}px`,
                  left:`${containerHeight*-0.06}px`,
                  width: '30%',
                  opacity:'100%',
                  filter: `blur(${(nearBlur*2.5 + cataractBlur*1)*0.6}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 10)}px, 0)`
                }}
                src={nearImage}
                alt="Near"
                />

              <img
                className='intermediateImage'
                style={{
                  position: 'absolute',
                  zIndex: '3',
                  bottom: `${-10*1 + astigmatismx*2}px`,
                  left:`${20*1-astigmatismy*2}px`,
                  width: '48%',
                  opacity: '28%',
                  filter: `blur(${intermediateBlur*1.5 + cataractBlur*1}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 5)}px, 0)`
                }}
                src={intermediateImage}
                alt="Intermediate"
                />

              <img
                className='intermediateImage'
                style={{
                  position: 'absolute',
                  zIndex: '3',
                  bottom: `${-10*1 +(-1*astigmatismx*2)}px`,
                  left:`${20*1-1*(astigmatismy*2)}px`,
                  width: '48%',
                  opacity: '28%',
                  filter: `blur(${intermediateBlur*1.5 + cataractBlur*1}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 5)}px, 0)`,
                }}
                src={intermediateImage}
                alt="Intermediate"
                />

              <img
                className='intermediateImage'
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  bottom: `-10px`,
                  left:`20px`,
                  width: '48%',
                  opacity: '100%',
                  filter: `blur(${intermediateBlur*1.5 + cataractBlur*1}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 5)}px, 0)`,
                }}
                src={intermediateImage}
                alt="Intermediate"
                />

              <img
                className='distanceImage'
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  bottom: `${astigmatismx*3}px`,
                  left:`${astigmatismy*3}px`,
                  width: '100%',
                  opacity: '28%',
                  filter: `blur(${distanceBlur*1.5 + cataractBlur*1}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 0)}px, 0)`,
                }}
                src={distanceImage}
                alt="Distance"
                />

              <img
                className='distanceImage'
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  bottom: `${0-astigmatismx*3}px`,
                  left:`${0-astigmatismy*3}px`,
                  width: '100%',
                  opacity: '28%',
                  filter: `blur(${distanceBlur*1.5 + cataractBlur*1}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 0)}px, 0)`,
                }}
                src={distanceImage}
                alt="Distance"
                />

              <img
                className='distanceImage'
                style={{
                  position: 'absolute',
                  zIndex: '0',
                  bottom: `0px`,
                  left:`0px`,
                  width: '100%',
                  opacity: '100%',
                  filter: `blur(${distanceBlur*1.5 + cataractBlur*1}px)`,
                  // transform: `translate3d(0, ${calculateParallaxOffset('near', 0)}px, 0)`,
                }}
                src={distanceImage}
                alt="Distance"
                />

              <div
                style={{
                  position: 'absolute',
                  zIndex: '6',
                  bottom: `0px`,
                  left:`0px`,
                  width: '100%',
                  height: containerHeight,
                  opacity:
                    props.lensOption === 'Cataract vision'
                    ? `${props.ns*15}%`
                    : '0%',

                  background: '#967724',
                }}
                >
              </div>
              <p
                style={{
                  color: '#292929',
                  textAlign: 'center',
                  padding: '10px 0 10px 0',
                  margin: "0",
                  background: '#FFF',
                  zIndex: '6',
                  position: "absolute",
                  left: "-10px",
                  top: containerHeight,
                  width: '102%',
                }}
              >
                *Simulation: results may vary
              </p>
          </div>
        </div>
      </div>

  )
}


// nightGlare image reference = https://www.ophthalmologytimes.com/view/match-trifocal-iol-patient-needs-preferences-best-results



// <ProfilePicker
//   lensOption={props.lensOption}
//   setContraindications={props.setContraindications}
//   setLensData={props.setLensData}
//   setSulcusLens={props.setSulcusLens}
//   setBiometer={props.setBiometer}
//   setTopographer={props.setTopographer}
//   setEquipmentPreference={props.setEquipmentPreference}
//   setSphAbTarget={props.setSphAbTarget}
//   setHoaMaxPreference={props.setHoaMaxPreference}
//   setCriOffer={props.setCriOffer}
//   setCriBioTopoAstigMax={props.setCriBioTopoAstigMax}
//   setCriBioTopoAxisMax={props.setCriBioTopoAxisMax}
//   setCriConsentId={props.setCriConsentId}
//   setFlacsOffer={props.setFlacsOffer}
//   setFlacsConsentId={props.setFlacsConsentId}
// />