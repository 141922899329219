function PreopInstCataract (props) {

    return(
      <div>

        <div>
          <h1 style={{
              textAlign: 'center',
              margin: '0',
            }}>
            AFTER cataract surgery
          </h1>
          <h3 style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '0 0 5px 0',
            }}>
            {props.fName} {props.lName}  <span style={{margin: '0 0 0 50px'}}></span>  {props.dob}
          </h3>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              border: '1px solid #AAB7B8',
              borderRadius: '10px',
              width: '85vw',
              maxWidth: '760px'
            }}>
            <div style={{
                display: 'flex',
                padding: '5px',
              }}>
              <div style={{
                width: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '3px',
                }}>
                <img style={{height: '60px'}} src="ofloxacin.png" alt=""></img>
              </div>
              <div style={{
                width: '150px',
                background: '#FEF5E7',
                borderRadius: '5px',
                margin: '5px 5px 0 0',
                padding: '5px',
                textAlign: 'center',
                }}>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>Ofloxacin</p>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>(antibiotic)</p>
              </div>
              <div style={{
                width: '400px',
                background: '#FEF5E7',
                borderRadius: '5px',
                margin: '5px 0 0 0',
                padding: '5px',
                }}>
                <p style={{fontSize: '14px',}}>1 drop, 4 times per day for 1 week, then STOP</p>
              </div>
            </div>
            <div style={{
                display: 'flex',
                padding: '5px',
              }}>
              <div style={{
                width: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '3px',
                }}>
                <img style={{height: '60px'}} src="PFpink.png" alt=""></img>
                <p
                  style={{margin: '5px'}}
                  >or</p>
                <img style={{height: '60px'}} src="PFwhite.png" alt=""></img>
              </div>
              <div style={{
                width: '150px',
                background: '#F9EBEA',
                borderRadius: '5px',
                margin: '5px 5px 0 0',
                padding: '5px',
                textAlign: 'center',
                }}>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>Prednisolone</p>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>(steroid)</p>
              </div>
              <div style={{
                width: '400px',
                background: '#F9EBEA',
                borderRadius: '5px',
                margin: '5px 0 0 0',
                padding: '5px',
                }}>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>
                  1 drop, 4 times per day for 1 week, then
                </p>
                <p style={{
                    margin: '0 0 0 50px',
                    fontSize: '14px',
                  }}>
                  3 times per day for 1 week, then
                </p>
                <p style={{
                    margin: '0 0 0 50px',
                    fontSize: '14px',
                  }}>
                  2 times per day for 1 week, then
                </p>
                <p style={{
                    margin: '0 0 0 50px',
                    fontSize: '14px',
                  }}>
                  1 times per day for 1 week, then STOP
                </p>
              </div>
            </div>

            <div style={{
                display: 'flex',
                padding: '5px',
              }}>
              <div style={{
                width: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '3px',
                }}>
                <img style={{height: '60px'}} src="ketorolac.png" alt=""></img>
              </div>
              <div style={{
                width: '150px',
                background: '#F2F4F4',
                borderRadius: '5px',
                margin: '5px 5px 0 0',
                padding: '5px',
                textAlign: 'center',
                }}>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>Ketorolac</p>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>(NSAID)</p>
              </div>
              <div style={{
                width: '400px',
                background: '#F2F4F4',
                borderRadius: '5px',
                margin: '5px 0 0 0',
                padding: '5px',
                }}>
                <p style={{
                    margin: '0',
                    fontSize: '14px',
                  }}>
                  1 drop, 4 times per day for 4 weeks, then STOP
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '3px',
              fontSize: '14px',
            }}>
            *Wait at least 5 minutes between drops and shake the bottles before use.
          </p>
          <p style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '3px',
              fontSize: '14px',
            }}>
            *4 times per day can be breakfast, lunch, dinner, and bedtime
          </p>
        </div>

        <div style={{
            display: 'flex'
          }}>
          <div style={{width: '7vw'}}></div>
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '800px',
              width: '86vw',
            }}>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <h3 style={{margin: '20px 0 3px 0'}}>First 24 hours after surgery</h3>
              <ul style={{margin: '0', fontSize: '14px',}}>
                <li>You may have a mild scratchy sensation and your vision will be a little blurry</li>
                <li>Leave your sheild in place to protect your eye.</li>
                <li>Take a nap after your procedure and then take it easy the rest of the day.</li>
                <li>You can use over-the-counter pain medication for mild pain.</li>
                <li>Call immediately if your pain persists or worsens despite the treatment above</li>
                <li>Call immediately if you have a sudden decrease in vision</li>
                {props.clinicPhone === ''
                  ? ''
                  : <li>Phone number <strong>{props.clinicPhone}</strong></li>
                }
              </ul> 
            </div>

            <div>
              <h3 style={{margin: '20px 0 3px 0'}}>First week after surgery</h3>
              <ul style={{margin: '0', fontSize: '14px',}}>
                <li>No lifting more than 10 lbs (~1 gallon of milk)</li>
                <li>No bending over with your head below your heart (bend with your knees)</li>
                <li>You can shower normally (without the shield), but should avoid swimming, baths, or hot tubs.</li>
                <li>Wear your shield when sleeping</li>
                <li>Wear sunglasses when outside to protect your eyes.</li>
                <li>No make-up on eyelids/lashes.</li>
              </ul>
            </div>
          </div>
          <div style={{width: '7vw'}}></div>
        </div>
      </div>
    )};


export default PreopInstCataract
