//I need to have an editorState functional component for each of the sections I want to create in the bedsheet.  Then, I need to allow for checking or unchecking whether or not to use that component. Would be nice if you could add the component in the order you want and the frequency you want.  Then on the main bedsheet, have conditional rendering so that only things you want are rendered.

import React, { useState, useEffect } from 'react';
import api from "../../services/api";

import Navbar from '../../partials/Navbar';
import 'draft-js/dist/Draft.css';
import '../consents/consentStyle.css';
import FormSelect from '../../partials/FormSelect'
import FormInput from '../../partials/FormInput'
import WaterMark from '../../partials/WaterMark';
import BedsheetEditor from './partials/BedsheetEditor'

export default function BedsheetEdit (props) {



  //Create the state hooks you need for the bedsheet
  const [bedsheetData, setBedsheetData] = useState('')
  const [bedsheetId, setBedsheetId] = useState('')
  const [bedsheetName, setBedsheetName] = useState('')
  const [bedsheetUse, setBedsheetUse] = useState('Yes')
   


  const updateBedsheetData = (i, newData) => {
    setBedsheetData(prevData => {
      // Create a copy of the previous bedsheetData array
      const updatedData = [...prevData];
      // Update the specific element at the provided i with the new data
      updatedData[i] = {...updatedData[i], data: newData};
      // Return the updated array
      return updatedData;
    });
  };

  const handleAddFullRow = () => {
    setBedsheetData(prevData => {
      const newRow = {
        split: 'false',
        isFocused: prevData.length === 0 ? 'true' : 'false',
        data: ''
      };
      return [...prevData, newRow];
    });
  };

  const handleAddSplitRow = () => {
    setBedsheetData(prevData => {
      const newRow = {
        split: 'true',
        isFocused: prevData.length === 0 ? 'true' : 'false',
        data: ''
      };
      return [...prevData, newRow];
    });
  };

  const handleDeleteRow = () => {
    setBedsheetData(prevData => {
      // Make a copy of the previous bedsheetData array
      const newData = [...prevData];
      // Remove the last object from the array
      newData.pop();
      // Return the updated array
      return newData;
    });
  };

  let rowEditStyle = {
    margin: "10px 0 0 0",
    padding: "7px 20px",
    border: 'none',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .15)',
    borderRadius: "3px",
    width: "auto",
    textAlign: 'center',
  }

  // Post to bedsheet database====================================================
  const postBedsheet = async(e) => {
    e.preventDefault();
    // alert('post route')
      try {
        await api.post(`/bedsheet` , {
          bedsheetUse,
          bedsheetData,
          bedsheetName,
        }).then(window.location =`/myaccount` )

    } catch (err) {
      console.error(err.message)
    }
  }
 
 
    // Update bedsheet database=========================================================
    const updateBedsheet = async(e) => {
      e.preventDefault();
      // alert('update route')
        try {
          await api.put(`/bedsheet` , {
            bedsheetId,
            bedsheetUse,
            bedsheetData, 
            bedsheetName,
          }).then(window.location = `/myaccount` )
          }
        catch (err) {
         console.error(err.message)
      }
    }
 
 
    // Delete bedsheet from database=======================================================
    const deleteBedsheet = async(e) => {
      e.preventDefault();
        try {
          await api.delete(`/bedsheet` , {
            headers: {
              // Authorization: authorizationToken
            },
            data: {
              bedsheetId: bedsheetId
            }
          }).then(window.location = `/bedsheet`)
        }
        catch (err) {
          console.error(err.message)
        }
    }


 useEffect(() => { 
    setBedsheetData(props.bedsheetData)
    setBedsheetId(props.bedsheetId)
    setBedsheetName(props.bedsheetName)
    setBedsheetUse(props.bedsheetUse)  
 }, [props.bedsheetData, props.bedsheetId, props.bedsheetName, props.bedsheetUse])
 
  
  //=====================================================================================

  return (
    <>
      <Navbar/>
      <div style={{
      display: 'flex',
    }}>
      <div
        style={{
          width: '7vw',
        }}>
      </div>
        <div
          style={{
            width: '84vw',
            position: 'relative'
          }}>
            <div>
              {
                bedsheetData && bedsheetData.map((m,i) => (
                  <BedsheetEditor
                    key={i}
                    margin={
                      i === 0
                      ? '300px 0 0 0'
                      : '0 0 0 0'
                    }
                    padding='10px'
                    border='1px dashed #292929'
                    placeholder='Start typing...'
                    bedsheetData={m.data}
                    setBedsheetData={newData => updateBedsheetData(i, newData)}
                    isFocused={m.isFocused}
                    splitView={m.split}
                    />
                ))
              }
              <div style={{
                width: '800px',
                display: 'flex',
                justifyContent: 'space-between'
                }}>
                <div
                  style={rowEditStyle}
                  onClick={() => handleAddFullRow()}
                >
                  + Full row
                </div>
                <div
                  style={rowEditStyle}
                  onClick={() => handleAddSplitRow()}
                >
                  + Half row (matches operative side)
                </div>
                <div
                  style={rowEditStyle}
                  onClick={() => handleDeleteRow()}
                >
                  Delete bottom row
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: '50px 0 150px 0',
            }}>
              <FormInput
                label="Name this bedsheet:"
                value={bedsheetName}
                setHook={setBedsheetName}
              />
              <FormSelect
                label={'Use bedsheet?'}
                value={bedsheetUse}
                setHook={setBedsheetUse}
                options={['Choose an option...','Yes', 'No']}
              />
              <div
                style={{
                  display: 'flex',
                  margin: '60px 0 0 0',
                  }}>
                <div
                  onClick={(e => {
                    bedsheetId === ''
                      ? postBedsheet(e)
                      : updateBedsheet(e)
                    }
                  )}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#007bff',
                    color: '#fff',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Save
                </div>
                <div
                  onClick={() => window.location = `/myaccount`}
                  style={{
                    borderRadius: '5px',
                    width: '100px',
                    margin: '5px',
                    padding: '7px',
                    background: '#292929',
                    color: '#FFF',
                    fontSize: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  >
                  Cancel
                </div>
                {
                  (bedsheetId === '')
                  ? null
                  :
                  <div
                      onClick={(e => deleteBedsheet(e))}
                      style={{
                        borderRadius: '5px',
                        width: '100px',
                        margin: '5px',
                        padding: '7px',
                        background: '#F25835',
                        color: '#FFF',
                        fontSize: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      >
                      Delete
                    </div>
                }
              </div>
          </div>
        </div>
      </div>
    </>
  );
}


