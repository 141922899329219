import React, { useEffect } from 'react';

import { Link } from "react-router-dom";

import glistenings from './images/glistenings.jpeg'
import notPreloaded from './images/notPreloaded.jpeg'
import yellowing from './images/yellowing.jpeg'
import blueblocker from './images/blueblocker.jpeg'
import glasses from '../visionsimulator/images/glasses.jpeg';
import glareIcon from './images/glareIcon.jpeg'
import BackArrow from "../partials/BackArrow";
import monofocalImg from './images/monofocalFocusUnlabeled.jpeg'
import toricImg from './images/toricFocusUnlabeled.jpeg'
import toricEdofImg from './images/edofToricFocusUnlabeled.jpeg'
import edofImg from './images/edofFocusUnlabeled.jpeg'
import toricTrifocalImg from './images/trifocalToricFocusUnlabeled.jpeg'
import trifocalImg from './images/trifocalFocusUnlabeled.jpeg'
import PrintPage from "../partials/PrintPage";

export default function LensGuideAdvanced(props) {
    // console.log(props.staticLensListOptions[0].lensName)



    const handleNewerLens = (e) => {
      const lens = props.staticLensListOptions.find(l => l.lensModel.includes(e));
      if (lens) {
        props.setLensOption(lens.lensName);
      }
    };
  
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const l = props.staticLensListOptions.find(option => option.lensName === props.lensOption);

  let subtitleStyle = {
    display: 'flex',
  } 

  let labelStyle = {
    fontSize: '26px',
    color: '#292929',
    margin: "10px 0 0 0"
  }
  
  let proStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
  }
  
  let conStyle = {
    fontSize: '20px',
    width: '40%',
    margin: '0 20px 0 0',
    alignContent: 'center',
    color: 'red',
  }

  let imgStyle = {
    width: '200px',
    borderRadius: '20px',
  }

  let buttonStyleTrue = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#FFF',
    borderRadius: '10px',
    background: "#007bff",
    margin: '20px 10px 60px 10px',
    padding: '20px 40px',
    cursor: 'pointer',
    width: '340px',
    textAlign: 'center',
    textDecoration: 'none',
    // position: 'fixed',
    // zIndex: '3',
  }
  
let buttonStyleFalse = {
    fontSize: "50px",
    border: '1px solid #007bff',
    color: '#007bff',
    background: '#FFF',
    borderRadius: '5px',
    margin: '20px 10px 20px 10px',
    padding: '10px 0',
    cursor: 'pointer',
}

let columnStyle = {
  width: "48%",
  display:'flex',
  justifyContent: 'center',
}

let rowStyle = {
  display: 'flex', 
  justifyContent: 'space-evenly',
  // borderBottom: "1px solid #292929",
  padding: "8px 0 60px 0",
}
  
let pStyle={
  fontSize: '20px',
  margin: '5px 0 0 0',
  textAlign: 'center',
}


  return (
    <div style={{display:'flex', background: "#FFF", margin: "40px 0 0 0"}}>
      <div style={{width: '7vw'}}></div>
      <div style={{width: '84vw'}}>
        <BackArrow
          linkTo='/comparelenses'
        />
        <div style={{margin: "40px 0 300px 0"}}>
                    <div
                    >
                      <div 
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {
                            (
                              l.lensNearVision === "Yes" 
                              && 
                              l.lensIntermediateVision === 'Yes'
                              &&
                              l.lensKAstigMin === '0'
                            )
                              ? 
                                <img
                                  src={trifocalImg}
                                  style={imgStyle}
                                />
                            : 
                            (
                              l.lensNearVision === "Yes" 
                              && 
                              l.lensIntermediateVision === 'Yes'
                              &&
                              l.lensKAstigMin*1 > 0
                            )
                              ? 
                                <img
                                  src={toricTrifocalImg}
                                  style={imgStyle}
                                />
                            : 
                            (
                              l.lensNearVision === "No" 
                              && 
                              l.lensIntermediateVision === 'Yes'
                              &&
                              l.lensKAstigMin === "0"
                            )
                              ? 
                                <img
                                  src={edofImg}
                                  style={imgStyle}
                                />
                            : 
                            (
                              l.lensNearVision === "No" 
                              && 
                              l.lensIntermediateVision === 'Yes'
                              &&
                              l.lensKAstigMin*1 > 0
                            )
                              ? 
                                <img
                                  src={toricEdofImg}
                                  style={imgStyle}
                                />
                            : 
                            (
                              l.lensNearVision === "No" 
                              && 
                              l.lensIntermediateVision === 'No'
                              &&
                              l.lensKAstigMin*1 > 0
                            )
                              ? 
                                <img
                                  src={toricImg}
                                  style={imgStyle}
                                />
                            : 
                            (
                              l.lensNearVision === "No" 
                              && 
                              l.lensIntermediateVision === 'No'
                              &&
                              l.lensKAstigMin === '0'
                            )
                              ? 
                                <img
                                  src={monofocalImg}
                                  style={imgStyle}
                                />
                            : null
                          }
                        </div>
                        <div style={{margin: "0"}}>
                          <div 
                            style={{
                              display:'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div>
                                <p 
                                  style={{
                                    fontSize: '46px',
                                    color: '#292929',
                                    margin: "20px 0 0 0",
                                    textAlign: 'center',
                                    maxWidth: '600px',
                                  }}
                                >
                                  {l.lensName}
                                </p>
                                {
                                  props.lensTargetNear === true
                                  ? 
                                    <p
                                      style={{
                                        fontSize: '26px',
                                        color: '#292929',
                                        margin:'0',
                                        textAlign: 'center',
                                      }}
                                    >
                                      Set for near
                                    </p>
                                  : null
                                }
                                
                                {
                                  (props.staticLensListOptions) && props.staticLensListOptions.map((c,i) => 
                                    (
                                      c.lensName === props.lensOption
                                      &&
                                      c.moreRecent
                                    )
                                    ? 
                                      <p 
                                        key={i}
                                        style={{
                                          fontSize: '26px',
                                          color: '#292929',
                                          margin: "10px 0 0 10px"
                                        }}
                                      >
                                        The 
                                          <span
                                            onClick={(e) => handleNewerLens(c.moreRecent)}
                                            style={{
                                              fontSize: '26px',
                                              color: '#007bff',
                                              margin: "0 7px",
                                              fontWeight: '600',
                                              cursor: 'pointer',
                                            }}
                                          >
                                          {c.moreRecent} 
                                          </span>
                                        lens is more up-to-date
                                      </p>
                                    : null
                                )}
                                
          
                          </div>
            
                        </div>
                      </div>
                      
                      
                      <div>
                        <div 
                          style={{
                            display: 'flex',
                            margin: '40px 0 0 0'
                            }} 
                        >
                          <div 
                            style={{
                              width: '120px',
                            }}
                          >
                            
                          </div>
                        </div>
                        








              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                      style={{
                        width: '50%',
                        textAlign: 'center',
                      }}
                    >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: "0",
                          }}>
                            ${
                              props.cri && props.cri === true
                              ? (l && l.prosCons[0].cost*1 + 800)
                              : (l && l.prosCons[0].cost*1)
                            }
                        </p>
                      <p style={pStyle}>  
                        {
                          props.cri && props.cri === true
                          ? 'Estimated extra out-of-pocket per eye' 
                          : 'Estimated extra out-of-pocket per eye'
                        } 
                      </p>  
                  </div>        
                </div>
              </div>
              

              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}>
                        {l && l.prosCons[0].glasses}
                    </p>   
                    <img
                      src={glasses}
                      style={l && l.prosCons[0].glasses === 'Some'
                        ? 
                          {
                            width: '60%',
                            borderRadius: '8px',
                            opacity: "0.5",
                          }
                        : l && l.prosCons[0].glasses === 'Rare'
                        ? 
                          {
                            width: '60%',
                            borderRadius: '8px',
                            opacity: "0.1",
                          }
                        : 
                          {
                            width: '60%',
                            borderRadius: '8px',
                            opacity: "1",
                          }}
                      alt='Need for glasses'
                    />
                    <p style={pStyle}>
                        need for glasses after surgery
                    </p>
                  </div>
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                      style={{
                        width: '50%',
                        textAlign: 'center',
                      }}
                    >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}>
                        {l && l.prosCons[0].halos}
                    </p> 
                    <img
                      src={glareIcon}
                      style={l && l.prosCons[0].halos === 'Mild'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.4",
                          }
                        : l && l.prosCons[0].halos === 'Minimal'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.1",
                          }
                        : 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.8",
                          }}
                      alt='Glare and halos'
                    /> 
                    <p style={pStyle}>
                      glare and halos around lights after surgery
                    </p> 
                  </div>            
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}>
                        {l && l.prosCons[0].blueblocker}
                    </p>   
                    <img
                      src={blueblocker}
                      style={l && l.prosCons[0].blueblocker === 'Does not'
                        ? 
                          {
                            width: '30%',
                            borderRadius: '8px',
                            opacity: "0.1",
                          }
                        : 
                          {
                            width: '30%',
                            borderRadius: '8px',
                            opacity: "1",
                          }}
                      alt='Blue blocker'
                    />         
                    <p style={pStyle}>
                      block blue light
                    </p>  
                  </div>
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                    <p 
                      style={{
                        fontSize: '50px', 
                        fontWeight: '600px',
                        margin: '0',
                      }}
                    >
                      {l && l.prosCons[0].yellowing}
                    </p> 
                    <img
                        src={yellowing}
                        style={l && l.prosCons[0].yellowing === 'No'
                          ? 
                            {
                              width: '30%',
                              borderRadius: '8px',
                              opacity: "0.1",
                            }
                          : 
                            {
                              width: '30%',
                              borderRadius: '8px',
                              opacity: "0.4",
                            }}
                        alt='Yellowing'
                      />     
                    <p style={pStyle}>
                      yellowing of vision
                    </p> 
                  </div>        
                </div>
              </div>


              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                  <p 
                    style={{
                      fontSize: '50px', 
                      fontWeight: '600px',
                      margin: '0',
                    }}
                  >
                    {l && l.prosCons[0].preloaded}
                  </p> 
                  <img
                      src={notPreloaded}
                      style={l && l.prosCons[0].preloaded === 'Minimal'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.2",
                          }
                        : 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.8",
                          }}
                      alt='Preloaded lens'
                    />
                    <p style={pStyle}>
                      risk of damaging lens when preparing it in the operating room
                    </p>  
                  </div>
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <div
                    style={{
                      width: '60%',
                      textAlign: 'center',
                    }}
                  >
                  <p 
                    style={{
                      fontSize: '50px', 
                      fontWeight: '600px',
                      margin: '0',
                    }}
                  >
                    {l && l.prosCons[0].glistenings}
                  </p> 
                  <img
                      src={glistenings}
                      style={l && l.prosCons[0].glistenings === 'Unlikely'
                        ? 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "0.8",
                          }
                        : 
                          {
                            width: '40%',
                            borderRadius: '8px',
                            opacity: "1",
                          }}
                      alt='Preloaded lens'
                    />
                    <p style={pStyle}>
                      to develop lens glistenings
                    </p>  
                  </div>
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  {
                    l && l.prosCons[0].other
                    ? 
                      <div
                        style={{
                          width: '60%',
                          textAlign: 'center',
                        }}
                      >
                      <p 
                        style={{
                          fontSize: '50px', 
                          fontWeight: '600px',
                          margin: '0',
                        }}
                      >
                        Of Note:
                      </p> 
                        <p style={pStyle}>
                          {l && l.prosCons[0].other}
                        </p>  
                      </div>
                    : null
                  }
                </div>
              </div>






                    </div>
                    <hr
                      style={{
                        fontSize: '26px',
                        margin: '80px auto 0 auto',
                        width: '70%',
                      }}
                      className='dontPrint'
                    />
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '26px',
                        margin: '20px 0 0 0'
                      }}
                      className='dontPrint'
                    >
                      You have reached the end.
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '26px',
                        margin: '20px 0 0 0'
                      }}
                      className='dontPrint'
                    >
                      Thank you for using Cataract Preop!
                    </div>
                    <div
                      className="dontPrint"
                    >
                      <PrintPage/>
                    </div>
                  </div>
  
        </div>
        {
          props.lensOption === 'Cataract vision'
          || 
          props.lensOption === ''
          ? 
            <div>
              No lens has been selected.  Click the back arrow to select a lens.
            </div>
          : null
        }
        
      </div>
      <div style={{width: '7vw'}}></div>
      
    </div>
  )
}




// <div style={subtitleStyle}>
//   <div style={labelStyle}>
//     ({
//       c.examples.map((m, i) =>
//         i === 0
//       ? <span key={i}>{m}</span>
//       : <span key={i}>, {m}</span>
//       )})
//   </div>
// </div>