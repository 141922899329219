import React, { useState, Fragment } from 'react';
import { FaAsterisk } from "react-icons/fa";

export default function HoverMessage(props) {
  const [toggle, setToggle] = useState(false);

  let infoStyle = {
    position: 'absolute',
    right: '-26px',
    top: '8px',
    color: '#F25835',
  };

  return (
    <div style={{ position: 'relative' }}>
      <FaAsterisk
        onMouseEnter={() => setToggle(true)}
        onMouseLeave={() => setToggle(false)}
        style={infoStyle}
        tabIndex="-1"
      />
      {toggle &&
        <div
          style={{
            height: 'auto',
            width: '800px',
            margin: '10px',
            padding: '20px',
            transitionDuration: '0.15s ease-out',
            zIndex: '7',
            position: 'fixed',
            top: '5%',
            left: '300px',
            boxShadow: '-2px 2px 10px gray',
            borderRadius: '10px',
            background: '#FFF',
          }}>
          {props.lensContraWarningList.length > 0 &&
            props.lensContraWarningList.map((c, i) => (
              <p key={i} style={{ color: '#292929', fontSize: '18px' }}>
                <span style={{ fontWeight: '700' }}>{c.name}</span> is contraindicated due to:{' '}
                {c.warning.map((w, j) => (
                  <Fragment key={j}>
                    <span>{w}</span>
                    {j !== c.warning.length - 1 && ', '}
                  </Fragment>
                ))}
              </p>
            ))}
          {props.lensParamWarningList.length > 0 &&
            props.lensParamWarningList.map((p, i) => (
              <p key={i} style={{ color: '#292929', fontSize: '18px' }}>
                <span style={{ fontWeight: '700' }}>{p.name}</span> is contraindicated due to:{' '}
                {p.warning}
              </p>
            ))}
        </div>
      }
    </div>
  );
}
