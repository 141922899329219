import './vf8Questionnaire.css'
import Answers from './Answers'
import SignatureCapture from '../../partials/SignatureCapture'

function Vf8Questionnaire (props) {

let ifYes =
<div className='vf8'>
  <p>If yes, how much difficulty do you currently have?</p>
</div>


    return(
      <div style={{display: 'flex'}}>
      <div style={{width: '3vw'}}></div>
      <div className='vf8' >
        <div style={{
            display: 'flex',
          }}
          className="topHeader">
          <div style={{width: '3vw'}}></div>
          <div style={{
            maxWidth: '800px',
            width: '86vw',
          }}>
          
            <p style={{margin: '10px 30px 0 0'}}>{props.side} eye</p>
            {/* <p style={{margin: '10px 30px 0 0'}}>Lens: {props.lensModel}</p> */}
          </div>
        </div>
        <h3 style={{textAlign: 'center', fontWeight: '600', fontSize: '20px', margin: '0 0 5px 0',}}>
          Pre‐Surgical Cataract Patient Questionnaire
        </h3>
        <h3 style={{textAlign: 'left', fontWeight: '600', fontSize: '16px', margin: '0 0 5px 0',}}>
          Vision Functionality
        </h3>
        <p style={{margin:'0 0 5px 0'}}>
          Do you have difficulty with your {props.side} eye, <span style={{textDecoration: 'underline'}}>even with glasses</span> with the following activities?
        </p>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Reading small print such as labels on medicine bottles, a telephone book or food labels?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q1}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Reading a newspaper or book?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q2}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Seeing steps, stairs or curbs?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q3}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Reading traffic signs, street signs or store signs?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q4}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Doing fine handwork like sewing, knitting or carpentry?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q5}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Writing checks or filling out forms?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q6}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Playing games such as bingo, dominos, or cards?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q7}
            />
        </div>
        <div style={{display: 'flex', border: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Watching television?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q8}
            />
        </div>


        <h3 style={{textAlign: 'left', fontWeight: '600', fontSize: '16px', margin: '30px 0 5px 0',}}>
          Vision Symptoms
        </h3>
        <p style={{margin:'0 0 5px 0'}}>
          Have you been bothered in the {props.side} eye by any of the following: 
        </p>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Poor night vision?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q9}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
              Glare caused by headlights, streetlights, or bright sunlight?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q10}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Hazy and/or blurry vision?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q11}
            />
        </div>
        <div style={{display: 'flex', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Seeing poorly in dim light?
              </p>
              
            </div>
          </div>
          <Answers
            hook={props.q12}
            />
        </div>
        <div style={{display: 'flex', border: '1px solid black', width: '720px'}}>
          <div>
            <div style={{width: '400px', margin: '5px'}}>
              <p style={{fontWeight: '400', margin: '10px 0 0 10px',}}>
                Poor color vision?
              </p>
            </div>
          </div>
          <Answers
            hook={props.q13}
            />
        </div>
        <h3 style={{textAlign: 'left', fontWeight: '600', fontSize: '16px', margin: '20px 0 5px 0', maxWidth: '800px',
            width: '86vw',}}>
          Cataract surgery can almost always be safely postponed until you feel you need better vision.  If a change in glasses won't improve your vision anymore and if the only way to help you see better is cataract surgery, do you feel your vision problems are bad enough to consider cataract surgery now?
        </h3>
        <Answers
            hook={props.q14}
            />
        <SignatureCapture 
          />
        </div>
      </div>
    )};


export default Vf8Questionnaire
