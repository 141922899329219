import React, { useState, useEffect } from 'react'

import OnOffButton from './OnOffButton'

export default function FormInputWithButton (props) {

  const [onOffButton, setOnOffButton] = useState(props.value)

  const checkOnOff = () => (
    onOffButton === 'turnOn'
    ? props.setHook('dontuse')
    : onOffButton === 'turnOff'
      ? props.setHook('')
      : null
  )

  useEffect(() => {
    checkOnOff()
  }, [onOffButton])

  useEffect(() => {
    setOnOffButton(props.value)
  }, [props.value])

  let inputDivStyle ={
    // display: 'flex',
    position: 'relative',
    margin: '10px 0 20px 0',
  }

  let inputStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '4px 0 4px 108px',
    padding: '5px',
    border: 'none',
    background: '#FFF',
    borderRadius: '7px',
    width: '330px'
  }

  let inputStyleOff = {
    margin: '4px 0 4px 108px',
    padding: '5px',
    border: 'none',
    background: '#F2F3F4',
    borderRadius: '7px',
    width: '330px',
    height: '24px',
  }

  let labelStyle = {
    margin: 'auto 30px auto 0',
    padding: '0 0 0 0px',
    textAlign: 'left',
    fontWeight: '600',
    width: '330px',
  }

  let labelStyleOff = {
    margin: 'auto 30px auto 0',
    padding: '0 0 0 0px',
    textAlign: 'left',
    fontWeight: '600',
    width: '330px',
    color: 'gray',
  }

  let rowStyle = {
    display: 'flex',
  }

  return (
    <div style={inputDivStyle}>
      <div
        style={rowStyle}>
        <OnOffButton
          hook={onOffButton}
          setHook={setOnOffButton}
          value={props.value}
          />
        <p style={
          onOffButton === 'turnOn'  ||
            onOffButton === 'dontuse'
            ? labelStyleOff
            : labelStyle
          }>{props.label}
        </p>
      </div>
        {onOffButton === 'turnOn' ||
          onOffButton === 'dontuse'
          ? <div style={inputStyleOff}>
            </div>
          : <input
              style = {inputStyle}
              value={props.value}
              onChange={(e) => props.setHook(e.target.value)}
              type={`${props.type}`}
              onWheel={(e) => e.target.blur()}
            />
        }
      <hr
        style={{
          width: '450px',
          position: 'absolute',
          left: '0',
          bottom: '-20px',
        }}/>
    </div>
  )
}
