import React, { Fragment, useEffect } from 'react';

import { MdGppGood } from "react-icons/md";
import CriPicker from "./CriPicker"
import FlacsPicker from "./FlacsPicker"
import FeePicker from "./FeePicker"
import NearPicker from "./NearPicker"
import HoverMessage from "./HoverMessage"

export default function LensPickerPatient ( props ) {

  //Filter each lens by patient information parameters and then create a list of all valid lens option names


  // Use equipmentPreference for astigmatism
  let equipmentPreferenceAstig =
    props.equipmentPreference === props.biometer
    ? props.biometerAstig
    : props.equipmentPreference === props.topographer
      ? props.topographerAstig
      : null
      // console.log(props.biometer)
      // console.log(props.topographer)
      // console.log(props.equipmentPreference)
      // console.log(equipmentPreference)
      // console.log(equipmentPreferenceAstig)


  // Create an array where we can push lenses with warnings
  let lensContraWarningList = [];
  let lensParamWarningList = [];


  // Get patient's contraindications
  let ptContraFilter = [];
  props.contraindications.map(item => {
    if(item.value === 'true'){
      return ptContraFilter.push(item.name)
    } else {
      return null
    }
  });

  // Use map to get a simple array of lens name with contraindications
  let basicLensDataFilter = props.basicLensData.length > 0
  ? props.basicLensData.map(itemY => {
      let testArr = []
      Object.entries(itemY.contraindications).map(itemZ => {
        if(itemZ[1].value === 'true'){
          return testArr.push(itemZ[1].name)
        } else {
          return null
        }
      })
      return [{name: itemY.lens_name, contraindications: testArr}];
    })
  : []
  


  //Map through lens name with contraindications array and filter out lenses where contraindication matches patient contraindications
  let basicLensOptionFilter = []
  basicLensDataFilter.map(item => {
    let lensContraindications = item[0].contraindications
    // console.log(lensContraindications)

    let ptContraindications = ptContraFilter
    // console.log(ptContraindications)

    let lensName = item[0].name
    // console.log(lensName)

    let res = lensContraindications.filter(cha =>
      ptContraindications.includes(cha)
      )
      if(res.length <= 0){
        return basicLensOptionFilter.push(lensName)
      } else {
        return lensContraWarningList.push({name: lensName, warning: res})
        //nb: can type {...res} to turn res arrays into objects
      }
    }
  )

  let finalLensWarning = [];
  let addLensWarning =
    lensContraWarningList.map(c =>

      c.warning.map(w =>
        finalLensWarning.push(`${c.name} is not an option because of a history of ${w}`)
      )
    )

  // console.log(lensContraWarningList)
  // console.log(finalLensWarning)

  // console.log(Object.entries(props.basicLensData).map((c) => (
  //   c[1].lens_name
  // )))

  //Create an array where we can push qualifying lenses
  let mainLensList = [];
  
  //Map through users lenses pulled from the database
  let mainLensFilter = Object.entries(props.basicLensData).map((c) => (

    //Is the lens okay to use based on the patient's contraindications
    basicLensOptionFilter.includes(c[1].lens_name)
    ?

    //Allow presbyopia-correcting lenses (non-toric or toric) if corneal astigmatism is more than or equal to the minimum tolerated amount of astigmatism AND less than or equal to the maximum tolerated amount of astigmatism plus the maximum tolerated residual astigmatism
    (
      //Presbyopia-correctiong lens
      (c[1].corrects_intermediate_vision === 'Yes' || c[1].corrects_near_vision === 'Yes')
      &&
      // and is within range of tolerated astigmatism
      (
        equipmentPreferenceAstig*1 >= c[1].lens_astig_min*1
        &&
        (
          equipmentPreferenceAstig*1 <= (c[1].lens_astig_max*1 + c[1].lens_astig_residual*1)
          ||
          (
            equipmentPreferenceAstig*1 < c[1].cri_max_astig*1
            &&
            equipmentPreferenceAstig*1 > c[1].cri_min_astig*1
          )
        )
      )
    )
    

    //Allow any non-presbyopia-correcting toric lenses if corneal astigmatism is more than or equal to the weakest toric power of that lens.  Of note, need to make sure it's not a presbyopia-correcting lens because there is no maximum on corneal astigmatism and a presbyopia-correcting lens cannot have residual uncorrected astigmatism like a purely toric lens can)
    ||
    (
      //Non-presbyopia-correcting lens
      (c[1].corrects_intermediate_vision === 'No' && c[1].corrects_near_vision === 'No')
      &&
      // and corneal astigmatism is more than or equal to weakest toric power for that lens
      equipmentPreferenceAstig*1 >= c[1].lens_astig_min*1
      &&
      //and corneal astigmatism is less than or equal to the strongest power for that lens or the lens has no limit
      (
        (equipmentPreferenceAstig*1 <= (c[1].lens_astig_max*1 + c[1].lens_astig_residual*1))
        ||
        c[1].lens_astig_residual === 'dontuse'
      )
    )

    //Always allow spherical lenses.  Need to include use case scenarios of "dontuse" and "0"
    ||
    (
      //Non-presbyopia-correcting lens
      (c[1].corrects_intermediate_vision === 'No' && c[1].corrects_near_vision === 'No')
      &&
      // and astigmatism is '0' for min astigmatism
      c[1].lens_astig_min === 0
      &&
      // and astigmatism is '0' for max astigmatism
      c[1].lens_astig_max === 0
      &&
      // and residual lens astigmatism is 'dontuse' (no limit)
      c[1].lens_astig_residual === 'dontuse'
    )

      ? c[1].k_sph_ab_residual === 'dontuse'
        ||
        (props.topographerSphAb*1 + c[1].lens_sph_ab*1) >= (props.sphAbTarget*1 -Math.abs(c[1].k_sph_ab_residual*1))
        &&
        (props.topographerSphAb*1 + c[1].lens_sph_ab*1) <= (props.sphAbTarget*1 + Math.abs(c[1].k_sph_ab_residual*1))

        ? c[1].lens_hoa_max === 'dontuse'||
          props.topographerHoa*1 <= c[1].lens_hoa_max*1

          ? c[1].chord_mu_max === 'dontuse'||
            props.chordMuTotal*1 <= c[1].chord_mu_max*1

            ? c[1].biometer_std_dev_max === 'dontuse'||
              props.biometerStdDev*1 <= c[1].biometer_std_dev_max*1

              ? c[1].biometer_topographer_astig_max === 'dontuse'
                ||
                equipmentPreferenceAstig*1 <= c[1].lens_astig_residual*1
                ||
                props.biometerTopographerAstigDiff*1 <= c[1].biometer_topographer_astig_max*1

                ? c[1].biometer_topographer_axis_max === 'dontuse'
                  ||
                  equipmentPreferenceAstig*1 <= c[1].lens_astig_residual*1
                  ||
                  props.biometerTopographerAxisDiff*1 <= c[1].biometer_topographer_axis_max*1

                  //Don't use sulcus lens
                  ? c[1].lens_location === "Posterior chamber (typical)"
                  //all viable lenses have been filtered by this point
                    ? mainLensList.push(c[1].lens_name)
                    : null
                  : lensParamWarningList.push({name: c[1].lens_name, warning: `difference between biometer and topographer axis > ${c[1].biometer_topographer_axis_max} degrees`})
                : lensParamWarningList.push({name: c[1].lens_name, warning: `difference between biometer and topographer astigmatism > ${c[1].biometer_topographer_astig_max} D`})
              : lensParamWarningList.push({name: c[1].lens_name, warning: `biometer standard deviation of keratometry > ${c[1].biometer_std_dev_max}`})
            : lensParamWarningList.push({name: c[1].lens_name, warning: `Chord mu > ${c[1].chord_mu_max} mm`})
          : lensParamWarningList.push({name: c[1].lens_name, warning: `higher order aberration > ${c[1].lens_hoa_max} µm`})
        : lensParamWarningList.push({name: c[1].lens_name, warning: `spherical aberration > ${c[1].k_sph_ab_residual} µm from target residual spherical aberration`})
      : null
    : null
  ))
  // console.log(mainLensList)


  //+++++++++modify mainLensList to only allow 1 monofocal+++++++++++++++++++++++++++++++++++++
  //Create array of only monovisin lenses
  let onlyMonoArr = [];
  let onlyMonoFilter = Object.entries(props.basicLensData).map((c) => (
    //Is the lens part of the viable lens options
    mainLensList.includes(c[1].lens_name)
      //is it a monofocal
    ? c[1].corrects_intermediate_vision === "No"
      && c[1].corrects_near_vision === "No"
      && c[1].lens_astig_max === '0'
      && c[1].lens_astig_min === '0'
      //create new array of only monofocal lenses
      ? onlyMonoArr.push({name: c[1].lens_name, sphAb: c[1].lens_sph_ab*1})
      : null
    : null
  ))
  // console.log(onlyMonoArr)


  //Create variable called finalLensList so it's outside of if function
  let finalLensList = [];
  //If onlyMonoArr is empty, then finalLensList = mainLensList
  if(onlyMonoArr.length < 1){
    mainLensList.map(m => (
      finalLensList.push(m)
    ))
    // console.log(mainLensList)
  } else {
    //If onlyMonoArr has any data, then pick the best lens as follows:

    //Filter onlyMonoArr for monovision IOL that has the spherical aberration closest to zero
    const absMinLensSphAb = onlyMonoArr.reduce((acc, obj) =>
       Math.abs(0 - obj.sphAb) < Math.abs(0 - acc.sphAb) ? obj : acc
      );
      // console.log(absMinLensSphAb)

    //Filter onlyMonoArr for monovision IOL that has the spherical aberration that combines with the corneal spherical aberration to bring the total residual spherical aberration closest to the target total residual spherical aberration
    // *****  Will error on side of POSITIVE spherical aberration  ****
    const absMinResSphAb = onlyMonoArr.reduce((acc, obj) =>
       Math.abs(props.sphAbTarget*1 - (obj.sphAb*1 + props.topographerSphAb*1)) < Math.abs(props.sphAbTarget*1 - (acc.sphAb*1 + props.topographerSphAb*1)) ? obj : acc
      );
      // console.log(absMinResSphAb)
      // console.log(props.topographerSphAb)
      // console.log(props.sphAbTarget)
      // console.log("DCB00: " + ((props.topographerSphAb*1 - 0.26) - props.sphAbTarget*1))
      // console.log("Envista: " + ((props.topographerSphAb*1 - 0.00) - props.sphAbTarget*1))

    //is topographerHoa more than globalHoaMax?
    const bestMonoLens =
      (props.topographerHoa*1 > props.hoaMaxPreference*1)
        //if yes, then go with the IOL with the least aberration
        ? absMinLensSphAb.name
        //if no, then go with the IOL that brings the residual spherical aberration closest to target
        : absMinResSphAb.name

      // console.log(bestMonoLens)

    // go through onlyMonoArr and push all but bestMonoLens to badMonoArr
    let badMonoArr = []
    let badMonoFilter = Object.entries(onlyMonoArr).map((m) => (
      m[1].name !== bestMonoLens
        ? badMonoArr.push(m[1].name)
        : null
      ))
      // console.log(badMonoArr)

    // go through mainLensList and pop monofocal lenses in badMonoArr
    finalLensList = mainLensList.filter( ( el ) => !badMonoArr.includes( el ) );
  }
    
  // console.log(finalLensList)
 
  //============================================================================
  //Set choseBestLens to true if they picked the best lens for their eye
  let chosenArray = []
  let startingArray = finalLensList

  // console.log(startingArray)

  function pushTrifocal(){
    startingArray.map(l => (
      props.basicLensData && props.basicLensData.map(f => {
        if(
          f.lens_name === l 
          && 
          f.corrects_near_vision === 'Yes' 
          && 
          f.corrects_intermediate_vision === 'Yes'
        ){
          chosenArray.push(f.lens_name)
          startingArray = startingArray.filter(item => item !== f.lens_name)
        }
      })
    ))
  }

  // console.log(startingArray)

  function pushEdof(){
    startingArray.map(l => (
      props.basicLensData && props.basicLensData.map(f => {
        if(
          f.lens_name === l 
          && 
          f.corrects_intermediate_vision === 'Yes'
        ){
          chosenArray.push(f.lens_name)
          startingArray = startingArray.filter(item => item !== f.lens_name)
        }
      })
    ))
  } 

  // console.log(startingArray)

  function pushToric() {
    startingArray.map(l => (
    props.basicLensData && props.basicLensData.map(f => {
      if(
        f.lens_name === l 
        && 
        f.lens_astig_min*1 > 0
      ){
        chosenArray.push(f.lens_name)
        startingArray = startingArray.filter(item => item !== f.lens_name)
      }
    })
  ))
  }

  // console.log(startingArray)

  function pushTheRest() { 
    startingArray.map(l => (
    props.basicLensData && props.basicLensData.map(f => {
      if(
        f.lens_name === l 
      ){
        chosenArray.push(f.lens_name)
        startingArray = startingArray.filter(item => item !== f.lens_name)
      }
    })
  ))
  }

  // console.log(startingArray)
  // console.log(chosenArray)

  // console.log(props.basicLensOption)
  // console.log(chosenArray[0])


  useEffect(() => {
    pushTrifocal();
    pushEdof();
    pushToric();
    pushTheRest();
  }, [props.basicLensOption])
  
  

  

  
    

 
  
  // console.log(lensContraWarningList.map(n => (n.warning)))
  // console.log(lensParamWarningList.map(n => (n.name)))


  useEffect(() => {
    props.setPtLensList(chosenArray)
  }, [])
  

  let selectStyle = {
    width: '290px',
    height: '35px',
    fontSize: '18px',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  }

  let buttonAstigStyleFalse = {
    border: '1px solid #F2F3F4',
    padding: '5px 10px 1px 10px',
    margin: '2px 2px -8px 2px',
    fontSize: '18px',
    color: 'lightgray',
    width: '126px',
    height: '26px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
    position: 'absolute',
    right: '158px',
    bottom: '15px',
    zIndex: '8',
    cursor: 'pointer',
  }

  let buttonAstigStyleTrue = {
    border: '3px solid #4CBB17',
    padding: '3px 8px',
    margin: '2px 2px -8px 2px',
    fontSize: '18px',
    fontWeight: '600',
    color: '#4CBB17',
    width: '126px',
    height: '22px',
    borderRadius: '5px',
    background: '#FFF',
    textAlign:'center',
    position: 'absolute',
    right: '158px',
    bottom: '15px',
    zIndex: '8',
    cursor: 'pointer',
  }

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return(
    <Fragment>
      <div style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 0 30px 0',
          position: 'absolute',
          right: '20px',
          bottom: '15px',
          zIndex: '8',
          borderRadius: '10px',
          background: '#fff',
        }}>
        <div style={{
            display: 'flex',
          }}>
          <select
            style={selectStyle}
            onChange = {(e) => props.setBasicLensOption(e.target.value)}
            value = {props.basicLensOption}
            >
            <option>
              Cataract vision
            </option>
            {finalLensList.map((c,i) => (
                <option
                  key={i}
                  >
                    {c}
                </option>
              ))
            }
          </select>
          {
            (
              lensContraWarningList.length > 0 || lensParamWarningList.length > 0
            )
            ?
              <HoverMessage
                lensContraWarningList={lensContraWarningList}
                lensParamWarningList={lensParamWarningList}
                />
            : null
          }
        </div>
      </div>
      {
        props.haveAstig === true
        ? 
          <div
            onClick={() => {
              props.setHaveAstig(false);
              props.setBiometerAstig('0.1');
              props.setTopographerAstig('0.2');
              props.setBasicLensOption('Cataract vision');
            }}
            style= {buttonAstigStyleTrue}
          >
            Astigmatism
          </div>
        : 
          <div
            onClick={() => {
              props.setHaveAstig(true);
              props.setBiometerAstig('2.1');
              props.setTopographerAstig('2.2');
              props.setBasicLensOption('Cataract vision');
            }}
            style= {buttonAstigStyleFalse}
          >
            Astigmatism
          </div>
      }
      <NearPicker
        lensCorrectsNearVision={props.lensCorrectsNearVision}
        lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
        lensOption={props.basicLensOption}
        lensTargetNear={props.lensTargetNear}
        setLensTargetNear={props.setLensTargetNear}
        />
  </Fragment>
  )
}





//
//
// } else{
//   return(
//     <div style={{
//         display: 'flex',
//         flexDirection: 'column',
//         margin: '0 0 30px 0',
//         position: 'absolute',
//         right: '20px',
//         bottom: '15px',
//         zIndex: '8',
//         borderRadius: '10px',
//         background: '#fff',
//       }}>
//       <select
//         style={selectStyle}
//         onChange = {(e) => props.setBasicLensOption(e.target.value)}
//         >
//         <option>
//           Cataract vision
//         </option>
//         <option>
//           Monofocal (distance)
//         </option>
//         <option>
//           Monofocal (near)
//         </option>
//         <option>
//           Toric (distance)
//         </option>
//         <option>
//           Toric (near)
//         </option>
//         <option>
//           EDOF
//         </option>
//         <option>
//           Trifocal
//         </option>
//       </select>
//     </div>
//   )
// }
