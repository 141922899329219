import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { MdOutlineArrowBackIosNew } from "react-icons/md";

export default function BackArrow (props) {

  const[backArrow, setBackArrow]=useState(false)

  let backArrowTrue = {
    color: '#007bff',
    fontSize: '82px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    left: '0px',
    zIndex: '12',
  }

  let backArrowFalse = {
    color: '#007bff',
    fontSize: '80px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    left: '0px',
    zIndex: '12',
    opacity: '0.7',
  }

  return (
    <Link to={props.linkTo}>
      <MdOutlineArrowBackIosNew
        className="dontPrint"
        onMouseOver={() => setBackArrow(true)}
        onMouseLeave={() => setBackArrow(false)}
        style={
          backArrow === true
          ? backArrowTrue
          : backArrowFalse
        }
      />
    </Link>
  )
}
