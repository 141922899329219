import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';

import api from '../services/api';
import { QRCodeSVG } from 'qrcode.react';
import QrCodePhysician from '../partials/QrCodePhysician';

import Header from '../partials/Header'
import BackArrow from '../partials/BackArrow';
import ForwardArrowValidation from '../partials/ForwardArrowValidation'
import ContraindicationsPtInfo from '../contraindications/ContraindicationsPtInfo';
import { BsPrinter } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { IoGlassesOutline } from "react-icons/io5"
import { TbEyeTable } from "react-icons/tb";
import { BsGlobe } from "react-icons/bs";
import { TfiRuler } from "react-icons/tfi";
import CustomSelectThreePremium from './CustomSelectThreePremium';


// import iolIcon from './images/iolIcon.png'
// import LensSearcher from '../purchaseUser/LensSearcher';



export default function EyeInfo ( props ) {

  const baseUrl = `${process.env.REACT_APP_API_URL}`

  let p = (props.power.toLowerCase() === 'plano' ||
    props.power.toLowerCase() === 'pl' ||
    props.power === '')
      ? 0
      : props.power*1;

  let iolPentacamAstig = Math.abs(props.biometerAstig*1 - props.topographerAstig*1)


  let iolPentacamAxis;
  // compensate for fact that 180 = 0
  props.biometerAxis*1 >= 165 && props.topographerAxis*1 <= 15
    ? iolPentacamAxis = ((props.topographerAxis*1 + 180)-props.biometerAxis*1)
    : props.biometerAxis*1 <= 15 && props.topographerAxis*1 >= 165
      ? iolPentacamAxis = ((props.biometerAxis*1 + 180) - props.topographerAxis*1)
      : iolPentacamAxis = Math.abs(props.biometerAxis*1 - props.topographerAxis*1)


  // console.log(iolPentacamAstig)
  // console.log(iolPentacamAxis)


  

  //++++++++++++++ERROR MESSAGE HANDLING++++++++++++++++++++++++++++++++++++++++++++
  //create a hook so that you can store the messages in state so they show up in JSX tags
  const[errorMessage, setErrorMessage]=useState([])

  //create blank array to send error messages
  let errorMessageArr = [];

  //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false

  let axisError =
    (
      props.axis === ''
      &&
      (
        props.cylinder.toLowerCase() === "sphere"
        ||
        props.cylinder.toLowerCase() === "sph"
      )
    )
      ? 'noerror'
      : props.axis

  let propsArr = [
    {name: `${props.side}`, message: '*Please choose operative eye'},
    {name: `${props.power}`, message: '*Please enter glasses prescription power'},
    {name: `${props.cylinder}`, message: '*Please enter glasses prescription cylinder'},
    {name: `${axisError}`, message: '*Please enter glasses prescription axis'},
    {name: `${props.add}`, message: '*Please enter glasses prescription add power'},
    {name: `${props.bcva}`, message: '*Please enter best corrected vision'},
    {name: `${props.ns}`, message: '*Please enter cataract grade'},
    {name: `${props.biometerAstig}`, message: '*Please enter biometer astigmatism'},
    {name: `${props.biometerAxis}`, message: '*Please enter biometer steep axis'},
    {name: `${props.topographerAstig}`, message: '*Please enter topographer astigmatism'},
    {name: `${props.topographerAxis}`, message: '*Please enter topographer steep axis'},
    {name: `${props.topographerSphAb}`, message: '*Please enter topographer spherical aberration ("Sph. Ab.")'},
    {name: `${props.topographerHoa}`, message: '*Please enter topographer higher order aberration'},
  ];

  //if not doing cataract surgery, just need to check for laterality for now
  let noCatArr = [
    {name: `${props.side}`, message: '*Please choose operative eye'},
  ];
  // console.log(propsArr)


  const navigate = useNavigate();

  //Map through props you are interested in and send error message to errorMessageArr if there is an error
  const handleFormValidate = () => {
    const errorMessageArr = [];
  
    if(props.cataractAddOn.some(c => c.value === 'true' && c.name === 'Cataract surgery')){
      Object.entries(propsArr).forEach(p => {
        if (p[1].name === '') {
          errorMessageArr.push(p[1].message);
        }
      });
    } else {
      Object.entries(noCatArr).forEach(p => {
        if (p[1].name === '') {
          errorMessageArr.push(p[1].message);
        }
      });
    }
    
  
    setErrorMessage(errorMessageArr);
  
    if (errorMessageArr.length > 0) {
      const timer = setTimeout(() => {
        setErrorMessage([]);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      // Check if any cataractAddOn has value === 'true' and name === 'Cataract surgery'
      const addOn = props.cataractAddOn.some(c => c.value === 'true' && c.name === 'Cataract surgery');
  
      navigate('/premiumsimulator');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])




  // QR code creation and handling=========================================================
  const [isPrinting, setIsPrinting] = useState(false);


  const handlePrint = async() => {
    encFxn()

    let qrEncryptionKey = props.encryptionKey
    let qrToken = props.qrToken
    let qrDate = new Date();
    console.log(qrDate)

    try {
      const res = await api.post("/qrcodecreate", { 
        qrToken,
        qrEncryptionKey,
        qrDate,
      });

      if (res.status === 200) {
        setIsPrinting(true);
      }
    } catch (err) {
      console.error(err.message)
    }

    setTimeout(() => {
      window.print(); // Trigger the browser's print functionality
      setIsPrinting(false);
    }, 500); // Delay to allow for any asynchronous actions to complete before printing
  };

  const doNothing = () => {

  }


  //create contraindications array
  let contraArray = []
  let contraindications = props.contraindications.map(c => {
    if(c.value === 'true'){
      contraArray.push(c.name)
    }
  })

  //create a string for the QR code using the props below
  let propsData = [
    props.side, 
    props.power, 
    props.cylinder, 
    props.axis, 
    props.add, 
    props.bcva, 
    props.ns, 
    props.biometerAstig, 
    props.biometerAxis, 
    props.topographerAstig, 
    props.topographerAxis, 
    props.topographerSphAb, 
    props.topographerHoa, 
    contraArray,
  ]

  let fillSvgArr = propsData.map(m => 
    `${m}`).join('&')

  // console.log(fillSvgArr)
  
  //generate encryption key
  const CryptoJS = require('crypto-js');
 
  // Generate an encryption key when page loads
  const encKeys = () => {
     const encKey = CryptoJS.lib.WordArray.random(32).toString()
    //  console.log(encKey)
     props.setEncryptionKey(encKey)
 
     //create qrToken
     const qrTok = CryptoJS.lib.WordArray.random(32).toString().replace(/\+/g, "-").replace(/\//g, "_")
     // console.log(qrTok)
     props.setQrToken(qrTok)
  }


  //encrypt when hit print button to give props time to load
  const encFxn = () => {
    //encrypt the props
    // console.log(props.encryptionKey)
    const ciText = CryptoJS.AES.encrypt(fillSvgArr, (props.encryptionKey)).toString().replace(/\+/g, "-").replace(/\//g, "_")
    props.setCipherText(ciText)
  }
  
  
useEffect(() => {
    // encKeys()
  }, []);



//need to use qrCode physician or just create our own composite qrCode here that creates a qrToken that is attached to same profile different from original qr and only has 3 hours 



  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  let backgroundStyle = {
      padding: '0 0 500px 0',
      display: 'flex',
  }

  let inputStyle = {
    border: 'none',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '135px',
    height: '40px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'left',
  }


  let pWarningStyle = {
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

  let warnStyle = {
    border: '2px solid red',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '131px',
    height: '36px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'left',
  }

  let liStyle = {
    fontSize: '20px',
    textAlign: 'left',
    margin: '0 0 10px 10px',
    maxWidth: '800px',
    listStyle: 'none',
  }

  let subtitleStyle = {
    textAlign: 'left',
    padding: '0 0 0 0px',
    fontSize: '18px',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: 'auto 0',
  }

  let labelStyle = {
    textAlign: 'left',
    fontSize: '18px',
    margin: 'auto 0',
    padding: '0 0 0 10px',
  }




  return(
    <div style={backgroundStyle}>
      <BackArrow
        linkTo='/advancedmode'
      />
      <ForwardArrowValidation
          onFormValidate={handleFormValidate}
      />

      <div style={{width: '10vw'}}></div>
          <div 
            style={{
              width: '81vw',
              display: 'flex',
              justifyContent: 'center',
            }}
            >
              <div>
              <Header
                title={"Your Eye Information"}
                subtitle={""}
                />
        
              <div
                style={{
                  display: 'flex',
                  flexDirection:'column',
                  justifyContent: 'start',
                  margin: '0 0 0 0',
                  filter: `blur(${(props.blurAmount)}px)`
                }}>

                  <div>
                    <div
                      style={{
                        display: 'flex',
                        margin: '60px 0px',
                      
                      }}
                    >
                      <div 
                        style={{
                          display: 'flex',
                          width: '30%',
                        }}
                      >
                        <div
                          style={{
                            minWidth: '60px',
                            margin: 'auto 0',
                          }}
                        >
                          <BsEye
                            style={{
                              fontSize: '40px',
                            }}
                          />
                        </div>
                        
                        <p style={subtitleStyle}>Operative Eye </p>
                      </div>
                      <div 
                        style={{
                          display: 'flex',
                          width: '70%',
                          margin: '0 0 0 20px',
                        }}
                      >
                        <select
                          style={{
                            width: '135px',
                            height: '40px',
                            fontSize: '18px',
                            padding: '0 0 0 10px',
                            borderRadius: '10px',
                            outline: 'none',
                          }}
                          value={props.side}
                          onChange={e => props.setSide(e.target.value)}
                          name="side"
                          >
                            <option value=''>Choose...</option>
                            <option value='RIGHT'>RIGHT</option>
                            <option value='LEFT'>LEFT</option>
                        </select>
                      </div>
                    </div>


                    <div
                      style={{
                        display: 'flex',
                        margin: '60px 0px',
                      }}
                    >
                      <div 
                        style={{
                          display: 'flex',
                          width: '30%',
                        }}
                      >
                        <div
                          style={{
                            minWidth: '60px',
                            margin: 'auto 0',
                          }}
                        >
                          <IoGlassesOutline
                            style={{fontSize: '50px'}}
                          />
                        </div>
                        <p style={subtitleStyle}>Glasses prescription</p>
                      </div>
                      

                      <div 
                        style={{
                          display: 'flex',
                          width: '70%',
                          margin: '0 0 0 20px',
                        }}
                      >
                        <div>
                          <p style={labelStyle}>Power</p>
                          <CustomSelectThreePremium
                            info = {[
                              {rowPlace: 'top', data: ['Plano']},
                              {rowPlace: 'left', data: minusPowers},
                              {rowPlace: 'right', data: plusPowers},
                            ]}
                            hook={props.power}
                            setHook={props.setPower}
                            hookName='power'
                            selectWidth='146'
                          />
                        </div>
                          
                        <div>
                          <p style={labelStyle}>Cylinder</p>
                          <CustomSelectThreePremium
                            info = {[
                              {rowPlace: 'top', data: ['Sphere']},
                              {rowPlace: 'left', data: minusPowers},
                              {rowPlace: 'right', data: plusPowers},
                            ]}
                            hook={props.cylinder}
                            setHook={props.setCylinder}
                            hookName='cylinder'
                            selectWidth='146'
                          />
                          
                        </div>
                        <div>
                          <p style={labelStyle}>Axis</p>
                          <input
                            style={inputStyle}
                            value={props.axis}
                            onChange={e => props.setAxis(e.target.value)}
                            placeholder=""
                            type='number'
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                        <div>
                          <p style={labelStyle}>Add</p>
                          <CustomSelectThreePremium
                            info = {[
                              {rowPlace: 'top', data: addPowers},
                            ]}
                            hook={props.add}
                            setHook={props.setAdd}
                            hookName='add'
                            selectWidth='146'
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        margin: '60px 0px',
                      }}
                    >
                      <div 
                        style={{
                          display: 'flex',
                          width: '30%',
                        }}
                      >
                        <div
                          style={{
                            minWidth: '60px',
                            margin: 'auto 0',
                          }}
                        >
                          <TbEyeTable
                            style={{fontSize: '40px'}}
                          />
                        </div>
                        
                        <p style={subtitleStyle}>Best Corrected Vision</p>
                      </div>  
                    <div 
                        style={{
                          display: 'flex',
                          width: '70%',
                          margin: '0 0 0 20px',
                        }}
                      >
                        <div style={{
                            display: 'flex'
                          }}>
                          <p style={{fontSize: '18px', margin: '15px 0 0 0',}}>20/</p>
                          <CustomSelectThreePremium
                            info = {[
                              {rowPlace: 'left', data: visionPowers},
                            ]}
                            hook={props.bcva}
                            setHook={props.setBcva}
                            hookName='xx'
                            selectWidth='100'
                          />
                        </div>
                    </div>
                  </div>

                    <div
                      style={{
                        display: 'flex',
                        margin: '60px 0px',
                      }}
                    >
                      <div 
                        style={{
                          display: 'flex',
                          width: '30%',
                        }}
                      >
                        <div
                          style={{
                            minWidth: '60px',
                            margin: 'auto 0',
                          }}
                        >
                          <TfiRuler
                            style={{
                              fontSize: '36px',
                            }}
                          />
                        </div>
                        <p style={subtitleStyle}>Biometer</p>
                      </div>
                      <div 
                        style={{
                          display: 'flex',
                          width: '70%',
                          margin: '0 0 0 20px',
                        }}
                      >
                        <div>
                          <p style={labelStyle}>Astigmatism (D)</p>
                          <input
                            style={
                              props.biometerAstig >= 0 && props.biometerAstig < 8 || props.biometerAstig === ''
                              ? inputStyle
                              : warnStyle
                            }
                            value={props.biometerAstig}
                            onChange={e => props.setBiometerAstig(e.target.value)}
                            placeholder=""
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <div>
                          <p style={labelStyle}>Steep Axis</p>
                          <input
                            style={
                              props.biometerAxis > 0 && props.biometerAxis < 181 || props.biometerAxis === ''
                              ? inputStyle
                              : warnStyle
                            }
                            value={props.biometerAxis}
                            onChange={e => props.setBiometerAxis(e.target.value)}
                            placeholder=""
                            type='number'
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        margin: '60px 0px',
                      }}
                    >
                      <div 
                        style={{
                          display: 'flex',
                          width: '30%',
                        }}
                      >
                        <div
                          style={{
                            minWidth: '60px',
                            margin: 'auto 0',
                          }}
                        >
                          <BsGlobe
                            style={{fontSize: '36px'}}
                          />
                        </div>
                        <p style={subtitleStyle}>Topographer</p>
                      </div>
                      <div 
                        style={{
                          display: 'flex',
                          width: '70%',
                          margin: '0 0 0 20px',
                        }}
                      >
                        <div>
                          <p style={labelStyle}>Astigmatism (D)</p>
                          <input
                            style={
                                  props.topographerAstig >= 0 && props.topographerAstig < 8 || props.topographerAstig === ''
                                  ? inputStyle
                                  : warnStyle
                                }
                            value={props.topographerAstig}
                            onChange={e => props.setTopographerAstig(e.target.value)}
                            placeholder=""
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <div>
                          <p style={labelStyle}>Steep Axis</p>
                          <input
                            style={
                              props.topographerAxis > 0 && props.topographerAxis < 181 || props.topographerAxis === ''
                              ? inputStyle
                              : warnStyle
                            }
                            value={props.topographerAxis}
                            onChange={e => props.setTopographerAxis(e.target.value)}
                            placeholder=""
                            type='number'
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                        <div>
                          <p style={labelStyle}>Sph. ab.*</p>
                          <input
                            style={
                              props.topographerSphAb >= 0 && props.topographerSphAb < 1.5 || props.topographerSphAb === ''
                              ? inputStyle
                              : warnStyle
                            }
                            value={props.topographerSphAb}
                            onChange={e => props.setTopographerSphAb(e.target.value)}
                            placeholder=""
                            type='number'
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                        <div>
                          <p style={labelStyle}>HOA**</p>
                          <input
                            style={
                              props.topographerHoa >= 0 && props.topographerHoa < 1 || props.topographerHoa === ''
                              ? inputStyle
                              : warnStyle
                            }
                            value={props.topographerHoa}
                            onChange={e => props.setTopographerHoa(e.target.value)}
                            placeholder=""
                            type='number'
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        margin: '60px 0px',
                      }}
                    >
                      <div 
                        style={{
                          display: 'flex',
                          width: '30%',
                        }}
                      >
                        <p 
                        style={{
                          width: '320px',
                          textAlign: 'left',
                          padding: '0 0 0 0px',
                          fontSize: '18px',
                          fontWeight: '600',
                          textTransform: 'uppercase',
                          margin: '0',
                        }}
                        >
                          Eye Conditions:
                        </p>
                      </div>
                      <div 
                        style={{
                          display: 'flex',
                          width: '70%',
                          margin: '0 0 0 20px',
                        }}
                      >
                        <ContraindicationsPtInfo
                          contraindications={props.contraindications}
                          setContraindications={props.setContraindications}
                        />
                      </div>
                    
                    </div>
                    <p>
                      * Spherical aberration should be meaured in microns (µm) over a 6 mm zone
                    </p>
                    <p>
                      ** Higher order aberration should be meaured in microns (µm) over a 4 mm zone.  Substitute HOA with square root of the x and y coma if still using the Atas.
                    </p>
                    <p>
                      Your doctor should know what these measurements are if you are planning to use a trifocal or EDOF lens.
                    </p>
                  </div>
                  {
                    (errorMessage && errorMessage.length > 0)
                    ? 
                      <div
                        style={{
                          justifyContent: 'left',
                          margin: '0 0 50px 280px',
                          padding: '20px',
                          border: '1px solid #292929',
                          borderRadius: '20px',
                          zIndex: '10',
                          position: 'fixed',
                          top: '40px',
                          left: '0',
                          background: '#FFF',
                        }}>
                        {errorMessage.map((m, i) => (
                            <p
                              key={i}
                              style={pWarningStyle}
                              >
                              {m}
                            </p>
                          ))
                        }
                      </div> 
                    : null
                  }
                

                </div>

                <div
                    style={{display: 'flex', justifyContent: 'center',}}
                  >
                    <div>
                        <hr/>
                        <p
                          style={{
                            fontSize: '40px',
                            margin: '40px 0 0 0',
                            textAlign: 'center',
                          }}
                        >
                          Don't have all the information right now?
                        </p>
                        <p
                          style={{
                            fontSize: '30px',
                            margin: '10px 0 0 0',
                            textAlign: 'center',
                          }}
                        >
                          No problem! Just follow these steps:
                        </p>
                        <ul style={{margin: '40px 0 0 0'}}>
                          <li style={liStyle}>
                            1. Click the <span style={{fontWeight: '600'}}>print button</span> below so you have the form and the QR code
                          </li>
                          <li style={liStyle}>
                            2. Ask your <span style={{fontWeight: '600'}}>doctor's office</span> for the information to fill out the form
                          </li>
                          <li style={liStyle}>
                            3. Scan the <span style={{fontWeight: '600'}}>QR code</span> when you have all the information and are using a tablet or desktop computer.
                          </li>
                          <li style={liStyle}>
                            Scanning this new QR code will start an <span style={{fontWeight: '600'}}>additional 3 hours</span> for you to have time to enter your information and explore your lens options.
                          </li>
                        </ul>   
                        <div 
                          style={{
                            display: 'flex', 
                            justifyContent: 'center',
                            margin: '40px 0 0 0',
                            }}>
                          <BsPrinter
                            onClick={(e) => window.print()}
                            style={{
                                color: '#007bff',
                                fontSize: '120px',
                                cursor: 'pointer',
                            }}
                            />
                        </div>   
                        <div 
                          style={{
                            display: 'flex', 
                            justifyContent: 'center',
                            margin: '40px 0 0 0',
                            
                            }}>
                        <QRCodeSVG 
                              style={{
                                  margin: '40px auto',
                              }}
                              value={`${baseUrl}/extrasession/${props.paymentQr}`}
                          />
                        </div>            
                    </div>
                  </div>


              </div>
            </div>
        <div style={{width: '7vw'}}></div>
      
    </div>

  )
}



const minusPowers = [
  '-0.25',
  '-0.50',
  '-0.75',
  '-1.00',
  '-1.25',
  '-1.50',
  '-1.75',
  '-2.00',
  '-2.25',
  '-2.50',
  '-2.75',
  '-3.00',
  '-3.25',
  '-3.50',
  '-3.75',
  '-4.00',
  '-4.25',
  '-4.50',
  '-4.75',
  '-5.00',
  '-5.25',
  '-5.50',
  '-5.75',
  '-6.00',
  '-6.25',
  '-6.50',
  '-6.75',
  '-7.00',
  '-7.25',
  '-7.50',
  '-7.75',
  '-8.00',
  '-8.25',
  '-8.50',
  '-8.75',
  '-9.00',
  '-9.25',
  '-9.50',
  '-9.75',
  '-10.00',
  '-10.25',
  '-10.50',
  '-10.75',
  '-11.00',
  '-11.25',
  '-11.50',
  '-11.75',
  '-12.00',
  '-12.25',
  '-12.50',
  '-12.75',
  '-13.00',
  '-13.25',
  '-13.50',
  '-13.75',
  '-14.00',
  '-14.25',
  '-14.50',
  '-14.75',
  '-15.00',
  '-15.25',
  '-15.50',
  '-15.75',
  '-16.00',
  '-16.25',
  '-16.50',
  '-16.75',
  '-17.00',
  '-17.25',
  '-17.50',
  '-17.75',
  '-18.00',
  '-18.25',
  '-18.50',
  '-18.75',
  '-19.00',
  '-19.25',
  '-19.50',
  '-19.75',
  '-20.00',
  '-20.25',
  '-20.50',
  '-20.75',
  '-21.00',
  '-21.25',
  '-21.50',
  '-21.75',
  '-22.00',
  '-22.25',
  '-22.50',
  '-22.75',
  '-23.00',
  '-23.25',
  '-23.50',
  '-23.75',
  '-24.00',
  '-24.25',
  '-24.50',
  '-24.75',
  '-25.00',
  '-25.25',
  '-25.50',
  '-25.75',
  '-26.00',
  '-26.25',
  '-26.50',
  '-26.75',
  '-27.00',
  '-27.25',
  '-27.50',
  '-27.75',
  '-28.00',
  '-28.25',
  '-28.50',
  '-28.75',
  '-29.00',
  '-29.25',
  '-29.50',
  '-29.75',
  '-30.00',
]

const plusPowers = [
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
  '+4.00',
  '+4.25',
  '+4.50',
  '+4.75',
  '+5.00',
  '+5.25',
  '+5.50',
  '+5.75',
  '+6.00',
  '+6.25',
  '+6.50',
  '+6.75',
  '+7.00',
  '+7.25',
  '+7.50',
  '+7.75',
  '+8.00',
  '+8.25',
  '+8.50',
  '+8.75',
  '+9.00',
  '+9.25',
  '+9.50',
  '+9.75',
  '+10.00',
]

const cylPowers = [
  'Sphere',
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
  '+4.00',
  '+4.25',
  '+4.50',
  '+4.75',
  '+5.00',
  '+5.25',
  '+5.50',
  '+5.75',
  '+6.00',
  '+6.25',
  '+6.50',
  '+6.75',
  '+7.00',
  '+7.25',
  '+7.50',
  '+7.75',
  '+8.00',
  '+8.25',
  '+8.50',
  '+8.75',
  '+9.00',
  '+9.25',
  '+9.50',
  '+9.75',
  '+10.00',
]

const addPowers = [
  'No add',
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
]

const visionPowers = [
  '20',
  '25',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '100',
  '150',
  '200',
  '400',
  'CF',
  'HM'
]

const cataractGrades = [
  '1',
  '2',
  '3',
  '4',
]




                  // <div
                  //   style={{
                  //     display: 'flex',
                  //     margin: '70px 0px',
                  //   }}
                  // >
                  //   <div 
                  //     style={{
                  //       display: 'flex',
                  //       width: '30%',
                  //     }}
                  //   >
                  //     <div
                  //       style={{
                  //         minWidth: '60px',
                  //         margin: '0',
                  //         position: 'relative'
                  //       }}
                  //     >
                  //       <img
                  //         src={iolIcon}
                  //         style={{
                  //           width:'34px',
                  //           position: 'absolute',
                  //           top: '-20px'
                  //         }}
                  //       />
                  //     </div>
                  //     <p style={{
                  //       textAlign: 'left',
                  //       padding: '0 0 0 0px',
                  //       fontSize: '18px',
                  //       fontWeight: '600',
                  //       textTransform: 'uppercase',
                  //       margin: '4px 0 0 0',
                  //     }}>Planned lens</p>
                  //   </div>
                  //   <div 
                  //     style={{
                  //       display: 'flex',
                  //       width: '70%',
                  //       margin: '0 0 0 20px',
                  //     }}
                  //   >
                  //     <div>
                  //       <LensSearcher
                  //         side={props.side}
                  //         plannedLens={props.plannedLens}
                  //         setPlannedLens={props.setPlannedLens}
                  //         lensModel={props.lensModel}
                  //         setLensModel={props.setLensModel}
                  //         staticLensListOptions={props.staticLensListOptions}
                  //       />
                  //     </div>
                      
                  //   </div>
                  // </div>