import React, { useEffect } from 'react'

import FormSelectMargin from '../partials/FormSelectMargin'
import FormInputWithButton from '../partials/FormInputWithButton'
import FormInputMargin from '../partials/FormInputMargin'

export default function CriQuestions (props) {

  let requireOptions = [
    "Choose an option below",
    "Yes (always)",
    "Optional (always offered)",
    "No (don't offer)",
  ]

  useEffect(() => {
    if(
        props.lensCriRequire === "No (don't offer)"
        ||
        props.lensCriRequire === "Choose an option below"
        ){
      props.setLensCriMinAstig('')
      props.setLensCriMaxAstig('')
      props.setLensCriFee('')
    } 
  }, [props.lensCriRequire]);


  let labelStyle = {
    // margin: 'auto 30px auto 0',
    // padding: '0 0 0 108px',
    textAlign: 'left',
    fontWeight: '600',
    display: 'inline',
  }


  let inputStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    margin: '4px',
    padding: '5px',
    border: 'none',
    background: '#FFF',
    borderRadius: '7px',
    width: '40px'
  }

  let selectDivStyle ={
    // display: 'flex',
    position: 'relative',
    margin: '10px 0 20px 0',
  }

  let selectStyle = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    margin: '4px 0 4px 108px',
    padding: '5px',
    border: 'none',
    background: '#FFF',
    borderRadius: '7px',
    width: '340px'
  }


  return (
    <div>
      <div>
        <p style={{
          margin: 'auto 30px auto 0',
          padding: '0 0 0 108px',
          textAlign: 'left',
          fontWeight: '600',
          width: '330px',
          display: 'inline',
        }}>
          Use CRI for this lens?
        </p>
        <select
          style = {selectStyle}
          value={props.lensCriRequire}
          onChange={e => props.setLensCriRequire(e.target.value)}
          >
          {requireOptions.map(e => (
            <option
              key={e}
              >
              {e}
            </option>
          ))}
        </select>
    
        {
          (
            props.lensCriRequire === "Yes (always)"
            ||
            props.lensCriRequire === "Optional (always offered)"
          )
          ? 
            <div style={{
              margin: 'auto 30px auto 0',
              padding: '0 0 0 108px',
              textAlign: 'left',
              fontWeight: '600',
              width: '330px',
              display: 'inline',
              }}>


            <p style={{display: 'inline'}}>  
              when corneal astigmatism is &gt; 
            </p>
            <input
              value={props.lensCriMinAstig}
              onChange={(e) => props.setLensCriMinAstig(e.target.value)}
              type={'number'}
              style={inputStyle}
              onWheel={(e) => e.target.blur()}
            />
            <p style={{display: 'inline'}}>
              D
            </p>
            <div style={{
              margin: 'auto 30px auto 0',
              padding: '0 0 0 314px',
              textAlign: 'left',
              fontWeight: '600',
              width: '330px',
              }}>
              <p style={{display: 'inline'}}>  
              and &lt; 
              </p>
              <input
                value={props.lensCriMaxAstig}
                onChange={(e) => props.setLensCriMaxAstig(e.target.value)}
                type={'number'}
                style={inputStyle}
                onWheel={(e) => e.target.blur()}
                />
              <p style={labelStyle}>
                D
              </p>
            </div>
            <p
              style={{
                fontWeight: '400',
                fontSize: '14px',
                margin: 'auto 30px auto 0',
                padding: '0 0 0 108px',
                textAlign: 'left',
                width: '360px',
              }}
            >
              For smaller number, remember to subtract any SIA from lowest treatable astigmatism (e.g., 0.5-0.1 = 0.4)
            </p>
            <FormInputMargin
              label="CRI out-of-pocket fee ($):"
              value={props.lensCriFee}
              setHook={props.setLensCriFee}
              type={'number'}
              />
            </div>
          : null

        }
    </div>
  </div>
  )
}


// when corneal astigmatism is more than ${props.lensCriMinAstig} D and less than ${props.lensCriMaxAstig} D?


