export default function Answers (props) {


  let trueStyle = {   
      // border: '1px solid #F25835',
      border: '1px solid #292929',
      borderRadius: '10px',
      margin: '0 3px 0 3px',
      padding: '5px',
      textAlign: 'center',
      fontWeight: '600',
    }

  let falseStyle = {
    margin: '0 3px 0 3px',
    padding: '5px',
    textAlign: 'center',
    color: '#292929'
    }

  return(
    <div style={{width: '350px'}}>
      <div style={{display: 'flex', padding: '5px 0 5px 20px',}}>
        <div style={{display: 'flex', width: '80px'}}>
          <p style={
            props.hook === 'yes'
              ? trueStyle
              : falseStyle
          }>
            Yes
          </p>
        </div>
        <div style={{display: 'flex', width: '80px'}}>
          <p style={
            props.hook === 'no'
              ? trueStyle
              : falseStyle
          }>
            No
          </p>
        </div>
        <div style={{display: 'flex'}}>
        <p style={
            props.hook === 'na'
              ? trueStyle
              : falseStyle
          }>
            Not applicable
          </p>
        </div>
      </div>
    </div>
  )
}
