import React, { Fragment } from 'react';

import '../consentStyle.css';

export default function StyleButton (props) {

  let handleToggle = (e) => {
    e.preventDefault()
    props.onToggle(props.style)
  }

    let styleNormal = {
      color: '#999',
      cursor: 'pointer',
      marginRight: '16px',
      padding: '2px 0',
      display: 'inline-block',
    }

    let styleActive = {
      cursor: 'pointer',
      marginRight: '16px',
      padding: '2px 0',
      display: 'inline-block',
      color: '#5890ff',
    }


  return (
    <Fragment>
      <span
        style={
          props.active
            ? styleActive
            : styleNormal
          }
        onMouseDown={(e) => handleToggle(e)}
        >
        {props.label}
      </span>
    </Fragment>
  )
}
