import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { MdOutlineArrowForwardIos } from "react-icons/md";

export default function ForwardArrow (props) {

  const[forwardArrow, setForwardArrow]=useState(false)

  let forwardArrowTrue = {
    color: '#007bff',
    fontSize: '82px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    right: '0px',
    zIndex: '13',
  }

  let forwardArrowFalse = {
    color: '#007bff',
    fontSize: '80px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    right: '0px',
    zIndex: '13',
    opacity: '0.7',
  }

  let backgroundArrowTrue = {
    color: '#007bff',
    fontSize: '82px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    right: '0px',
    zIndex: '12',
  }

  let backgroundArrowFalse = {
    color: '#007bff',
    fontSize: '80px',
    cursor: 'pointer',
    position: 'fixed',
    top: '10%',
    right: '0px',
    zIndex: '12',
    opacity: '0.7',
  }

  return (
    <>
      <Link to={props.linkTo}>
        <MdOutlineArrowForwardIos
          className="dontPrint"
          onMouseOver={() => setForwardArrow(true)}
          onMouseLeave={() => setForwardArrow(false)}
          style={
            forwardArrow === true
            ? forwardArrowTrue
            : forwardArrowFalse
          }
          />
      </Link>
      <MdOutlineArrowForwardIos
          className="dontPrint"
          style={
            forwardArrow === true
            ? backgroundArrowTrue
            : backgroundArrowFalse
          }
          />
    </>
    
  )
}

// let nextButton = (e) => {
//   (cylinder.toLowerCase() === 'sphere' ||
//   cylinder.toLowerCase() === 'sph'
//     ? 'sphere'
//     : cylinder
//   side === '' ||
//   bat === '' ||
//   allergies === '' ||
//   props.codeStatus === '' ||
//   props.biometerAxis === '' ||
//   props.biometerAstig === '' ||
//   props.topographerAstig === '' ||
//   props.topographerAxis === '' ||
//   topographerSphAb === '' ||
//   topographerHoa === '' ||
//   props.power === '' ||
//   (cylinder !== '' && sphereCheck !== 'sphere' && axis === ''))
//   ? alert("Please check all required inputs for completeness and spelling")
//   :
//   window.location = '/visionsimulator';
// }
