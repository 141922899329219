import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import QuestionBox from './QuestionBox';
import ForwardArrow from '../partials/ForwardArrow';
import BackArrow from '../partials/BackArrow';

export default function Tutorial1 (props) {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
 

    const[tutorialHover, setTutorialHover]=useState(false)
    const[simulatorHover, setSimulatorHover]=useState(false)


    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 500px 0',
        display: 'flex',
    }

    let titleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'center',
        margin: '40px 0 0 0',
    }

    let subtitleStyle = {
        fontSize: '30px',
        color: '#FFF',
        textAlign: 'left',
        margin: '6px 30px 0px 0px',
        width: "30%",
        minWidth: '300px',
    }

    let buttonStyleTrue = {
        // border: '1px solid #007bff',
        color: '#FFF',
        borderRadius: '5px',
        background: "#007bff",
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer', 
      }
      
    let buttonStyleFalse = {
        // border: '1px solid #007bff',
        color: '#007bff',
        background: '#FFF',
        borderRadius: '5px',
        margin: '20px 10px 20px 10px',
        padding: '10px 0',
        cursor: 'pointer',
    }

    let buttonTextStyle = {
        padding: '0',
        margin: '10px 20px',
        textAlign: 'center',
        fontSize: '40px',
    }

    let imgContainerStyle = {
        minWidth: '200px',
    }

    let imgStyle = {
        margin: '10px 10px 0px 10px',
        borderRadius: '5px',
        width: '90%',
    }

    return(
        <div style={backgroundStyle}>   
        {
            (
                props.userType === 'qrpt'
                &&
                props.useVideo === 'true'
            )
            ? <BackArrow
                linkTo='/tutorialvideo'
                />
            : 
            (
                (props.userType === 'md' || props.userType === 'qrmd')
                &&
                props.useVideo === 'true'
            )
            ? <BackArrow
                linkTo='/tutorialvideo'
                />
            : 
            (
                (props.userType === 'md' || props.userType === 'qrmd')
                &&
                props.useVideo === 'false'
            )
            ? <BackArrow
                linkTo='/choice1'
                />
            : null
        }
        <ForwardArrow
            linkTo='/tutorial2'
        />
         
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                
                <div 
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {props.modalNum === 1
                        ? 
                            <QuestionBox
                                question={'Which eye is having surgery first?'}
                                q1="RIGHT"
                                q2="LEFT"
                                setHook={props.setSide}
                                value1={'RIGHT'}
                                value2={'LEFT'}
                                modalNum={props.modalNum}
                                setModalNum={props.setModalNum}
                            />
                        : props.modalNum === 2
                            ? 
                                <QuestionBox
                                    question={'I have read and agree to the Terms of Use'}
                                    q1="YES"
                                    q2="NO"
                                    setHook={props.setTermsOfUse}
                                    value1={'Yes'}
                                    value2={'No'}
                                    modalNum={props.modalNum}
                                    setModalNum={props.setModalNum}
                                />
                            : null
                    }
                </div>
                
                <p style={titleStyle}>What is a cataract?</p>
                <div
                    style={{
                        display: 'flex',
                        margin: "40px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        When we are young, the natural lens of the eye is clear so that light can focus in our eye.
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='clearLensLabeled.jpeg'
                                alt='Clear lens'
                                style={imgStyle}
                            />
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='clearLensFocus.jpeg'
                                alt='Focus through clear lens'
                                style={imgStyle}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        margin: "100px 0 0 0",
                    }}
                >
                    <p style={subtitleStyle}>
                        As we age, the clear lens becomes cloudy and our vision becomes blurry.  This is a cataract.
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                    }}>
                        <div style={imgContainerStyle}>
                            <img
                                src='cataractLabeled.jpeg'
                                alt='Cataract lens'
                                style={imgStyle}
                            />
                        </div>
                        <div style={imgContainerStyle}>
                            <img
                                src='cataractBlur.jpeg'
                                alt='Blurry focus through cataract'
                                style={imgStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 


// During cataract surgery, your cloudy lens is removed and replaced with a new clear artificial lens.


// <div 
// style={{
// display: 'flex',
// justifyContent: 'center',
// margin: "100px 0 0 0"
// }}>
// <Link
//     to='/visionsimulator'
//     style={{ textDecoration: 'none'}}
// >
//     <div 
//         style={
//             tutorialHover === true
//             ? buttonStyleTrue
//             : buttonStyleFalse
//         }
//             onMouseEnter={() => setTutorialHover(true)}
//             onMouseLeave={() => setTutorialHover(false)}
//         >
        
//         <p style={buttonTextStyle}>
//             Skip intro
//         </p>
//     </div>
// </Link>
// </div>