import React, {useState, useEffect} from 'react'

import api from '../services/api'


export default function Analytics() {

    const[ptLensList,setPtLensList] = useState('')
    const[lensOption, setLensOption] = useState('')
    const[whyLens, setWhyLens] = useState('')
    //Get all clinic data
    const getWhy = async() => {

        try {
            const response = await api.get("/survey");

            setPtLensList(response.data.rows[0].pt_lens_list)
            setLensOption(response.data.rows[0].lens_option)
            setWhyLens(response.data.rows[0].why_lens)

        } catch (err) {
        console.error(err.message)
        }
    }

    useEffect(() => {
        getWhy();
        }, []);


    return (
    <div>
        Analytics:
        <p>
            {ptLensList}
        </p>
        <p>
            {lensOption}
        </p>
        <p>
            {whyLens}
        </p>
    </div>
  )
}
