import React from 'react'

import { Link } from 'react-router-dom';

let linkStyle = {
    textDecoration: 'none',
  }

  let tileStyle = {
    background: '#FFF',
    border: 'none',
    borderRadius: '10px',
    padding: '20px 40px',
    margin: '20px 8px 0px 8px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0, .2), 0 3px 10px 0 rgba(0,0,0, .15)',
    cursor: 'pointer',
  }

  let pStyle = { 
    textAlign: 'center',
    fontSize: '24px',
    color: "#595959",
    margin: '10px 0 0 0',
  }

  export default function ChoiceTile(props) {
    return (
      <>
        <Link 
          to={props.linkTo} 
          style={linkStyle}
          onClick={() => props.onPrint()}
        >
          <div style={tileStyle}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {props.linkIcon && (
                <props.linkIcon
                  style={{
                    fontSize: '100px',
                    color: '#595959',
                  }}
                />
              )}
            </div>
            <p style={pStyle}>{props.linkText}</p>
          </div>
        </Link>
      </>
    );
  }
  
