import React, { useState, useEffect } from 'react';

import api from "../services/api";

import VideoEdit from './VideoEdit'
import VideoSearch from './VideoSearch';
import Header from '../partials/Header'
import Navbar from '../partials/Navbar'
import VideoType from './VideoType'
import EducationOnOff from './EducationOnOff';


export default function EducationList (props) {

const[userName, setUserName]=useState('')
const[modal, setModal] = useState('')

  // Post to education database
  const postEducation = async() => {
    // alert('post route')
      const useTutorial = props.useTutorial
      const useVideo = props.useVideo
      try {
        await api.post(`/education` , {
          useTutorial,
          useVideo
        })

    } catch (err) {
      console.error(err.message)
    }
  }



  // Update education database
  const updateEducation = async() => {
    // alert('update route')
    const useTutorial = props.useTutorial
    const useVideo = props.useVideo
      try {
        await api.put(`/education` , {
          useTutorial,
          useVideo
        })
        }
      catch (err) {
       console.error(err.message)
    }
  }


  useEffect(() => {
    if(props.educationPost === false){
      updateEducation()
    } else {
      postEducation()
    } 
  }, [props.useVideo, props.useTutorial])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  let labelStyle = {
    // fontWeight: '600',
    margin: '6px 0 0 0',
    fontSize: '24px',
  }

  return (
    <div style={{
        display: 'flex',
      }}>
      <div
        style={{
          width: '7vw',
        }}>
      </div>
      {
        (modal === '')
        ?
          <div
            style={{
              width: '84vw',
              margin: '0 0 300px 0',
            }}>
            <Navbar
              setUserName={setUserName}/>
            <Header
              title={"Patient Education"}
              />

            <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <div style={{
                  margin: '50px 0 0 0',
                }}>
            
              
              <div
                style={{display: 'flex'}}
              >
                <EducationOnOff
                  hook={props.useTutorial}
                  setHook={props.setUseTutorial}
                />
                <div style={{width: '500px'}}>
                  <p style={labelStyle}>Use Cataract Preop's illustrated tutorial</p>
                  {
                    props.useTutorial === 'true'
                      ? <div>
                          <img 
                            src="screenshotTutorial.jpeg"
                            style={{
                              height: '200px',
                              borderRadius: '10px',
                              margin: '10px',
                            }}
                            />
                        </div>
                      : <div>
                          <img 
                            src="screenshotTutorial.jpeg"
                            style={{
                              height: '200px',
                              borderRadius: '10px',
                              margin: '10px',
                              opacity: '0.1',
                            }}
                            />
                      </div>
                  }
                </div>  
                  
              </div>
              
              
              <div
                style={{
                  display: 'flex',
                  margin: '60px 0 0 0',
                }}
              >
                <EducationOnOff
                  hook={props.useVideo}
                  setHook={props.setUseVideo}
                />
                <div>
                    <p style={labelStyle}>Use your own videos</p>
                    <div style={{width: '500px'}}>
                    {
                      props.useVideo === 'true'
                      ? <div>        
                          <VideoType
                            videoData={props.videoData}
                            videoFilter='Preop'
                            videoTitle='Preop Videos:'
                            setModal={setModal}
                            />
                          <VideoType
                            videoData={props.videoData}
                            videoFilter='Postop'
                            videoTitle='Postop Videos:'
                            setModal={setModal}
                            />
                          <div
                            onClick={() => setModal('new')}
                            style={{
                              borderRadius: '5px',
                              width: '200px',
                              margin: '50px auto 50px auto',
                              padding: '2px 7px 2px 7px',
                              color: '#007bff',
                              border: '1px solid #007bff',
                              fontSize: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                            >
                            +add video
                          </div>

                        </div>
                      : null
                  }
              </div>
            </div>
          </div>
              
              
                
              </div>
            </div>
          </div>
        : (modal === 'new')
            ? <VideoSearch
                videoName={''}
                videoUrl={''}
                videoType={''}
                videoOrder={''}
                videoStart={''}
                videoEnd={''}
                modal={modal}
                setModal={setModal}
                side={props.side}
              />
            : ((props.videoData).length > 0 && modal)
              ?
              (props.videoData).map(v => (
                  (v.video_id === modal)
                  ? <VideoEdit
                      key={v.video_id}
                      videoId={v.video_id}
                      videoName={v.video_name}
                      videoUrl={v.video_url}
                      videoType={v.video_type}
                      videoOrder={v.video_order}
                      videoStart={v.video_start}
                      videoEnd={v.video_end}
                      setModal={setModal}
                      modal={modal}
                      side={props.side}
                    />
                  : null
                ))
              : null
    }
    <div
      style={{
        width: '7vw',
      }}>
    </div>
  </div>
  )
}
